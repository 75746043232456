import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Form, Input, Button, Radio, Space, Checkbox, Select, Modal, Table, DatePicker, Switch, Cascader, message, TreeSelect, Typography, Steps } from 'antd';
import moment from 'moment'
import {CheckOutlined} from '@ant-design/icons'

import Api from "../../../../api";
import SelfTable from "../../../../components/SelfTable"
import {pagingParam} from "../../../../utils/defaultValue";
import BackIcon from "../../../../components/BackIcon/index";
import ExportExcel from '../../../../utils/ExportExcel'
import storage from "../../../../utils/storage";
import { ModalConfirm, toChineseNumber} from '../../../../utils/tool'
import './index.scss'

const { RangePicker } = DatePicker;

const ImportantKnowledgeList = () => {

    const [settingForm] = Form.useForm();
    const [form] = Form.useForm();

    const [searchValue, setSearchValue] = useState('');

    // 分页
    const [pagination, setPagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "total":0
    });

    // 列表数据
    const [listData, setListData] = useState([]);

    const [tableLoading, setTableLoading] = useState(false);


    // 查询数据
    const [searchData, setSearchData] = useState({
        ...pagingParam,
    });

    const [operationData, setOperationData] = useState({});
    

    // 列表选择数据
    const [selectedRowKey, setSelectedRowKey] = useState([]);

    const [settingModalOpen, setSettingModalOpen] = useState(false);
    const [detailsModalOpen, setDetailsModalOpen] = useState(false);
    const [checkedList, setCheckedList] = useState([
        "knowledgeName","fileType","departmentName","knowledgeTypeName","departmentSubclassificationName",
        "authors","creatorName","accessLink"
    ]);
    const [dataRange, setDataRange] = useState([]);
    
    const [confirmLoading, setConfirmLoading] = useState(false);

    const [modalSelectTree, setModalSelectTree] = useState([]);
    const [cascaderValue, setCascaderValue] = useState([]);
    const [knowledgeTypeId, setKnowledgeTypeId] = useState('');
    // 部门
    const [showDepartment, setShowDepartment] = useState(true);
    const [department, setDepartment] = useState([]);
    const [departmentTree, setDepartmentTree] = useState([]);

    // 知识类型
    const [knowledgeList, setKnowledgeList] = useState([]);

    const [readFlow, setReadFlow] = useState([]);
    const [downloadFlow, setDownloadFlow] = useState([]);
    
    const [edictID, setEdictID] = useState([]);
    
    const columns = [
        {
            title: '知识名称',
            dataIndex: 'knowledgeName',
            width: '',
            editable: true,
        },
        {
            title: '格式类型',
            dataIndex: 'fileType',
            width: '90px',
            editable: true,
        },
        {
            title: '知识类型',
            dataIndex: 'knowledgeTypeName',
            width: '90px',
            editable: true,
        },
        {
            title: '所属部门',
            dataIndex: 'departmentName',
            width: '160px',
            editable: true,
        },
        {
            title: '部门分类',
            dataIndex: 'subclassification',
            width: '140px',
            editable: true,
        },
        {
            title: '作者',
            dataIndex: 'authors',
            width: '100px',
            editable: true,
            ellipsis: true,
            render: (text,record) => {
                // 0不是 1是
                return text.join('，');
            }
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            width: '160px',
            sorter: true,
            sortOrder: searchData.sort,
            editable: true,
        },
        {
            title: '是否已标记',
            width: '80px',
            dataIndex: 'isMark',
            render: (status,record) => {
                // 0不是 1是
                return status == 0 ? '' : <CheckOutlined style={{color:'#52c41a'}}/>;
            }
        },
        {
            title: '是否是重要文件',
            width: '80px',
            dataIndex: 'isImportant',
            render: (status,record) => {
                // 0不是 1是
                // return status == 0 ? '不是' : '是';
                return <Switch checked = {status == 1} onChange={(value)=>editList(record, value)}/>
            }
        },
        {
            title: '操作',
            width: '100px',
            dataIndex: 'operation',
            render: (_, record) => {
                return <Space>
                    {record.isImportant == 1 && <Typography.Link 
                    onClick={() => editList(record, true)}
                    >
                        调整
                    </Typography.Link>}
                    {record.isImportant == 1 && <Typography.Link onClick={()=>{
                        
                        let downIdocWf = record.downIdocWf.flowNodeStr.split('->');
                        let readIdocWf = record.readIdocWf.flowNodeStr.split('->');

                        let newDownIdocWf = downIdocWf.map((item,index)=>{
                            return {
                                title: `第${toChineseNumber(index+1)}級`,
                                description:item
                            }
                        })

                        let newReadIdocWf = readIdocWf.map((item,index)=>{
                            return {
                                title: `第${toChineseNumber(index+1)}級`,
                                description:item
                            }
                        })
                        
                        setDetailsModalOpen({
                            'downIdocWf':newDownIdocWf,
                            'readIdocWf':newReadIdocWf
                        });
                    }}>
                        详情
                    </Typography.Link>}
                </Space>
            },
        },
    ];

    useEffect(() => {
        let routeRes = storage.getItem('directory');
        let router = routeRes[1].children;
        if(router[0].perms === "departmentChange"){
            getList();
        }else if(searchData.departmentId){
            getList();
        }
    }, [searchData]);



    useEffect(() => {
        getKnowledgeList();
        // 是否有权限切换部门
        let routeRes = storage.getItem('directory');
        let router = routeRes[1].children
        if(router.length > 0 && router[0].perms === "departmentChange"){
            getAllDepartmentF();
        }else{
            setShowDepartment(false);
            getAllKnowledgeListF(storage.getItem('userInfo').departmentId);
            let newOperationData = {...operationData};
            newOperationData.departmentId = storage.getItem('userInfo').departmentId; 
            setOperationData(newOperationData);
            setSearchData({
                ...searchData,
                departmentId: storage.getItem('userInfo').departmentId
            });
        }

        getFlowList();
    }, []);

    const getFlowList  = async() => {
        const res = await Api.IDoc.getIDocFlowNode({data:{
            flowType:'read'
        }});
        if(res&&res.code === 0){
            let _data = res.data;
            setReadFlow(_data);
        }else{
            setReadFlow([]);
        }

        const resDown = await Api.IDoc.getIDocFlowNode({data:{
            flowType:'down'
        }});
        if(resDown&&resDown.code === 0){
            let _data = resDown.data;
            setDownloadFlow(_data);
        }else{
            setDownloadFlow([]);
        }
    }


    const getAllDepartmentF = async() => {
        //  flag = 1 需要查询班子成员
        const resDepartment = await Api.Department.getDepartmentListAll({data:{
            flag:1
        }});
        if(resDepartment && resDepartment.code === 0){
            let newData = resDepartment.data.map(item=>{
                return{
                    value: item.id,
                    label: item.name,
                    text: item.name,
                }
            });
            newData.push({
                value: '',
                label: '无',
            })
            setDepartment(newData);
        }
    }

    const getAllKnowledgeListF = async(value) => {
        // 知识类型
        const resKnowledge = await Api.Knowledge.getAllKnowledgeList({
            data: { 'departmentId':value },
        });
        if(resKnowledge && resKnowledge.code === 0 && resKnowledge.data){
            let newData = resKnowledge.data.map(item=>{
                return{
                    value: item.id,
                    label: item.knowledge_type_name,
                    text: item.knowledge_type_name,
                }
            });
            setKnowledgeList(newData);
        }else{
            setKnowledgeList([]);
        }
    }

    const getDepartmentTreeF = async(value) => {
        try{
            const res = await Api.Department.getDepartmentTree({data:{
                departmentId:operationData.departmentId,
                knowledgeTypeId:value
            }});
            
            if(res && res.code === 0 && res.data){
                setDepartmentTree(res.data);
            }else{
                setDepartmentTree([]);
            }
        }catch{}
    }

    const handleDepartmentChange = (value) => {
        getAllKnowledgeListF(value);
        let newOperationData = {...operationData};
        newOperationData.departmentId = value; 
        newOperationData.knowledgeTypeId = ""; 
        newOperationData.departmentSubclassificationId = "";
        setOperationData(newOperationData);
    };

    const handleKnowledgeChange = (value) => {
        getDepartmentTreeF(value);
        let newOperationData = {...operationData};
        newOperationData.knowledgeTypeId = value; 
        newOperationData.departmentSubclassificationId = "";
        setOperationData(newOperationData);
    };

    const handleDepartmentSubChange = (value) => {
        let newOperationData = {...operationData};
        newOperationData.departmentSubclassificationId = value;
        setOperationData(newOperationData);
    };


    const getKnowledgeList = async() => {
        try{
            let res = await Api.Knowledge.getPutKnowledgeType({ data: {},});

            if(res && res.code === 0 && res.data){
                let _data = res.data, ids = [];

                _data.map(item=>{
                    item.isLeaf = false;
                    item.subclassification = item.knowledgeTypeName;
                    ids.push(item.id)
                })

                setModalSelectTree(res.data)
                setDefaultKnowledgeType(ids)
            }else{}
        }catch{}
    }

    const getList = async() => {
        setTableLoading(true);
        let newSearchData = {
            ...searchData
        }
        if(newSearchData.sort){
            newSearchData.sort = newSearchData.sort ==='descend' ? 0 : newSearchData.sort === 'ascend' ? 1 : 0
        }
        const res = await Api.IDoc.getIDocPageList({data:newSearchData});
        setTableLoading(false);
        if(res&&res.code === 0){
            let _data = res.data;
            setListData(_data.records);
            setPagination({
                "pageIndex": _data.current,
                "pageSize": _data.size,
                "total":_data.total
            });
        }else{
            setListData([]);
        }
    }

    const editList = (record, value) =>{
        console.log(value);
        if(value){

            settingForm.setFieldsValue({
                readIdocWfId:record.readIdocWfId,
                downIdocWfId:record.downIdocWfId
            });
            setEdictID([record.id]);
            setSettingModalOpen(true);
        }else{
            ModalConfirm({
                title: "确认取消这个重要文件吗？",
                onOk: async (resolve, reject) => {
                  try {
                    let res = await Api.IDoc.cancelIdoc({data:{
                        knowledgeId: record.id
                    }});
                    resolve();
                    if (res.code === 0) {
                        message.success("取消成功");
                        getList();
                    }
                  } catch (error) {}
                },
            });
        }
        
    }

    // 搜索框
    const changeValue = (e) => {
        setSearchValue(e.target.value)
    }

    const onSearch = () => {
        let newSearchData = {...searchData}
        newSearchData.pageIndex = 1;
        newSearchData.knowledgeName = searchValue;
        setSearchData({
            ...newSearchData,
            ...operationData
        });
    }

    const onResetting = () => {
        setSearchValue("");
        setSearchData({
            ...pagingParam,
            "departmentId" : storage.getItem('userInfo').departmentId,
            "knowledgeName":"",
        })
        setOperationData({})
    }


    const onChanges = (pagination, filters, sorter) => {
        let newSearchData = {...searchData}

        newSearchData.pageIndex = pagination.current ? pagination.current : 1;
        newSearchData.pageSize = pagination.pageSize ? pagination.pageSize : 10;

        newSearchData.sort = sorter.order;
        
        if(filters.creatorName){
            newSearchData.creator = filters.creatorName[0]
        }

        setSearchData(newSearchData);
    };


    const exportExcel = () => {
        if(selectedRowKey && selectedRowKey.length > 0){
            queryExportList()
        }else{
            setSettingModalOpen(true);
        }
    }

    const handleOk = async() => {
        
        try{
            const row = await settingForm.validateFields();
           
            let param = {
                'knowledgeIds': edictID,
                ...row
            }

            const res = await Api.IDoc.setIDoc({data:param});
            
            if(res&&res.code === 0){
                message.success('设置成功');
                handleCancel();
                getList();
            }
        }catch(err){console.log(err)}
    };

    const queryExportList = async() => {

        let data = {
            ids: selectedRowKey
        };

        if(dataRange.length >0){
            data.startTime = dataRange[0];
            data. endTime = dataRange[1];
        }

        const res = await Api.Knowledge.getExportInventory({data});
        
        if(res&&res.code === 0){
            let _data = res.data;
            exportList(_data);
        }
    }

    const exportList = async(dataList) => {

        let exportColumns = [];
        columns.map(item=>{
            if(checkedList.includes(item.dataIndex)){
                exportColumns.push(item)
            }
        })

        if(checkedList.includes("accessLink")){
            exportColumns.push({
                title: '访问链接',
                dataIndex: 'accessLink',
            })
        }

        ExportExcel([
            {
                column: exportColumns,
                dataSource: dataList,
                options:{
                    colWidth: 8,
                    fontSize: 10,
                    isBold: true
                }
            }
        ],"已发布知识列表")
    }


    const handleCancel = () => {
        setSettingModalOpen(false);
        settingForm.resetFields();
        setSelectedRowKey([]);
    };

    const onCascaderChange = (value, selectedOptions) => {
        setCascaderValue(value)
        console.log(value, selectedOptions);
        let newOperationData = {...operationData};
        if(value){
            newOperationData.knowledgeTypeId = value[0];
            newOperationData.departmentId = value.length > 1 ? value[1] : ""; 
            newOperationData.departmentSubclassificationId = value.length > 2 ? value[value.length-1] : "";
        }else{
            newOperationData.knowledgeTypeId = ""; 
            newOperationData.departmentId = ""; 
            newOperationData.departmentSubclassificationId = "";
        }
        setOperationData(newOperationData);
    }

     const loadData = async(selectedOptions) => {
            
        const targetOption = selectedOptions[selectedOptions.length - 1];
        targetOption.loading = true;

        console.log(targetOption,"targetOption")
        
        if(targetOption.name){
            getSubcategorization(targetOption);
        }else if(targetOption.knowledgeTypeName){
            getAllDepartment(targetOption);
            setKnowledgeTypeId(targetOption.id);
        }
    }

    const getAllDepartment = async(targetOption) => {
            try{
                const res = await Api.Department.getDepartmentListAll({
                    data:{
                        knowledgeTypeId:targetOption.id
                    }
                });
                targetOption.loading = false;
                if(res && res.code === 0 && res.data){
                    res.data.map(item=>{
                        item.subclassification = item.name;
                        item.isLeaf = false;
                    })
                    
                    targetOption.childList = [...res.data];
                    setModalSelectTree([...modalSelectTree]);
                }else{
                    targetOption.isLeaf = true;
                    targetOption.childList = [];
                    setModalSelectTree([...modalSelectTree]);
                }
            }catch{targetOption.loading = false;}
        }

    const getSubcategorization = async(targetOption) => {
            try{
                const res = await Api.Department.getDepartmentTree({data:{
                    departmentId:targetOption.id,
                    knowledgeTypeId:knowledgeTypeId
                }});
                targetOption.loading = false;
                if(res && res.code === 0 && res.data){
                    res.data.map(item=>{
                        if(targetOption.childList && targetOption.childList.length > 0){
                            item.isLeaf = false;
                        }
                    })
                    targetOption.childList = [...res.data];
                    setModalSelectTree([...modalSelectTree]);
                }else{
                    targetOption.isLeaf = true;
                    targetOption.childList = [];
                    setModalSelectTree([...modalSelectTree]);
                }
            }catch{targetOption.loading = false;}
        }


    const handleDetailsCancel = () => {
        setDetailsModalOpen(false)
    }

    return (
        <div className="importantKnowledgeListFrame">
             <div className="title">已发布知识</div>
            <div className="operation">
                <div className="operationLeft">
                    {showDepartment&&<div className="operationItem">
                        <span className='operationTitle'>所属部门：</span>
                        <Select
                            style={{width: 150}}
                            value={operationData.departmentId}
                            onChange={(value)=>{
                                handleDepartmentChange(value)
                            }}
                            options={department}
                            placeholder="所属部门"
                            allowClear
                        />
                    </div>}
                    
                    <div className="operationItem">
                        <span className='operationTitle'>知识类型：</span>
                        <Select
                            style={{width: 150,}}
                            value={operationData.knowledgeTypeId}
                            onChange={(value)=>{
                                handleKnowledgeChange(value)
                            }}
                            options={knowledgeList}
                            placeholder="知识类型"
                            allowClear
                        />
                    </div>

                    <div className="operationItem">
                        <span className='operationTitle'>部门分类：</span>
                        <TreeSelect
                            style={{width: 150,}}
                            value={operationData.departmentSubclassificationId}
                            onChange={(value)=>{
                                handleDepartmentSubChange(value)
                            }}
                            treeData={departmentTree}
                            fieldNames={{ label: 'subclassification', value: 'id'}}
                            placeholder="部门分类" 
                            allowClear
                        />
                    </div>
                    
                    <div className="operationItem">
                        <span className='operationTitle'>知识标题：</span>
                        <Input
                            placeholder="输入关键词搜索"
                            onChange={changeValue}
                            onPressEnter={onSearch}
                            value={searchValue}
                            width={200}
                            allowClear
                        />
                    </div>
                    <div className="operationItem" style={{width:170}}>
                        <Space size={"large"}> 
                            <Button type="primary" onClick={onSearch}>搜索</Button>
                            <Button onClick={onResetting}>重置</Button>
                        </Space>
                    </div>
                </div>
                
                <div className="operationRight">
                    <Space size={"large"}>
                        <Button type="primary" disabled={selectedRowKey?.length < 1} onClick={()=>{
                            setEdictID(selectedRowKey);
                            setSettingModalOpen(true);
                        }}>设置为重要文件</Button>
                    </Space>
                </div>
            </div>
            <div className='operationSec'>
                <div className="operationItem">
                    <span className='operationTitle'>重要文件：</span> 
                    <Checkbox onChange={(e)=>{
                        let newSearchData = {...searchData};
                        let checked = e.target.checked;
                        // 0不是 1是
                        newSearchData.isImportant = checked ? 1 : 0;
                        setSearchData({
                            ...newSearchData,
                            ...pagingParam
                        })
                    }}></Checkbox>
                </div>
                <div className="operationItem">
                    <span className='operationTitle'>已标记文件：</span>
                    <Checkbox onChange={(e)=>{
                        let newSearchData = {...searchData};
                        let checked = e.target.checked;
                        // 0不是 1是
                        newSearchData.isMark = checked ? 1 : 0;
                        setSearchData({
                            ...newSearchData,
                            ...pagingParam
                        })
                    }}></Checkbox>
                </div>
            </div>
            <BackIcon />
            <div className="table">
                <Form form={form} component={false}>
                    <SelfTable
                        dataSource={listData}
                        columns={columns}
                        loading={tableLoading}
                        rowRadioSelection={{
                            selectedRowKey:selectedRowKey,
                            setSelectedRowKey:setSelectedRowKey,
                            type:"checkbox",
                            preserveSelectedRowKeys:true,
                            getCheckboxProps: (record) => ({
                                disabled: record.isImportant != 0,
                            }),
                        }}
                        pagination={{...pagination,showTotal:false}}
                        onChange={onChanges} 
                    />
                </Form>
            </div>
            <Modal 
                title="设置重要文件" 
                wrapClassName="settingModalWrap" 
                open={settingModalOpen} 
                onOk={handleOk} 
                onCancel={handleCancel}
            >
                <Form
                    form={settingForm}
                    component={false}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    preserve={false}
                >
                    <Form.Item
                        key={'downIdocWfId'}
                        label={`请选择下载流程`}
                        name={'downIdocWfId'}
                        rules={[{ required: true, message:`请选择下载流程!` }]}
                    >
                        <Select
                            style={{
                                width: '85%',
                            }}
                            options={downloadFlow}
                            fieldNames={
                                { label: 'nikeName', value: 'id'}
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        key={'readIdocWfId'}
                        label={`请选择阅读流程`}
                        name={'readIdocWfId'}
                        rules={[{ required: true, message:`请选择阅读流程!` }]}
                    >
                        <Select
                            style={{
                                width: '85%',
                            }}
                            options={readFlow}
                            fieldNames={
                                { label: 'nikeName', value: 'id'}
                            }
                        />
                    </Form.Item>
                </Form>
            </Modal>


            <Modal 
                title="详情" 
                wrapClassName="flowDetailsModalWrap" 
                open={detailsModalOpen} 
                footer={
                    [
                        <Button onClick={handleDetailsCancel}>取消</Button>
                    ]
                }
                onCancel={handleDetailsCancel}
                width={600}
            >
                <div className='flowDetailsBox'>
                    <div className='flowDetailsTitle'>下载流程:</div>
                    <Steps items={detailsModalOpen.downIdocWf} />
                </div>
                <div className='flowDetailsBox'>
                    <div className='flowDetailsTitle'>阅读流程:</div>
                    <Steps items={detailsModalOpen.readIdocWf} />
                </div>
            </Modal>
        </div>
    );
};
export default ImportantKnowledgeList;
