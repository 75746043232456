import React, { useState, useEffect } from "react";
import { DatePicker, Button, Col, Row, Typography, Empty, Spin, message} from "antd";
import { useHistory, useLocation } from "react-router-dom";
import moment from 'moment'

import Api from "../../../api";
import SelfPagination from "../../../components/SelfPagination";
import ApprovalModal from "../components/approvalModal/index";
import {gethalfMonthDate, jumpToPage} from '../../../utils/tool'
import "./index.scss";


const { RangePicker } = DatePicker;
const { Text, Link } = Typography;

const ToDo = () => {
  const history = useHistory();
  const location = useLocation().state;
  // 查询数据
  const [searchData, setSearchData] = useState({
    pageIndex: 1,
    pageSize: 10,
    startTime: gethalfMonthDate()[0],
    endTime: gethalfMonthDate()[1],
  });

  const [dataList, setDataList] = useState([]);

  // 分页
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 0,
    total: 0,
  });

  const [spinningSate, setSpinningSate] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);

  const [approvalID, setApprovalID] = useState("");
  const [flowType, setFlowType] = useState("");

    useEffect(() => {
        getList();
    }, [searchData]);

    useEffect(() => {
        if (location && location.checkId) {
            setApprovalID(location.checkId);
            setFlowType(location.flowType);
        }
    }, [location]);

    const getList = async () => {
        setSpinningSate(true);
        try {
            let res = await Api.Message.getCheckToDoMessage({ data: searchData });

            setSpinningSate(false);
            if (res && res.code === 0 && res.data) {

                let _data = [...res.data.records]
                let newData = [];
                let nowDate = "";

                _data.map(item=>{
                    let date = item.createTime.split(" ")[0];
                    if(nowDate === date){
                        newData[newData.length-1].list.push(item)
                    }else{
                        nowDate = date
                        newData.push({
                            date: date,
                            list: [item]
                        })
                    }

                })
                setDataList(newData);

                setPagination({
                    pageIndex: res.data.current,
                    pageSize: res.data.size,
                    total: res.data.total,
                });
            }else{
                setDataList([]);
            }
            // 更新未读消息数量
            document.dispatchEvent(new CustomEvent("notify"));
        } catch (errInfo) {
            setDataList([]);
            setSpinningSate(false);
            console.log("验证失败:", errInfo);
        }
    };

    const dateChange = (date, dateString) => {
        let newSearchData = { ...searchData };
        newSearchData.startTime = dateString[0];
        newSearchData.endTime = dateString[1];
        setSearchData(newSearchData);
    };

    const onSizeChange = (page, pageSize) => {
        let _searchData = { ...searchData };
        _searchData.pageIndex = page;
        _searchData.pageSize = pageSize;
        setSearchData(_searchData);
    };

    const goKnowleageDetailesUnPublished = async(item)=>{
        let knowledgeId = item.knowledgeId;
        let url = "";
        if (item.fileType === "online") {
            url = `/page/onLineWrite?knowledgeId=${knowledgeId}&type=view`;
        } else {
            url = `/page/newKnowledge?knowledgeId=${knowledgeId}&type=view`;
        }
        window.open(url);
    }

    const goKnowleageDetailesPublished = async(item)=>{
        jumpToPage({...item, id: undefined, type: ""})
    }

    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    };

    const goApproval = (items) => {
        setFlowType(items.flowType);
        setApprovalID(items.checkId)
    }

    const checkStatus = async(item) => {
        let res = await Api.Message.checkMessageStatus({
            data: {
                id:item.knowledgeId,
                applyId:item.id
            },
        });

        if(res.code === 0){
            let _code = res.data;
            //  applyForType 1下载申请,2发布知识申请,3取消发布知识申请,4阅读权限申请
            // -1已被删除 0已拒绝，1已通过，2待审批，3草稿文章，4当前知识已取消发布并重新发布  ------ 该知识当前实际状态
            if(_code === -1){
                message.info("当前知识已被删除");
            }else if(_code != 1 && _code != 2){
                // 文件待审批中的时候审核人员可以查看
                message.info("当前知识已取消发布");
            }
            else{
                // 不知道当前这个人有没有权限做笔记和摘录
                // 统一不能操作，进入个人中心的查看
                goKnowleageDetailesUnPublished(item)
            }
            // else if(item.applyForType === 2){
            //     // 申请发布的文件，只能在个人中心查看
            //     goKnowleageDetailesUnPublished(item)
            // }
            // else if(item.applyForType === 3 || item.applyForType === 1 || item.applyForType === 4){
            //     // 取消发布、下载、阅读的文件，进入知识详情页查看
            //     goKnowleageDetailesPublished(item)
            // }
        }
    }

    const goHistory = () => {
        history.push(`/page/manage/knowledgeReview`);
    }


    return (
        <div className="approvalNoticeFrame">
            <div className="operation">
                最近的待办
                <RangePicker
                    disabledDate={disabledDate}
                    value={[
                        searchData.startTime?moment(searchData.startTime):"",
                        searchData.endTime?moment(searchData.endTime):""
                    ]}
                    onChange={dateChange}
                    style={{marginLeft:20}}
                />
                <Button style={{float: 'right'}} type="primary" onClick={goHistory}>查看审批历史</Button>
            </div>
            <div className="content">

                {
                    (searchData.startTime&&searchData.endTime&&
                    dataList&&dataList.length<=0)&&(<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />)
                }

                <Spin spinning={spinningSate}>
                    {dataList&&dataList.map(item=>{
                        return <div className="listItem" key={item.date}>
                            <p className="date">{item.date}</p>
                            {item.list&&item.list.map(items=>{
                                return(<div key={items.id}>
                                    {/* 1下载申请,2发布知识申请,3取消发布知识申请,4阅读权限申请更新知识申请 6重要文件阅读申请 7重要文件下载申请 */}
                                    <p className="time">
                                        {items.createTime.split(" ")[1]}&nbsp;&nbsp;
                                        <Link>{items.applyUserName}</Link>
                                        {items.applyForType === 1 ? "申请下载":
                                        items.applyForType === 7 ? "申请下载重要文件":
                                        items.applyForType === 4 ? "申请查看":
                                        items.applyForType === 6 ? "申请查看重要文件":
                                        items.applyForType === 3 ? "申请取消发布":
                                        items.applyForType === 2 ? "申请在":""}
                                        &nbsp;&nbsp;<Link>{items.departmentName}</Link>&nbsp;&nbsp;
                                        <Link>{items.knowledgeTypeName}</Link>&nbsp;&nbsp;
                                        {items.applyForType === 2 ? "中发布文件":"中的文件:"}
                                    </p>
                                    <div className="fileList">
                                        <Row>
                                            <Col span={22}>
                                                <Link onClick={()=>{checkStatus(items)}}>《{items.knowledgeName}》</Link>
                                            </Col>
                                            <Col span={2}>
                                                <Link onClick={()=>{
                                                    goApproval(items)
                                                }}>立即审批</Link>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>)
                            })}
                        </div>
                    })}
                </Spin>
            </div>
            {(dataList&&dataList.length>0)?(
            <div className="footer">
                <SelfPagination
                    total={pagination.total}
                    current={pagination.pageIndex}
                    onSizeChange={onSizeChange}
                />
            </div>
            ):""}

            <ApprovalModal
                checkId={approvalID}
                flowType={flowType}
                onCancelBack={()=>{
                    history.push(`/page/notifications`, {checkId:""});
                    setApprovalID("");
                    getList();
                }}
                checkStatus={checkStatus}
            />
        </div>
    );
  };
export default ToDo;
