import React,{useState, useEffect} from 'react';
import { Tabs, Button, Modal, Form, Select, TreeSelect, Tag, Transfer, Spin, Empty, Tree, Checkbox, Input, Popconfirm, message, Space, Typography } from 'antd';
import { PlusOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import BackIcon from "../../../../components/BackIcon/index";
import Api from "../../../../api";
import SelfTable from "../../../../components/SelfTable"
import {toChineseNumber} from "../../../../utils/tool";
import ViewImg from "../../../../components/ViewImg";
import './index.scss'

const ProcessConfig = () => {
    const items = [
        {key: "down", label: "下载流程"},
        {key: "read", label: "阅读流程"},
    ];
    const [approveForm] = Form.useForm();

    const [activeKey, setActiveKey] = useState('down');
    // 列表数据
    const [listData, setListData] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const columns = [
        {
            title: '流程名称',
            dataIndex: 'nikeName',
            width: '',
        },
        {
            title: '流程平台流程名称',
            dataIndex: 'flowName',
            width: '200px',
        },
        {
            title: '流程节点',
            dataIndex: 'flowNodeStr',
            width: '200px',
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            width: '180px',
        },
        {
            title: '操作',
            width: '130px',
            dataIndex: 'operation',
            render: (_, record) => {
                return <Space>
                    <Typography.Link onClick={() => editList(record)}>
                        调整
                    </Typography.Link>
                    <Popconfirm
                        title="您确认要删除这条数据吗?"
                        onConfirm={()=>deleteList(record)}
                        okText="确定"
                        cancelText="取消"
                        placement="topRight"
                    >
                        <Typography.Link>
                            删除
                        </Typography.Link>
                    </Popconfirm>
                </Space>
            },
        },
    ];

    const [processModalOpen, setProcessModalOpen] = useState(false);
    const [approvalConfiguration, setApprovalConfiguration] = useState([]);

    // 选择人员
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newUserList, setNewUserList] = useState([]);
    const [userIDList, setUserIDList] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [authorNameList, setAuthorNameList] = useState([]);
    
    const [userSpinning, setUserSpinning] = useState(false);
    const [approvalList, setApprovalList] = useState([]);
    const [peopleTypeActiveKey, setPeopleTypeActiveKey] = useState('specific');

    const [dynamicsUserList, setDynamicsUserList] = useState([]);
    const [dynamicsUserObj, setDynamicsUserObj] = useState([]);
    const [currentApprovalLevel, setCurrentApprovalLevel] = useState({});

    const [flowName,setFlowName] = useState('');

    const [fromDataObj, setFromData] = useState({});

    const [defList, setDefList] = useState([]);
    const [editId, setEditId] = useState('');

    const [processImg, setProcessImg] = useState('');
    const [isShowViewImg, setIsShowViewImg] = useState(false);

    useEffect(() => {
        getAllDepUser();
        getApprovalList();
        getIDocDefList();
    }, []);

    useEffect(() => {
        getList();
    }, [activeKey]);

    useEffect(() => {
        // 审批层级变化完成后，重新设置form的值
        approveForm.setFieldsValue({
            ...fromDataObj,
        });
    }, [approvalConfiguration]);
    
    const getIDocDefList = async() => {
        const res = await Api.IDoc.getIDocDefList({data:{
            pageIndex:1,
            pageSize:1000
        }});
        if(res&&res.code === 0&&res.data){
            let _data = res.data;
            setDefList(_data.records);
        }else{
            setDefList([]);
        }
    }
    const getList = async() => {
        setTableLoading(true);
        
        const res = await Api.IDoc.getIDocFlowNode({data:{
            flowType:activeKey
        }});
        setTableLoading(false);
        if(res&&res.code === 0){
            let _data = res.data;
            setListData(_data);
        }else{
            setListData([]);
        }
    }

    const deleteList = async(record) => {
        const res = await Api.IDoc.deleteIDocFlow({data:{
            id:record.id
        }});
        if(res&&res.code === 0){
            message.info("删除成功");
            getList();
        }
    }

    const editList = async(record) => {
        setProcessModalOpen(true);
        getDefFlowChart(record);
        setEditId(record.id);
        let _list = [], formObj = {};
        record.idocWfUserList.map(item=>{
            _list.push({
                key: item.id,
                value: item.flowNode
            })
            formObj[item.id] = item.flowNode;
        })

        setFlowName(record.flowName);

        setFromData({
            flowCode: record.flowCode,
            nikeName: record.nikeName,
            ...formObj
        })

        setApprovalConfiguration(_list);
    }
    
    const onChange = (activeKey) => {
        setActiveKey(activeKey);
    }
   

    const addApproveList = async() => {
        try{
            const row = await approveForm.validateFields();
            setApprovalConfiguration([
                ...approvalConfiguration,
                { key:`approve${Date.now()}`,value:""}
            ])
        }catch{}
    }



    //  ---   配置流程人员

    const getApprovalList = async() => {
        const resCheck = await Api.Knowledge.selectNameByCheckPerm({});
        if(resCheck && resCheck.code === 0){

            let newData0 = resCheck.data.map(item=>{
                return{
                    value: `U_${item.id}_0`,
                    label: `用户所属部门${item.name}`,
                    text: `用户所属部门${item.name}`,
                }
            });

            let newData1 = resCheck.data.map(item=>{
                return{
                    value: `K_${item.id}_1`,
                    label: `知识所属部门${item.name}`,
                    text: `知识所属部门${item.name}`,
                }
            });

            setApprovalList([...newData0,...newData1]);
        }
    }

    const getAllDepUser = async() => {
        // 获取所有用户
        setUserSpinning(true);
        let _ApprovalList = []
        const resCheck = await Api.Knowledge.selectNameByCheckPerm({});
        if(resCheck && resCheck.code === 0){
            let newData0 = resCheck.data.map(item=>{
                return{
                    key: `U_${item.id}`,
                    value: `U_${item.id}`,
                    label: `用户所属部门${item.name}`,
                    title: `用户所属部门${item.name}`,
                    selectable: true
                }
            });

            let newData1 = resCheck.data.map(item=>{
                return{
                    key: `K_${item.id}`,
                    value: `K_${item.id}`,
                    label: `知识所属部门${item.name}`,
                    title: `知识所属部门${item.name}`,
                    selectable: true
                }
            });
            _ApprovalList = [...newData0,...newData1]
        }

        try{
          const res = await Api.Common.getAllDepUser({data:{
            name:searchValue
          }});
          setUserSpinning(false);
          if(res&&res.code === 0&& res.data){
              let _data = res.data;
              let _expandedKeys = [];

              _data.map(item=>{
                  item.key = item.id;
                  item.value = item.id;
                  item.label = item.name;
                  item.title = item.name;
                  item.selectable = false;
                  item.children = item.users;
                  _expandedKeys.push(item.id)
                  if(item.users){
                      item.children = cycleData(item.users)
                  }
              })
              setNewUserList([..._ApprovalList,..._data])
              
          }else{
            setNewUserList([])
          }
        }catch{setUserSpinning(false);setNewUserList([])}
        
    }
    const cycleData = (data) => {
        data.map(item=>{
            item.key = item.userId;
            item.value = item.userId;
            item.title = item.userName+'-'+item.roleName;
            item.label = item.userName+'-'+item.roleName;
        })
        return data
    }
    // const handlePeopleOk = () => {
    //     let selectValue = userIDList.map((item) => {
    //       return { userId: item };
    //     });
    
    //     let userList = [];
    
    //     for(let i=0; i<newUserList.length; i++){
    //       let childrenList = newUserList[i].children
    //       for(let j=0; j<childrenList.length; j++){
    //         let childrenItem = childrenList[j];
            
    //         if(userIDList.includes(childrenItem.key)){
    //           childrenItem.departmentName = newUserList[i].name
    //           userList.push(childrenItem)
    //         }
    
    //       }
    //     }

    //     // 显示
    //     console.log('showUserList',dynamicsUserObj, userList);
    //     // 传参
    //     console.log('userList', selectValue, dynamicsUserList);

    //     let _approvalConfiguration = [...approvalConfiguration];
    //     _approvalConfiguration.map(item=>{
    //         if(item.key === currentApprovalLevel.key){
    //             item.userIDList = [...selectValue,...dynamicsUserList];
    //             item.userList = [...dynamicsUserObj,...userList];
    //         }
    //     })

    //     setApprovalConfiguration(_approvalConfiguration)
        
    //     setIsModalOpen(false);
    // }

    // 删除审批流程
    const removeApproveList = async(records) => {
        let _approvalConfiguration = [];
        
        approvalConfiguration.map(item=>{
            if(item.key != (records.key || records.id)){
                _approvalConfiguration.push(item)
            }
        })

        const value = await approveForm.getFieldsValue();
        let fromData = {};
        for(let key in value){
            if(key != records.key){
                fromData[key] = value[key]
            }
        }
        
        setFromData(fromData);

        setApprovalConfiguration(_approvalConfiguration);
    }

    // const onApproveChange = (e,records) => {
    //     let _approvalConfiguration = [...approvalConfiguration];

    //     _approvalConfiguration.map(item=>{
    //         if(item.key === (records.key || records.id)){
    //             item.value = e
    //         }
    //     })

    //     let fromData = {};
    //     _approvalConfiguration.map(item=>{
    //         fromData[item.key] = item.value
    //     })
        
    //     approveForm.setFieldsValue({
    //         ...fromData,
    //     });
    //     setApprovalConfiguration(_approvalConfiguration)
    // }

    const handleOk = async() => {

        try{
            const row = await approveForm.validateFields();
            console.log(row,"rowrowrow")
            let flowNodes = [];
            approvalConfiguration.map(item=>{
                flowNodes.push(row[item.key])
                
            })

            let param = {
                'flowNodes':flowNodes,
                'flowCode': row.flowCode,
                'nikeName': row.nikeName,
                "flowType": activeKey,
                'flowName': flowName
            }

            if(editId){
                param.id = editId
            }

            const res = await Api.IDoc.setIDocFlowNode({data:param});
            
            if(res&&res.code === 0){
                message.success('保存成功');
                handleCancel();
                getList();
            }
        }catch(err){console.log(err)}
        
    };
    const handleCancel = () => {
        setProcessModalOpen(false);
        setEditId('');
        setProcessImg('');
        approveForm.resetFields();
        setApprovalConfiguration([]);
        setFromData({});
        setFlowName('');
    };

    const getDefFlowChart = async(option) => {
        try{

            const res = await Api.IDoc.defFlowChart({data:{
                flowCode: option.flowCode
            }});
            
            if(res&&res.code === 0){
                setProcessImg("data:image/png;base64," + res.data);
            }
        }catch(err){console.log(err)}
        
    }

    // 删除审核人员
    // const removeApproveUser = (records, childItem) =>{
    //     let _approvalConfiguration = [...approvalConfiguration];
    //     _approvalConfiguration.map(item=>{
    //         if(item.key === (records.key || records.id)){
    //             let userList = [];
    //             for(let i=0; i<item.userList.length; i++){
    //                 let id = item.userList[i].value || item.userList[i].userId;
    //                 if(id != (childItem.value || childItem.userId)){
    //                     userList.push(item.userList[i])
    //                 }
    //             }
    //             item.userList = userList;
    //         }
    //     })
    //     setApprovalConfiguration(_approvalConfiguration);
    // }
    
    // const handlePeopleCancel = () => {
    //     setIsModalOpen(false);
    // }

    // const onTransferChange = (targetKeys) => {
    //     setUserIDList(targetKeys);
    // }; 
    // const onTransferSearch = (direction,value) => {
    //     setSearchValue(value)
    // }

    // const onCheckboxChange = (checkedValues) => {
    //     setDynamicsUserList(checkedValues);
    //     let list = []
    //     approvalList.map(item=>{
    //         if(checkedValues.includes(item.value)){
    //             list.push(item);
    //         }
    //     })
    //     setDynamicsUserObj(list);
    //     console.log('checked = ', list, checkedValues);
    // };

    // // 作者穿梭框
    // const isChecked = (selectedKeys, eventKey) => selectedKeys.includes(eventKey);
    // const generateTree = (treeNodes = [], checkedKeys = []) =>
    // treeNodes.map(({ children, ...props }) => ({
    //     ...props,
    //     disabled: checkedKeys.includes(props.key),
    //     children: generateTree(children, checkedKeys),
    // }));

    // const transferDataSource = [];
    // function flatten(list = []) {
    //     list.forEach((item) => {
    //         transferDataSource.push(item);
    //         flatten(item.children);
    //     });
    // }
    // flatten(newUserList);

    return (
        <div className="processConfigFrame">
            <div className="processConfigTabs">
                <Tabs activeKey={activeKey} onChange={onChange} items={items} destroyInactiveTabPane></Tabs>
            </div>
            <BackIcon />

            <div className='processConfigContent'>
                <div className="operation">
                    <Button type="primary" onClick={() => {setProcessModalOpen(true)}}>新增</Button>
                </div>
                <div className="table">
                    <SelfTable
                        // pagination={pagination}
                        dataSource={listData}
                        columns={columns}
                        loading={tableLoading}
                    />
                </div>
            </div>

            <Modal 
                title={activeKey === 'down' ? "下载流程配置" : "阅读流程配置" }
                wrapClassName="processModalWrap" 
                open={processModalOpen} 
                onOk={handleOk} 
                onCancel={handleCancel}
                zIndex={900}
            >
                <Form
                    form={approveForm}
                    component={false}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    preserve={false}
                >
                    <Form.Item
                        key={'nikeName'}
                        label={`流程名称`}
                        name={'nikeName'}
                        rules={[{ required: true, message:`请输入流程名称!` }]}
                    >
                        <Input style={{ width: '85%'}}/>
                    </Form.Item>

                    <Form.Item
                        key={'flowCode'}
                        label={`请选择流程`}
                        name={'flowCode'}
                        rules={[{ required: true, message:`请选择流程!` }]}
                        style={{marginBottom:0}}
                    >
                        <Select
                            style={{
                                width: '85%',
                            }}
                            onChange={(e,option) => {
                                setFlowName(option.flowName);
                                getDefFlowChart(option);
                            }}
                            options={defList}
                            fieldNames={
                                { label: 'flowName', value: 'flowCode'}
                            }
                        />
                    </Form.Item>
                    <Tag className='warningTag' icon={<ExclamationCircleOutlined  />} color="warning">
                        请按照您当前选择的流程严格配置审批人，以防止审批流程出错！
                    </Tag>

                    {approvalConfiguration.map((item,index)=>{
                        return(
                            <div className="formItem">
                                <Form.Item
                                    key={item.key}
                                    label={`${toChineseNumber(index+1)}级审批人`}
                                    name={item.key}
                                    rules={[{ required: true, message:`请选择审批人!` }]}
                                >

                                    <TreeSelect
                                        style={{
                                            width: '80%',
                                        }}
                                        // value={item.value}
                                        treeData={[...newUserList]}
                                        // fieldNames={{ label: 'title', value: 'key'}}
                                        showSearch
                                        treeNodeFilterProp={"label"}
                                        // onChange={(e) => onApproveChange(e,item)}
                                    />

                                    {/* <Button className='addPeopleButton' onClick={() => {
                                        setCurrentApprovalLevel(item);
                                        setIsModalOpen(true)}
                                    }>
                                        <PlusOutlined />
                                    </Button> */}
                                </Form.Item>

                                <Popconfirm
                                    title="您确认要删除这级审批吗?"
                                    onConfirm={()=>removeApproveList(item)}
                                    okText="确定"
                                    cancelText="取消"
                                    placement="topRight"
                                >
                                    <DeleteOutlined className="dynamic-delete-button" />
                                </Popconfirm>


                                {/* <div className='peopleList'>
                                    {item.userList?.map((childItem,childIndex)=>{
                                        return (<div className='peopleListItem'>
                                            {childItem.text || childItem.userName}
                                            <Popconfirm
                                                title="您确认要删除这个审批人吗?"
                                                onConfirm={()=>removeApproveUser(item, childItem)}
                                                okText="确定"
                                                cancelText="取消"
                                                placement="topRight"
                                            >
                                                <CloseOutlined />
                                            </Popconfirm>
                                        </div>)
                                    })}
                                </div> */}
                            </div>
                        )
                    })}
                </Form>
                <div style={{textAlign:"center"}}>
                    <Button
                        type="dashed"
                        onClick={() => addApproveList()}
                        style={{
                            width: '40%',
                        }}
                        icon={<PlusOutlined />}
                    >
                        添加审批层级
                    </Button>
                </div>

                {processImg&&<div className='processImg' onClick={()=>setIsShowViewImg(true)}>
                    <img src={processImg}/>
                </div>}
            </Modal>


            <ViewImg
                viewImgList={[{
                    url: processImg,
                    name: flowName,
                    id: 0
                }]}
                isShowViewImg={isShowViewImg}
                viewImgIdx={0}
                closeViewImg={() => setIsShowViewImg(false)}
            />

            {/* <Modal 
                wrapClassName="selectPeopleModal"  
                title="选择人员" 
                open={isModalOpen} 
                onOk={handlePeopleOk} 
                onCancel={handlePeopleCancel}
                width={700}
                mask={false}
            >
                <Tabs items={[
                    { label: '具体用户', key: 'specific'},
                    { label: '动态用户', key: 'dynamics'}, 
                ]} onChange={(key)=>{
                    setPeopleTypeActiveKey(key);
                }}/>

                {peopleTypeActiveKey === "specific" ? 
                <Transfer
                    targetKeys={userIDList}
                    dataSource={transferDataSource}
                    className="tree-transfer"
                    render={(item) => item.title}
                    showSelectAll={false}
                    onChange={onTransferChange}
                    showSearch={true}
                    onSearch={onTransferSearch}
                >
                    {({ direction, onItemSelect, selectedKeys }) => {
                    if (direction === 'left') {
                        const checkedKeys = [...selectedKeys, ...userIDList];
                        return (
                            <Spin spinning={userSpinning} tip="加载中...">
                            {newUserList&&newUserList.length>0?<Tree
                            blockNode
                            checkable
                            checkStrictly
                            // defaultExpandAll
                            checkedKeys={checkedKeys}
                            showSearch={true}
                            treeData={generateTree(newUserList, userIDList)}
                            onCheck={(_, { node: { key } }) => {
                                onItemSelect(key, !isChecked(checkedKeys, key));
                            }}
                            onSelect={(_, { node: { key } }) => {
                                onItemSelect(key, !isChecked(checkedKeys, key));
                            }}
                            />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                        </Spin>
                        );
                    }
                    }}
                </Transfer>:
                <div className='dynamicsPeopleBox'>
                    <Checkbox.Group
                        options={approvalList}
                        // defaultValue={['Apple']}
                        value={dynamicsUserList}
                        onChange={onCheckboxChange}
                    />
                </div>}
                
            </Modal> */}
        </div>
    );
}
export default ProcessConfig
