import React, {useState, useEffect, useRef} from 'react';
import { Empty, Typography, Button, Input, Modal, Switch, Tabs, DatePicker, Space, Form, Popconfirm, Tooltip, message } from 'antd';
import { ClockCircleOutlined, SearchOutlined, CheckOutlined, CloseOutlined, PlusSquareOutlined, UploadOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import moment from 'moment';

import Api from "../../../../api";
import SelfTable from "../../../../components/SelfTable"
import {ModalConfirm} from '../../../../utils/tool'
import {pagingParam} from "../../../../utils/defaultValue";

import "./index.scss";

const { Text, Link, Title } = Typography;

const PolicyDataSource = ({
    getDetails,
    details,
    policyLableThemeId,
    getPolicyList
}) => {

    const items = [
        { label: '时间段', key: 'timeRage'}, // 务必填写 key
        { label: '关键词', key: 'filterWords'},
        { label: '屏蔽词', key: 'blockWords'},
        { label: '数据源', key: 'data'},
    ];

    const filterWordsColumns = [
        {
          title: '关键词',
          dataIndex: 'keyword',
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width:'100px',
          render: (_, record, index) => {
            return <Space>
                <Popconfirm
                    title="您确认要删除这条数据吗?"
                    onConfirm={()=>deleteWords(record)}
                    okText="确定"
                    cancelText="取消"
                    placement="topRight"
                >
                    <Text style={{cursor: 'pointer'}} type="danger">移除</Text>
                </Popconfirm>
                <Link onClick={() => editWords(record)}>
                    修改
                </Link>
            </Space>
        },
        },
    ];    
    const blockWordsColumns = [
        {
          title: '屏蔽词',
          dataIndex: 'shieldword',
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width:'100px',
          render: (_, record, index) => {
            return <Space>
                <Popconfirm
                    title="您确认要删除这条数据吗?"
                    onConfirm={()=>deleteWords(record)}
                    okText="确定"
                    cancelText="取消"
                    placement="topRight"
                >
                    <Text style={{cursor: 'pointer'}} type="danger">移除</Text>
                </Popconfirm>
                <Link onClick={() => editWords(record)}>
                    修改
                </Link>
            </Space>
        },
        },
    ];    

     

    const [allDataChecked, setAllDataChecked] = useState(false);
    const [startData, setStartData] = useState(null);
    const [activeKey, setActiveKey] = useState('timeRage');

    // 列表数据
    const [filterWordsListData, setFilterWordsListData] = useState([]);
    const [filterWordssDefaultData, setFilterWordsDefaultData] = useState([]);
    // keyWordList
    const [blockWordsListData, setBlockWordsListData] = useState([]);
    const [blockWordsDefaultData, setBlockWordsDefaultData] = useState([]);
    const [shieldInText, setShieldInText]  = useState(false);
    const [switchLoading, setSwitchLoading]  = useState(false);
    const [keyWordSearchValue, setKeyWordSearchValue]  = useState("");
    const [wordsSearchParam, setWordsSearchParam] = useState({
        ...pagingParam,
        // policyLableThemeId:policyLableThemeId,
        word:""
    });
    const [wordsPagination, setWordsPagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "total":0
    });
    
    const [defalutStartData, setDefalutStartData] = useState(null);
    
    const [tableLoading, setTableLoading] = useState(false);
    // 列表选择数据
    const [selectedRowKey, setSelectedRowKey] = useState([]);

    const [showSearch, setShowSearch] = useState(false);

    
    // 新增过滤词/屏蔽词
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [editObj, setEditObj] = useState(false);
    const [addForm] = Form.useForm();
    const [confirmLoading, setConfirmLoading] = useState(false)


    // 数据源
    const [sourceSearchParam, setSourceSearchParam] = useState({
        ...pagingParam,
        // policyLableThemeId:policyLableThemeId,
        source:""
    });
    const [sourceSearchValue, setSourceSearchValue] = useState("");
    const [pagination, setPagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "total":0
    });
    const [sourceDataList, setSourceDataList] = useState([]);
    const sourceDataColumns = [
        {
          title: '来源',
          dataIndex: 'source',
          render: (text, record, index) => {
            //  0未选 1已选
            if(record.isChoose == 1){
                return <span>{text}</span>
            }else{
                return<Text disabled>{text}</Text>
            }
        },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width:'80px',
          render: (_, record, index) => {
            if(record.isChoose == 1){
                return<Popconfirm
                    title="您确认要移除这条数据吗?"
                    onConfirm={()=>removeSourceData(record)}
                    okText="确定"
                    cancelText="取消"
                    placement="topRight"
                >
                    <Text style={{cursor: 'pointer'}} type="danger">移除</Text>
                </Popconfirm>
            }else{
                return<Link style={{cursor: 'pointer'}} onClick={()=>restoreData(record)}>恢复</Link>
            }
        },
        },
    ];    


    useEffect(() => {
        if(policyLableThemeId){
            setSelectedRowKey([]);
            if(activeKey === 'data'){
                getPageSourceList();
            }else if(activeKey === 'blockWords'){
                getShieldWordsRule();
                getShieldWordsRuleList();
            }else if(activeKey === 'filterWords'){
                getKeyWordsRuleList();
                getKeyWordsRule();
            }else if(activeKey === 'timeRage'){
                getTimeRule();
            }
        }
    }, [activeKey, sourceSearchParam, wordsSearchParam]);


    useEffect(() => {
        if(activeKey === 'timeRage'){
            getTimeRule();
        }else{
            setActiveKey('timeRage');
        }
    }, [policyLableThemeId]);

    const onChange = (key) => {
        setActiveKey(key);
    };  

    // 时间
    const getTimeRule = async() => {
        try{
            const res = await Api.Policy.getTimeRule({data:{
                policyLableThemeId:policyLableThemeId,
            }});
            if(res && res.code ===0 && res.data){
                if(res.data.startTime){
                    setStartData(moment(res.data.startTime));
                    setDefalutStartData(res.data.startTime);
                    setAllDataChecked(false);
                }else{
                    setAllDataChecked(true);
                    setStartData(null);
                    setDefalutStartData(null);
                } 
            }else{
            }
        }catch(err){
        }
    }

    const onChangeConditionData = (value, dateString, type) => {
        console.log(value,type,"onChangeConditionData")
        if(type === "allData"){
            setAllDataChecked(value);
            setStartData(null);
            if(value){
                // 全部时段
                setTimeRule('')
            }
        }else if(type != "allData"){
            setAllDataChecked(false);
            setStartData(value)
            let time = moment(value).format('YYYY-MM-DD');
            setTimeRule(time);
            console.log(time);
        }
    };

    // 设置时间
    const setTimeRule = async(startData) => {
        try{
            const res = await Api.Policy.setTimeRule({data:{
                policyLableThemeId:policyLableThemeId,
                startTime:startData
            }});
            if(res && res.code ===0 && res.data){
                message.success("设置成功")
                getTimeRule();
                getPolicyList();
            }else{
            }
        }catch(err){
        }
    }



    // 获取数据源列表
    const getPageSourceList = async() => {
        try{
            setTableLoading(true);
            setSelectedRowKey([]);
            const res = await Api.Policy.getPageSource({data:{
                ...sourceSearchParam,
                policyLableThemeId:policyLableThemeId,
            }});
            setTableLoading(false);
            if(res && res.code ===0 && res.data){
                let _data = res.data;
                setSourceDataList(_data.records);
                setPagination({
                    "pageIndex": _data.current,
                    "pageSize": _data.size,
                    "total":_data.total
                });

                let checkedList = [];
                _data.records.map(item=>{
                    if(item.isChoose === 1){
                        checkedList.push(item.source)
                    }
                })
                setSelectedRowKey(checkedList)
            }else{
                setSourceDataList([]);
            }
        }catch(err){
            setSourceDataList([]);
            setTableLoading(false);
        }
    }
    const onSourceSearch = () => {
        let newSearchData = {...sourceSearchParam}
        newSearchData.source = sourceSearchValue;
        setSourceSearchParam(newSearchData);
    }
    const onSourceTableChanges = (pagination, filters, sorter) => {
        let newSearchData = {...sourceSearchParam};
        newSearchData.pageIndex = pagination.current;
        newSearchData.pageSize = pagination.pageSize;
        setSourceSearchParam(newSearchData);
    };

    // 恢复数据源
    const restoreData = async(record) => {
        try{
            const res = await Api.Policy.setSourceRule({data:{
                sourceList:[record.source],
                policyLableThemeId:policyLableThemeId,
            }});
            if(res&&res.code === 0){
                message.success("恢复成功");
                getPageSourceList();
                getPolicyList(); //查讯列表
            }
        }catch{}
    }

    // 移除
    const removeSourceData = async(record) => {
        if(record && record.source){
            const res = await Api.Policy.setSourceRule({data:{
                excludeSourceList:[record.source],
                policyLableThemeId:policyLableThemeId,
            }});
            if(res&&res.code === 0){
                message.success("移除成功");
                getPolicyList(); //查讯列表
                getPageSourceList();
            }
            // 删除
        }else{
            ModalConfirm({
                title: `您确认要删除所选择的数据吗?`,
                onOk:async(resolve, reject)=> {
                    try{
                        const res = await Api.Policy.setSourceRule({data:{
                            excludeSourceList:selectedRowKey,
                            policyLableThemeId:policyLableThemeId,
                        }});
                        resolve()
                        if(res&&res.code === 0){
                            message.success("移除成功");
                            getPolicyList(); //查讯列表
                            getPageSourceList();
                        }
                    }catch{reject()}
                },
            })
        }
    }






    // 关键词
    // 查讯 - 是否应用于正文
    const getKeyWordsRule = async() => {
        try{
            const res = await Api.Policy.getKeyWordsRule({data:{
                policyLableThemeId:policyLableThemeId,
            }});
            setTableLoading(false);
            if(res && res.code ===0 && res.data){
               // 是否作用正文,0不作用 1作用
               setShieldInText(res.data.keyInText == 0 ? false : true);
            }
        }catch(err){}
    }
    // 列表
    const getKeyWordsRuleList = async() => {
        try{
            setTableLoading(true);
            const res = await Api.Policy.getKeyWordsPageList({data:{
                ...wordsSearchParam,
                policyLableThemeId:policyLableThemeId,
            }});
            setTableLoading(false);
            if(res && res.code ===0 && res.data){
                let _data = res.data;
                setWordsPagination({
                    "pageIndex": _data.current,
                    "pageSize": _data.size,
                    "total":_data.total
                });
                setFilterWordsListData(_data.records);
            }else{
                setFilterWordsListData([]);
            }
        }catch(err){
            setFilterWordsListData([]);
            setTableLoading(false);
        }
    }
    


    // 屏蔽词
    // 查讯 - 是否应用于正文
    const getShieldWordsRule = async() => {
        try{
            const res = await Api.Policy.getShieldWordsRule({data:{
                policyLableThemeId:policyLableThemeId,
            }});
            setTableLoading(false);
            if(res && res.code ===0 && res.data){
               // 是否作用正文,0不作用 1作用
               setShieldInText(res.data.shieldInText == 0 ? false : true);
            }
        }catch(err){}
    }

    // 列表
    const getShieldWordsRuleList = async() => {
        try{
            setTableLoading(true);
            const res = await Api.Policy.getShieldWordsPageList({data:{
                ...wordsSearchParam,
                policyLableThemeId:policyLableThemeId,
            }});
            setTableLoading(false);
            if(res && res.code ===0 && res.data){
                let _data = res.data;
                setWordsPagination({
                    "pageIndex": _data.current,
                    "pageSize": _data.size,
                    "total":_data.total
                });
                setBlockWordsListData(_data.records);
            }else{
                setBlockWordsListData([]);
            }
        }catch(err){
            setBlockWordsListData([]);
            setTableLoading(false);
        }
    }


    // 翻页分类搜索
    const onWordsTableChanges = (pagination, filters, sorter) => {
        let newSearchData = {...wordsSearchParam};
        newSearchData.pageIndex = pagination.current;
        newSearchData.pageSize = pagination.pageSize;
        setWordsSearchParam(newSearchData);
    };
    // 搜索名称
    const onKeyWordSearch = () => {
        let newSearchData = {...wordsSearchParam};
        newSearchData.word = keyWordSearchValue;
        setWordsSearchParam(newSearchData);
    }

    const deleteWords = async(record) => {
        if(record && record.id){
            try{
                let res = null;
                if(activeKey === 'blockWords'){
                    // 屏蔽词
                    res = await Api.Policy.deleteShieldWordsPage({data:[record.id]});
                }else if(activeKey === 'filterWords'){
                    // 关键词
                    res = await Api.Policy.deleteKeyWordsPage({data:[record.id]});
                }
                if(res&&res.code === 0){
                    setSelectedRowKey([]);
                    message.success("删除成功");
                    if(activeKey === 'blockWords'){
                        getShieldWordsRuleList();
                    }else{
                        getKeyWordsRuleList();
                    }
                    getPolicyList(); //查讯列表
                }
            }catch{reject()}
            // 删除
        }else{
            ModalConfirm({
                title: `您确认要删除所选择的数据吗?`,
                onOk:async(resolve, reject)=> {
                    try{
                        let res = null;
                        if(activeKey === 'blockWords'){
                            // 屏蔽词
                            res = await Api.Policy.deleteShieldWordsPage({data:selectedRowKey});
                        }else if(activeKey === 'filterWords'){
                            // 关键词
                            res = await Api.Policy.deleteKeyWordsPage({data:selectedRowKey});
                        }
                        resolve()
                        if(res&&res.code === 0){
                            setSelectedRowKey([]);
                            message.success("删除成功");
                            if(activeKey === 'blockWords'){
                                getShieldWordsRuleList();
                            }else{
                                getKeyWordsRuleList();
                            }
                            getPolicyList(); //查讯列表
                        }
                    }catch{reject()}
                },
            })
        }
        
    }

    const editWords = (record) => {
        setIsModalOpen(true);
        setEditObj(record);
        setModalTitle(activeKey === 'blockWords' ? '屏蔽词': "关键词");
        if(activeKey === 'filterWords'){
            addForm.setFieldsValue({
                name:record.keyword
            });
        }else{
            addForm.setFieldsValue({
                name:record.shieldword
            });
        }
    }

    const addFilterWords = () => {
        setIsModalOpen(true);
        setEditObj(false);
        setModalTitle(activeKey === 'blockWords' ? '屏蔽词': "关键词");
    }

    const handleOk = async() => {
        try {
            const row = await addForm.validateFields();
            setConfirmLoading(true);
            let res = null;
            if(activeKey === 'blockWords'){
                // 屏蔽词
                let param = {
                    shieldword: row.name,
                    policyLableThemeId:policyLableThemeId,
                }
                
                if(editObj && editObj.id){
                    param.id = editObj.id;
                    res = await Api.Policy.editShieldWordsPage({data:param});
                }else{
                    res = await Api.Policy.addShieldWordsPage({data:param});
                }
            }else if(activeKey === 'filterWords'){
                // 关键词
                let param = {
                    keyword: row.name,
                    policyLableThemeId:policyLableThemeId,
                }
                if(editObj && editObj.id){
                    param.id = editObj.id;
                    res = await Api.Policy.editKeyWordsPage({data:param});
                }else{
                    res = await Api.Policy.addKeyWordsPage({data:param});
                }
            }
            if(res.code === 0){
                message.success(res.msg,2,()=>{
                    setConfirmLoading(false);
                    if(activeKey === 'blockWords'){
                        getShieldWordsRuleList();
                    }else{
                        getKeyWordsRuleList();
                    }
                    handleCancel();
                })

                // 重新获取列表数据
                getPolicyList();
            }
            
        }catch(error){setConfirmLoading(false);}
    }

    const handleCancel = () => {
        setIsModalOpen(false);
        setEditObj(false);
        setModalTitle("");
        addForm.resetFields();
    }

    const applyText = async(value) => {
        try {
            setSwitchLoading(true)
            let res = null;
            if(activeKey === 'blockWords'){
                res = await Api.Policy.setShieldWordsRule({data:{
                    shieldInText: value ? 1 : 0, //0不作用 1作用
                    policyLableThemeId:policyLableThemeId,
                }});
            }else if(activeKey === 'filterWords'){
                res = await Api.Policy.setKeyWordsRule({data:{
                    keyInText: value ? 1 : 0, //0不作用 1作用
                    policyLableThemeId:policyLableThemeId,
                }});
            }
            if(res.code === 0){
                message.success(res.msg,2,()=>{
                    setConfirmLoading(false);
                    if(activeKey === 'blockWords'){
                        getShieldWordsRule();
                    }else{
                        getKeyWordsRule();
                    }
                    handleCancel();
                    setSwitchLoading(false);
                    // 重新获取列表数据
                    getPolicyList();
                })
            }
        }catch(error){}
    }

    const handleFileChange = async() => {
        message.loading({
            content:'导入中..',
            key:"import"
        });
        const file = document.getElementById("uploadWords").files[0];
        
        // const formData = new FormData();
        // formData.append("file",file);
        
        try {
            let res = null, param = {
                file:file,
                policyLableThemeId:policyLableThemeId,
            };
            if(activeKey === 'blockWords'){
                // 屏蔽词
                res = await Api.Policy.exportShieldWordsRule({
                    data:param
                });
            }else if(activeKey === 'filterWords'){
                res = await Api.Policy.exportWordsRule({
                    data:param
                });
            }

            document.getElementById("uploadWords").value = "";
            message.destroy("import");
            if(res&&res.code === 0){
                message.info(res.msg);
                if(activeKey === 'blockWords'){
                    getShieldWordsRuleList();
                }else{
                    getKeyWordsRuleList();
                }
                // 重新获取列表数据
                getPolicyList();
            }
        } catch (errInfo) {
            document.getElementById("uploadWords").value = "";
            message.destroy("import");
            console.log('验证失败:', errInfo);
        }
    }

    // 系统推荐屏蔽词
    const systemRecommendsWords = async() => {
        try {
            message.loading({
                content:'导入中..',
                key:"exportBySystem"
            });

            let res = res = await Api.Policy.exportShieldBySystem({
                data:{
                    policyLableThemeId:policyLableThemeId,
                }
            });
            message.destroy("exportBySystem");
            if(res&&res.code === 0){
                message.info(res.msg);
                getShieldWordsRuleList();
                // 重新获取列表数据
                getPolicyList();
            }
        } catch (errInfo) {
            message.destroy("exportBySystem");
        }
    }

    

    return (
        <div className="policyDataSourceFrame">
            <Tabs items={items} onChange={onChange} activeKey={activeKey}/>

            {activeKey === 'timeRage' && <div className='timeRage'>
                <div className='timeTopBox'>
                    <span className='title'>数据起始时间</span>
                    <div className='switchBox'>
                        <Switch 
                            checked={allDataChecked}
                            onChange={(value, dateString)=>onChangeConditionData(value, dateString,"allData")} 
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                        /> 全部时段
                    </div>
                </div>
                <div>
                    <DatePicker 
                        value={startData}
                        onChange={onChangeConditionData} 
                        disabled={allDataChecked}
                        suffixIcon={<ClockCircleOutlined />}
                        style={{width: "100%"}}
                    />
                </div>
            </div>}

            {(activeKey === 'blockWords' || activeKey === 'filterWords') && <div className='blockWords'>
                <div className='filterWordsTitle'>{activeKey === 'filterWords' ? '设置应当包含的关键词':'设置不应当包含的关键词'}</div>
                <div className='switchBox'>
                    <Space>
                        是否作用于正文<Switch 
                            loading={switchLoading}
                            checked={shieldInText}
                            onChange={(value, dateString)=>applyText(value)} 
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                        /> 
                    </Space>
                </div>
                <div className='tableTitle'>
                    <span className='title'>关键词列表</span>

                    {selectedRowKey?.length > 0 ? <div className='selectStatusBox'>
                        已选择{selectedRowKey.length}项<Link style={{marginLeft:5}} onClick={() => setSelectedRowKey([])}>取消选择</Link>
                        <Button onClick={deleteWords} type="primary" ghost shape="round" size='small' style={{marginLeft:10}}>移除</Button>
                    </div>:<Space>
                        {showSearch?<div className='inputSearchBox'>
                            <Input
                                size='small'
                                placeholder=""
                                allowClear
                                value={keyWordSearchValue}
                                onChange={(e)=>{
                                    setKeyWordSearchValue(e.target.value);
                                }}
                                onPressEnter={onKeyWordSearch}
                            />
                            <SearchOutlined />
                        </div>:<SearchOutlined onClick={()=>setShowSearch(true)}/>}
                        <PlusSquareOutlined onClick={addFilterWords}/>
                        <Tooltip 
                            title="上传过滤词"
                            placement="topRight"
                        >
                            <div className='uploadWordsBox'>
                                <UploadOutlined />
                                <input 
                                    type="file" 
                                    name="" 
                                    id="uploadWords"
                                    className='uploadWords'
                                    accept=".xls,.xlsx,.xlsm,.doc,.docx,.docm,.pdf,.ppt,.pptx,.pptm,application/pdf,application/msword,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.presentationml.presentation"
                                    onChange={handleFileChange}
                                />
                            </div>
                        </Tooltip >
                    </Space>}
                </div>

                {
                    (
                        (activeKey === 'blockWords' && blockWordsListData.length <= 0) ||
                        (activeKey === 'filterWords' && filterWordsListData.length <= 0)
                    ) ? <Empty
                        description={
                            <span>
                                暂无数据
                            </span>
                        }
                    >
                        {activeKey === 'blockWords'&&<span>
                            点击使用 <a href="#API" onClick={systemRecommendsWords}>系统推荐屏蔽词</a>
                        </span>}
                    </Empty>:
                    <SelfTable
                        rowRadioSelection={{
                            selectedRowKey:selectedRowKey,
                            setSelectedRowKey:setSelectedRowKey,
                            type:"checkbox",
                            preserveSelectedRowKeys:true
                        }}
                        rowKey={"id"}
                        bordered={false}
                        isShowSort={false}
                        columns={activeKey === 'blockWords' ? blockWordsColumns : filterWordsColumns}
                        dataSource={activeKey === 'blockWords' ? blockWordsListData : filterWordsListData}
                        loading={tableLoading}
                        pagination={{
                            ...wordsPagination,
                            simple:true,
                            showTotal:false
                        }}
                        onChange={onWordsTableChanges} 
                        
                    /> 
                }
            </div>}


            {activeKey === 'data' && <div className='dataSource'>
                <div className='filterWordsTitle'>勾选需要的数据来源</div>
                <div className='tableTitle'>

                    {/* <div className='selectStatusBox'>
                        已选择{selectedRowKey.length}项<Link style={{marginLeft:5}} onClick={() => setSelectedRowKey([])}>取消选择</Link>
                        <Button onClick={deleteWords} type="primary" ghost shape="round" size='small' style={{marginLeft:10}}>移除</Button>
                    </div>

                    <span className='title'>关键词列表</span> */}

                    {selectedRowKey?.length > 0 ? <div className='selectStatusBox'>
                        已选择{selectedRowKey.length}项<Link style={{marginLeft:5}} onClick={() => setSelectedRowKey([])}>取消选择</Link>
                        <Button onClick={removeSourceData} type="primary" ghost shape="round" size='small' style={{marginLeft:10}}>移除</Button>
                    </div>:<Space>
                        {showSearch?<div className='inputSearchBox'>
                            <Input
                                size='small'
                                placeholder=""
                                allowClear
                                value={sourceSearchValue}
                                onChange={(e)=>{
                                    setSourceSearchValue(e.target.value);
                                }}
                                onPressEnter={onSourceSearch}
                            />
                            <SearchOutlined />
                        </div>:<SearchOutlined onClick={()=>setShowSearch(true)}/>}
                    </Space>}
                </div>

                <div>
                    <SelfTable
                        rowRadioSelection={{
                            selectedRowKey:selectedRowKey,
                            setSelectedRowKey:setSelectedRowKey,
                            type:"checkbox",
                            preserveSelectedRowKeys:true,
                            getCheckboxProps: (record) => ({
                                disabled: record.isChoose != 1,
                            }),
                        }}
                        rowKey={"source"}
                        bordered={false}
                        isShowSort={false}
                        columns={sourceDataColumns}
                        dataSource={sourceDataList}
                        loading={tableLoading}
                        pagination={{
                            ...pagination,
                            simple:true,
                            showTotal:false
                        }}
                        onChange={onSourceTableChanges} 
                    /> 
                </div>
            </div>}




            <Modal
                title={<div className='modalTitle'><ExclamationCircleOutlined />{editObj ? '修改' : "新增"}{modalTitle}</div>}
                open={isModalOpen} 
                onOk={handleOk} 
                onCancel={handleCancel}
                wrapClassName="addWordsModal"
                confirmLoading={confirmLoading}
            >
                <Form
                    form={addForm}
                    component={false}
                    labelCol={{span: 4}}
                    wrapperCol={{span: 20}}
                    preserve={false}
                >
                    <Form.Item
                        key={'name'}
                        name={'name'}
                        label={modalTitle}
                        rules={[{
                            required: true,
                            message: `请输入${modalTitle}!`
                        }]}
                    >
                        <Input/>
                    </Form.Item>
                </Form>
            </Modal>
            
        </div>)
}

export default PolicyDataSource;



