import react, {useEffect,useState} from 'react';
import { message, Typography, Select, DatePicker, Spin, Input, Button, Divider, Radio, Collapse, Tag, Checkbox, Tabs, Tooltip,Empty } from 'antd';
import { BulbOutlined, ApartmentOutlined, FileSearchOutlined, DownOutlined, UpOutlined, SearchOutlined } from '@ant-design/icons';
import { useDispatch } from "react-redux";

import Iconfont from "../../../components/Iconfont/Iconfont";
import Image from "../../../components/Image/Image";
import ContentList from "./contentList";
import { setAIModalState, refreshPublicKnowledge } from "../../../features/AIIntelligent/AIReducer";
import Api from "../../../api";
import storage from "../../../utils/storage";
import {getTodayDate, getWeekDate} from "../../../utils/tool";
import {serachType,pagingParam} from '../../../utils/defaultValue'
import './index.scss';

const { Text, Link } = Typography;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const { Search } = Input;

const KnowledgeSearch = ({pageType, addPublicKnowledge, defaultValue, onAddCarBtn, singleChoice, dataType}) => {
    const isCar = pageType === "car";

    const dispatch = useDispatch();

    let searchTypeList = [{
        label: (
            <span>
              <BulbOutlined /> 知识
            </span>
        ),
        key: 'knowledge',
    }];

    if (!isCar) {
        searchTypeList.push(
            {
                label: (
                    <span>
                  <ApartmentOutlined /> 政策
                </span>
                ),
                key: 'policy',
            },
            {
                label: (
                    <span>
                  <FileSearchOutlined /> 研报
                </span>
                ),
                key: 'report',
            },
        )
    }
    if(dataType === "policy"){
        searchTypeList = [{
            label: (
                <span>
              <ApartmentOutlined /> 政策
            </span>
            ),
            key: 'policy',
        }]
    }

    const sortTypeList = [
        {text: "按地理分区", key: "geographic"},
        {text: "按赛迪分区", key: "saidiCity"},
    ];// 赛迪分区(saidiCity) / 地理分区(geographic)

    const policyTypeList = [
        {label: "全部", value: "1"},
        {label: "国务院", value: "2"},
        {label: "部委", value: "3"},
        {label: "地方", value: "4"},
    ];

    const ptherKnowledgeType = [
        {
            label:"参考研报",
            key:'7',
            value:'7'
        },
        {
            label:"政策跟踪",
            key:'4',
            value:'4',
        }
    ]

    const [dataRange, setDataRange] = useState([
        {
            value: '0',
            label: '时间不限'
        },
        {
            value: 'today',
            label: '一天内'
        },
        {
            value: 'week',
            label: '一周内'
        },
        {
            value: 'custom',
            label: '自定义'
        }
    ]);

    const [searchType, setSearchType] = useState("knowledge");
    const [loadingState, setLoadingState] = useState(false);
    const [rangeKey, setRangeKey] = useState('0');
    const [selecthValue, setSelecthValue] = useState('1');
    const [accurateType, setAccurateType] = useState('0');

    const [searchValue, setSearchValue] = useState('');
    const [sortKey, setSortKey] = useState('0');
    const [collapseKey, setCollapseKey] = useState(['1']);
    const [selectedConditions, setSelectedConditions] = useState(false);
    const [searchData, setSearchData] = useState({
        ...pagingParam,
        "searchType":'1',
        // 'isImportant': 0,
        "sort":'0' //0相关度排序，1阅读量排序 2 时间升序 3时间降序
    });
    // 分页
    const [pagination, setPagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "total":0
    });



    // 列表数据
    const [listDate, setListDate] = useState([]);
    const [listHight, setListHight] = useState(0);

    // 知识
    const [checkedDepartmentAll, setCheckedDepartmentAll] = useState(false);
    const [departmentIndeterminate, setDepartmentIndeterminate] = useState(false);
    const [checkedKnowledgeTypeAll, setCheckedKnowledgeTypeAll] = useState(false);
    const [knowledgeTypeIndeterminate, setKnowledgeTypeIndeterminate] = useState(false);

    const [knowledgeType, setKnowledgeType] = useState([]);
    const [knowledgeTypeId, setKnowledgeTypeId] = useState([]);
    const [knowledgeTypeValue, setKnowledgeTypeValue] = useState([]);

    const [departmentData, setDepartmentData] = useState([]);
    const [departmentId, setDepartmentId] = useState([]);
    const [departmentValue, setDepartmentValue] = useState([]);

    // 政策
    const [policyId, setPolicyId] = useState([]);
    const [tooltipIsOpen, setTooltipIsOpen] = useState(false); // 控制选择地区/部委弹窗是否显示
    const [ministriesList, setMinistriesList] = useState([]);
    const [selectedMinistriesList, setSelectedMinistriesList] = useState([]); // 选择的部委
    const [selectedMinistries, setSelectedMinistries] = useState([]); // 选择的部委对象

    const [regions, setRegions] = useState({
        saidiCity: [],
        geographic: [],
    });
    const [allRegions, setAllRegions] = useState([]);
    const [sortType, setSortType] = useState("geographic");// 赛迪分区(saidiCity) / 地理分区(geographic)
    const [selectedAreaList, setSelectedAreaList] = useState([]); // 选择的地方codes
    const [selectedRegions, setSelectedRegions] = useState([]); // 选择的地方对象
    const [policySearchData, setPolicySearchData] = useState({
        ...pagingParam,
        "searchType":'1',
        "sort":'0' //0相关度排序，1阅读量排序 2 时间升序 3时间降序
    });

    // 研报
    const [reportSearchData, setReportSearchData] = useState({
        ...pagingParam,
        "searchType":'1',
        "sort":'0' //0相关度排序，1阅读量排序 2 时间升序 3时间降序
    });
    // 行业分类
    const [letterList, setLetterList] = useState([]);
    const [IndustryClassification, setIndustryClassification] = useState({});
    const [seletedLetter, setSeletedLetter] = useState("全部");
    const [industryId, setIndustryId] = useState([]);
    const [expandBtnType, setExpandBtnType] = useState(false);
    // 发布机构
    const [letterAgencyList, setLetterAgencyList] = useState([]);
    const [publishingAgency, setPublishingAgency] = useState({});
    const [seletedAgencyLetter, setSeletedAgencyLetter] = useState("全部");
    const [agencyExpandBtnType, setAgencyExpandBtnType] = useState(false);
    const [agencyId, setAgencyId] = useState([]);

    // 是否固定顶部搜素狂
    const [fixedTopSearch, setFixedTopSearch] = useState(false);

    const [checkedList, setCheckedList] = useState({});
    const [checkedCurrentPageAll, setCheckedCurrentPageAll] = useState(false);
    const [checkedCurrentPageList, setCheckedCurrentPageList] = useState({});
    const [currentPageIndeterminate, setCurrentPageIndeterminate] = useState(false);
    const [selectedTotal, setSelectedTotal] = useState(0);

    const [searchFileType, setSearchFileType] = useState('ordinary');

    useEffect(()=>{
        let _searchData = storage.getItem('searchData');
        console.log(_searchData,"_searchData")
        if(_searchData && !isCar){
            // 知识中心带入的数据
            let searchDataJson = JSON.parse(_searchData);
            let _searchType = sessionStorage.getItem("searchType") || 'knowledge';
            setSearchType(_searchType);
            setSelecthValue(searchDataJson.searchType? searchDataJson.searchType : "1");
            setSearchValue(searchDataJson.text);

            storage.removeItem('searchData');
            storage.removeItem('searchType');

            if(_searchType === 'knowledge') {
                setParamKnowledge(searchDataJson);
            }else if(_searchType === 'policy'){
                setParamPolicy(searchDataJson);
            }else if(_searchType === 'report'){
                setParamReport(searchDataJson);
            }

        }else{

            // 第一次进入页面默认勾选上部门的全部按钮，但是后面的具体部门不需要勾选
            setCheckedDepartmentAll(true);
            // 类型默认勾选上除 政策和研报外的其他类型
            setCheckedKnowledgeTypeAll(true);
        }


        getMinistriesList();
        getRegionsList();
        getIndustryList();
        getIssuerList();

        if(isCar){
            setCollapseKey(['0']);
        }

        if(dataType === "policy"){
            setSearchType('policy')
        }

        return () => {
            removeEventListenerF();
        }
    },[]);

    useEffect(()=>{
        if(listDate && listDate.length > 0){
            if(!isCar){
                addEventListenerF();
            }
        }
    },[listDate]);

    useEffect(()=>{
        if(defaultValue){
            setSearchValue(defaultValue);
            onSearch(defaultValue);
        }
    },[defaultValue]);

    useEffect(()=>{
        let divHeight= document.querySelector('#root');
        setListHight(divHeight.offsetHeight-259);
    },[]);

    useEffect(()=>{
        showSelectedConditions();
        if(
            // 搜素输入框必须有值
            searchValue && searchData.text
        ){
            if(searchType === 'policy'){
                getPolicyList();
            }else{
                getResult();
            }
            if(!isCar){
                storage.setItem('searchData',JSON.stringify(searchData));
            }
        }
    },[JSON.stringify(searchData)]);


    const addEventListenerF = () => {
        const dom = document.querySelector('#knowledgeSearchFrameScroll');
        console.log('dom',dom);
        if(dom) {
            dom.addEventListener("scroll",useFn);
        }
    }

    const removeEventListenerF = () => {
        const dom = document.querySelector('#knowledgeSearchFrameScroll');
        if(dom) {
            dom.removeEventListener("scroll",useFn);
        }
    }

    const isTouchBottom = () => {

        let scrollTop = Math.ceil(document.querySelector('#knowledgeSearchFrameScroll').scrollTop);
        // console.log('【滚动距离】', scrollTop);

        if(scrollTop > 0){
            setCollapseKey(['0']);
            setFixedTopSearch(true);
        }else if(scrollTop === 0){
            // setCollapseKey(['1']);
            setFixedTopSearch(false);
        }
    };

    const useFn = () => {
        // 此处调用 加载更多函数
        isTouchBottom();
    };

    // 显示搜索条件 - 知识
    const setParamKnowledge = (searchDataJson) => {
        let _knowledgeTypeId = [];

        if(searchDataJson.knowledgeTypeIds){
            setKnowledgeTypeId(searchDataJson.knowledgeTypeIds)
        }

        delete searchDataJson.departmentSubclassificationId;
        delete searchDataJson.policyRegionId
        delete searchDataJson.policyThemeId

        if(searchDataJson.endTime || searchDataJson.startTime){
            setRangeKey(sessionStorage.rangeType)
        }

        let _defalutsearchData = {
            ...searchData,
            ...searchDataJson
        };

        // 查询数据
        setSearchData(_defalutsearchData);

        setCollapseKey(['1'])

        if(_knowledgeTypeId[0] != '4'){
            setDepartmentId(searchDataJson.departmentIds ? searchDataJson.departmentIds : [])
        }

        if(!searchDataJson.departmentIds || searchDataJson.departmentIds.length <= 0){
            // 第一次进入页面默认勾选上部门的全部按钮，但是后面的具体部门不需要勾选
            setCheckedDepartmentAll(true);
        }
        if(!searchDataJson.knowledgeTypeIds || searchDataJson.knowledgeTypeIds.length <= 0){
            // 类型默认勾选上除 政策和研报外的其他类型
            setCheckedKnowledgeTypeAll(true);
        }
    }

    // 显示搜索条件 - 政策
    const setParamPolicy = (searchDataJson) => {

        let _defalutsearchData = {
            ...searchData,
            type:searchDataJson.type,
            text:searchDataJson.text,
            searchType:searchDataJson.searchType,
            policyRegionId:searchDataJson.policyRegionId
        };

        setPolicyId(searchDataJson.policyRegionId === '2' ? '4': searchDataJson.type === '0' ? '2' : searchDataJson.type === '1' ? '3' : '1');
        setSearchData(_defalutsearchData);
    }
    // 显示搜索条件 - 研报
    const setParamReport = (searchDataJson) => {
        let _defalutsearchData = {
            ...searchData,
            knowledgeType: '7',
            text:searchDataJson.text,
            searchType:searchDataJson.searchType,
        };
        setSearchData(_defalutsearchData);
    }

    // useEffect(()=>{
    //     if(
    //         // 搜素输入框必须有值
    //         searchValue &&
    //         searchData.text &&
    //         searchType === 'knowledge'
    //     ){
    //         console.log(searchValue,searchData,"请求调用")
    //         storage.setItem('searchData',JSON.stringify(searchData))
    //         getResult();
    //     }
    // },[
    //     searchData.sort,
    //     JSON.stringify(searchData.knowledgeTypeIds),
    //     JSON.stringify(searchData.departmentIds),
    //     searchData.startTime,
    //     searchData.endTime,
    //     searchData.pageIndex,
    //     searchData.pageSize,
    //     searchData.text,
    //     searchData.searchType,
    //     searchData.countryDeptIds
    // ]);

    // useEffect(()=>{
    //     if(
    //         // 搜素输入框必须有值 - 政策
    //         searchValue &&
    //         policySearchData.text &&
    //         searchType === 'policy'
    //     ){
    //         storage.setItem('searchData',JSON.stringify(policySearchData))
    //         getPolicyList();
    //     }
    // },[JSON.stringify(policySearchData)]);

    // useEffect(()=>{
    //     if(
    //         // 搜素输入框必须有值 - 研报
    //         searchValue &&
    //         reportSearchData.text &&
    //         searchType === 'report'
    //     ){
    //         storage.setItem('searchData',JSON.stringify(reportSearchData))
    //         getResult({...reportSearchData,knowledgeTypeId:'7'});
    //     }
    // },[JSON.stringify(reportSearchData)]);



    useEffect(()=>{
        if(knowledgeTypeId&&knowledgeType.length>0){
            let names = [], _knowledgeType = [...knowledgeType,...ptherKnowledgeType];

            _knowledgeType.map(item=>{
                if(knowledgeTypeId.includes(item.key)){
                    names.push(item)
                }
            })
            setKnowledgeTypeValue(names)
        }
        if(departmentId&&departmentData&&departmentData.length>0){
            let names = [];
            departmentData.map(item=>{
                if(departmentId.includes(item.key)){
                    names.push(item);

                }
            })
            setDepartmentValue(names)
        }

    },[knowledgeTypeId, departmentId, knowledgeType, departmentData]);


    useEffect(()=>{
        getKnowledgeList();
    },[JSON.stringify(departmentId)]);

    useEffect(()=>{
        getDepartmentList();
    },[JSON.stringify(knowledgeTypeId)]);

    const showSelectedConditions = () => {
        if(
            (knowledgeTypeId && knowledgeTypeId.length>0) || (departmentId && departmentId.length>0)
            || rangeKey!='0'
            // 政策
            || (selectedMinistries && selectedMinistries.length>0)
            || (selectedRegions && selectedRegions.length>0)
            // 研报
            || (industryId && industryId.length>0)
            || (agencyId && agencyId.length>0)
        ){
            setSelectedConditions(true);
        }else{
            setSelectedConditions(false);
        }
    }

    // 查询行业分类
    const getIndustryList = async(value) => {
        try {
            let res = await Api.Common.getIndustry({data:{name:value}});
            if(res && res.code === 0 && res.data){
                let mewData = [] ,allDate = [], letters = ['全部'];
                for (let key in res.data){
                    allDate = [...allDate,...res.data[key]];
                    letters.push(key)
                }
                mewData = {
                    '全部':allDate,
                    ...res.data
                }
                setLetterList(letters)
                setIndustryClassification(mewData)
            }else{}
        } catch (errInfo) {
            console.log('验证失败:', errInfo);
        }
    }

    // 查询发布机构
    const getIssuerList = async(value) => {
        try {
            let res = await Api.Common.getIssuer({data:{name:value}});
            if(res && res.code === 0 && res.data){
                let mewData = [] ,allDate = [], letters = ['全部'];
                for (let key in res.data){
                    res.data[key].sort(function (a, b) {
                        return b.length - a.length ; // 按照文本长度从大到小排序
                    });
                    allDate = [...allDate,...res.data[key]];
                    letters.push(key)
                }
                allDate.sort(function (a, b) {
                    return b.length - a.length ; // 按照文本长度从大到小排序
                });
                mewData = {
                    '全部':allDate,
                    ...res.data
                }
                setLetterAgencyList(letters)
                setPublishingAgency(mewData)
            }else{}
        } catch (errInfo) {
            console.log('验证失败:', errInfo);
        }
    }


    // 重置知识的筛选项
    const restKnowledge = () => {
        // 默认勾选上部门的全选，但是后面的具体部门不需要勾选
        setDepartmentIndeterminate(false);
        setCheckedDepartmentAll(true);
        // 默认勾选上类型
        setKnowledgeTypeIndeterminate(false);
        setCheckedKnowledgeTypeAll(true);
        setDepartmentId([]);
        setKnowledgeTypeId([]);

        setDepartmentValue([]);
        setKnowledgeTypeValue([]);
    }
    // 重置政策的筛选项
    const restPolicy = () => {
        setPolicyId('1');
        setSelectedMinistriesList([]);
        setSelectedAreaList([]);
        setSelectedRegions([]);
        setSelectedMinistries([]);
    }
    // 重置研报的筛选项
    const restReport = () => {
        setIndustryId([]);
        setAgencyId([]);
    }

    const restDepartment = () => {
        setDepartmentId([]);
        setDepartmentValue([]);
        setDepartmentData([]);

        if(searchData.departmentIds){
            let _searchData = {...searchData}
            _searchData.departmentIds = [];
            console.log(_searchData,14)
            setSearchData(_searchData);
        }
    }

    const getMinistriesList = async() => {
        // 0 国务院 1 部委
        try {
            let res = await Api.Common.getDepartments({data:{type:1}});
            if(res && res.code === 0 && res.data){
                setMinistriesList(res.data[0].childList || [])
            }else{}
        } catch (errInfo) {
            console.log('验证失败:', errInfo);
        }
    }
    const getRegionsList = async() => {
        try {
            let res = await Api.Common.getRegions({});
            if(res && res.code === 0 && res.data){
                setRegions(res.data)
            }else{}
        } catch (errInfo) {
            console.log('验证失败:', errInfo);
        }

        try {
            let res = await Api.Common.getDepartments({data: {type: 2}});
            if (res && res.code === 0 && res.data) {
                setAllRegions(res.data[0].childList || [])
            } else {
            }
        } catch (errInfo) {
            console.log('验证失败:', errInfo);
        }
    }

    const getResult = async(value) => {

        setLoadingState(true);

        let _data = value ? {...value} : {
            ...searchData,
            searchResearchReport:false, //不查研报数据
        }

        if(searchData.knowledgeType === '7'){
            delete _data.searchResearchReport
        }

        // 一个类型都不选的时候默认不查研报数据
        // if(!searchData.knowledgeTypeIds || (searchData.knowledgeTypeIds&&searchData.knowledgeTypeIds.length <= 0)){
        //     _data.searchResearchReport = false
        // }

        try {
            const res = await Api.Knowledge.getESSearch({data:_data})
            setLoadingState(false);
            if(res && res.code === 0 && res.data && res.data.records){
                setListDate(res.data.records);
                setPagination({
                    "pageIndex": res.data.current,
                    "pageSize": res.data.size,
                    "total":res.data.total,
                })
                if(isCar){
                    setCheckedStatus(res.data.records, res.data.current);
                }
            }else{
                setListDate([]);
                setPagination({
                    "pageIndex": 0,
                    "pageSize": 0,
                    "total":0
                })
            }
        }catch{
            setLoadingState(false);
            setListDate([]);
            setPagination({
                "pageIndex": 0,
                "pageSize": 0,
                "total":0
            })
        }
    }

    const getPolicyList = async() => {

        setLoadingState(true);
        let param = {
            ...searchData,
            search : searchData.text,
            searchPlace:666
        }

        delete param.text;


        try {
            let res = await Api.Knowledge.getPolicyPageList({data:param});
            setLoadingState(false);
            if(res && res.code === 0 && res.data.records){
                setListDate(res.data.records)
                setPagination({
                    "pageIndex": res.data.current,
                    "pageSize": res.data.size,
                    "total":res.data.total,
                })
                if(isCar){
                    setCheckedStatus(res.data.records, res.data.current);
                }
            }else{setListDate([]);}
        } catch (errInfo) {
            setListDate([]);
            setLoadingState(false);
            console.log('验证失败:', errInfo);
        }
    }

    const setCheckedStatus = (list, pageIndex) => {
        if(checkedList[pageIndex]){
            setCurrentPageIndeterminate(!!checkedList[pageIndex].length && checkedList[pageIndex].length < list.length);
            if(checkedList[pageIndex].length === list.length){
                setCheckedCurrentPageAll(true)
            }
        }else{
            setCheckedCurrentPageAll(false);
            setCurrentPageIndeterminate(false);
        }

        dispatch(refreshPublicKnowledge({
            publicCheckedList:checkedList,
            publicPageIndex:pageIndex
        }))
    }

    const onSearch = (value) =>{
        let searchValueTrim = value ? value.trim() : searchValue.trim();
        if(searchValueTrim){
            setSearchValue(searchValueTrim);
            let param = {
                text:searchValueTrim,
                searchType:selecthValue,
                pageIndex:1,
                advancedSearch: accurateType == '1' ? true : false,
            }
            if(searchType === 'knowledge'){
                setSearchData({
                    ...searchData,
                    ...param
                })
            }else if(searchType === 'policy'){
                setSearchData({
                    ...searchData,
                    ...param
                })
            }else if(searchType === 'report'){
                setSearchData({
                    ...searchData,
                    ...param
                })
            }
        }else{
            message.info('请输入需要搜索的内容')
        }
    }

    const getDepartmentList = async() => {
        let departmentData = [];

        // 获取部门列表
        try {
            const resDepartment = await Api.Department.getDepartmentListAll({data:{
                knowledgeTypeIds:knowledgeTypeId,
                knowledgeRel:0
            }});
            if(resDepartment&&resDepartment.code === 0){

                var departmentNamesArr = [...resDepartment.data];
                departmentNamesArr.sort(function (a, b) {
                    return b.name.length - a.name.length ; // 按照文本长度从大到小排序
                });

                departmentNamesArr.map(item=>{
                    departmentData.push({
                        label:item.name,
                        key:item.id,
                        value:item.id
                    })
                })

            }
        }catch{}

        setDepartmentData(departmentData);
    }

    const getKnowledgeList = async(type) => {
        let knowledgeData=[],newKnowledgeTypeId = [];

        // 获取知识类型
        try {
            const res = await Api.Knowledge.getPutKnowledgeType({data:{
                departmentIds:departmentId
            }});
            if(res && res.code === 0){
                res.data.map(item=>{

                    newKnowledgeTypeId.push(item.id)

                    knowledgeData.push({
                        label:item.knowledgeTypeName,
                        key:item.id,
                        value:item.id,
                    })
                })
            }
        }catch{}

        // if(type === "defalut"){
        //     setKnowledgeTypeId([...newKnowledgeTypeId]);
        //     setCheckedKnowledgeTypeAll(true);
        // }

        // let _searchData = {...searchData}

        // // 政策跟踪
        // if(knowledgeTypeId[0] === '4' || knowledgeTypeId[0] === '7'){
        //     setKnowledgeTypeIndeterminate(false);
        //     setDepartmentIndeterminate(false);
        //     // setDisabledCheckbox(true);

        //     if(knowledgeTypeId[0] === '4'){
        //         // 政策跟踪时 - 不允许选择部门 - 重置搜索
        //         setDepartmentData([]);
        //     }

        //     _searchData.knowledgeTypeIds = knowledgeTypeId;
        //     _searchData.pageIndex = 1;
        //     console.log(_searchData,'2-1')
        //     setSearchData(_searchData);

        // }else if(knowledgeTypeId && knowledgeTypeId.length > 0){
        //     let ids = [];
        //     knowledgeTypeId.map(item=>{
        //         if(newKnowledgeTypeId.includes(item)){
        //             ids.push(item)
        //         }
        //     })

        //     _searchData.knowledgeTypeIds = ids;
        //     _searchData.pageIndex = 1;
        //     console.log(_searchData,'2-2')
        //     setSearchData(_searchData);
        //     setKnowledgeTypeId(ids);
        // }

        setKnowledgeType(knowledgeData);
    }

    // tabs改变
    const knowledgeChange = (checkedValue) => {
        // let value = (e&&e.target) ? e.target.value : '';
        // if(!value){
        //     setKnowledgeTypeValue('');
        // }

        // // 选择政策跟踪时重置回全文状态
        // if(value === '4'){
        //     setSelecthValue('1')
        // }
        // setKnowledgeTypeId(value);

        // let _searchData = {...searchData}
        // _searchData.knowledgeTypeId = value;
        // _searchData.pageIndex = 1;
        // setSearchData(_searchData);

        if(!checkedValue || checkedValue.length <= 0){
            setKnowledgeTypeValue('');
            // setDisabledRadio(false)
        }else{
            // setDisabledRadio(true)
        }

        setKnowledgeTypeId(checkedValue);

        let _searchData = {...searchData}
        _searchData.knowledgeTypeIds = checkedValue;
        _searchData.pageIndex = 1;
        console.log(_searchData,3)
        setSearchData(_searchData);

        setCheckedKnowledgeTypeAll(checkedValue.length === knowledgeType.length && checkedValue.length>0)
        setKnowledgeTypeIndeterminate(!!checkedValue.length && checkedValue.length < knowledgeType.length);
    }

    //  研报 政策
    const ontherKnowledgeChange = (checkedValue) => {

        let value = checkedValue.length > 1 ? checkedValue[1] : checkedValue.length > 0 ? checkedValue[0]: "";

        if(!value){
            setKnowledgeTypeValue('');
            // setDisabledCheckbox(false)
        }else{
            // setDisabledCheckbox(true)
        }

        // 选择政策跟踪时重置回全文状态
        if(value === '4'){
            setSelecthValue('1')
        }

        setKnowledgeTypeId(value?[value]:[]);
        setCheckedKnowledgeTypeAll(false)
        setKnowledgeTypeIndeterminate(false);

        let _searchData = {...searchData}
        _searchData.knowledgeTypeIds = value?[value]:[];
        _searchData.pageIndex = 1;
        console.log(_searchData,4)
        setSearchData(_searchData);
    }

    const departmentChange = (checkedValue) => {
        if(!checkedValue || checkedValue.length <= 0){
            setDepartmentValue([]);
        }else{

        }
        setDepartmentId(checkedValue);
        let _searchData = {...searchData}
        _searchData.departmentIds = checkedValue;
        _searchData.pageIndex = 1;
        console.log(_searchData,5)
        setSearchData(_searchData);

        setCheckedDepartmentAll(checkedValue.length === departmentData.length && checkedValue.length > 0)
        setDepartmentIndeterminate(!!checkedValue.length && checkedValue.length < departmentData.length);

    }

    const delectDepartment = (e) => {

        let _departmentId = departmentId.filter(item=>item!=e);

        departmentChange(_departmentId)

    }

    const delectKnowledgeChange = (e) => {

        let _knowledgeTypeId = knowledgeTypeId.filter(item=>item!=e);

        if(!_knowledgeTypeId || _knowledgeTypeId.length<=0){
            // setDisabledRadio(false);
            // setDisabledCheckbox(false);
        }

        knowledgeChange(_knowledgeTypeId);

    }

    // const departmentChange = (e) => {
    //     let value = (e&&e.target) ? e.target.value : ''
    //     if(!value){
    //         setDepartmentValue('');
    //     }
    //     setDepartmentId(value);

    //     let _searchData = {...searchData}
    //     _searchData.departmentId = value;
    //     _searchData.pageIndex = 1;
    //     setSearchData(_searchData);
    // }

    // 快捷时间范围
    const rangeChange = (e) => {
        let value = (e&&e.target) ? e.target.value : '0';
        sessionStorage.rangeType = value;
        setRangeKey(value);
        if(value == 'today'){
            dateChange('',getTodayDate())
        }else if(value == 'week'){
            dateChange('',getWeekDate())
        }else if(value != 'custom'){
            dateChange('',['',''])
        }
    }

    // 自定义时间范围
    const dateChange = (date, dateString) => {
        let _searchData = {...searchData}
        _searchData.startTime = dateString[0];
        _searchData.endTime = dateString[1];
        console.log(_searchData,6)
        setSearchData(_searchData)
    }

    // 查讯的文件类型变化
    const searchFileTypeChange = (value) => {
        console.log(value,"valuevaluevalue")
        setSearchFileType(value);
        let _searchData = {...searchData}
        if(value.length === 2){
            // 两个都选了，删除isImportant字段，查讯全部数据
            delete _searchData.isImportant;
        }else{
            // 是否查讯重要文件（0不是 1是）
            _searchData.isImportant = value[0] === 'isImportant' ? 1 : 0;
        }
        setSearchData(_searchData);
    }

    // 分页变化
    const paginationChange = (pageIndex,pageSize) => {
        let _searchData = {...searchData}
        _searchData.pageIndex = pageIndex;
        _searchData.pageSize = pageSize;
        setSearchData(_searchData);
    }

    // 排序变化
    const sortChange = (_sort,value) => {
        changeSearchValue('sort',_sort);
        setSortKey(value)
    }


    const onSearchValueChange = (e) => {
        setSearchValue(e.target.value)
    }

    const onSelect = (value) => {
        setSelecthValue(value);
    }

    const onAccurateTypeSelect = (value) => {
        setAccurateType(value);
    }

    const onChangeCollapse = (value) => {
        setCollapseKey(value);
    }

    // 更新查询参数
    const changeSearchValue = (key,value)=>{
        let _searchData = {...searchData}
        _searchData[key] = value;
        console.log(_searchData,9)
        setSearchData(_searchData);
    }

    // 重置条件
    const clearSelectedParam = () => {
        let _searchData = {
            ...pagingParam,
            "searchType":'1',
            "sort":searchData.sort, //0相关度排序，1阅读量排序 2 时间升序 3时间降序
            "text":searchData.text
        };
        if(searchType === 'report'){
            _searchData.knowledgeType = '7';
        }

        setSearchData(_searchData);
        setRangeKey('0');
        restKnowledge();
        restPolicy();
        restReport();
    }

    const updateParam = (data) => {

        setSearchData(data);
        setSearchData(data);
    }

    const onChangeDepartmentCheckedAll = () => {
        setCheckedDepartmentAll(!checkedDepartmentAll)
        let ids = [];
        if(!checkedDepartmentAll){
            departmentData.map(item=>{
                ids.push(item.value)
            })
            setDepartmentId(ids)
            setDepartmentIndeterminate(false);
        }else{
            setDepartmentId([])
            setDepartmentIndeterminate(false);
        }

        let _searchData = {...searchData}
        _searchData.departmentIds = ids;
        _searchData.pageIndex = 1;
        console.log(_searchData,10)
        setSearchData(_searchData);
    }

    const onChangeKnowledgeTypeCheckedAll = () => {
        setCheckedKnowledgeTypeAll(!checkedKnowledgeTypeAll);
        let ids = [];
        if(!checkedKnowledgeTypeAll){
            knowledgeType.map(item=>{
                ids.push(item.value)
            })
            setKnowledgeTypeId(ids)
            setKnowledgeTypeIndeterminate(false);
            // setDisabledRadio(true);
        }else{
            setKnowledgeTypeId([])
            setKnowledgeTypeIndeterminate(false);
            // setDisabledRadio(false);
        }

        let _searchData = {...searchData}
        _searchData.knowledgeTypeIds = ids;
        _searchData.pageIndex = 1;
        console.log(_searchData,11)
        setSearchData(_searchData);
    }

    // 搜索类型切换
    const onChangeKnowledgeType = (e) => {
        // 重置搜索条件
        let _searchData = {
            ...pagingParam,
            "searchType":'1',
            "sort":'0', //0相关度排序，1阅读量排序 2 时间升序 3时间降序
            "text":searchValue,
            "advancedSearch":searchData.advancedSearch,
        }
        restKnowledge();
        restPolicy();
        restReport();
        setSelecthValue('1');
        // if(e === "knowledge"){
        //     restKnowledge();
        // }

        if(e === "policy"){
            _searchData.knowledgeType = '4';
        }

        if(e === "report"){
            _searchData.knowledgeType = '7';
        }

        sessionStorage.setItem("searchType",e)
        setSearchType(e);
        setSearchData(_searchData);
    }

    // 政策
    const policyTypeChange = (e) => {
        let val = e.target.value;
        setPolicyId(val);

        let _policySearchData = {...searchData}
        _policySearchData.countryDeptIds = [];
        _policySearchData.codes = [];
        _policySearchData.type = val === '2' ? '0' : val === '3' ? '1' : val === '4' ? '2' : ''
        _policySearchData.policyRegionId = val === '4' ? '2' : ''

        setSearchData(_policySearchData);
        setSelectedMinistriesList([]);
        setSelectedAreaList([]);
        setSelectedRegions([]);
        setSelectedMinistries([]);
    }

    const delectPolicySelected = (value,type) => {
        
        let _searchData = {...searchData}
        if(type === 'policy'){
            _searchData.type = '';
            setSearchData(_searchData);
            setPolicyId('1');
            return false;
        }
        let objectDate = [], objectDateCodes = [];
        let ministriesObjectDate = [], ministriesObjectDateCodes = [];
        // if(type === 'ministries'){
            ministriesObjectDate = [...selectedMinistries];
            ministriesObjectDateCodes = [...selectedMinistriesList];

        // }else if(type === 'regions'){
            objectDate = [...selectedRegions];
            objectDateCodes = [...selectedAreaList];

        // }
        
        let newMinistriesObjectDate = ministriesObjectDate.filter(item => {
            if(item.id){
                return item.id !== value
            }else{
                return item.code !== value
            }
        });
        let newMinistriesObjectDateCodes = ministriesObjectDateCodes.filter(item => item !== value);


        let newObjectDate = objectDate.filter(item => {
            if(item.id){
                return item.id !== value
            }else{
                return item.code !== value
            }
        });
        let newObjectDateCodes = objectDateCodes.filter(item => item !== value);
        
        // if(type === 'ministries'){
            setSelectedMinistries(newMinistriesObjectDate)
            setSelectedMinistriesList(newMinistriesObjectDateCodes)
        // }else if(type === 'regions'){
            setSelectedRegions(newObjectDate)
            setSelectedAreaList(newObjectDateCodes)
        // }

        _searchData.countryDeptIds = [...newObjectDateCodes,...newMinistriesObjectDateCodes];


        setSearchData(_searchData);
    }

    // 政策弹窗-选中
    const renderDepartmentList = (selectedCodes) => {
        let allCodeList = [];
        ministriesList.forEach(item => {
            const departmentChildren = item.childList || [];
            departmentChildren.forEach(it => {
                allCodeList.push(it.id);
            })
        });
        const isAllSelected = selectedCodes.length >= allCodeList.length;
        return (
            <div className="regionsPopoverBox departmentPopoverBox">
                <div className="areaListBox">
                    <span
                        className={`regionsAllArea ${isAllSelected ? "selectRegion" : ""}`}
                        onClick={()=>{
                            ministriesChange(isAllSelected ? [] : allCodeList)
                        }}
                    >全部部委</span>
                    <div className="regionsAllListBox">
                        {
                            ministriesList.map((item, idx) => {
                                if(item.childList.length > 0){
                                    let departmentIsAllSelected = true;
                                    const departmentCodeList = [];
                                    for (const addressItem of item.childList) {
                                        const code = addressItem.id;
                                        let flag = selectedCodes.includes(code);
                                        if (departmentIsAllSelected) {
                                            departmentIsAllSelected = flag;
                                        }
                                        departmentCodeList.push(code);
                                    }
                                    return (
                                        <div key={idx} className="regionsList">
                                            <span
                                                className={`regionsArea ${(departmentIsAllSelected || isAllSelected) ? "selectRegion":""}`}
                                                onClick={()=>{
                                                    console.log(item,departmentIsAllSelected,departmentCodeList)
                                                    let codes = [...selectedCodes], ministries = [];;
                                                    if (departmentIsAllSelected) {
                                                        departmentCodeList.forEach(code => {
                                                            let idx = codes.indexOf(code);
                                                            codes.splice(idx, 1);
                                                        })
                                                        // 删除选中
                                                        selectedMinistries.map((it,index)=>{
                                                            if(!departmentCodeList.includes(it.id)){
                                                                ministries.push(it);
                                                            }
                                                        })
                                                    } else {
                                                        departmentCodeList.forEach(code => {
                                                            if (!codes.includes(code)) {
                                                                codes.push(code);
                                                            }
                                                        })
                                                        // 选中大类型
                                                        let newDate = [...selectedMinistries,...item.childList];
                                                        ministries = newDate.filter((item, index, self) => {
                                                            return index === newDate.findIndex((obj2) => obj2.id === item.id)
                                                        });
                                                    }
                                                    ministriesChange(ministries,codes)
                                                }}
                                            >{item.name}</span>
                                            <div className="regionsBox">
                                                {
                                                    item.childList.map(it => {
                                                        const idx = selectedCodes.indexOf(it.id);
                                                        const isSelected = idx > -1;
                                                        return (
                                                            <span
                                                                key={it.id}
                                                                onClick={()=> {
                                                                    let codes = [...selectedCodes], ministries = [...selectedMinistries];
                                                                    if (isSelected) {
                                                                        codes.splice(idx, 1);
                                                                        ministries.splice(idx, 1);
                                                                    } else {
                                                                        codes.push(it.id);
                                                                        ministries.push(it);
                                                                    }
                                                                    ministriesChange(ministries,codes);
                                                                }}
                                                                className={`regionsItem ${(isSelected || departmentIsAllSelected || isAllSelected) ? "selectRegion":""}`}
                                                            >{it.name}</span>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>

                </div>
                <div className="footerOperateBox">
                    <span className="operateBtn cancelBtn" onClick={() => cancelChangeMinistries(selectedMinistries)}>取消</span>
                    <span className="operateBtn confirmBtn" onClick={() => confirmChangeMinistries(selectedMinistries,selectedMinistriesList)}>确定</span>
                </div>
            </div>
        )
    }
    const cancelChangeMinistries = (data) => {
        setTooltipIsOpen(false);
        ministriesChange([data,...(searchData._searchData || [])]);
    }

    const confirmChangeMinistries = (data,codes) => {
        setTooltipIsOpen(false);
        let _policySearchData = {...searchData}
        _policySearchData.countryDeptIds = codes || [];
        setSearchData(_policySearchData);
    }

    const ministriesChange = (data,codes) => {
        setSelectedMinistriesList(codes);
        setSelectedMinistries(data);
    }

    // 地方弹窗-选中
    const renderRegions = (sortType, selectedCodes, selectedDepartCodesList = []) => {
        const areaList = regions[sortType] || [];

        let _codes = [], countryDeptIds = [];
        selectedCodes.map(item=>{
            if(item.length > 5 || item == 156){
                _codes.push(item)
            }else{
                countryDeptIds.push(item)
            }
        })

        // 地理分区
        let allAreaCodeList = [];
        areaList.forEach(areaItem => {
            const addressChildren = areaItem.children || [];
            addressChildren.forEach(addressItem => {
                allAreaCodeList.push(addressItem.code);
            })
        });

        // 地方政府
        let allLocalGovernmentCodeList = [];
        allRegions.forEach(localItem => {
            allLocalGovernmentCodeList.push(localItem.id);
        });

        const isAllSelected = selectedCodes.length >= allAreaCodeList.length;
        const isAllLocalGovernmentSelected = selectedDepartCodesList.length >= allAreaCodeList.length;


        return (
            <div className="regionsPopoverBox">
                <div className="sortTypeListBox">
                    {
                        sortTypeList.map(item => {
                            const {text, key} = item;
                            const isActive = sortType === key;
                            return (
                                <span
                                    className={`sortItem ${isActive ? "activeStatus" : ""}`}
                                    key={key}
                                    onClick={() => {
                                        if (!isActive) {
                                            setSortType(key)
                                        }
                                    }}
                                >{text}</span>
                            )
                        })
                    }
                </div>
                <div className="areaListBox">
                    <div className='regionsPlace'>
                        <span
                            className={`regionsAllArea ${isAllSelected ? "selectRegion" : ""}`}
                            onClick={()=>{
                                regionsChange({
                                    id: 999,
                                    name:""
                                }, isAllSelected ? [] : allAreaCodeList)
                            }}
                        >全部地区</span>
                        <div className="regionsAllListBox">
                            {
                                areaList.map(item => {
                                    let addressIsAllSelected = true;
                                    const addressCodeList = [];
                                    for (const addressItem of item.children) {
                                        const code = addressItem.code;
                                        let flag = selectedCodes.includes(code);
                                        if (addressIsAllSelected) {
                                            addressIsAllSelected = flag;
                                        }
                                        addressCodeList.push(code);
                                    }
                                    return (
                                        <div key={item.id} className="regionsList">
                                            <span
                                                className={`regionsArea ${(addressIsAllSelected || isAllSelected) ? "selectRegion" : ""}`}
                                                onClick={()=>{
                                                    console.log(item)
                                                    let codes = [...selectedCodes], ministries = [];
                                                    if (addressIsAllSelected) {
                                                        addressCodeList.forEach(code => {
                                                            let idx = codes.indexOf(code);
                                                            codes.splice(idx, 1);
                                                        })
                                                        // 删除选中
                                                        selectedRegions.map((it,index)=>{
                                                            if(!addressCodeList.includes(it.code)){
                                                                ministries.push(it);
                                                            }
                                                        })
                                                    } else {
                                                        addressCodeList.forEach(code => {
                                                            if (!codes.includes(code)) {
                                                                codes.push(code);
                                                            }
                                                        })
                                                        // 选中大类型
                                                        let newDate = [...selectedRegions,...item.children];
                                                        ministries = newDate.filter((item, index, self) => {
                                                            return index === newDate.findIndex((obj2) => obj2.code === item.code)
                                                        });
                                                        console.log(ministries,"ministries")
                                                    }
                                                    regionsChange(ministries, codes)

                                                }}
                                            >{item.name}</span>
                                            <div className="regionsBox">
                                                {
                                                    item.children.map(it => {
                                                        const {code, name} = it;
                                                        const idx = selectedCodes.indexOf(code);
                                                        const isSelected = idx > -1;
                                                        return (
                                                            <span
                                                                key={code}
                                                                onClick={() => {
                                                                    let codes = [...selectedCodes], regions = [...selectedRegions];
                                                                    if (isSelected) {
                                                                        codes.splice(idx, 1);
                                                                        regions.splice(idx, 1);
                                                                    } else {
                                                                        codes.push(code);
                                                                        regions.push(it);
                                                                    }
                                                                    regionsChange(regions, codes);
                                                                }}
                                                                className={`regionsItem ${(isSelected || addressIsAllSelected || isAllSelected) ? "selectRegion" : ""}`}
                                                            >{name}</span>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    
                    <div className='localGovernment'>
                        <span
                            className={`regionsAllArea ${isAllLocalGovernmentSelected ? "selectRegion" : ""}`}
                            onClick={() => {
                                ministriesChange(allRegions, isAllLocalGovernmentSelected ? [] : allLocalGovernmentCodeList)
                            }}
                        >全部地方政府机构</span>
                        <div className="regionsAllListBox">
                            <div className="regionsAllList">
                                {
                                    allRegions.map(item => {
                                        let addressIsAllSelected = false;
                                        const addressCodeList = [];
                                        const {id, name} = item;
                                        const idx = selectedDepartCodesList.indexOf(id);
                                        const isSelected = idx > -1;
                                        return (
                                            <span
                                                key={item.id}
                                                // key={code}
                                                onClick={() => {
                                                    let codes = [...selectedDepartCodesList], ministries = [...selectedMinistries];
                                                    if (isSelected) {
                                                        codes.splice(idx, 1);
                                                        ministries.splice(idx, 1);
                                                    } else {
                                                        codes.push(item.id);
                                                        ministries.push(item);
                                                    }
                                                    ministriesChange(ministries,codes);
                                                }}
                                                className={`regionsItem ${(isSelected || addressIsAllSelected || isAllLocalGovernmentSelected) ? "selectRegion" : ""}`}
                                            >{name}</span>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    

                </div>
                <div className="footerOperateBox">
                    <span className="operateBtn cancelBtn"
                          onClick={() => cancelChangeRegions(selectedRegions)}>取消</span>
                    <span className="operateBtn confirmBtn"
                          onClick={() => confirmChangeRegions(selectedRegions, selectedAreaList, selectedDepartCodesList)}>确定</span>
                </div>
            </div>
        )
    }

    const regionsChange = (regions, codes) => {
        setSelectedAreaList(codes);
        setSelectedRegions(regions);
    }
    const cancelChangeRegions = (regions) => {
        setTooltipIsOpen(false);
        regionsChange(regions,[...(searchData.countryDeptIds || [])]);
    }

    const confirmChangeRegions = (region, codes, selectedDepartCodesList) => {
        setTooltipIsOpen(false);
        let _policySearchData = {...searchData}
        _policySearchData.codes = [];
        _policySearchData.countryDeptIds= [...codes,...selectedDepartCodesList] || [],
        setSearchData(_policySearchData);
    }

    // 研报
    const delectReportSelected = (value,type) => {
        let objectDate = [], _searchData = {...searchData};
        if(type === 'industry'){
            objectDate = industryId.filter(item => item !== value);
            _searchData.industrys = objectDate
            setIndustryId(objectDate)
        }else if(type === 'agency'){
            objectDate = agencyId.filter(item => item !== value);
            _searchData.issuers = objectDate
            setAgencyId(objectDate)
        }
        console.log(objectDate,"objectDate")
        setSearchData(_searchData);
    }

    // 行业分类
    const industryChange = (data) => {
        setIndustryId(data)
        setSearchData({
            ...searchData,
            industrys:data,
            pageIndex:1
        })
    }
    // 发布机构
    const agencyChange = (data) => {
        setAgencyId(data)
        setSearchData({
            ...searchData,
            issuers:data,
            pageIndex:1
        })
    }

    const onSearchList = (value,type) => {
        if(type === 'industry'){
            getIndustryList(value)
        }else{
            getIssuerList(value)
        }
    }

    const onAISearch = () => {
        let searchParam = {searchResearchReport:false};
        if(searchValue && searchData.text){
            searchParam = {...searchParam,...searchData}
        }

        dispatch(setAIModalState({
            modalOpen:true,
            questionLocation: 2,
            knowledgeEsParam: searchParam,
            selectKnowledgeParam:{},
            knowledgePublishedParam:{},
            questionModule:"summary",
            knowledgeIds:[],
            sendAPI: null
        }))
    }


    const onAllchange = (e) => {
        let _newList = {...checkedList}
        if(e.target.checked){
            let ids = [];
            listDate.map(item=>{
                if(item.cartStatus === 0){
                    let knowledgeId = item.id || item.knowledgeId || item.policyId || item.elasticId;
                    ids.push(knowledgeId)
                }
            })
            if(ids.length === 0){
                message.info('当前页没有可选择的数据！');
            }
            if(ids.length === listDate.length){
                setCheckedCurrentPageAll(true);
            }else{
                setCheckedCurrentPageAll(false);
            }
            _newList[pagination.pageIndex] = ids;
        }else{
            _newList[pagination.pageIndex] = [];
            setCheckedCurrentPageAll(false)
        }
        let allLength = 0;
        for(let key in _newList){
            allLength += _newList[key].length
        }
        setSelectedTotal(allLength)
        setCheckedList({..._newList})
        dispatch(refreshPublicKnowledge({
            publicCheckedList:_newList,
            publicPageIndex:pagination.pageIndex
        }))
    }


    const checkOnchange = (e, type) => {
        console.log(e)
        if(type === "checkbox"){
            let newCheckedList = {...checkedList}
            let _newList = newCheckedList[pagination.pageIndex];
    
            if(e.target.checked){
                _newList = _newList ? [..._newList,e.target.value]:[e.target.value]
            }else{
                _newList = _newList.filter(item=>item!=e.target.value)
            }
            newCheckedList[pagination.pageIndex] = _newList;
    
    
            // 统计选中的数量
            let allLength = 0;
            for(let key in newCheckedList){
                allLength += newCheckedList[key].length
            }
            setSelectedTotal(allLength);
    
            setCheckedList({...newCheckedList})
            dispatch(refreshPublicKnowledge({
                publicCheckedList:newCheckedList,
                publicPageIndex:pagination.pageIndex
            }))
            setCurrentPageIndeterminate(!!_newList.length && _newList.length < listDate.length);
            if(_newList.length >= listDate.length){
                setCheckedCurrentPageAll(true)
            }
        }else{
            
            let newCheckedList = {...checkedList};
            newCheckedList[pagination.pageIndex] = [e.target.value];
            setCheckedList({...newCheckedList})
        }
        
    }

    const isExpand = collapseKey[0] === '1';
    
    return (<div className={`knowledgeSearchFrame ${isCar?'carPublicModal':''}`}>
        <div className='knowledgeSearchFrameScroll' id='knowledgeSearchFrameScroll'>
            
        
        <Spin tip="数据加载中" spinning={loadingState}>
            <div className={`topSearch ${fixedTopSearch ? 'flexdTop':''}`}>

                <div className='topSearchInput'>

                    <div className="searchBar">
                        <Select
                            value={selecthValue || '全文'}
                            size={"large"}
                            options={searchType === 'policy' ? [ 
                                {
                                    value: '1',
                                    label: '全文'
                                },
                                {
                                    value: '0',
                                    label: '标题'
                                }
                            ]:[...serachType]}
                            onChange={onSelect}
                            bordered={false}
                            style={{width:'5rem'}}>
                        </Select>
                        <Divider className='verticalDivider' type="vertical" />
                        <Select
                            value={accurateType}
                            size={"large"}
                            options={[
                                {
                                    value: '1',
                                    label: '精确'
                                },
                                {
                                    value: '0',
                                    label: '模糊'
                                }
                            ]}
                            onChange={onAccurateTypeSelect}
                            bordered={false}
                            style={{width:'5rem'}}>
                        </Select>
                        <Input
                            value={searchValue}
                            placeholder="输入关键字"
                            size={"large"}
                            bordered={false}
                            onChange={onSearchValueChange}
                            onPressEnter={()=>{onSearch()}}
                            allowClear={true}
                            suffix={isCar?<Iconfont type={"icon-sousu"} onClick={()=>{onSearch()}}/>:null}
                        />
                        {!isCar&&<Button
                        className='searchBtn'
                        onClick={()=>{onSearch()}}
                        type="primary"
                        size={"large"}>搜索一下</Button>}

                    </div>

                    {isCar&&<Button 
                        className='addCarBtn'
                        onClick={()=>{
                            if(singleChoice && dataType === 'policy'){
                                onAddCarBtn(checkedList[pagination.pageIndex])
                            }else{
                                onAddCarBtn();
                            }
                        }} 
                        type="primary" 
                    >
                            确定添加
                    </Button>}

                    {(!isCar && searchType === "knowledge") && <div className="AiBtnBox">
                        <Button
                            icon={<Iconfont type="icon-waixingren" />}
                            onClick={onAISearch}
                            size={"large"}>
                                AI帮我找
                        </Button>
                    </div>}

                </div>

                <div className='toolBox'>
                    <Tabs
                        defaultActiveKey={searchType}
                        onChange={onChangeKnowledgeType}
                        items={searchTypeList}
                        activeKey={searchType}
                    />
                    <span className='moreTool' onClick={()=>{
                        setCollapseKey(isExpand ? ['0'] : ['1'])
                    }}>
                        <Iconfont type={'icon-shaixuan1'}
                            iStyle={{
                                color:"#60B4F6",
                                marginRight:'8px',
                                lineHeight: 'inherit'
                            }}
                        />{isExpand ? "收起工具":"更多工具"}
                    </span>
                </div>

                <Collapse
                    activeKey={collapseKey}
                    ghost
                    expandIcon={()=>null}
                    header={()=>null}
                >
                    <Panel key="1">
                        <div className="moreSearchBar">
                            {searchType === "knowledge"&&<>
                                <div>
                                    <Text type="secondary">所属部门</Text>
                                    <div className="flexBox departmentList">
                                        <div style={{display: 'flex'}}>
                                            <Checkbox
                                                indeterminate={departmentIndeterminate}
                                                checked={checkedDepartmentAll}
                                                onChange={onChangeDepartmentCheckedAll}
                                                style={{float: 'left',minWidth:"110px"}}
                                            >全部</Checkbox>
                                            <Checkbox.Group name="department" onChange={departmentChange} value={departmentId}>
                                                {departmentData.map(item=>{
                                                    return (<Checkbox key={item.value} value={item.value}>{item.label}</Checkbox>)
                                                })}
                                            </Checkbox.Group>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <Text type="secondary">知识类型</Text>
                                    <div className="flexBox knowledgeTypeList">
                                        <div style={{display: 'flex'}}>
                                            <Checkbox
                                                indeterminate={knowledgeTypeIndeterminate}
                                                checked={checkedKnowledgeTypeAll}
                                                onChange={onChangeKnowledgeTypeCheckedAll}
                                                style={{float: 'left'}}
                                            >全部</Checkbox>

                                            <Checkbox.Group
                                                name="knowledgeType"
                                                onChange={knowledgeChange}
                                                value={knowledgeTypeId}
                                            >
                                                {knowledgeType.map(item=>{
                                                    return (<Checkbox key={item.value} value={item.value}>{item.label}</Checkbox>)
                                                })}
                                            </Checkbox.Group>
                                        </div>
                                    </div>
                                </div>
                            </>}

                            {searchType === "policy"&&<>
                                <div>
                                    <Text type="secondary">政策级别</Text>
                                    <div className="flexBox policyList">

                                        <div style={{display: 'flex'}}>

                                            <Radio.Group name="policy" onChange={policyTypeChange} value={policyId}>
                                                {policyTypeList.map(item=>{
                                                    return (<div>
                                                        <Radio key={item.value} value={item.value}>{item.label}</Radio>
                                                        {item.value === '3' && policyId === '3' &&
                                                            <Tooltip
                                                                open={tooltipIsOpen == '3' ? true : false}
                                                                overlayClassName={"areaOrDepartmentTooltipBox"}
                                                                placement={"bottomRight"}
                                                                title={(
                                                                    <div className="areaOrDepartmentContentBox" onClick={e => e.stopPropagation()}>
                                                                        {renderDepartmentList(selectedMinistriesList)}
                                                                    </div>
                                                                )}
                                                                trigger={"click"}
                                                                onClick={e => e?.stopPropagation()}
                                                            >
                                                                <Iconfont onClick={(e) => {
                                                                    setTooltipIsOpen(item.value);
                                                                }} type={"icon-guolvqi"} />
                                                            </Tooltip>
                                                        }
                                                        {item.value === '4' && policyId === '4' &&
                                                            <Tooltip
                                                                open={tooltipIsOpen == '4' ? true : false}
                                                                overlayClassName={"areaOrDepartmentTooltipBox"}
                                                                placement={"bottomRight"}
                                                                title={(
                                                                    <div className="areaOrDepartmentContentBox" onClick={e => e.stopPropagation()}>
                                                                        {renderRegions(sortType, selectedAreaList, selectedMinistriesList)}
                                                                    </div>
                                                                )}
                                                                trigger={"click"}
                                                                onClick={e => e?.stopPropagation()}
                                                            >
                                                                <Iconfont onClick={(e) => {
                                                                    setTooltipIsOpen(item.value);
                                                                }} type={"icon-guolvqi"} />
                                                            </Tooltip>
                                                        }
                                                    </div>)
                                                })}
                                            </Radio.Group>
                                        </div>
                                    </div>
                                </div>
                            </>}

                            {searchType === "report"&&<>
                                <div>
                                    <Text type="secondary">行业分类</Text>
                                    <div className="flexBox reportList">
                                        {expandBtnType&&<div className="letterClassifyList">
                                            {letterList.map(item=>{
                                                return <span className={seletedLetter === item ? 'seletedLetter letterItem' : "letterItem"} key={item.value} onClick={(e) => {
                                                    setSeletedLetter(item);
                                                }}>{item}</span>
                                            })}
                                            <Search
                                                allowClear
                                                className='searchBtn'
                                                placeholder="输入名称搜素"
                                                size='small'
                                                style={{ width: 150,height:24 }}
                                                onSearch={(value)=>{onSearchList(value,"industry")}}
                                            />
                                        </div>}


                                        <div style={!expandBtnType ? {marginRight: 65,height: 24,overflow: 'hidden'} : {}}>
                                            <Checkbox.Group name="report" onChange={industryChange} value={industryId}>
                                                {IndustryClassification[seletedLetter] ? IndustryClassification[seletedLetter].map(value=>{
                                                    return (<Checkbox key={value} value={value}>{value}</Checkbox>)
                                                }):<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                                            </Checkbox.Group>
                                        </div>

                                        <Button size='small' className='expandBtn' onClick={(e) => {
                                            setExpandBtnType(!expandBtnType)
                                        }}>
                                            {expandBtnType?<>收起<UpOutlined /></>:<>展开<DownOutlined /></>}
                                        </Button>
                                    </div>
                                </div>

                                <div>
                                    <Text type="secondary">发布机构</Text>
                                    <div className="flexBox reportList">
                                        {agencyExpandBtnType&&<div className="letterClassifyList">
                                            {letterAgencyList.map(item=>{
                                                return <span className={seletedAgencyLetter === item ? 'seletedLetter letterItem' : "letterItem"} key={item.value} onClick={(e) => {
                                                    setSeletedAgencyLetter(item);
                                                }}>{item}</span>
                                            })}
                                            <Search
                                                allowClear
                                                className='searchBtn'
                                                placeholder="输入名称搜素"
                                                size='small'
                                                style={{ width: 150,height:24 }}
                                                onSearch={(value)=>{onSearchList(value,"agency")}}
                                            />
                                        </div>}


                                        <div style={!agencyExpandBtnType ? {marginRight: 65,height: 24,overflow: 'hidden'} : {}}>
                                            <Checkbox.Group name="report" onChange={agencyChange} value={agencyId}>
                                                {publishingAgency[seletedAgencyLetter]?publishingAgency[seletedAgencyLetter].map(value=>{
                                                    return (<Checkbox key={value} value={value}>{value}</Checkbox>)
                                                }):<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                                            </Checkbox.Group>
                                        </div>

                                        <Button size='small' className='expandBtn' onClick={(e) => {
                                            setAgencyExpandBtnType(!agencyExpandBtnType)
                                        }}>
                                            {agencyExpandBtnType?<>收起<UpOutlined /></>:<>展开<DownOutlined /></>}
                                        </Button>
                                    </div>
                                </div>

                            </>}

                            <div>
                                <Text type="secondary" name="time">时间</Text>
                                <div className="flexBox" style={{marginLeft:8}}>
                                    <Radio.Group onChange={rangeChange} value={rangeKey}>
                                        {dataRange.map(item=>{
                                            return (<Radio key={item.value} value={item.value}>{item.label}</Radio>)
                                        })}
                                    </Radio.Group>
                                    {rangeKey == 'custom' ? <RangePicker onChange={dateChange} bordered={false}/>:''}
                                </div>
                            </div>

                            {/* <div>
                                <Text type="secondary" name="time">文件类型</Text>
                                <div className="flexBox" style={{marginLeft:8}}>
                                    <Checkbox.Group name="report" value={searchFileType} onChange={searchFileTypeChange}>
                                        <Checkbox key={'ordinary'} value={'ordinary'}>普通文件</Checkbox>
                                        <Checkbox key={'isImportant'} value={'isImportant'}>重要文件</Checkbox>
                                    </Checkbox.Group>
                                </div>
                            </div> */}
                        </div>
                    </Panel>
                </Collapse>
            </div>

            {/* 已选条件 */}
            {selectedConditions && <div className='selectedConditions' style={{maxHeight: isExpand ? "5rem" : "0"}}>
                <Text type="secondary">已选</Text>
                <div className="flexBox">

                    {/* 知识 */}
                    {searchType === "knowledge"&&<>
                        {knowledgeTypeValue && knowledgeTypeValue.length>0 && knowledgeTypeValue.map(item=>{
                            return<Tag
                                className="edit-tag"
                                key={item.value}
                                closable={true}
                                onClose={() => delectKnowledgeChange(item.value)}
                            >
                                {item.label}
                            </Tag>
                        })}
                        {departmentValue && departmentValue.length>0 && departmentValue.map(item=>{
                            return<Tag
                                className="edit-tag"
                                key={item.value}
                                closable={true}
                                onClose={() => delectDepartment(item.value)}
                            >
                                {item.label}
                            </Tag>
                        })}
                    </>}

                    {/* 政策 */}
                    {searchType === "policy"&&<>
                        {policyId!='1'&&<Tag
                            className="edit-tag"
                            key={policyId}
                            closable={true}
                            onClose={() => delectPolicySelected(policyId,"policy")}
                        >
                            {policyTypeList.map(item=>{
                                if(item.value === policyId){
                                    return item.label
                                }
                            })}
                        </Tag>}

                        {/* 部委 */}
                        {selectedMinistries.map(item=>{
                            return<Tag
                                className="edit-tag"
                                key={item.id}
                                closable={true}
                                onClose={() => delectPolicySelected(item.id,"ministries")}
                            >
                                {item.name}
                            </Tag>
                        })}

                        {/* 地方 */}
                        {selectedRegions.map(item=>{
                            return<Tag
                                className="edit-tag"
                                key={item.code}
                                closable={true}
                                onClose={() => delectPolicySelected(item.code,"regions")}
                            >
                                {item.name}
                            </Tag>
                        })}
                    </>}

                    {/* 研报 */}
                    {searchType === "report"&&<>
                        {/* 行业分类 */}
                        {industryId.map(item=>{
                            return<Tag
                                className="edit-tag"
                                key={item}
                                closable={true}
                                onClose={() => delectReportSelected(item,"industry")}
                            >
                                {item}
                            </Tag>
                        })}

                        {/* 发布机构 */}
                        {agencyId.map(item=>{
                            return<Tag
                                className="edit-tag"
                                key={item}
                                closable={true}
                                onClose={() => delectReportSelected(item,"agency")}
                            >
                                {item}
                            </Tag>
                        })}
                    </>}

                    {rangeKey!='0'?(<Tag
                        className="edit-tag"
                        key={999}
                        closable={true}
                        onClose={() => rangeChange()}
                    >
                        {rangeKey === 'today'?'一天内':rangeKey === 'week'?'一周内':'自定义' }
                    </Tag>):''}

                    <Tag color="#60B4F6" style={{cursor: 'pointer'}} onClick={clearSelectedParam}>重置</Tag>
                </div>
            </div>}

            <div className="searchList">
                <div className="searchNumber">
                    <span style={isCar ? {fontSize: '0.6rem'}:{}}>搜索到约<span className='num'>{pagination.total}</span>项结果</span>
                    
                    {isCar && !singleChoice &&<Checkbox
                        onChange={onAllchange}
                        indeterminate={currentPageIndeterminate}
                        checked={checkedCurrentPageAll}
                        style={{marginLeft:10}}
                    >全选当前页</Checkbox>}
                    
                    {isCar&&<span style={{fontSize: '0.6rem'}}>已选择<span className='num'>{selectedTotal}</span>项</span>}
                </div>

                <ContentList
                    sortChange={sortChange}
                    paginationChange={paginationChange}
                    pagination={pagination}
                    searchData={searchData}
                    listDate={listDate}
                    // autoHight={listHight}
                    defaultSortKey={sortKey}
                    selecthValue={selecthValue}
                    knowledgeTypeId={knowledgeTypeId}
                    showSelect={isCar}
                    checkOnchange={checkOnchange}
                    checkedValue={checkedList[pagination.pageIndex]}
                    singleChoice={singleChoice}
                />
            </div>
        </Spin>
        </div>
    </div>)
}

export default KnowledgeSearch;
