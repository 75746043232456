import React, {useState, useEffect, useRef} from 'react';
import { Transfer, Typography, Button, Spin, Modal, message, Tabs, Checkbox, Space, Tree, Popconfirm, Tooltip, List, Select, Avatar } from 'antd';
import { UserDeleteOutlined, UserAddOutlined, CheckOutlined, CloseOutlined, PlusSquareOutlined, UploadOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import moment from 'moment';

import Api from "../../../../api";
import DefalutHeadImg from "../../../../assets/img/defalutHeadImg.png";
import SelfTable from "../../../../components/SelfTable"
import {ModalConfirm} from '../../../../utils/tool'

import "./index.scss";

const { Text, Link, Title } = Typography;

const PolicyAnnotation = ({
    getDetails,
    details,
    getPolicyList
}) => {

    const items = [
        { label: '标注团队设置', key: 'annotateTeam'}, // 务必填写 key
        { label: '标注选项设置', key: 'annotateOptions'},
    ];

    const [activeKey, setActiveKey] = useState('annotateTeam');

     // 标注
        const [teamOpen, setTeamOpen] = useState(false);
        const [managementTeamBoxType, setManagementTeamBoxType] = useState('list');
        const [managementTeamBoxOption, setManagementTeamBoxOption] = useState({});
        const [initLoading, setInitLoading] = useState(false);
        const [annotateMembers, setAnnotateMembers] = useState([]);
        const [allMembers, setAllMembers] = useState([]);
        const [targetKeys, setTargetKeys] = useState([]);
        const [checkboxValue, setCheckboxValue] = useState([]);
        const [userList, setUserList] = useState([]);
        const [listLoading, setListLoading] = useState(false);

        const [allDict, setAllDict] = useState(false);
        const [dictValue, setDictValue] = useState("");
        const [dictDetails, setDictDetails] = useState([]);

    useEffect(() => {
        getAllAnnotateMembersList()
    }, [details]);

    const onChange = (key) => {
        setActiveKey(key);
        if(key === "annotateTeam"){
            getAllAnnotateMembersList()
        }else{
            getAllDict();
            getSysDictTypeRule();
        }
    };   

    const getAllDict = async() => {
        try {
            const res = await Api.Dict.getOptionselect({});
            if(res && res.code === 0){
                setAllDict(res.data)
            }
        } catch (errInfo) {
            console.log('验证失败:', errInfo);
        }
    }

    const getSysDictTypeRule = async() => {
        try {
            const res = await Api.Policy.getSysDictTypeRule({data:{
                policyLableThemeId:details.id,
            }});
            if(res && res.code === 0){
                setDictValue(res.data);
                selectDictByPolicyLableThemeId();
            }
        } catch (errInfo) {
            console.log('验证失败:', errInfo);
        }
    }

    // 设置字典
    const setSysDictTypeRule = async(dictType) => {
        try {
            const res = await Api.Policy.setSysDictTypeRule({data:{
                dictType,
                policyLableThemeId:details.id,
            }});
            if(res && res.code === 0){
                selectDictByPolicyLableThemeId();
                getPolicyList();
            }
        } catch (errInfo) {
            console.log('验证失败:', errInfo);
        }
    }
    // 根据政策标注主题id获取字典信息
    const selectDictByPolicyLableThemeId = async() => {
        try {
            setListLoading(true);
            setDictDetails([]);
            const res = await Api.Policy.selectDictByPolicyLableThemeId({data:{
                policyLableThemeId:details.id,
            }});
            setListLoading(false);
            if(res && res.code === 0){
                setDictDetails(res.data);
            }
        } catch (errInfo) {
            setDictDetails([]);
            setListLoading(false);
            console.log('验证失败:', errInfo);
        }
    }
    

    const getAllAnnotateMembersList = async() => {
        try{
            setListLoading(true);
            const res = await Api.Knowledge.getAllPolicyUser({data:{
                policyLableThemeId:details.id,
            }});
            setListLoading(false);
            if(res && res.code ===0 && res.data){
                let _data = res.data;
                setAnnotateMembers(_data);
            }else{
                setAnnotateMembers([]);
            }
        }catch(err){
            setAnnotateMembers([]);
            setListLoading(false);
        }
    }

    const managementTeam = () => {
        if(annotateMembers.length > 0){
            setManagementTeamBoxType('list');
            getManagementTeamOperation('list');
        }else{
            setManagementTeamBoxType('selct');
            getManagementTeamOperation('selct');
            getNotBecomeAnnotateMembers();
        }
        setTeamOpen(true);
    }

    const getManagementTeamOperation = (type) => {
        let option = null;
        switch (type) {
            case 'list':
                setCheckboxValue([]);
                option = {
                    wrapClassName:"memberManagementModal",
                    title: <div><span>成员管理</span><Link onClick={()=>{
                        setManagementTeamBoxType('selct');
                        getManagementTeamOperation('selct');
                        getNotBecomeAnnotateMembers();
                    }}>添加成员</Link></div>,
                    width: 600,
                    onOk: (state) => {
                        setTeamOpen(false);
                    },
                    onCancel: (state) => {
                        setTeamOpen(false);
                    },
                };
            break;
            case 'selct':
                option = {
                    wrapClassName:"selectMemberManagementModal",
                    title: "选择标注人员",
                    width: 600,
                    onOk: (state) => {
                        addAnnotateMembers(state.targetKeys);
                    },
                    onCancel: (state) => {
                        if(state.annotateMembers.length <= 0){
                            setTeamOpen(false);
                        }else{
                            setManagementTeamBoxType('list');
                            getManagementTeamOperation('list');
                            getAllAnnotateMembersList();
                            setTargetKeys([]);
                        }
                    },
                };
            break;
        }
        
        setManagementTeamBoxOption(option);
    }

    const getManagementTeamContent = (type) => {
        let content = null;
        switch (type) {
            case 'list':
                
                content = (<div className='managementTeamList'>
                    <Checkbox.Group onChange={checkboxChange}>
                        <List
                            className="demo-loadmore-list"
                            loading={initLoading}
                            itemLayout="horizontal"
                            dataSource={annotateMembers}
                            renderItem={(item) => (
                                <List.Item key={item.id}>
                                    <Checkbox key={item.id} value={item.id}></Checkbox>
                                    <List.Item.Meta
                                        avatar={<Avatar src={item.fileUrl || DefalutHeadImg} />}
                                        title={item.userName}
                                    />
                                    <Space>
                                        <Text>成员</Text>
                                        <Popconfirm placement="topLeft" title={`确定移出标注人员${item.userName}吗？`} onConfirm={()=>{onMoveUser([item.id])}} okText="确定" cancelText="取消">
                                            <Text type="danger">移除</Text>
                                        </Popconfirm>
                                    </Space>
                                </List.Item>
                            )}
                        />
                    </Checkbox.Group>

                    {checkboxValue.length>0&&<Button type="danger" size="small" onClick={batchDeletion}>删除</Button>}
                </div>)
            break;
            case 'selct':
                const isChecked = (selectedKeys, eventKey) => selectedKeys.includes(eventKey);

                const generateTree = (treeNodes = [], checkedKeys = []) =>
                treeNodes.map(({ children, ...props }) => ({
                    ...props,
                    disabled: checkedKeys.includes(props.key),
                    children: generateTree(children, checkedKeys),
                }));

                const transferDataSource = [];
                function flatten(list = []) {
                  list.forEach((item) => {
                    transferDataSource.push(item);
                    flatten(item.children);
                  });
                }
                flatten(allMembers);

                content = (<div>
                    <Transfer
                        dataSource={transferDataSource} 
                        onChange={onTransferChange}
                        targetKeys={targetKeys}
                        className="tree-transfer"
                        render={(item) => item.title}
                        showSelectAll={false}
                        showSearch={true}
                        onSearch={onTransferSearch}
                    >
                        {({ direction, onItemSelect, selectedKeys }) => {
                            if (direction === 'left') {
                                const checkedKeys = [...selectedKeys, ...targetKeys];
                                return (
                                    <Tree
                                        blockNode
                                        checkable
                                        checkStrictly
                                        defaultExpandAll
                                        checkedKeys={checkedKeys}
                                        treeData={generateTree(allMembers, targetKeys)}
                                        onCheck={(_, { node: { key } }) => {
                                            onItemSelect(key, !isChecked(checkedKeys, key));
                                        }}
                                        onSelect={(_, { node: { key } }) => {
                                            onItemSelect(key, !isChecked(checkedKeys, key));
                                        }}
                                    />
                                );
                            }
                        }}
                    </Transfer>
                </div>)
            break;
        }

        return content
    }

    // 移除标注团队成员
    const onMoveUser = async(userIds) => {
        try {
            let res = await Api.Knowledge.removePolicyUser({data:{
                userIds,
                policyLableThemeId:details.id,
            }});
            if (res && res.code === 0) {
                message.success("删除成功");
                getAllAnnotateMembersList();
                setCheckboxValue([])
            }
        } catch (errInfo) {
            console.log('失败:', errInfo);
        }
    }
    // 批量删除
    const batchDeletion = () => {
        ModalConfirm({
            title: `您确认要删除${checkboxValue.length}位标注人员吗?`,
            onOk:async(resolve, reject)=> {
                onMoveUser(checkboxValue)
                resolve();
            },
        })
    }

    // 获取所有未成为标注成员的人员
    const getNotBecomeAnnotateMembers = async(name) => {
        try {
            let res = await Api.Knowledge.getNotBecomeAnnotateMembers({data:{name}});
            if (res && res.code === 0) {
                const generateTree = (treeNodes = []) =>{
                    return treeNodes.map((item) => ({
                        key: item.userId || item.id,
                        title: item.userName || item.name,
                        children: item.users && generateTree(item.users),
                    }));
                }
                let data = generateTree(res.data)

                setAllMembers(data)
            }
        } catch (errInfo) {
            console.log('失败:', errInfo);
        }
    }

    const onTransferChange = (keys) => {
        setTargetKeys(keys);
    }

    const onTransferSearch = (direction,value) => {
        if(direction === "left"){
            getNotBecomeAnnotateMembers(value);
        }
    }

    const checkboxChange = (checkedValue) => {
        setCheckboxValue(checkedValue)
    }

    // 添加标注团队成员
    const addAnnotateMembers = async(userIds) => {
        if(userIds && userIds.length > 0){
            try {
                let res = await Api.Knowledge.addAnnotateMembers({data:{
                    userIds,
                    policyLableThemeId:details.id,
                }});
                if (res && res.code === 0) {
                    message.success("添加成功");
                    setManagementTeamBoxType('list');
                    getManagementTeamOperation('list');
                    getAllAnnotateMembersList();
                    setTargetKeys([]);
                    getPolicyList();
                }
            } catch (errInfo) {
                console.log('失败:', errInfo);
            }
        }else{
            message.info("请选择要添加的成员！")
        }
    }

    const handleDictChange = (value) => {
        console.log(value,"value");
        setDictValue(value);
        setSysDictTypeRule(value);
    }

    const state = {targetKeys, annotateMembers};

    return (
        <div className="policyAnnotationFrame">
            <Tabs items={items} onChange={onChange} activeKey={activeKey}/>

            {activeKey === 'annotateTeam' && <div className='annotateTeam'>
                <div className='teamTopBox'>
                    <span className='title'>设置标注团队</span>
                    <div>
                        <Space>
                            <Tooltip title="添加成员">
                                <UserAddOutlined onClick={()=>{
                                    setTeamOpen(true);
                                    setManagementTeamBoxType('selct');
                                    getManagementTeamOperation('selct');
                                    getNotBecomeAnnotateMembers();
                                }}/>
                            </Tooltip>
                            <Tooltip title="删除成员">
                                <UserDeleteOutlined onClick={managementTeam}/>
                            </Tooltip>
                        </Space>
                    </div>
                </div>
                <div className="rightBoxList">
                    <Spin tip="数据加载中" spinning={listLoading}>
                        <List
                            itemLayout="horizontal"
                            dataSource={annotateMembers}
                            renderItem={item => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={<Avatar src={item.fileUrl || DefalutHeadImg} />}
                                    title={item.userName}
                                    description={item.departmentName}
                                />
                            </List.Item>
                            )}
                        />
                    </Spin>
                </div>
            </div>}

            {activeKey === 'annotateOptions' && <div className='annotateOptions'>
                <div>
                    <div className='teamTopBox'>
                        <span className='title'>设置标注选项</span>
                    </div>
                    <Select
                        style={{
                            width: '100%',
                        }}
                        // mode="multiple"
                        allowClear
                        value={dictValue}
                        fieldNames={{ label: 'dictName', value: 'dictType'}}
                        onChange={handleDictChange}
                        placeholder={"请选择分类字典"}
                        options={allDict}
                    />
                </div>
                <div className='optionPreview'>
                    <div className='title'>选项预览</div>
                    <Spin tip="数据加载中" spinning={listLoading}>
                        {dictDetails.map(item=>{
                            return <div className='ListItem'>{item.dictLabel}</div>
                        })}
                    </Spin>
                </div>
            </div>}


            <Modal
                // onCancel={onClose}
                open={teamOpen}
                // title={"成员管理"}
                maskClosable={false}
                zIndex={800}
                {...managementTeamBoxOption}
                onOk={() => managementTeamBoxOption.onOk(state)} 
                onCancel={() => managementTeamBoxOption.onCancel(state)} 
            >
                {teamOpen && getManagementTeamContent(managementTeamBoxType)}
            </Modal>

        </div>)
}

export default PolicyAnnotation;



