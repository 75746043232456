import React, { useEffect, useState, useRef } from "react";

import { useHistory, useLocation } from "react-router-dom";
import { Form, message, Space, Button } from "antd";
import moment from "moment";

import { ModalConfirm, getSearchParams, jumpToPage } from "../../../utils/tool";
import AddKnowledgeForm from "../components/addKnowledgeForm/AddKnowledgeForm";
import storage from "../../../utils/storage";
import Api from "../../../api";
import "./index.scss";
import KnowledgeFileIcon from "../../../components/KnowledgeFileIcon";
import NoneData from "../../../components/NoneData";
import ViewImg from "../../../components/ViewImg";
const NewKnowledge = () => {
  const history = useHistory();
  const location = getSearchParams();
  // const location = useLocation().state;
  // console.log(location, "location");

  const userinfo = storage.getItem("userInfo");
  const viewImgType = ["png", "jpg", "jpeg", "gif", "bmp", "titf", "psd", "raw", "eps", "svg"];

  // 左侧tab切换下标
  const [tabKey, setTabKey] = useState(1);
  const tabsList = [
    { id: 1, value: 1, title: "基本信息" },
    // { id: 2, value: 2, title: "目录结构" },
  ];
  const [formData, setFormData] = useState({});
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  // 所有用户
  const [userList, setUserList] = useState([]);
  // 作者列表
  const [selectOptions, setSelectOptions] = useState([]);
  const [userSelectTags, setUserSelectTags] = useState([]);
  const [form] = Form.useForm();
  // 文档标签
  const [tags, setTags] = useState([]);
  // 判断点击的是发布还是存草稿
  const [type, setType] = useState();
  const containerRef = useRef(null);
  const [jsSDK, setJsSDK] = useState(null);
  // 所有项目
  const [projectNameList, setProjectNameList] = useState([]);
  // 知识详情信息
  const [knowledgeDetail, setKnowledgeDetail] = useState({});
  // 按钮加载中状态
  const [saveLoading, setSaveLoading] = useState(false);
  const [publishLoading, setPublishLoading] = useState(false);

  // 图片预览列表
  const [viewImgList, setViewImgList] = useState([
    {name: 'adfasd', url: "https://img1.baidu.com/it/u=221510954,374425508&fm=253&fmt=auto&app=138&f=JPEG?w=750&h=500", id: 0},
    {name: 'qet', url: "https://img1.baidu.com/it/u=3893389324,4043822814&fm=253&fmt=auto&app=120&f=JPEG?w=1280&h=800", id: 1},
    {name: 'adfasgfsfdsd', url: "https://img2.baidu.com/it/u=676258172,2884080302&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=889", id: 2},
    {name: 'rifh', url: "https://www.androidonline.net/uploads/allimg/c191026/15H0D50303050-223I3.jpg", id: 3},
    {name: 'cgjdgfh', url: "https://img1.baidu.com/it/u=4014765096,45504095&fm=253&fmt=auto&app=120&f=JPEG?w=1280&h=800", id: 4},
    {name: '虽然他公司', url: "https://img1.baidu.com/it/u=4248488632,2142549045&fm=253&fmt=auto&app=138&f=JPEG?w=425&h=637", id: 5},
  ]);
  const [isShowViewImg, setIsShowViewImg] = useState(false);
  const [viewImgIdx, setViewImgIdx] = useState(0);

  // 保存或发布按钮禁用
  // const [saveDisabled, setSaveDisabled] = useState(true);
  useEffect(() => {
    getUserAll();
    if (location?.knowledgeId) {
      getKnowledgeDetail(location?.knowledgeId);
    }
    // 不明白为什么这里要调用一次wps，但是调用两次会导致重复加载，有问题
    // if (location?.fileId) {
    //   getWPSFileUrl(location?.fileId);
    // }
    if (location?.fileName) {
      form.setFieldsValue({ knowledgeName: location.fileName });
    }
    if (location?.type === "view" || location?.type === "historyView") {
      setIsFormDisabled(true);
      if (jsSDK) {
        jsSDK.Enabled = false;
      }
    }
  }, []);
  useEffect(() => {
    getKnowledgeProject();
    window.addEventListener("beforeunload", (ev) => {
      ev.returnValue = "你确定离开此页面吗?";
    });
  }, []);
  // 获取所有用户
  const getUserAll = async () => {
    let res = await Api.User.getUserListAll();
    setUserList(res.data);
  };
  const getKnowledgeDetail = async (knowledgeId) => {
    try {
      let res = null;
      if (location?.type === "historyView") {
        res = await Api.Knowledge.getVersionDetail({
          data: { "knowledgeVersionId":knowledgeId },
        });
      }else{
        res = await Api.Knowledge.getKnowledgeDetailById({
          data: { knowledgeId },
        });
      }
      
      if (res && res.code === 0 && res.data) {
        let _data = { ...res.data };

        if (location?.type === "historyView") {
          // 历史记录多了一层detail的结构
          _data = { ...res.data.detail };
        }
        
        let selectOptionsNew;
        let newUserIdList;
        let resList;
        setKnowledgeDetail(_data);

        if (_data.knowledgeUserRelVOS?.length > 0) {
          selectOptionsNew = _data.knowledgeUserRelVOS.map((item) => {
            return {
              userId: item.userId,
            };
          });
          newUserIdList = _data.knowledgeUserRelVOS.map((item) => {
            return item.userId;
          });
          let _resList = [];
          newUserIdList.map((item) => {
            _data.knowledgeUserRelVOS.forEach((obj) => {
              if (obj.userId == item) {
                _resList.push({
                  userId: obj.userId,
                  userName: obj.userName,
                  departmentName: obj.departmentMessage,
                });
              }
            });
            return _resList;
          });
          resList = _resList;
        } else {
          selectOptionsNew = [];
          newUserIdList = [];
          resList = [];
        }
        if (_data.fileType !== "zip") {
          getWPSFileUrl(_data);
        } else {
          const imgList = (_data.unzipFile || []).filter(item => viewImgType.includes(String(item.type).toLowerCase()));
          setViewImgList(imgList);
        }

        let formData = {
          // departmentId: _data.departmentId,
          departmentSubclassificationId: _data.departmentSubclassificationId,
          // isMust: _data.isMust,
          knowledgeTypeId: _data.knowledgeTypeId,
          knowledgeName: _data.knowledgeTitle,
          knowledgeDigest: _data.knowledgeDigest,
          // shareDepartmentIds: _data.shareDepartmentIds,
          knowledgeUserRelDTOList: newUserIdList,
          knowledgeSource: _data.knowledgeSource,
          nameExts: _data.nameExts,
          isMark: _data.isMark == 1 ? true : false,
        };

        // 上传文件新增的时候，不知道为什么要走这里，departmentId和shareDepartmentIds查询是肯定为空的
        // 会导致覆盖新增页面知识归属和分享范围的默认值
        // 如果没有就不要
        if(_data.departmentId){
          formData.departmentId = _data.departmentId
        }
        if(_data.shareDepartmentIds && _data.shareDepartmentIds.length > 0){
          formData.shareDepartmentIds = _data.shareDepartmentIds
        }
        // ----

        if (_data?.knowledgeTypeId == 2) {
          formData.projectId = _data.projectId;
          if(_data.projectStartTime && _data.projectEndTime){
            formData.time = [
              changeDate(_data.projectStartTime),
              changeDate(_data.projectEndTime),
            ];
          }
        }
        setFormData(formData);
        setUserSelectTags(resList);
        setTags(_data.knowledgeDocumentLabelList);
        setSelectOptions(selectOptionsNew);
      }
    } catch {}
  };
  // 获取对应项目接口
  const getKnowledgeProject = async () => {
    let res = await Api.Knowledge.getProjectIdAndName({data: {},});
    setProjectNameList(res.data);
  };
  // 转化时间类型函数
  const changeDate = (date) => {
    const newDate = new Date(date);
    const Year = newDate.getFullYear();
    const Month = newDate.getMonth();
    const Day = newDate.getDate();
    return moment(`${Year}-${Month + 1}-${Day}`);
  };
  // 获取WPS内容
  const getWPSFileUrl = async (detailsData) => {
    if (location?.type === "view" || location?.type === "historyView") {
      try {
        let res = "";
        // 有researchReportId 代表是参考研报
        if(detailsData.researchReportId){
          res = await Api.Knowledge.getWPSFileResearchReport({data:{researchReportId:detailsData.researchReportId}});
        }else{
            res = await Api.Knowledge.getWPSFilePreview({fileId:detailsData.relationFileId});
        }

        if (res && res.code === 200 && res.data) {
          const container = containerRef.current;
          const data = JSON.stringify({
            tagName: container.tagName,
            className: container.className,
            // 在这里可以提取其他需要的HTML属性
          });
          const jsSdk = OpenSDK.config({
            url: res.data.link,
            mount: document.querySelector(".custom-mount"),
          });
          setJsSDK(jsSdk);
          if (jsSdk.iframe) {
            jsSdk.iframe.width = "100%";
            jsSdk.iframe.height = "100%";
          }
        }
      } catch (error) {}
    } else {
      try {
        let res = await Api.Knowledge.getWPSFileEdit({
          fileId: detailsData.relationFileId,
        });
        if (res && res.code === 200 && res.data) {
          const container = containerRef.current;
          const data = JSON.stringify({
            tagName: container.tagName,
            className: container.className,
            // 在这里可以提取其他需要的HTML属性
          });
          const jsSdk = OpenSDK.config({
            url: res.data.link,
            mount: document.querySelector(".custom-mount"),
          });
          if (jsSdk.iframe) {
            jsSdk.iframe.width = "100%";
            jsSdk.iframe.height = "100%";
          }
          setJsSDK(jsSdk);
        }
      } catch (error) {}
    }
  };
  const changeTabKey = (id) => {
    setTabKey(id);
  };
  const onFinish = (values) => {
    if (tags?.length > 0 && values) {
      ModalConfirm({
        title: `${type === 0 ? "确认发布吗?" : "确认存入草稿箱?"}`,
        onOk: async (resolve, reject) => {
          if (type === 0) {
            setPublishLoading(true);
          } else {
            setSaveLoading(true);
          }
          if (values.knowledgeTypeId == "2" && values.time && values.time.length > 0) {
            values.projectStartTime = moment(values.time[0]._d).format(
              "YYYY-MM-DD"
            );
            values.projectEndTime = moment(values.time[1]._d).format(
              "YYYY-MM-DD"
            );
          }
          
          /* 6 参考资料；7 参考研报 时不选择作者，输入知识来源*/
          if(values.knowledgeTypeId != 6 && values.knowledgeTypeId != 7){
            values.knowledgeUserRelDTOList = selectOptions;
          }
          values.knowledgeDocumentLabel = tags;
          values.knowledgeTitle = values.knowledgeName;
          values.operateType = type;
          values.isMark = values.isMark ? 1 : 0;
          
          useApi(values);
          resolve();
        },
      });
    } else {
      // if (!addKnowledgeTitle) {
      //   setIsAddKnowledgeTitle(false);
      // }
      message.info(`请补充完整信息后再进行${type === 0 ? "发布" : "保存"}`);
    }
  };
  //点击保存/发布
  const saveNewKnowledge = (id) => {
    setType(id);
    form.submit();
  };
  // 接口
  const useApi = async (data) => {
    if (jsSDK) {
      jsSDK.save();
    }
    if (knowledgeDetail) {
      data.fileType = knowledgeDetail.fileType;
      data.relationFileId = knowledgeDetail.relationFileId;
      data.knowledgeManagementId = knowledgeDetail.id;
      data.knowledgeId = knowledgeDetail.id;
    } else {
      data.fileType = location?.fileType;
      data.relationFileId = location?.fileId;
      data.knowledgeManagementId = location?.knowledgeId;
      // data.knowledgeId = storage.getItem("knowledgeId");
    }
    // 提交
    data.operateType = type;
    try {
      let res = await Api.PersonalCenter.addNewKnowledge({ data });
      if (res.data) {
        if (type === 0) {
          message.success(res.data.message);
          setPublishLoading(false);
        } else {
          message.success(res.data.message);
          setSaveLoading(false);
        }

        // 不需要审核的权限，直接发布成功，跳转已发布知识列表
        if(res.data.code === 1){
          history.push("/personalCenter/myUpload/published");
        }else{
          history.push("/personalCenter/myUpload/notPublished");
        }
      }
    } catch (error) {
      if (type === 0) {
        setPublishLoading(false);
      } else {
        setSaveLoading(false);
      }
    }
  };
  const onFinishField = (values, errorFields) => {
    message.info(`请补充完整信息后再进行${type === 0 ? "发布" : "保存"}`);
    if (type === 0) {
      setPublishLoading(false);
    } else {
      setSaveLoading(false);
    }
  };

  const isMobile = () => {
    let userAgentInfo = navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(userAgentInfo);
  }
  // 获取wps地址
  async function getFileViewUrl (fileId, type, viewImgList, fileItem) {
    if (viewImgType.includes(String(type).toLowerCase())) {
      if (!isMobile()) {
        const idx = viewImgList.findIndex(item => item.id === fileId);
        setViewImgIdx(idx);
        setIsShowViewImg(true);
      }
      return
    }

    if (fileItem.knowledgeId) {
      jumpToPage({
          ...fileItem,
          "selecthValue":null,
          "id":"",
          "fileType":type
      });
    }else{
      message.info("当前文件类型不支持")
    }

    // const res = await Api.Knowledge.getWPSFilePreview({fileId: fileId});
    // if (res?.code === 200) {
    //   window.open(res.data.link)
    // }
  }

  const unzipFileList = knowledgeDetail?.unzipFile || [];

  return (
    <div className="newKnowledgeFrame">
      {isFormDisabled ? null : (
        <div className="title">
          <h4>新增文档知识</h4>
          <div className="btns">
            <Space>
              <Button
                loading={saveLoading}
                onClick={() => {
                  saveNewKnowledge(2);
                }}
                type={"primary"}
              >
                保存
              </Button>
              <Button
                loading={publishLoading}
                onClick={() => {
                  saveNewKnowledge(0);
                }}
                type={"primary"}
              >
                发布
              </Button>
            </Space>
          </div>
        </div>
      )}
      <div className="newKnowledgeContent">
        <div className="knowledgeInfo">
          <div className="scrollBox">
            <div className="InfoTabs">
              基本信息
              {/* {tabsList.map((item) => {
              return (
                <span
                  className={item.id === tabKey ? "tabLight" : null}
                  key={item.id}
                  onClick={() => changeTabKey(item.id)}
                >
                  {item.title}
                </span>
              );
            })} */}
            </div>
            <div className="tabCon">
              {tabKey === 1 ? (
                  <div>
                    <AddKnowledgeForm 
                        onFinish={onFinish}
                        form={form}
                        selectOptions={selectOptions}
                        setSelectOptions={setSelectOptions}
                        tags={tags}
                        setTags={setTags}
                        pageType={1}
                        formData={formData}
                        isFormDisabled={isFormDisabled}
                        projectNameList={projectNameList}
                        onFinishField={onFinishField}
                        userSelectTags={userSelectTags}
                        setUserSelectTags={setUserSelectTags}
                        userList={userList}
                        setUserList={setUserList}
                    ></AddKnowledgeForm>
                    <div className="creatInfo">
                      <p>
                    <span className="label">
                      创&nbsp;建&nbsp;者&nbsp;&nbsp;
                    </span>
                        <span className="value">
                      {knowledgeDetail?.creator
                          ? knowledgeDetail?.creator
                          : userinfo?.userName}
                    </span>
                      </p>
                      <p>
                        <span className="label">创建时间</span>
                        {location?.createDate ? (
                            <span>{knowledgeDetail?.createDate}</span>
                        ) : (
                            <span>{moment().format("L")}</span>
                        )}
                      </p>
                    </div>
                  </div>
              ) : null}
            </div>
          </div>
        </div>
        {
          knowledgeDetail.fileType === "zip" ? (
              <div className="zipChildFileList">
                <div className="scrollBox">
                  {
                    unzipFileList.length > 0 ? unzipFileList.map(item => {
                      const {id, name, createTime, type} = item;
                      return (
                          <div
                              className="fileItem"
                              key={id}
                              onClick={() => getFileViewUrl(id, type, viewImgList,item)}
                          >
                            <div className="infoBox">
                              <KnowledgeFileIcon fileType={type} />
                              <span className="fileName overflowEllipsis">{name}</span>
                            </div>
                            <span className="timeText">{moment(createTime).format("YYYY-MM-DD HH:mm:ss")}</span>
                          </div>
                      )
                    }) : (
                        NoneData({text: "无文件"})
                    )
                  }
                </div>
              </div>
          ) : (<div className="custom-mount" ref={containerRef}></div>)
        }
      </div>

      <ViewImg
          viewImgList={viewImgList}
          isShowViewImg={isShowViewImg}
          viewImgIdx={viewImgIdx}
          closeViewImg={() => setIsShowViewImg(false)}
      />
    </div>
  );
};

export default NewKnowledge;
