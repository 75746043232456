import React, {useEffect, useState,} from 'react';
import {useHistory} from "react-router-dom";
import {
    Timeline,
    Typography,
    Space,
    Empty,
    DatePicker,
    Tooltip,
    message,
    Input,
    Popconfirm,
    Modal,
    Spin,
    Tree,
    Tag
} from 'antd';
import {BugOutlined, UploadOutlined, FileSearchOutlined, CheckCircleOutlined, ExportOutlined, FlagOutlined} from '@ant-design/icons';
import moment from 'moment';
import {ModalConfirm, goKnowledgeDetails, responseDataFilter} from '../../utils/tool';
import SelfPagination from "../SelfPagination/index";
import KnowledgeFileIcon from "../KnowledgeFileIcon/index";
import Iconfont from "../Iconfont/Iconfont";
import Api from "../../api/index";

import './index.scss';

const {Text, Title,} = Typography;
const {RangePicker} = DatePicker;

const PolicyList = (props) => {
    const {
        policyTypeChange,
        listDate,
        pagination,
        paginationChange,
        policyThemeName, 
        policyType,
        searchData,
        getPolicyList,
        setToEditFormData,
        setOpen,
        operationChange,
        setPolicyFromType,
        pageType,
        isPolicySign,
        selectedSignList,
        hideTime,
        annotationPolicy,
        activeTab
    } = props;

    
    const history = useHistory();
    const policyTypeList = [
        {text: "全部", value: {id: "1", type: "", policyRegionId: ""}},
        {text: "国务院", value: {id: "2", type: "0", policyRegionId: ""}},
        {text: "部委", value: {id: "3", type: "1", policyRegionId: ""}},
        {text: "地方", value: {id: "4", type: "2", policyRegionId: "2"}},
    ];

    const [tableHight, setTableHight] = useState(0);

    const [operationData, setOperationData] = useState({
        searchType: 0 // 搜索类型 0标题、1全文
    });

    const [regions, setRegions] = useState({
        saidiCity: [],
        geographic: [],
    });
    const [allRegions, setAllRegions] = useState([]);
    
    const [departmentList, setDepartmentList] = useState([]);

    const [type, setType] = useState({id: "1"});

    const [currentArea, setCurrentArea] = useState({})

    const [contentWidth, setContentWidth] = useState(0);

    const sortTypeList = [
        {text: "按地理分区", key: "geographic"},
        {text: "按赛迪分区", key: "saidiCity"},
    ];// 赛迪分区(saidiCity) / 地理分区(geographic)
    const [sortType, setSortType] = useState("geographic");// 赛迪分区(saidiCity) / 地理分区(geographic)
    const [selectedAreaList, setSelectedAreaList] = useState([]); // 选择的地方codes
    const [selectedLocalList, setSelectedLocalList] = useState([]); // 选择的地方z政府codes


    const [selectedRegions, setSelectedRegions] = useState(null); // 选择的地方对象
    const [selectedDepartList, setSelectedDepartList] = useState([]); // 选择的部委

    const [showSearch, setShowSearch] = useState(false); // 控制搜索输入框是否显示
    const [showDate, setShowDate] = useState(false); // 控制日历选择框是否显示
    const [tooltipIsOpen, setTooltipIsOpen] = useState(false); // 控制选择地区/部委弹窗是否显示


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [listTree, setListTree] = useState(false);
    const [selectedKeys, setSelectedKeys] = useState("");
    const [modalSpinningType, setModalSpinningType] = useState(false);

    useEffect(() => {
        window.addEventListener("resize", computeContentWidth);
        window.addEventListener("click", closeOperateBox);

        getRegionsList();

        getDepartmentList();

        computeContentWidth();

        return () => {
            window.removeEventListener("resize", computeContentWidth);
            window.removeEventListener("click", closeOperateBox);
        }
    }, [])

    useEffect(() => {
        setOperationData({
            search: searchData.search,
            endTime: searchData.endTime,
            startTime: searchData.startTime
        })

        if(searchData.type){
            policyTypeList.map((item) => {
                if(item.value.type === searchData.type){
                    setType(item.value)
                }
            })
        }
    }, [JSON.stringify(searchData)])

    useEffect(() => {

        let rootHeight = document.querySelector("#root").offsetHeight;
        let tableBodyTop = document.querySelector(".policyListFrame").getBoundingClientRect().top + 90;
        let tableContentHeight = rootHeight - tableBodyTop - 60;

        tableContentHeight = policyThemeName ? tableContentHeight - 50 : tableContentHeight

        setTableHight(tableContentHeight);

    }, [JSON.stringify(listDate)])

    useEffect(() => {
        cancelChangeRegions(selectedRegions);
        cancelChangeDepartment();
    }, [showSearch, showDate])

    useEffect(() => {
        setSelectedDepartList(policyType.countryDeptIds || []);
        setSelectedAreaList(policyType.countryDeptIds || []);
        setSelectedLocalList(policyType.countryDeptIds || []);
    }, [policyType])

    const getRegionsList = async () => {
        try {
            let res = await Api.Common.getRegions({}); 
            if (res && res.code === 0 && res.data) {
                setRegions(res.data)
            } else {
            }
        } catch (errInfo) {
            console.log('验证失败:', errInfo);
        }

        try {
            let res = await Api.Common.getDepartments({data: {type: 2}});
            if (res && res.code === 0 && res.data) {
                setAllRegions(res.data[0].childList || [])
            } else {
            }
        } catch (errInfo) {
            console.log('验证失败:', errInfo);
        }

    }
    const getDepartmentList = async () => {
        // 0 国务院 1 部委
        try {
            let res = await Api.Common.getDepartments({data: {type: 1}});
            if (res && res.code === 0 && res.data) {
                setDepartmentList(res.data[0].childList || [])
            } else {
            }
        } catch (errInfo) {
            console.log('验证失败:', errInfo);
        }
    }

    // 计算内容所占宽度
    const computeContentWidth = () => {
        const dom = document.getElementById("rightContentBox");
        if (dom) {
            setContentWidth(dom.clientWidth);
        }
    }

    // 点击关闭
    const closeOperateBox = () => {
        if (!operationData.search) {
            setShowSearch(false);
        }
        if (!operationData.startTime) {
            setShowDate(false);
        }
        setTooltipIsOpen(false)
    }

    const handleModeChange = (value) => {
        setType(value);
        const val = {...value, codes: [], countryDeptIds: [],};
        delete val.id;
        policyTypeChange(val);
    };

    const deleteTheme = (item) => {
        ModalConfirm({
            title: '确定要删除这个政策数据吗？',
            onOk: async (resolve, reject) => {
                try {
                    let policyLableThemeId = "";
                    if(searchData.policyLableThemeId){
                        policyLableThemeId = searchData.policyLableThemeId;
                    }else if(searchData.lableThemeParams[0] && searchData.lableThemeParams[0].policyLableThemeId){
                        policyLableThemeId = searchData.lableThemeParams[0].policyLableThemeId
                    }
                    let res = await Api.Knowledge.deleteCWPolicyByPolicyId({
                        data: {
                            // "departmentId": searchData.departmentId,
                            "policyLableThemeId": policyLableThemeId,
                            "id": item.id,
                            // "policySourceType": item.policySourceType,
                            // "policyThemeId": searchData.policyThemeId,
                            // "titleHref": item.titleHref
                        }
                    });
                    if (res && res.code === 0) {
                        resolve()
                        message.success("删除成功");
                        getPolicyList();
                    } else {
                        reject();
                    }
                } catch (errInfo) {
                    console.log('验证失败:', errInfo);
                    reject();
                }
            },
        })
    }

    const updateTheme = (item) => {
        setToEditFormData(item);
        setOpen(true);
        setPolicyFromType(null)
    };


    // 爬虫文件，通过站内搜素添加到新的主题下，在新的主题列表中点击修改，查讯详情报错
    const getByPolicyDetailBYPolicyId = async (item) => {
        try {
            let res = await Api.Knowledge.getByPolicyDetailBYPolicyId({
                data: {
                    policyId: item.id,
                    policySourceType: item.policySourceType,
                    titleHref: item.titleHref,
                }
            });
            if (res && res.code === 0) {
                setPolicyFromType("2")
                setOpen(true);
                setToEditFormData({
                    ...res.data,
                    fileType: item.fileType
                });
            }
        } catch (errInfo) {
            console.log('验证失败:', errInfo);
        }
    }

    const exgetDitals = (item) => {
        if (item.titleHref) {
            window.open(item.titleHref);
        } else if (item.relationFileId) {
            getWPSFileUrl(item.relationFileId)
        }
    }

    const getWPSFileUrl = async (relationFileId) => {
        try {
            let res = await Api.Knowledge.getWPSFilePreview({fileId: relationFileId});
            if (res && res.code === 200 && res.data) {
                window.open(res.data.link);
            }
        } catch {
        }
    }

    const searchChange = (value, type) => {
        let newOperationData = {...operationData};
        newOperationData.pageIndex = 1;
        // 搜索类型 0标题、1全文
        newOperationData.searchType = 0;
        if (type === "timeRange") {
            newOperationData.startTime = value[0];
            newOperationData.endTime = value[1];

            operationChange(newOperationData)
        } else {
            newOperationData[type] = value;
            if(value === ""){
                operationChange(newOperationData)
            }
        }
        setOperationData(newOperationData)
        // operationChange(newOperationData);
    }
    const onSearch = () => {
        operationChange(operationData)
    }

    const regionsChange = (regions, codes) => {
        setSelectedAreaList(codes);
        setSelectedRegions(regions);
    }
    const cancelChangeRegions = (regions) => {
        setTooltipIsOpen(false);
        regionsChange(regions, [...(policyType.codes || [])]);
    }

    const confirmChangeRegions = (region, codes = [], selectedDepartCodesList = []) => {
        setTooltipIsOpen(false);
        const params = {...type};
        delete params.id;
        
        // let _codes = [], countryDeptIds = [];
        // codes.map(item=>{
        //     if(item.length > 5){
        //         _codes.push(item)
        //     }else{
        //         countryDeptIds.push(item)
        //     }
        // })

        policyTypeChange({
            ...params,
            codes: [], //codes || 
            countryDeptIds: codes || [],
            regionId: "",
            name: region?.name,
        });
    }

    const departmentChange = (codes) => {
        setSelectedDepartList(codes);
    }
    const cancelChangeDepartment = () => {
        setTooltipIsOpen(false);
        departmentChange([...(policyType.codes || [])]);
    }

    const confirmChangeDepartment = (codes) => {
        setTooltipIsOpen(false);
        const params = {...type};
        delete params.id;
        policyTypeChange({
            ...params,
            countryDeptIds: codes || [],
        });
    }

    const deleteSelectedCode = (policyType, isDepartment, code, region) => {
        
        // const key = isDepartment ? "countryDeptIds" : "codes";
        const key = "countryDeptIds";
        let selectedCodes = [...(policyType[key] || [])];
        const idx = selectedCodes.indexOf(code);
        console.log(selectedCodes, code);
        if (idx > -1) {
            selectedCodes.splice(idx, 1);
            if (isDepartment) {
                confirmChangeDepartment(selectedCodes);
            } else {
                confirmChangeRegions(region, selectedCodes);
            }
        }
    }

    const renderRegions = (sortType, selectedCodes) => {
        const areaList = regions[sortType] || [];
        
        let _codes = [], countryDeptIds = [];
        selectedCodes.map(item=>{
            if(item.length > 5 || item == 156){
                _codes.push(item)
            }else{
                countryDeptIds.push(item)
            }
        });

        // 地理分区
        let allAreaCodeList = [];
        areaList.forEach(areaItem => {
            const addressChildren = areaItem.children || [];
            addressChildren.forEach(addressItem => {
                allAreaCodeList.push(addressItem.code);
            })
        });

        // 地方政府
        let allLocalGovernmentCodeList = [];
        allRegions.forEach(localItem => {
            allLocalGovernmentCodeList.push(localItem.id);
        });

        const isAllSelected = _codes.length >= allAreaCodeList.length;
        const isAllLocalGovernmentSelected = countryDeptIds.length >= allAreaCodeList.length;


        return (
            <div className="regionsPopoverBox">
                <div className="sortTypeListBox">
                    {
                        sortTypeList.map(item => {
                            const {text, key} = item;
                            const isActive = sortType === key;
                            return (
                                <span
                                    className={`sortItem ${isActive ? "activeStatus" : ""}`}
                                    key={key}
                                    onClick={() => {
                                        if (!isActive) {
                                            setSortType(key)
                                        }
                                    }}
                                >{text}</span>
                            )
                        })
                    }
                </div>
                <div className="areaListBox">
                    <div className='regionsPlace'>
                        <span
                            className={`regionsAllArea ${isAllSelected ? "selectRegion" : ""}`}
                            onClick={() => {
                                departmentChange(isAllSelected ? [] : allAreaCodeList)
                            }}
                        >全部地区</span>
                        <div className="regionsAllListBox">
                            {
                                areaList.map(item => {
                                    let addressIsAllSelected = true;
                                    const addressCodeList = [];
                                    for (const addressItem of item.children) {
                                        const code = addressItem.code;
                                        let flag = selectedCodes.includes(code);
                                        if (addressIsAllSelected) {
                                            addressIsAllSelected = flag;
                                        }
                                        addressCodeList.push(code);
                                    }
                                    return (
                                        <div key={item.id} className="regionsList">
                                            <span
                                                className={`regionsArea ${(addressIsAllSelected || isAllSelected) ? "selectRegion" : ""}`}
                                                onClick={() => {
                                                    let codes = [...selectedCodes];
                                                    if (addressIsAllSelected) {
                                                        addressCodeList.forEach(code => {
                                                            let idx = codes.indexOf(code);
                                                            codes.splice(idx, 1);
                                                        })
                                                    } else {
                                                        addressCodeList.forEach(code => {
                                                            if (!codes.includes(code)) {
                                                                codes.push(code);
                                                            }
                                                        })
                                                    }
                                                    departmentChange(codes)
                                                }}
                                            >{item.name}</span>
                                            <div className="regionsBox">
                                                {
                                                    item.children.map(it => {
                                                        const {code, name} = it;
                                                        const idx = selectedCodes.indexOf(code);
                                                        const isSelected = idx > -1;
                                                        return (
                                                            <span
                                                                key={code}
                                                                onClick={() => {
                                                                    let codes = [...selectedCodes];
                                                                    if (isSelected) {
                                                                        codes.splice(idx, 1);
                                                                    } else {
                                                                        codes.push(code);
                                                                    }
                                                                    departmentChange(codes);
                                                                }}
                                                                className={`regionsItem ${(isSelected || addressIsAllSelected || isAllSelected) ? "selectRegion" : ""}`}
                                                            >{name}</span>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    
                    <div className='localGovernment'>
                        <span
                            className={`regionsAllArea ${isAllLocalGovernmentSelected ? "selectRegion" : ""}`}
                            onClick={() => {
                                departmentChange(isAllLocalGovernmentSelected ? [] : allLocalGovernmentCodeList)
                            }}
                        >全部地方政府机构</span>
                        <div className="regionsAllListBox">
                            <div className="regionsAllList">
                                {
                                    allRegions.map(item => {
                                        let addressIsAllSelected = false;
                                        const addressCodeList = [];
                                        const {id, name} = item;
                                        const idx = selectedCodes.indexOf(id);
                                        const isSelected = idx > -1;
                                        return (
                                            <span
                                                key={item.id}
                                                // key={code}
                                                onClick={() => {
                                                    let codes = [...selectedCodes];
                                                    if (isSelected) {
                                                        codes.splice(idx, 1);
                                                    } else {
                                                        codes.push(id);
                                                    }
                                                    departmentChange(codes);
                                                }}
                                                className={`regionsItem ${(isSelected || addressIsAllSelected || isAllLocalGovernmentSelected) ? "selectRegion" : ""}`}
                                            >{name}</span>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    

                </div>
                <div className="footerOperateBox">
                    <span className="operateBtn cancelBtn"
                          onClick={() => cancelChangeRegions(selectedRegions)}>取消</span>
                    <span className="operateBtn confirmBtn"
                          onClick={() => confirmChangeRegions(selectedRegions, selectedDepartList)}>确定</span>
                </div>
            </div>
        )
    }

    const renderDepartmentList = (selectedCodes) => {
        let allCodeList = [];
        departmentList.forEach(item => {
            const departmentChildren = item.childList || [];
            departmentChildren.forEach(it => {
                allCodeList.push(it.id);
            })
        });
        const isAllSelected = selectedCodes.length >= allCodeList.length;
        return (
            <div className="regionsPopoverBox departmentPopoverBox">
                <div className="areaListBox">
                    <span
                        className={`regionsAllArea ${isAllSelected ? "selectRegion" : ""}`}
                        onClick={() => {
                            departmentChange(isAllSelected ? [] : allCodeList)
                        }}
                    >全部部委</span>
                    <div className="regionsAllListBox">
                        {
                            departmentList.map((item, idx) => {
                                if (item.childList.length > 0) {
                                    let departmentIsAllSelected = true;
                                    const departmentCodeList = [];
                                    for (const addressItem of item.childList) {
                                        const code = addressItem.id;
                                        let flag = selectedCodes.includes(code);
                                        if (departmentIsAllSelected) {
                                            departmentIsAllSelected = flag;
                                        }
                                        departmentCodeList.push(code);
                                    }
                                    return (
                                        <div key={idx} className="regionsList">
                                            <span
                                                className={`regionsArea ${(departmentIsAllSelected || isAllSelected) ? "selectRegion" : ""}`}
                                                onClick={() => {
                                                    let codes = [...selectedCodes];
                                                    if (departmentIsAllSelected) {
                                                        departmentCodeList.forEach(code => {
                                                            let idx = codes.indexOf(code);
                                                            codes.splice(idx, 1);
                                                        })
                                                    } else {
                                                        departmentCodeList.forEach(code => {
                                                            if (!codes.includes(code)) {
                                                                codes.push(code);
                                                            }
                                                        })
                                                    }
                                                    departmentChange(codes)
                                                }}
                                            >{item.name}</span>
                                            <div className="regionsBox">
                                                {
                                                    item.childList.map(it => {
                                                        const idx = selectedCodes.indexOf(it.id);
                                                        const isSelected = idx > -1;
                                                        return (
                                                            <span
                                                                key={it.id}
                                                                onClick={() => {
                                                                    let codes = [...selectedCodes];
                                                                    if (isSelected) {
                                                                        codes.splice(idx, 1);
                                                                    } else {
                                                                        codes.push(it.id);
                                                                    }
                                                                    departmentChange(codes);
                                                                }}
                                                                className={`regionsItem ${(isSelected || departmentIsAllSelected || isAllSelected) ? "selectRegion" : ""}`}
                                                            >{it.name}</span>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>

                </div>
                <div className="footerOperateBox">
                    <span className="operateBtn cancelBtn" onClick={() => cancelChangeDepartment()}>取消</span>
                    <span className="operateBtn confirmBtn"
                          onClick={() => confirmChangeDepartment(selectedDepartList)}>确定</span>
                </div>
            </div>
        )
    }

    let paginationObject = {};

    if (contentWidth) {
        if (contentWidth <= 650) {
            paginationObject.simple = true;
        } else if (contentWidth <= 720) {
            paginationObject.showLessItems = true;
        }
    }

    const sortTypeId = type.id;
    let selectedAreaOrDepartList = [];
    const isDepartment = sortTypeId === "3";

    if (isDepartment) {
        const selectedList = [...(policyType.countryDeptIds || [])];
        departmentList.forEach(item => {
            item.childList.forEach(it => {
                if (selectedList.includes(it.id)) {
                    selectedAreaOrDepartList.push({
                        code: it.id,
                        name: it.name,
                    });
                }
            })
        })
    } else if (sortTypeId === "4") {
        const selectedList = [...(policyType.countryDeptIds || [])];
        regions[sortType]?.forEach(item => {
            item.children.forEach(it => {
                if (selectedList.includes(it.code)) {
                    selectedAreaOrDepartList.push(it);
                }
            })
        })
        // 地方政府
        const selectedLists = [...(policyType.countryDeptIds || [])];
        allRegions.forEach(item => {
            if (selectedLists.includes(item.id)) {
                selectedAreaOrDepartList.push({
                    code: item.id,
                    name: item.name,
                });
            }
        })
    }


    const getPublicpolicyTree = async () => {
        setModalSpinningType(true);
        try {
            let res = await Api.Knowledge.getPublicPolicyeTree({
                data: {
                    departmentId: "1702277047663071232"
                }
            });
            setModalSpinningType(false);
            if (res && res.code === 0 && res.data) {
                setListTree(res.data)

            } else {
                setListTree([])
            }
        } catch (errInfo) {
            setListTree([]);
            setModalSpinningType(false);
            console.log('验证失败:', errInfo);
        }
    }

    const movePublicClassification = (item) => {
        getPublicpolicyTree();
        setIsModalOpen(true);
    }

    // 取消分享
    const cancelSharing = async (item) => {

    }

    const handleOk = async () => {
        if (!selectedKeys) {
            message.info("选择移动知识的目标位置");
            return false;
        }
        try {
            setConfirmLoading(true);
            let res = await Api.Knowledge.moveKnowledgeType({
                data: {
                    departmentSubclassificationId: selectedKeys,
                },
            });
            setConfirmLoading(false);
            if (res && res.code === 0) {
                getList();
                message.success("移动成功");
                handleCancel()
            } else {
            }
        } catch (errInfo) {
            setConfirmLoading(false);
            console.log('验证失败:', errInfo);
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setSelectedKeys("")
    };

    const onSelect = (selectedKeys, e) => {
        const node = e.node;
        if (String(node?.policyThemeType) === "1") {
            setSelectedKeys(selectedKeys[0]);
        }
    }

    const getFooterOperation = (item) => {
        let content = "";
        if (pageType === "public") {
            content = (
                <div className="oprationBox">
                    <Space>
                        {item.titleHref&&<Tooltip placement={"bottom"} title={"原文地址"}>
                            <span className="operateBtn editBtn" onClick={() => {
                                window.open(item.titleHref);
                            }}><Iconfont type={"icon-a-zu27018"} /></span>
                        </Tooltip>}
                        <Tooltip placement="topLeft" title="移动分类">
                            <Iconfont type="icon-move" onClick={(e) => {
                                e.stopPropagation();
                                movePublicClassification(item)
                            }}/>
                        </Tooltip>
                        <Tooltip placement="topLeft" title="取消分享">
                            <Iconfont type="icon-quxiaolianjie" nClick={(e) => {
                                e.stopPropagation();
                                cancelSharing(item)
                            }}/>
                        </Tooltip>
                    </Space>
                </div>
            )
        } else if (!policyThemeName) {
            content = (
                <div className="oprationBox">
                    <Space>
                        {item.titleHref&&<Tooltip placement={"bottom"} title={"原文地址"}>
                            <span className="operateBtn editBtn" onClick={() => {
                                window.open(item.titleHref);
                            }}><Iconfont type={"icon-a-zu27018"} /></span>
                        </Tooltip>}
                        <Tooltip placement="topLeft" title="修改">
                            <Iconfont type={'icon-bianji11'} onClick={(e) => {
                                e.stopPropagation();
                                // updateTheme(item)
                                editFunc(item);
                            }}/>
                        </Tooltip>
                        <Tooltip placement="topLeft" title="删除">
                            <Iconfont type={'icon-shanchu1'} onClick={(e) => {
                                e.stopPropagation();
                                deleteTheme(item)
                            }}/>
                        </Tooltip>
                        
                    </Space>
                </div>
            )
        }else if(item.titleHref){
            content = (
                <div className="oprationBox" style={{width: 'auto'}}>
                    <Tooltip placement={"bottom"} title={"原文地址"}>
                        <span className="operateBtn editBtn" onClick={(e) => {
                            e.stopPropagation()
                            window.open(item.titleHref);
                        }}><Iconfont type={"icon-a-zu27018"} /></span>
                    </Tooltip>
                </div>
            )
        }

        return content
    }

    const editFunc = (policy) => {
        sessionStorage.setItem('policyThemeSearchDataValue', JSON.stringify({
            searchData:searchData,
            activeTab:activeTab
        }));
        history.push(`/page/policySignDetail?policyId=${policy.id}`);
    };

    const changePolicyStatusFunc = async (policy, status) => {
        const res = await Api.Knowledge.changePolicyStatus({
            data: {
                id: policy.id,
                status,
            },
            loading: true,
        });

        const resData = responseDataFilter(res);

        if (resData) {
            message.success(status === "freeze" ? "冻结成功" : "恢复成功");
            operationChange({
                num: Date.now(),
            });
        }
    };

    const deletePolicy = async(item) => {
        ModalConfirm({
            title: '确定要删除这个政策数据吗？',
            onOk:async(resolve, reject)=> {
                try{
                    let policyLableThemeId = "";
                    if(searchData.policyLableThemeId){
                        policyLableThemeId = searchData.policyLableThemeId;
                    }else if(searchData.lableThemeParams[0] && searchData.lableThemeParams[0].policyLableThemeId){
                        policyLableThemeId = searchData.lableThemeParams[0].policyLableThemeId
                    }
                    let res = await Api.Knowledge.deleteCWPolicyByPolicyId({
                        data: {
                            "policyLableThemeId": policyLableThemeId,
                            "id": item.id
                        }
                    });
                    if(res && res.code === 0){
                        message.success("删除成功");
                        getPolicyList();
                        resolve();
                    }else{
                        reject();
                    }
                }catch(errInfo){console.log('验证失败:', errInfo);reject();}
            },
        })
    }

    const iconObject = {
        0: <BugOutlined/>,
        1: <UploadOutlined/>,
        2: <FileSearchOutlined/>,
    };

    const tipTextObject = {
        0: <Text type={'success'}>不限</Text>,
        1: <Text type={'success'}>全文有效</Text>,
        2: <Text type={'success'}>已修改</Text>,
        3: <Text type={'danger'}>全文失效</Text>,
        4: <Text type={'danger'}>全文废止</Text>,
    };

    return (
        <div className="policyListFrame">
            <div className="operationGroup">
                {
                    policyTypeList.map(item => {
                        const {value, text} = item;
                        const isActive = type.id === String(value.id);
                        return (
                            <div
                                onClick={() => {
                                    handleModeChange(value)
                                }}
                                className={`groupItem ${isActive ? "groupItemChecked" : ""}`}
                                key={text}
                            >{text}</div>
                        )
                    })
                }
            </div>
            <div className="policyListContentBox">
                <div className="conditionBox">
                    <div className="conditionItem selectedAreaOrDepartmentBox"
                         style={{display: ["3", "4"].includes(sortTypeId) ? "" : "none"}}>
                        <Tooltip
                            open={tooltipIsOpen}
                            overlayClassName={"areaOrDepartmentTooltipBox"}
                            placement={"bottomRight"}
                            title={(
                                <div className="areaOrDepartmentContentBox" onClick={e => e.stopPropagation()}>
                                    {
                                        isDepartment ?
                                            renderDepartmentList(selectedDepartList)
                                            :
                                            (sortTypeId === "4" ? renderRegions(sortType, selectedDepartList) : undefined)
                                    }
                                </div>
                            )}
                            trigger={"click"}
                            onClick={e => e?.stopPropagation()}
                        >
                            <span className="iconBtn" onClick={() => setTooltipIsOpen(!tooltipIsOpen)}><Iconfont
                                type={"icon-guolvqi"}/></span>
                        </Tooltip>
                        <div
                            className="areaOrDepartmentContentBox"
                            style={{display: selectedAreaOrDepartList.length > 0 ? "" : "none"}}
                        >
                            <i></i>
                            <div className="selectedOperateBox">
                                {
                                    (policyType.codes?.length > 0 || policyType.countryDeptIds?.length > 0) ? (
                                        <Popconfirm
                                            title={`确定清空所选${isDepartment ? "部委" : "地方"}吗？`}
                                            placement="right"
                                            onConfirm={() => {
                                                if (isDepartment) {
                                                    confirmChangeDepartment([]);
                                                } else {
                                                    confirmChangeRegions({}, [], [])
                                                }
                                            }}
                                            overlayClassName={"deletePupConfirmBox"}
                                        >
                                            <div className="listItem deleteBtn">
                                                <span className="clickBtn">< Iconfont type="icon-shanchu1"/></span>
                                                <span className="itemText">清空</span>
                                            </div>
                                        </Popconfirm>
                                    ) : undefined
                                }
                            </div>
                            <div className="areaOrDepartmentListBox">
                                {
                                    selectedAreaOrDepartList.map(item => {
                                        const {code, name} = item;
                                        return (
                                            <div className="listItem" key={code}>
                                                <span className="itemText" title={name}>{name}</span>
                                                <span
                                                    className="clickBtn"
                                                    onClick={() => deleteSelectedCode(policyType, isDepartment, code, selectedRegions)}
                                                ><Iconfont type="icon-close1"/></span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="conditionItem" onClick={(e) => e.stopPropagation()}>
                        {
                            ((showDate || operationData.startTime) && !hideTime) ? (
                                <RangePicker
                                    className="choiceDateBox"
                                    style={{width: "10rem"}}
                                    suffixIcon={undefined}
                                    value={operationData.startTime ? [moment(operationData.startTime), moment(operationData.endTime)] : []}
                                    onChange={(date, dateString) => {
                                        searchChange(dateString, "timeRange")
                                    }}
                                />
                            ) : undefined
                        }
                        {!hideTime&&<span
                            className="iconBtn"
                            onClick={(e) => {
                                e.stopPropagation();
                                if (!operationData.startTime) {
                                    setShowDate(!showDate)
                                }
                            }}
                        ><Iconfont type={"icon-riqi2"}/></span>}
                        
                    </div>
                    <div className="conditionItem" onClick={(e) => e.stopPropagation()}>
                        {
                            (showSearch || operationData.search) ? (
                                <Input
                                    className="searchInputBox"
                                    value={operationData.search}
                                    placeholder={"输入标题或内容关键字"}
                                    allowClear={true}
                                    onPressEnter={() => {
                                        onSearch();
                                    }}
                                    onChange={(e) => {
                                        searchChange(e.target.value, "search");
                                    }}
                                />
                            ) : undefined
                        }

                        <span
                            className="iconBtn"
                            onClick={(e) => {
                                e.stopPropagation();
                                if (!operationData.search) {
                                    setShowSearch(!showSearch)
                                }else{
                                    onSearch();
                                }
                            }}
                        ><Iconfont type={"icon-search"}/></span>

                    </div>
                </div>

                <div className="rightContentBox" id="rightContentBox">
                    <div className="listContent">
                        {listDate && listDate.length > 0 ? (
                            <Timeline mode={'left'}>
                                {
                                    listDate.map((item, idx) => {
                                        const {
                                            id, idCw, uuid, clazz, title: policyTitle, time: publicationTime, titleHref,
                                            content: policyContent, sources: policyInstitution,
                                            status: signStatus, spiderName, insertTime, updateTime, labelType, userId,
                                            lableTime, docNum, timeliness: isEffective, digest: policyDigest, type, fileId, countryDeptId,
                                            countryDeptName, lables: labelList, areaCodes, readNum, createTime, dictCodes,
                                            knowledgeTypeId, fileType,
                                        } = item;
                                        const isHandWrite = String(type) === "1";
                                        const textContent = isHandWrite ? policyDigest : policyContent;
                                        const enableLabelList = (labelList || []).filter(item => !(selectedSignList || []).includes(item));
                                        const signStatusStr = String(signStatus); // 0：未标注  1：标注完成  2：标注删除
                                        const isNormal = signStatusStr === "1";
                                        const enableLabelListStr = enableLabelList.join('<i style="width: 0.5rem;display: inline-block;"></i>');
                                        return (
                                            <Timeline.Item
                                                onClick={() => {
                                                    if (isPolicySign) {
                                                        if (isNormal) {
                                                            editFunc(item);
                                                        }
                                                    } else {
                                                        goKnowledgeDetails({
                                                            ...item,
                                                            type: "ES",
                                                            knowledgeTypeId: "4",
                                                        })
                                                    }
                                                }}
                                                style={(!isNormal && isPolicySign) ? {cursor: "not-allowed", opacity: 0.6} : {}}
                                                key={id || idCw || idx}
                                                label={publicationTime && publicationTime.slice(0,11)}
                                            >
                                                <div className="iconAndTitle">
                                                    <div className="icon">
                                                        {/* 文件图标 */}
                                                        <KnowledgeFileIcon fileType={fileType}/>
                                                    </div>
                                                    <div className="contentItem">
                                                        <div className="title">
                                                            <div
                                                                className="contentItemTitle"
                                                                dangerouslySetInnerHTML={{__html: policyTitle}}
                                                            />

                                                            {/* 判断是否已标注 */}
                                                            {/* labelType 0未标注 1已标注*/}
                                                            {isPolicySign && labelType == 1 && <Tag
                                                                icon={<CheckCircleOutlined style={{color: '#46F10D'}}/>} 
                                                                color="#2BA471"
                                                            >已标注</Tag>}

                                                            {/* 0爬虫 1手动上传政策 2站内搜索添加 */}
                                                            {/* 来源 */}
                                                            {
                                                                isPolicySign ? (
                                                                    <div className="signPolicyOperateBox" onClick={(e) => e.stopPropagation()}>
                                                                        {/* {
                                                                            signStatusStr === "2" ? (
                                                                                <Tooltip placement={"bottom"} title={"恢复"}>
                                                                                    <span className="operateBtn recoverBtn" onClick={() => changePolicyStatusFunc(item, "recover")}><Iconfont type={"icon-huifu"} /></span>
                                                                                </Tooltip>
                                                                            ) : (
                                                                                <React.Fragment>
                                                                                    <Popconfirm
                                                                                        title={"确定冻结吗？"}
                                                                                        overlayClassName="deletePupConfirmBox"
                                                                                        onConfirm={() => changePolicyStatusFunc(item, "freeze")}
                                                                                    >
                                                                                        <Tooltip placement={"bottom"} title={"冻结"}>
                                                                                            <span className="operateBtn deleteBtn"><Iconfont type={"icon-jian"} /></span>
                                                                                        </Tooltip>
                                                                                    </Popconfirm>
                                                                                </React.Fragment>
                                                                            )
                                                                        } */}

                                                                        {/* 已标注的 - 删除 - 回到未标注列表 */}
                                                                        {/* 未标注的 - 删除 - 直接删除 */}
                                                                        <Tooltip placement={"bottom"} title={"删除"}>
                                                                            <span className="operateBtn editBtn" onClick={() => deletePolicy(item)}><Iconfont type={"icon-delete"} /></span>
                                                                        </Tooltip>

                                                                        {item.titleHref&&<Tooltip placement={"bottom"} title={"原文地址"}>
                                                                            <span className="operateBtn editBtn" onClick={() => {
                                                                                window.open(item.titleHref);
                                                                            }}><Iconfont type={"icon-a-zu27018"} /></span>
                                                                        </Tooltip>}

                                                                        {/* 判断未标注的可以显示 */}
                                                                        {labelType == 0 && 
                                                                        <Tooltip placement={"bottom"} title={"标注"}>
                                                                            <span className="operateBtn editBtn" onClick={() => annotationPolicy(item)}><FlagOutlined /></span>
                                                                        </Tooltip>}

                                                                        {/* 已标注的可以移动 - 重新标注 */}
                                                                        {labelType == 1 && 
                                                                        <Tooltip placement={"bottom"} title={"移动"}>
                                                                            <span className="operateBtn editBtn" onClick={() => annotationPolicy(item)}><ExportOutlined /></span>
                                                                        </Tooltip>}
                                                                        
                                                                        <Tooltip placement={"bottom"} title={"查看详情"}>
                                                                            <span className="operateBtn editBtn" onClick={() => goKnowledgeDetails({
                                                                                ...item,
                                                                                type: "ES",
                                                                                knowledgeTypeId: "4",
                                                                            })}><Iconfont type={"icon-yanjing-zhengyan"} /></span>
                                                                        </Tooltip>
                                                                    </div>
                                                                ) : getFooterOperation(item)
                                                            }
                                                        </div>

                                                        <div className="signLabelBox overflowEllipsis">
                                                            <div className="signLabelContentBox">

                                                                {policyInstitution && <Text type="secondary">{policyInstitution}</Text>}

                                                                {docNum && <Text type="secondary">{docNum}</Text>}

                                                                {tipTextObject[isEffective]}

                                                                {isHandWrite && (<span>（手动摘要）</span>)}

                                                                <span className="policySourceType">
                                                                    {iconObject[type]}
                                                                </span>

                                                                {
                                                                    isPolicySign ? (
                                                                        <div className="policyLabelListBox" dangerouslySetInnerHTML={{__html: enableLabelListStr}}>
                                                                            {/*{*/}
                                                                            {/*    enableLabelList.map(item => {*/}
                                                                            {/*        return `item + <i></i>`*/}
                                                                            {/*    })*/}
                                                                            {/*}*/}
                                                                            {/*{enableLabelList.join()}*/}
                                                                        </div>
                                                                    ) : undefined
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* 是否是手动摘要(1是，2否) */}
                                                <p className="contentText" title={textContent}>{textContent}</p>

                                                {/*<div className="listItemFooter">*/}
                                                {/*    <div className="listItemFooterItem">*/}
                                                {/*        <Space size={"large"}>*/}

                                                {/*            <span>发文时间：{item.publicationTime || "--"}</span>*/}

                                                {/*        </Space>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                {/* <Tooltip placement="topLeft" title={item.isManual === 1 ? item.policyDigest: item.policyContent}> */}

                                                {/* </Tooltip> */}
                                            </Timeline.Item>
                                        )
                                    })
                                }
                            </Timeline>
                        ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                    </div>
                    {pagination && (pagination.total > 0) ? (<div className="pagination">
                        <SelfPagination
                            current={pagination.pageIndex}
                            total={pagination.total}
                            onSizeChange={paginationChange}
                            {...paginationObject}
                        />
                    </div>) : ""}
                </div>
            </div>

            <Modal
                title="移动文件"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                confirmLoading={confirmLoading}
            >
                <p>选择移动知识的目标位置</p>
                <div
                    style={{
                        padding: '1rem',
                        background: '#ffffff'
                    }}
                >
                    <Spin size="small" spinning={modalSpinningType}>
                        <Tree
                            onSelect={onSelect}
                            treeData={listTree}
                            selectedKeys={[selectedKeys]}
                            fieldNames={{
                                title: "policyName",
                                key: "id",
                                children: "children",
                                isLeaf: "policyThemeType",
                            }}
                        />
                    </Spin>
                </div>
            </Modal>
        </div>
    )
}

export default PolicyList;
