import React, {useState, useEffect, useRef} from 'react';
import {Typography, Button, Input, Modal} from 'antd';
import { PlusOutlined, SearchOutlined} from '@ant-design/icons';
import moment from 'moment';

import Api from "../../../../../api/index";
import {pagingParam} from '../../../../../utils/defaultValue'
import BackIcon from "../../../../../components/BackIcon/index";

import "./index.scss";

const { Text, Link, Title } = Typography;

const ThemeCconfiguration = ({
    changeModalOpen,
    getThemeList,
    themeList,
    setSerachValue,
    serachValue,
    goDetailsPage
}) => {


    return (
        <div className="themeCconfigurationFrame">
            <div className='themeTopBox'>
                <span className='themeTitle'>政策主题列表</span>
                <Input placeholder="搜索" 
                    value={serachValue} 
                    onChange={(e)=>{setSerachValue(e.target.value)}} 
                    onPressEnter={getThemeList}
                    allowClear
                    suffix={
                        <SearchOutlined />
                    }/>
            </div>
            <div className="themeListBox">
                <div className="themeCard" onClick={()=>changeModalOpen(true)}>
                    <div className='themeImage'>
                        <div className='plusIcon'><PlusOutlined /></div>
                        <div className='plusTitle'>新建政策主题</div>
                    </div>
                </div>
                {
                    themeList.map(item=>{
                        
                        return(
                            <div className="themeCard" key={item.id} onClick={()=>goDetailsPage(item)}>
                                <div className="manageCardTitle" title={item.themeName}>{item.themeName}</div>
                                <div className="manageCardTime">{item.createTime}</div>
                                <div className="manageCardDescription" title={item.themeIntro}>{item.themeIntro}</div>
                            
                                <div className="manageCardButton">
                                    <Button type="primary">编辑</Button>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>)
}

export default ThemeCconfiguration;
