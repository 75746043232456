import React, {useState, useEffect, useRef} from "react";
import moment from "moment";
import {message, Input, Tooltip, Popconfirm, Switch, InputNumber, Select, DatePicker, Modal} from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import Iconfont from "../../../components/Iconfont/Iconfont";
import SelfDesignModal from "../../../components/SelfDesignModal";
import ResizeTable from "../../../components/ResizeTable";
import BackIcon from "../../../components/BackIcon";

import Api from "../../../api/index";
import {isVideoFunc, responseDataFilter, copyToClip, valueIsValid} from "../../../utils/tool";
import "./index.scss";

const BannerManage = () => {
    const BannerApi = Api.Banner;

    const positionTypeList = [
        {title: "左侧", key: "0",},
        {title: "中部", key: "1",},
        {title: "右侧", key: "2",},
    ];

    const positionTypeObject = {
        0: "左侧",
        1: "中部",
        2: "右侧",
    };

    const titleColor = [
        {title: "浅色", key: "0",},
        {title: "深色", key: "1",},
    ];

    const titleColorObject = {
        0: "浅色",
        1: "深色",
    };

    const columns = [
        {
            title: "序号",
            key: "idx",
            dataIndex: "idx",
            align: "center",
            width: 60,
            minWidth: 60,
            disableResize: true,
            render: (val) => {
                return val || "-";
            },
        },
        {
            title: "标题",
            key: "name",
            dataIndex: "name",
            align: "center",
            width: 100,
            minWidth: 100,
            disableResize: true,
            render: (val) => {
                return val || "-";
            },
        },
        {
            title: "介绍文案",
            key: "officialDocument",
            dataIndex: "officialDocument",
            align: "center",
            width: 100,
            minWidth: 100,
            disableResize: true,
            render: (val) => {
                return val || "-";
            },
        },
        {
            title: "按钮名称",
            key: "buttonName",
            dataIndex: "buttonName",
            align: "center",
            width: 120,
            minWidth: 120,
            disableResize: true,
            render: (val) => {
                return val || "-";
            },
        },
        {
            title: "按钮及文字位置",
            key: "location",
            dataIndex: "location",
            align: "center",
            width: 120,
            minWidth: 120,
            render: (val) => {
                return positionTypeObject[val] || "-";
            },
        },
        {
            title: "标题颜色",
            key: "titleColor",
            dataIndex: "titleColor",
            align: "center",
            width: 120,
            minWidth: 120,
            render: (val) => {
                return titleColorObject[val] || "-";
            },
        },
        {
            title: "关联系统",
            key: "subSystemName",
            dataIndex: "subSystemName",
            align: "center",
            width: 120,
            minWidth: 120,
            className: "accountType",
            disableResize: true,
            render: (val, record) => {
                return val || "-";
            },
        },
        {
            title: "系统地址",
            key: "address",
            dataIndex: "address",
            align: "center",
            width: 110,
            minWidth: 110,
            ellipsis: true,
            render: (val) => {
                let result = "-";
                if (val) {
                    result = (
                        <div className="addressBox">
                            <Tooltip title={val} placement={"top"}><span>{val}</span></Tooltip>
                            <Tooltip title={"复制"} placement={"top"}>
                                        <span className="copyBtn" onClick={() => copyFunc(val)}>
                                            <Iconfont type="icon-fuzhi1"/>
                                        </span>
                            </Tooltip>
                        </div>
                    )
                }
                return result;
            },
        },
        {
            title: "Banner图片",
            key: "fileUrl",
            dataIndex: "fileUrl",
            align: "center",
            width: 200,
            minWidth: 200,
            render: (val, record) => {
                const isVideo = isVideoFunc(val);
                return val ? (
                    <div className="bannerImgBox" onClick={() => setDetailImg(record)}>
                        {
                            isVideo ? (<video src={val}></video>) : (<img src={val} alt=""/>)
                        }
                    </div>
                ) : "-";
            },
        },
        {
            title: "显示排序",
            key: "sort",
            dataIndex: "sort",
            align: "center",
            width: 80,
            minWidth: 80,
            disableResize: true,
        },
        {
            title: "上架状态",
            key: "status",
            dataIndex: "status",
            align: "center",
            width: 100,
            minWidth: 120,
            disableResize: true,
            filters: [
                {text: "已上架", value: 0},
                {text: "已下架", value: 1},
            ],
            // onFilter: (val, record) => val === record.status,
            render: (val, record) => {
                const isNormal = String(val) === "0";
                const text = isNormal ? "下架" : "上架";
                return (
                    <Popconfirm
                        title={`确认${text}该banner吗？`}
                        onConfirm={() => updateBannerStatusFunc(record, text)}
                        overlayClassName={"deletePupConfirmBox"}
                    >
                        <div className="userStatusSwitchBox">
                            <Switch
                                checked={isNormal}
                                checkedChildren={"已上架"}
                                unCheckedChildren={"已下架"}
                            ></Switch>
                        </div>
                    </Popconfirm>
                )
            },
        },
        {
            title: "操作",
            key: "operate",
            dataIndex: "operate",
            align: "center",
            disableResize: true,
            width: 100,
            render: (val, record) => {
                return (
                    <div className="operateBox">
                        <Tooltip title={"调整"} placement={"top"}>
                            <span
                                className="operateBtn editBtn"
                                onClick={() => editFunc(record)}
                            ><Iconfont type="icon-bianji2"/></span>
                        </Tooltip>
                        <Popconfirm
                            placement={"left"}
                            title={"确定删除吗？"}
                            okText={"确定"}
                            cancelText={"取消"}
                            overlayClassName={"deletePupConfirmBox"}
                            onConfirm={() => deleteBannerFunc(record)}
                        >
                            <Tooltip title={"删除"} placement={"top"}>
                                <span className="operateBtn resetBtn"><Iconfont type="icon-shanchu1"/></span>
                            </Tooltip>
                        </Popconfirm>
                    </div>
                )
            },
        },
    ];
    const formList = [
        {
            title: "标题",
            key: "name",
            isRequired: true,
            rules: [
                {
                    regExp: /.+/,
                    message: "名称不能为空",
                },
                {
                    regExp: /^\S+(\s+\S+)*$/,
                    message: "名称不能以空格开头或结尾",
                },
            ],
            render: (val) => {
                return (<Input placeholder={"输入banner名称"} value={val}
                               onChange={(e) => valChange("name", e.target.value)}/>)
            },
        },
        {
            title: "标题颜色",
            key: "titleColor",
            isRequired: false,
            render: (val) => {
                return (
                    <div className="radioGroupBox">
                        {
                            titleColor?.map(item => {
                                const {title, key} = item;
                                const isChoice = String(val) === key;
                                return (
                                    <div
                                        className={`radioItem ${isChoice ? "choiceStatus" : ""}`}
                                        key={key}
                                        onClick={() => {
                                            if (!isChoice) {
                                                valChange("titleColor", key)
                                            }
                                        }}
                                    >
                                        <i className="radioCircle"/>
                                        <span className="radioText">{title}</span>
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            },
        },
        {
            title: "介绍文案",
            key: "officialDocument",
            isRequired: true,
            rules: [
                {
                    regExp: /.+/,
                    message: "介绍文案不能为空",
                },
            ],
            render: (val) => {
                return (<Input placeholder={"输入介绍文案(100字以内)"} maxLength={100} value={val}
                               onChange={(e) => valChange("officialDocument", e.target.value)}/>)
            },
        },
        {
            title: "按钮名称",
            key: "buttonName",
            isRequired: true,
            rules: [
                {
                    regExp: /.+/,
                    message: "按钮名称不能为空",
                },
                {
                    regExp: /^\S+(\s+\S+)*$/,
                    message: "按钮名称不能以空格开头或结尾",
                },
            ],
            render: (val) => {
                return (<Input placeholder={"输入按钮名称"} value={val}
                               onChange={(e) => valChange("buttonName", e.target.value)}/>)
            },
        },
        {
            title: "系统地址",
            key: "address",
            isRequired: true,
            rules: [
                // {
                //     regExp: /.+/,
                //     message: "系统地址不能为空",
                // },
                {
                    regExp: /^https?:\/\/(([a-zA-Z0-9_-])+(\.)?)*(:\d+)?(\/((\.)?(\?)?=?&?[a-zA-Z0-9_-](\?)?)*)*$/i,
                    message: "系统地址格式不正确",
                },
            ],
            render: (val) => {
                return (<div className="bannerBgBox">
                        <Input placeholder={"输入系统地址"} value={val}
                               onChange={(e) => valChange("address", e.target.value)}/>
                        {/*<span className="tipText">系统地址</span>*/}
                    </div>
                )
            },
        },
        {
            title: "显示排序",
            key: "sort",
            isRequired: true,
            rules: [
                {
                    regExp: /.+/,
                    message: "展示顺序不能为空",
                },
            ],
            render: (val) => {
                return (
                    <div className="sortNumberBox">
                        <InputNumber
                            size={"small"}
                            value={val}
                            min={1}
                            style={{width: 150}}
                            onChange={(val) => valChange("sort", val)}
                        />
                        <span className="tipText">数字越小越靠前</span>
                    </div>
                )
            },
        },
        {
            title: "背景文件",
            key: "fileUrl",
            isRequired: true,
            rules: [
                {
                    regExp: /.+/,
                    message: "文件不能为空",
                },
            ],
            render: (val) => {
                let fileType = [
                    'image/*',
                    'video/*',
                ];
                const isVideo = isVideoFunc(val);

                return (
                    <div className="bannerBgBox">
                        <div className="bannerThumbImgBox">
                            {
                                val ? (
                                    <div className="bannerThumbImg" id="bannerThumbImg"
                                         onClick={() => selectFileFunc()}>
                                        {
                                            isVideo ? (<video src={val}></video>) : (<img src={val} alt=""/>)
                                        }
                                    </div>
                                ) : (
                                    <span className="uploadBtn" onClick={() => selectFileFunc()}><Iconfont
                                        type={"icon-jiahao"}/></span>
                                )
                            }
                            <input
                                id="fileInput"
                                type="file"
                                key={Math.random()}
                                accept={fileType.join(",")}
                                onChange={(e) => confirmFileFunc("fileUrl", e.target)}
                            />
                        </div>
                        <span
                            className="tipText">支持JPG、PNG、GIF、SVG、MP4，文件比例为29:5，图片最大不超过1Mb，视频最大不超过5Mb</span>
                    </div>
                )
            },
        },
        {
            title: "文案位置",
            key: "location",
            isRequired: false,
            render: (val) => {
                return (
                    <div className="radioGroupBox">
                        {
                            positionTypeList?.map(item => {
                                const {title, key} = item;
                                const isChoice = String(val) === key;
                                return (
                                    <div
                                        className={`radioItem ${isChoice ? "choiceStatus" : ""}`}
                                        key={key}
                                        onClick={() => {
                                            if (!isChoice) {
                                                valChange("location", key)
                                            }
                                        }}
                                    >
                                        <i className="radioCircle"/>
                                        <span className="radioText">{title}</span>
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            },
        },
    ];

    const [dataList, setDataList] = useState([]);
    const [createBannerData, setCreateBannerData] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [formErrorTipObject, setFormErrorTipObject] = useState({});
    const [detailImg, setDetailImg] = useState(null);
    const [listingStatus, setListingStatus] = useState([]);

    const stateRef = useRef({
        dataList,
        createBannerData,
        isOpen,
        pageSize,
        currentPage,
        total,
        formErrorTipObject,
        detailImg,
        listingStatus,
    });

    useEffect(() => {
        stateRef.current = {
            ...stateRef.current,
            currentPage,
            pageSize,
            listingStatus,
        };
        getBannerList();
    }, [currentPage, pageSize, listingStatus]);

    useEffect(() => {
        stateRef.current = {
            ...stateRef.current,
            dataList,
            createBannerData,
            isOpen,
            total,
            formErrorTipObject,
            detailImg,
        };
    }, [dataList, createBannerData, isOpen, total, formErrorTipObject, detailImg]);

    // 复制地址
    function copyFunc(path) {
        copyToClip(path)
    }

    // 获取banner列表
    async function getBannerList(state = {}) {
        const {
            pageSize,
            currentPage,
            listingStatus,
        } = stateRef.current;
        const res = await BannerApi.getBannerList({
            data: {
                pageIndex: currentPage,
                pageSize: pageSize,
                status: listingStatus
            }, loading: true
        });
        const data = responseDataFilter(res);
        if (data) {
            const {
                records, total
            } = data || {};
            setTotal(total);
            setDataList((records || []).map((item, idx) => {
                item.idx = pageSize * (currentPage - 1) + idx + 1;
                return item;
            }));
        }
    }

    // 获取子系统列表
    async function getSystemList() {
        const res = await Api.Manager.ChildSystemManager.getAllChildSystemList();
        const data = responseDataFilter(res);
        let state = {systemList: []};
        if (data) {
            state.systemList = data || [];
        }
        return state;
    }

    // 编辑/新增 用户数据变化
    function valChange(key, value, state) {
        const {createBannerData, formErrorTipObject} = stateRef.current;
        const tipObject = {...formErrorTipObject};
        delete tipObject[key];

        setCreateBannerData({...createBannerData, [key]: value, ...state});
        setFormErrorTipObject(tipObject);
    }

    // 编辑数据
    function editFunc(record) {
        setIsOpen(true);
        setCreateBannerData(JSON.parse(JSON.stringify(record)));
    }

    // 删除数据
    async function deleteBannerFunc(record) {
        const res = await BannerApi.deleteBanner({
            data: {id: record.id},
            loading: true,
            loadingText: "正在删除...",
        });
        const data = responseDataFilter(res);
        if (data) {
            message.success("已删除！");
            getBannerList();
        }
    }

    // 修改banner状态
    async function updateBannerStatusFunc(record, text) {
        const res = await BannerApi.changeBannerStatus({
            data: {id: record.id}, loading: true, loadingText: `${text}中...`,
        })

        const data = responseDataFilter(res);
        if (data) {
            message.success(`${text}成功！`);
            getBannerList();
        }
    }

    // 打开选择文件弹窗
    function selectFileFunc() {
        const inputDom = document.getElementById("fileInput");
        if (inputDom) {
            inputDom.click()
        }
    }

    // 上传文件
    async function uploadFile(file) {
        let formData = new FormData();
        formData.append("file", file);
        const fileRes = await Api.Common.uploadFile({
            data: formData, loading: true, loadingText: "文件上传中..."
        });
        const fileData = responseDataFilter(fileRes, "文件上传失败");
        return fileData?.id;
    }

    // 选择文件
    async function confirmFileFunc(key, value) {
        let file = value.files[0];
        if (file) {
            const fileType = file.type;
            const isVideo = fileType.includes("video");
            let limitSize = isVideo ? 5 : 1;
            if (file.size / 1024 / 1024 > limitSize) {
                return message.warning(`请选择不超过 ${limitSize}M 的文件`);
            }
            if (fileType.includes("image") || isVideo) {
                const fileId = await uploadFile(file);
                if (fileId) {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = (evt) => {
                        valChange(key, evt.target.result, {file, fileId});
                    };
                }
                value.value = "";
            } else {
                message.warning("请选择提示的文件类型的文件");
            }
        }
    }

    // 验证表单
    function validate(state) {
        const {createBannerData} = state;
        let formErrorTipObject = {};
        formList.forEach(item => {
            const {key, title, rules, isRequired} = item;
            let {value, isValid} = valueIsValid(createBannerData[key]);
            if (!isRequired && !isValid) {
                return
            }
            if (rules?.length > 0) {
                for (let rule of rules) {
                    const {regExp, message} = rule;
                    if (!regExp.test(value)) {
                        formErrorTipObject[key] = message;
                        break;
                    }
                }
            }
        });
        let isPass = true;
        if (Object.keys(formErrorTipObject).length > 0) {
            setFormErrorTipObject(formErrorTipObject)
            isPass = false;
        }
        return isPass;
    }

    // 确定 编辑/新增 Banner
    async function confirmFunc() {
        const isPass = validate(stateRef.current);
        const {createBannerData} = stateRef.current;
        if (!createBannerData.address) {
            message.info(`请填写系统地址`);
            return false
        }
        if (isPass) {
            let text = "新增";
            let funcKey = "createBanner";
            createBannerData.status = Number(createBannerData.status);
            if (createBannerData.id) {
                text = "修改";
                funcKey = "updateBanner";
            } else {
                createBannerData.status = 0;
            }

            const res = await BannerApi[funcKey]({
                data: {...createBannerData},
                loading: true,
                loadingText: `Banner${text}中...`
            });
            const data = responseDataFilter(res);
            if (data) {
                message.success(`Banner${text}成功`);
                cancelFunc()
                getBannerList();
            }
        }
    }

    // 取消 编辑/新增 Banner
    function cancelFunc() {
        setIsOpen(false);
        setCreateBannerData({});
        setFormErrorTipObject({});
    }

    return (
        <div className="bannerManageFrame">
            <div className="headBox">
                <i className="zhanwei"></i>
                <span className="titleText">banner 管理</span>
                <BackIcon />
            </div>
            <div className="contentBox">
                <div className="operateHeadBox">
                    <div className="leftAddBox">
                        <span
                            className="addBannerBtn"
                            onClick={() => {
                                setCreateBannerData({location: 0, titleColor: 0});
                                setIsOpen(true);
                            }}
                        >新增Banner</span>
                    </div>
                </div>
                <div className="tableBox" id="tableBox">
                    <ResizeTable
                        columns={columns}
                        dataSource={dataList}
                        tableBoxId="tableBox"
                        computedWidth={false}
                        pagination={{
                            size: 'small',
                            hideOnSinglePage: true,
                            pageSizeOptions: ['10', '20', '50', '100'],
                            pageSize: pageSize,
                            current: currentPage,
                            total,
                            showTotal: (num) => `总计 ${num} 条`,
                            onChange: (pageNum, pageSize) => {
                                setCurrentPage(pageNum);
                                setPageSize(pageSize);
                            },
                        }}
                        onChange={(pagination, filters) => {
                            setListingStatus(filters.status);
                        }}
                    />
                </div>
            </div>
            <SelfDesignModal
                open={isOpen}
                width={550}
                title={`${createBannerData?.id ? "调整" : "新增"}Banner`}
                wrapClassName={"addBannerModalBox"}
                content={(
                    <div className="addBannerModalContentBox">
                        {
                            formList.map((item) => {
                                const {title, key, isRequired, render} = item;
                                return (
                                    <div className={`formItem ${isRequired ? "isRequired" : ""}`} key={key}>
                                        <span className="labelText">{title}</span>
                                        <div className="formItemContent">
                                            {render(createBannerData[key])}
                                        </div>
                                        <span className="errorTipText">{formErrorTipObject[key]}</span>
                                    </div>
                                )
                            })
                        }
                    </div>
                )}
                okText={"确定"}
                cancelText={"取消"}
                onOk={() => confirmFunc()}
                onCancel={() => cancelFunc()}
            />
            <SelfDesignModal
                open={detailImg !== null}
                width={800}
                content={(
                    <div className="detailImgModalContent">
                        {isVideoFunc(detailImg?.fileUrl) ? (
                            <video autoPlay={true} muted={true} controls={true}
                                   src={detailImg?.fileUrl}>视频已失效</video>) : (
                            <img src={detailImg?.fileUrl} alt=""/>)}
                    </div>
                )}
                onCancel={() => setDetailImg(null)}
            />
        </div>
    )
}

export default BannerManage;
