/*
    * 重要文件
*/ 
import myAxios from '../utils/request.js';

export default {
    async getIDocPageList (params={}, cb) {
        let res = await myAxios.postJson('/iDoc/pageList', params);
        return res.data;
    },

    // 查询流程平台的流程模型
    async getIDocDefList (params={}, cb) {
        let res = await myAxios.postJson('/iDoc/defList', params);
        return res.data;
    },
    
    // 删除流程
    async deleteIDocFlow (params={}, cb) {
        let res = await myAxios.getJson('/iDoc/deleteFlow', params);
        return res.data;
    },

    // 查询流程及流程节点人员
    async getIDocFlowNode (params={}, cb) {
        let res = await myAxios.getJson('/iDoc/getFlowNode', params);
        return res.data;
    },

    // 设置流程模型对应的审批节点人员
    async setIDocFlowNode (params={}, cb) {
        let res = await myAxios.postJson('/iDoc/setFlowNode', params);
        return res.data;
    },

    // 设置知识为重要文件
    async setIDoc (params={}, cb) {
        let res = await myAxios.postJson('/iDoc/setIdoc', params);
        return res.data;
    },
    
    // 取消知识的重要文件
    async cancelIdoc (params={}, cb) {
        let res = await myAxios.getJson('/iDoc/cancelIdoc', params);
        return res.data;
    },
    
    // 获取流程平台流程图图片
    async defFlowChart (params={}, cb) {
        let res = await myAxios.getJson('/iDoc/defFlowChart', params);
        return res.data;
    },
}