import React, { useEffect, useState } from "react";
import { Button, DatePicker, message } from "antd";

import AllNumView from "../../components/allNumView";
import SelfTable from "../../../../components/SelfTable";
import Api from "../../../../api";
import "./index.scss";

const { RangePicker } = DatePicker;

const PointsInquiry = () => {

  const changeTypeList = [
    { value: "publishPublisher", label: "发布知识", text: "发布知识"},
    { value: "publishAuthor", label: "创作知识", text: "创作知识"},
    { value: "unPublishPublisher", label: "取消发布-扣回", text: "取消发布-扣回"},
    { value: "unPublishAuthor", label: "取消发布-扣回", text: "取消发布-扣回"},
    { value: "readAuthor", label: "知识被阅读", text: "知识被阅读"},
    { value: "readReader", label: "阅读知识", text: "阅读知识"},
    { value: "readReaderHour", label: "阅读时长奖励", text: "阅读时长奖励"},
    { value: "downloadAuthor", label: "知识被下载", text: "知识被下载"},
    { value: "downloadDownloader", label: "下载知识", text: "下载知识"},
    { value: "collectAuthor", label: "知识被收藏", text: "知识被收藏"},
    { value: "collectCollector", label: "收藏知识", text: "收藏知识"},
    { value: "unCollectAuthor", label: "被取消收藏-扣回", text: "被取消收藏-扣回"},
    { value: "unCollectUnCollector", label: "取消收藏-扣回", text: "取消收藏-扣回"},
    { value: "likeAuthor", label: "知识被点赞", text: "知识被点赞"},
    { value: "unLikeAuthor", label: "被取消点赞-扣回", text: "被取消点赞-扣回"},
    { value: "commentAuthor", label: "知识被评论", text: "知识被评论"},
    { value: "commentCommenter", label: "评论知识", text: "评论知识"},
    { value: "takeNotesTakeNoteser", label: "记录笔记", text: "记录笔记"},
    { value: "deleteNotesDeleteNoteser", label: "删除笔记-扣回", text: "删除笔记-扣回"}
  ]
  

  const [data, setData] = useState([]);
  const [isSpinning, setIsSpinning] = useState(true);
  // 查询数据
  const [siftData, setSiftData] = useState({
    endTime: null,
    pageIndex: 1,
    pageSize: 10,
    startTime: null,
  });
  const [pagination, setPagination] = useState({});
  const [defaultDate, setDefaultDate] = useState();
  useEffect(() => {
    getHistoryList(siftData);
  }, [siftData]);
  // 获取积分记录
  const getHistoryList = async (data) => {
    setIsSpinning(true);
    try {
      let res = await Api.PersonalCenter.getScoreDetail({ data });
      setIsSpinning(false);
      if (res && res.code === 0) {
        let _data = res.data;
        setData(_data.records);
        setPagination({
          pageIndex: _data.current,
          pageSize: _data.size,
          total: _data.total,
        });
      }else{
        message.error(res.msg)
      }
    } catch (error) {}
  };
  const columns = [
    {
      title: "时间",
      dataIndex: "createTime",
      key: "createTime",
      width: '180px',
    },
    {
      title: "变更分值",
      dataIndex: "changeScore",
      key: "changeScore",
      width: '100px',
      render: (_, records) => (
        <div className="scoreChangeValue">
          {" "}
          {records.changeScore > 0 ? (
            <span className="add">+</span>
          ) : records.changeScore < 0 ?(
            <span className="reduce">-</span>
          ):''}{" "}
          {Math.abs(records.changeScore)}
        </div>
      ),
    },
    {
      title: "当前余额",
      dataIndex: "balance",
      key: "balance",
      width: '130px',
    },
    {
      title: "变更原因",
      dataIndex: "changeType", 
      key: "changeType",
      filters: changeTypeList,
      filterMultiple:false,
      filteredValue:siftData.changeType ? [siftData.changeType] : [],
      render:(_, record)=>{
        let text = ""
        changeTypeList.map(item=>{
          if(item.value === record.changeType){
            text = item.label;
          }
        })
          return text
      },
      changeType:"select",
      width: '240px',
    },
    {
      title: "详情",
      dataIndex: "knowledgeName",
      key: "knowledgeName"
    },
  ];
  const onChange = (date, dateString) => {
    let newSearchData = { ...siftData };
    newSearchData.startTime = dateString[0];
    newSearchData.endTime = dateString[1];
    newSearchData.pageIndex = 1;
    newSearchData.pageSize = 10;
    setSiftData(newSearchData);
  };
  const tableChange = (pagination, filters) => {
    let newSearchData = { ...siftData };
    newSearchData.pageSize = pagination.pageSize || 10;
    newSearchData.pageIndex = pagination.current || 1;
    for (let key in filters) {
      newSearchData[key] = filters[key] ? filters[key][0] : ''
    }
    setSiftData(newSearchData);
  };
  const resetPickDate = () => {
    setSiftData({
      endTime: null,
      pageIndex: 1,
      pageSize: 10,
      startTime: null,
    });
    setDefaultDate(new Date());
  };

  return (
    <div className="pointsInquiryFrame">
      <div className="numall">
        <AllNumView></AllNumView>
      </div>
      <div className="pointsHistory">
        <div className="pointsHistoryTitle">
          系统积分变动记录
          <div className="pickDate">
            <RangePicker
              onChange={onChange}
              allowClear={true}
              key={defaultDate}
            />
            <Button
              type="primary"
              style={{ marginLeft: "0.3rem" }}
              onClick={resetPickDate}
            >
              重置
            </Button>
          </div>
        </div>
        <div className="pointsHistoryContent">
          <SelfTable
            onChange={tableChange}
            columns={columns}
            dataSource={data}
            loading={isSpinning}
            pagination={pagination}
          />
        </div>
      </div>
    </div>
  );
};

export default PointsInquiry;
