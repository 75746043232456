import React, {useState, useEffect} from 'react';
import {Modal, Button, List, message} from "antd";
import {CaretDownOutlined} from '@ant-design/icons';

import Api from "../../api";
import Iconfont from "../Iconfont/Iconfont";
import './index.scss';
import {jumpToPage} from "../../utils/tool";

const ProcessStatus = ({id, onCancelBack, flowType}) => {

    const [processData, setProcessData] = useState({});
    const [modaOpen, setModaOpen] = useState(false);

    useEffect(() => {
        if (id) {
            getViewStatus(id);
        }
    }, [id, flowType])

    const getViewStatus = async (id) => {
        message.loading({
            content: '查询中...',
            key: "messageloading"
        });

        try {
            let res = null;
            if(String(flowType) === '1'){
                // 流程平台
                res = await Api.Knowledge.getWorkflowDetailByApplyWfId({
                    data: { applyId: id },
                  });
            }else{
                res = await Api.Knowledge.getWorkflowDetailByApplyId({
                    data: {
                        "id": id
                    }
                });
            }

            message.destroy("messageloading");
            if (res && res.code === 0 && res.data) {

                let approvalProcessUsers = [];

                if (res.data.workflowUsers && res.data.workflowUsers.length > 0) {
                    res.data.workflowUsers.map(item => {
                        let names = [], steps = {};

                        item.map(items => {
                            names.push(items.checkUserName)
                        })

                        steps = {
                            ...item[0],
                            names,
                        }

                        approvalProcessUsers.push(steps)
                    })
                }

                var departmentNamesArr = [...res.data.shareKnowledgeDepartmentNames];
                departmentNamesArr.sort(function (a, b) {
                    return b.length - a.length; // 按照文本长度从大到小排序
                });

                res.data.shareKnowledgeDepartmentNames = departmentNamesArr;

                res.data.approvalProcessUsers = [...approvalProcessUsers];

                setProcessData(res.data);
                setModaOpen(true);
            }
        } catch (errInfo) {
            message.destroy("messageloading");
        }

    }

    const onCancel = () => {
        setModaOpen(false);
        onCancelBack()
    }

    const goKnowleageDetailes = async (item) => {
        let knowledgeId = item.knowledgeId;
        let res = await Api.Message.checkMessageStatus({
            data: {
                id: item.knowledgeId,
                applyId: item.applyId
            },
        });

        if (res.code === 0) {
            let _code = String(res.data);
            const passType = ["1","2","3","4","5"];
            const forType = String(item.applyForType);

            //  applyForType 1下载申请,2发布知识申请,3取消发布知识申请,4阅读权限申请
            // -1已被删除 0已拒绝，1已通过，2待审批，3草稿文章
            if (_code === "-1") {
                message.info("当前知识已被删除");
            } else if (_code === "1" && passType.includes(forType)) {
                // 阅读 只要文件是已通过状态，就可以直接进入详情页面
                // 下载 只要文件是已通过状态，就可以直接进入详情页面
                // 发布 文件已通过才可以进入详情，其他情况进入个人中心详情
                // 取消发布 文件已通过才可以进入详情，其他情况进入个人中心详情
                // 更新文件 文件已通过才可以进入详情，其他情况进入个人中心详情

                jumpToPage({...item, id: undefined, type: ""})
            } else if (_code !== "1" && (forType === "2" || forType === "3" || forType === "5")) {
                // 发布 文件已通过才可以进入详情，其他情况进入个人中心详情
                // 取消发布 文件已通过才可以进入详情，其他情况进入个人中心详情
                // 更新文件 文件已通过才可以进入详情，其他情况进入个人中心详情
                let encodedObject = encodeURIComponent(knowledgeId);
                let url = "";
                if (item.fileType === "online") {
                    url = `/page/onLineWrite?knowledgeId=${encodedObject}&type=view`;
                } else {
                    url = `/page/newKnowledge?knowledgeId=${encodedObject}&type=view`;
                }
                window.open(url);
            } else {
                message.info("当前知识已取消发布");
            }
        }
    }


    return (
        <Modal
            title="流程状态"
            open={modaOpen}
            onCancel={onCancel}
            maskClosable={false}
            destroyOnClose={true}
            footer={null}
            width={520}
            wrapClassName={"processStatusModal"}
        >
            <div className="detailsTitle">申请流程当前状态</div>

            <List itemLayout="horizontal">
                {processData.approvalProcessUsers && processData.approvalProcessUsers.length > 0 && processData.approvalProcessUsers.map((item, index) => {
                    return (<List.Item>
                        <List.Item.Meta
                            avatar={
                                <div className="avatar">

                                    <div className={`${item.checkType === 0 ? "refuseStatus" :
                                        item.checkType === 1 ? "passStatus" :
                                            item.checkType === 2 ? "currentStatus" :
                                                item.checkType === 3 ? "notArrivedStatus" : ""
                                    } names`} title={item.names.join("/")}>
                                        {item.names.join("/")}
                                    </div>

                                    {index + 1 < processData.approvalProcessUsers.length &&
                                        <div className="arrowIcon"
                                             style={{
                                                 borderColor: `${item.checkType === 0 ? "#ff4d4f" :
                                                     item.checkType === 1 ? "#52c41a" :
                                                         item.checkType === 2 ? "#1890ff" :
                                                             item.checkType === 3 ? "#e0e3e8" : ""
                                                 }`
                                             }}
                                        >
                                            <CaretDownOutlined
                                                style={{
                                                    color: `${item.checkType === 0 ? "#ff4d4f" :
                                                        item.checkType === 1 ? "#52c41a" :
                                                            item.checkType === 2 ? "#1890ff" :
                                                                item.checkType === 3 ? "#e0e3e8" : ""
                                                    }`
                                                }}
                                            />
                                        </div>}

                                </div>
                            }
                            title={
                                <div className="userDepartmentInfo">
                                    <span className="departmentName">{item.departmentName}</span>
                                    <span className="title">{item.businessRoleName}</span>
                                    {/* 3未到达 2待审批，1同意，0拒绝 */}
                                    {item.checkType === 0 ? <span className="status refuseStatusText">已拒绝</span> :
                                        item.checkType === 1 ? <span className="status passStatusText">已通过</span> :
                                            item.checkType === 2 ?
                                                <span className="status currentStatusText">未操作</span> :
                                                item.checkType === 3 ? <span className="status">未到达</span> : ""
                                    }
                                </div>
                            }
                            description={(item.checkType === 1 || item.checkType === 0) ? <div className="description">
                                <div className="checkOption">{item.checkOption}</div>
                                <div className="optionInfo">
                                    <span>{item.checkUserName}</span>
                                    <span>
                                        {/* 批准于 */}
                                        {item.updateTime}</span>
                                </div>
                            </div> : <div className="description-empty"></div>}
                        />
                    </List.Item>)
                })
                }
            </List>

            <div className="detailsTitle">基本信息</div>

            <div className="detailsItem">
                <div className="title">申请时间</div>
                <div className="content">{processData.createTime}</div>
            </div>

            <div className="detailsItem">
                <div className="title">申请人</div>
                <div className="content">{processData.userName}</div>
            </div>

            <div className="detailsItem">
                <div className="title">申请人工号</div>
                <div className="content">{processData.jobNum}</div>
            </div>

            <div className="detailsItem">
                <div className="title">申请人所在部门</div>
                <div className="content">{processData.userDepartmentName}</div>
            </div>

            <div className="detailsTitle">申请详情</div>

            <div className="detailsItem">
                <div className="title">申请类型</div>
                <div className="content">{
                    processData.applyForType === 1 ? "下载" :
                        processData.applyForType === 2 ? "发布知识" :
                            processData.applyForType === 3 ? "取消发布知识" :
                                processData.applyForType === 4 ? "阅读" :
                                    processData.applyForType === 5 ? "更新文件" : 
                                    processData.applyForType === 6 ? "阅读重要文件" :
                                    processData.applyForType === 7 ? "下载重要文件" : ""
                }</div>
            </div>
            <div className="detailsItem">
                <div className="title">知识名称</div>
                <div className="content">
                    {processData.knowledgeName}
                    {((processData.applyForType != 4 && processData.applyForType != 6) || 
                    ((processData.applyForType === 4 || processData.applyForType === 6) && processData.applyStatus === 1)) ?
                        <Button type="primary" size={'small'} onClick={() => {
                            goKnowleageDetailes(processData)
                        }}>查看</Button> : ""}

                </div>
            </div>
            <div className="detailsItem">
                <div className="title">知识类型</div>
                <div className="content">{processData.knowledgeTypeName}</div>
            </div>
            <div className="detailsItem">
                <div className="title">分享范围</div>
                <div
                    className="content sharingScope">{processData.shareKnowledgeDepartmentNames && processData.shareKnowledgeDepartmentNames.map(item => {
                    return <span className="shareDepartmentNames">{item}</span>
                })}</div>
            </div>
        </Modal>
    )
}

export default ProcessStatus
