import React,{useState, useEffect} from 'react';
import { Table, DatePicker, Space, Button, message } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';

import storage from "../../../utils/storage";
import BackIcon from "../../../components/BackIcon/index";
import Iconfont from "../../../components/Iconfont/Iconfont";
import './index.scss'

const OverseasCaseConfig = () => {

    const history = useHistory();
    const [menuList, setMenuList] = useState([])

    useEffect(()=>{
        let routeRes = storage.getItem('directory');
        setMenuList(routeRes);
    },[])

    const onchange = (item) => {
        if(item.path){
            history.push(item.path);
        }else{
            message.info("暂未开放")
        }
    }

    return (
        <div className="overseasCaseConfigFrame">
            <div className="title">重要文件管理</div>
            <BackIcon />

            <div className="manageList">
                {menuList.map(itemChild=>{
                     if (itemChild.visible === 1) {
                        return undefined;
                    }
                    const iconList = String(itemChild.icon).split("@@");
                    return(
                        <div className="manageCard" key={itemChild.id} onClick={()=>onchange(itemChild)}>
                            <div className="manageCardImage">
                                <Iconfont
                                    type={iconList[0]}
                                    iStyle={{
                                        color: iconList[1],
                                    }}
                                />
                            </div>
                            <div className="manageCardTitle">{itemChild.name}</div>
                            <div className="manageCardDescription" title={itemChild.description}>{itemChild.description}</div>
                        </div>
                    )
                })}
            </div>
            
        </div>
    )
}
export default OverseasCaseConfig
