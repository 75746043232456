import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Select,
  DatePicker,
  Tree,
  Checkbox,
  Button,
  Tag,
  Tooltip,
  TreeSelect,
  Switch,
  Empty,
  Transfer,
  Modal,
  message,
  Spin
} from "antd";
import moment from 'moment'
import Api from "../../../../api";
import {
  PlusOutlined,
  CloseCircleOutlined,
  FileTextOutlined,
  FolderOutlined,
} from "@ant-design/icons";
import "./index.scss";
import storage from "../../../../utils/storage";
import { ModalConfirm, getSearchParams, moveModal } from "../../../../utils/tool";
const { RangePicker } = DatePicker;
const { TextArea } = Input;

let typs = false;
let timer = null;

const AddKnowledgeFormModal = ({
    form,
    type,
    onSaveInfo,
    knowledgeDetail,
    editStatus
}) => {
  const location = getSearchParams();
  const isEdit = location?.knowledgeId;
   // 作者列表
   const [selectOptions, setSelectOptions] = useState([]);
   
    // 文档标签
  const [tags, setTags] = useState([]);
  const [formData, setFormData] = useState({});
  // 所有项目
  const [projectNameList, setProjectNameList] = useState([]);
  const [userSelectTags, setUserSelectTags] = useState([]);
  // 所有用户
  const [userList, setUserList] = useState([]);

  // 获取用户信息
  const [userInfo] = useState(storage.getItem("userInfo"));
  // 知识归属-所有部门-关联知识
  const [departmentListKnowledge, setDepartmentListKnowledge] = useState([]);

  // 知识归属-所有部门-关联发布
  const [departmentList, setDepartmentList] = useState([]);

  // 子类
  const [secondDepartmentList, setSecondDepartmentList] = useState([]);
  // 知识类型列表
  const [knowledgeTypeId, setKnowledgeTypeId] = useState(null);
  const [knowledgeTypeList, setKnowledgeTypeList] = useState([]);
  // 文档标签
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");
  const inputRef = useRef(null);
  const editInputRef = useRef(null);
  // 部门id
  const [departmentId, setDepartmentId] = useState(null);

  const [newUserList, setNewUserList] = useState([]);
  const [userIDList, setUserIDList] = useState([]);

  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  const [checkedMenuKeys, setCheckedMenuKeys] = useState([]);

  const [searchValue, setSearchValue] = useState('');

  const [userSpinning, setUserSpinning] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [authorNameList, setAuthorNameList] = useState([]);
  const [authorName, setAuthorName] = useState("");
  const [lableOptions, setLableOptions] = useState([]);
  
  // 初始化
  useEffect(() => {
    getDepartment();
    getKnowledgeProject();
    getUserAll();
    // if (userInfo?.departmentId && (userInfo?.departmentName !== "班子成员")) {
    //   setDepartmentId(userInfo?.departmentId);
    //   form.setFieldsValue({
    //     departmentId: userInfo?.departmentId,
    //     shareDepartmentIds: [userInfo?.departmentId],
    //   });
    // }
    getAllHotLable();
  }, []);


  // 回显数据
  useEffect(() => {
    if (JSON.stringify(knowledgeDetail) != "{}") {
      setFormDefalutData();
    }
  }, [knowledgeDetail, departmentList]);
  const setFormDefalutData = () => {
    let _formData = {...knowledgeDetail}
    let newUserIdList = [], selectOptionsNew = [], resList = [];

    if (_formData.knowledgeUserRelVOS?.length > 0) {
      _formData.knowledgeUserRelVOS.map((item) => {
        selectOptionsNew.push({userId: item.userId});
        newUserIdList.push(item.userId);
      });

      newUserIdList.map((item) => {
        _formData.knowledgeUserRelVOS.forEach((obj) => {
          if (obj.userId == item) {
            resList.push({
              userId: obj.userId,
              userName: obj.userName,
              departmentName: obj.departmentMessage,
            });
          }
        });
      });
    } else {
      selectOptionsNew = [];
      newUserIdList = [];
      resList = [];
    }

    
    _formData.knowledgeName = _formData.knowledgeTitle;
    _formData.knowledgeUserRelDTOList = newUserIdList;
    _formData.knowledgeUserRelDTOListObj = selectOptionsNew;
    _formData.knowledgeDocumentLabel = _formData.knowledgeDocumentLabelList;
    _formData.isMark = _formData.isMark == 1 ? true : false;

    if(_formData.projectStartTime && _formData.projectEndTime){
      _formData.time = [
        changeDate(_formData.projectStartTime),
        changeDate(_formData.projectEndTime),
      ];
    }

    if (!_formData?.departmentId && userInfo?.departmentId && (userInfo?.departmentName !== "班子成员")) {
      _formData.departmentId = userInfo?.departmentId
    }
    
    setFormData(_formData);
    
    form.setFieldsValue(_formData);
    setTags(_formData.knowledgeDocumentLabelList);
    setKnowledgeTypeId(_formData.knowledgeTypeId);
    setUserSelectTags(resList);
    setSelectOptions(selectOptionsNew);
    if (_formData?.departmentId) {
      setDepartmentId(_formData?.departmentId);
    }

    // 编辑的时候，回显分享范围是否全选
    if (_formData && _formData.shareDepartmentIds && (_formData.shareDepartmentIds.length > 0) && departmentList) {
      selectAllStatus(_formData.shareDepartmentIds,departmentList)
    }

    if(_formData.nameExts){
      setAuthorNameList(_formData.nameExts)
    }
  };

  useEffect(() => {
    debounce();
  }, [searchValue]);

  useEffect(() => {
    if(userSelectTags){
      let idList = [];

      userSelectTags.map(item=>{
        idList.push(item.userId)
      })

      setUserIDList(idList)
    }
  }, [userSelectTags]);

   // 部门id发生变化时查询关联知识类型列表
   useEffect(() => {
    getKnowledgeTypeList();
  }, [departmentId]);

  // 知识类型发生变化时获取部门内部分类
  useEffect(() => {
    if (departmentId && knowledgeTypeId) {
      getSecondDepartmentList();
    } else {
      setSecondDepartmentList([]);
      form.setFieldsValue({
        departmentSubclassificationId: undefined,
        overseasCaseTypeId: undefined,
      });
    }
  }, [departmentId, knowledgeTypeId]);

  useEffect(() => {
    if (typs) {
      defaultShareDepartment();
    }
  }, [knowledgeTypeId]);

  // 文档标签
  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);

  useEffect(() => {
    if(isModalOpen){
      console.log("isModalOpen", document.querySelector(".selectAuthorModal .ant-modal-header"))
      moveModal(
        document.querySelector(".selectAuthorModal .ant-modal-header"), 
        700,
        580,
        document.querySelector(".selectAuthorModal .ant-modal")
        )
    }
  }, [isModalOpen]);

   // 转化时间类型函数
   const changeDate = (date) => {
    const newDate = new Date(date);
    const Year = newDate.getFullYear();
    const Month = newDate.getMonth();
    const Day = newDate.getDate();
    return moment(`${Year}-${Month + 1}-${Day}`);
  };
  const onFinishField = (values, errorFields) => {
    message.info(`请补充完整信息后再进行${type === 0 ? "发布" : "保存"}`);
  };

  // 获取对应项目接口
  const getKnowledgeProject = async () => {
    let res = await Api.Knowledge.getProjectIdAndName({data: {},});
    setProjectNameList(res.data);
  };

  // 获取所有用户
  const getUserAll = async () => {
    let res = await Api.User.getUserListAll();
    setUserList(res.data);
  };

  const onFinish = (values) => {
    if (tags?.length > 0 && values) {
      if(onSaveInfo){
        onSaveInfo(values,tags, selectOptions);
      }else{
        console.log("没有保存接口")
      }
    } else {
      message.info(`请补充完整基本信息`);
    }
  };
  const debounce = () => {
    if(timer) {
      // 有定时器证明已经在执行中
      // 清楚之前的 添加一个新的
      clearTimeout(timer);
      timer = null;
      setTimer();
    }else{
      setTimer();
    }
  }

  const setTimer = () => {
    timer = setTimeout(function(){
      searchUser();
       // 成功执行后要清楚定时器
      clearTimeout(timer);
      timer = null;
    }, 500)
  }

  const cycleData = (data) => {
    data.map(item=>{
        item.key = item.userId;
        item.title = item.userName+'-'+item.roleName;
    })
    return data
}

// 获取所有是热点标签的标签
const getAllHotLable  = async() => {
  try{
    const res = await Api.Tag.getAllHotLable({data:{}});
    if(res&&res.code === 0&& res.data){
      setLableOptions(res.data)
    }else{
      setLableOptions([])
    }
  }catch{setLableOptions([])}
}

  const getAllDepUser = async() => {
    // 获取所有用户
    setUserSpinning(true);
    try{
      const res = await Api.Common.getAllDepUser({data:{
        name:searchValue
      }});
      setUserSpinning(false);
      if(res&&res.code === 0&& res.data){
          let _data = res.data;
          let _expandedKeys = [];
          _data.map(item=>{
              item.key = item.id;
              item.title = item.name;
              item.checkable = false;
              item.children = item.users;
              _expandedKeys.push(item.id)
              if(item.users){
                  item.children = cycleData(item.users)
              }
          })
          setNewUserList(_data)
          
      }else{
        setNewUserList([])
      }
    }catch{setUserSpinning(false);setNewUserList([])}
    
  }

  // 设置分享范围全选按钮的状态
  const selectAllStatus = (shareDepartmentIds,departmentList) => {
    let type = true;
    departmentList.map((item)=>{
      if(!shareDepartmentIds.includes(item.id)){
        type = false
      }
    })
    setCheckAll(type);
    setIndeterminate(!type);
  }

  
  // 获取所有部门
  const getDepartment = async () => {
    let res = await Api.Department.getDepartmentListAll({data:{
      publishRel:0
    }});
    let list = [];
    if (res.data != null) {
      list =
        res.data &&
        res.data.map((item) => {
          return {
            id: item.id,
            name: item.name,
          };
        });
    }
    setDepartmentList(list);

    let resKnowledge = await Api.Department.getDepartmentListAll({data:{
      knowledgeRel:0
    }});
    let listKnowledge = [];
    if (resKnowledge.data != null) {
      listKnowledge =
      resKnowledge.data &&
      resKnowledge.data.map((item) => {
        return {
          id: item.id,
          name: item.name,
        };
      });
    }
    setDepartmentListKnowledge(listKnowledge);
  };

  const defaultShareDepartment = async () => {
    try {
      let res = await Api.Department.getDepartmentByKnowledgeTypeId({
        data: { knowledgeTypeId },
      });
      if (res && res.code === 0) {
        let _data = res.data;
        if (userInfo?.departmentId) {
          if (_data.find((item) => item == userInfo.departmentId)) {
            _data = _data;
          } else {
            _data = [..._data, userInfo.departmentId];
          }
        }
        form.setFieldsValue({ shareDepartmentIds: _data });
        // 控制全选状态
        selectAllStatus(_data,departmentList)
      }
    } catch (error) {}
  };

  // 获取子部门
  const getSecondDepartmentList = async () => {
    setSecondDepartmentList([]);
    let res = null;
    if(knowledgeTypeId === "9"){
      res = await Api.Knowledge.selectOverseasCaseType({
        data: { departmentId },
      });
      if (res.data) {
        let data = getOverseasCaseTree(res.data);
        setSecondDepartmentList(data);
        if (data?.length > 0 && !isEdit) {
          
          form.setFieldsValue({
            overseasCaseTypeId: res.data[0].value,
          });
        }
      } else {
        form.setFieldsValue({ overseasCaseTypeId: undefined });
      }
    }else{

      res = await Api.Department.getDepartmentTree({
        data: { knowledgeTypeId, departmentId },
      });
      if (res.data) {
        let list = getSecondDepartmentTree(res.data);
        setSecondDepartmentList(list);
        if (list?.length > 0 && !isEdit) {
          form.setFieldsValue({
            departmentSubclassificationId: list[0].value,
          });
        }
      } else {
        form.setFieldsValue({ departmentSubclassificationId: undefined });
      }
    }
    
  };
  
  // 海外案例库树
  const getOverseasCaseTree = (data) => {
    let result = [];
    for (let item of data) {
      let node = {
        title: item.octName,
        value: item.id,
        selectable: item.type != "0",
        isLeaf: item.type != "0",
        icon: item.type != "0" ? [<FileTextOutlined />] : [<FolderOutlined />]
      };
      
      if (item.children?.length > 0) {
        node.children = getOverseasCaseTree(item.children);
      }
      result.push(node);
    }
    return result;
  };

  // 递归获取树状子部门id
  const getSecondDepartmentTree = (data) => {
    let result = [];
    for (let item of data) {
      let node = {
        title: item.subclassification,
        value: item.id,
      };
      if (item.childList?.length > 0) {
        node.children = getSecondDepartmentTree(item.childList);
      }
      result.push(node);
    }
    return result;
  };

  // 获取所有知识列表
  const getKnowledgeTypeList = async () => {
    try {
      let res = await Api.Knowledge.getAllKnowledgeList({
        data: { departmentId },
      });
      if (res.data && res.code == 0) {
        let _data = [...res.data];
        let list = [];
        _data.forEach((item) => {
          if (item.id != 4 && item.id != 8) {
            list.push(item);
          }
        });
        setKnowledgeTypeList(list);
        // form.setFieldsValue({ knowledgeTypeId: list[0]?.id });
        // setKnowledgeTypeId(list[0]?.id);
        // if (formData) {
        //   form.setFieldsValue({ knowledgeTypeId: formData.knowledgeTypeId });
        //   setKnowledgeTypeId(formData.knowledgeTypeId);
        // } else {
        //   form.setFieldsValue({ knowledgeTypeId: list[0]?.id });
        //   setKnowledgeTypeId(list[0]?.id);
        // }
      } else {
        form.setFieldsValue({ knowledgeTypeId: undefined });
        setKnowledgeTypeId(null);
        setKnowledgeTypeList([]);
      }
    } catch (error) {setKnowledgeTypeList([]);}
  };

  const knowledgeTypeChange = async (value) => {
    typs = true;
    setKnowledgeTypeId(value);
    if (value != 2) {
      form.setFieldsValue({ projectId: undefined, time: undefined });
    }
  };

  const verticalLabel = (
    <>
      {tags&&tags.length <= 0 ? null : (
        <span
          style={{
            color: "red",
            marginRight: "0.1rem",
            fontSize: "0.3rem",
            marginLeft: "-0.1rem",
          }}
        >
          ✲
        </span>
      )}
      文档标签
    </>
  );

  // 文档标签
  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);

    form.setFieldsValue({ knowledgeDocumentLabel: newTags });
    setTags(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      form.setFieldsValue({ knowledgeDocumentLabel: [...tags, inputValue] });
      setTags([...tags, inputValue]);
    }
    if ([...tags, inputValue].length > 0) {
      form.resetFields(["vertical"]);
    }
    setInputVisible(false);
    setInputValue("");
  };

  const handleEditInputChange = (e) => {
    setEditInputValue(e.target.value);
  };

  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    form.setFieldsValue({ knowledgeDocumentLabel: newTags });
    setTags(newTags);
    setEditInputIndex(-1);
    setInputValue("");
  };

  const deleteUserSelectTag = (e) => {
    
    let _selectOptions = [...selectOptions];
    let list = _selectOptions.filter((item) => item.userId != e);
    let resList = list.map((item) => {
      return userList.find((obj) => obj.userId === item.userId);
    });
    let selectValue = list.map((item) => {
      return item.userId;
    });

    let newCheckedMenuKeys = checkedMenuKeys.filter((item) => item != e);
    setCheckedMenuKeys(newCheckedMenuKeys)
    
    form.setFieldsValue({ knowledgeUserRelDTOListObj: list });
    form.setFieldsValue({ knowledgeUserRelDTOList: selectValue });
    setUserSelectTags(resList);
    setSelectOptions(list);
  };


  // 选择作者-搜索框变化，根据userID name department搜索
  const searchUser = () => {
    getAllDepUser();
  }


  // 全选分享范围
  const onCheckAllChange = (e) => {
    if(e.target.checked){
      let allDepartmentIds = departmentList.map(item=>{
        return item.id
      })
      form.setFieldsValue({
        shareDepartmentIds:allDepartmentIds
      });
      setIndeterminate(false);
    }else{
      form.setFieldsValue({
        shareDepartmentIds:[userInfo?.departmentId]
      });
      setIndeterminate(true);
    }
    setCheckAll(e.target.checked);
  };

  const onChangeGroup = (list) => {
    setIndeterminate(!!list.length && list.length < departmentList.length);
    setCheckAll(list.length === departmentList.length);
  }

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };

  // 作者穿梭框
  const isChecked = (selectedKeys, eventKey) => selectedKeys.includes(eventKey);
  const generateTree = (treeNodes = [], checkedKeys = []) =>
  treeNodes.map(({ children, ...props }) => ({
    ...props,
    disabled: checkedKeys.includes(props.key),
    children: generateTree(children, checkedKeys),
  }));

  const transferDataSource = [];
  function flatten(list = []) {
    list.forEach((item) => {
      transferDataSource.push(item);
      flatten(item.children);
    });
  }
  flatten(newUserList);

  const addAuthor = () => {
    setIsModalOpen(true);
  }

  const handleOk = () => {

    let selectValue = userIDList.map((item) => {
      return { userId: item };
    });

    let userList = []

    for(let i=0; i<newUserList.length; i++){
      let childrenList = newUserList[i].children
      for(let j=0; j<childrenList.length; j++){
        let childrenItem = childrenList[j];
        
        if(userIDList.includes(childrenItem.key)){
          childrenItem.departmentName = newUserList[i].name
          userList.push(childrenItem)
        }

      }
    }
    
    setSelectOptions(selectValue);
    setUserSelectTags(userList)
    form.setFieldsValue({ knowledgeUserRelDTOListObj: selectValue });
    form.setFieldsValue({ knowledgeUserRelDTOList: userIDList });
    setIsModalOpen(false);
  }

  const handleCancel = () => {
    setIsModalOpen(false);
  }

  const addCustonAuthor = () => {
    if(authorName){
      let _authorNameList = [...authorNameList]
      _authorNameList.push(authorName);
      setAuthorNameList(_authorNameList);
      form.setFieldsValue({ nameExts: _authorNameList });
      setAuthorName("")
    }else{
      message.info("请输入作者姓名")
    }
  }

  const deleteCustonAuthor = (name) => {
    let _authorNameList = [...authorNameList];
    const newAuthorNameList = _authorNameList.filter((tag) => tag !== name);
    setAuthorNameList(newAuthorNameList)
    form.setFieldsValue({ nameExts: newAuthorNameList });
    
  }

  const onCustonAuthorChange = (e) => {
    setAuthorName(e.target.value)
  }

  const onTransferChange = (targetKeys) => {
    setUserIDList(targetKeys);
  };

  const onTransferSearch = (direction,value) => {
    setSearchValue(value)
  }

  const handleAddLable = (value) => {
    if (tags.indexOf(value) === -1) {
      form.setFieldsValue({ knowledgeDocumentLabel: [...tags, value] });
      setTags([...tags, value]);
    }
  }

  return (
    
           
    <div className="addForm">
      <Form
        form={form}
        labelCol={{
          span: 7,
        }}
        wrapperCol={{
          span: 17,
        }}
        layout="horizontal"
        labelAlign="left"
        onFinish={onFinish}
        colon={false}
        onFinishFailed={onFinishField}
      >

        {/* 单文件显示 */}
        {editStatus != 'editBatch' && <Form.Item
          label="标题"
          name="knowledgeName"
          required
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                let nameReg = /^(?![-.~!@#$%^&*！￥……，。,./?？、|+=])/
                if (!value) {
                  return Promise.reject(new Error('请输入标题!'));
                }else if(!nameReg.test(value)){
                  return Promise.reject(new Error('标题不能以特殊字符开始!'));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input placeholder="请输入标题" allowClear={true} />
        </Form.Item>}

        <Form.Item
          label="知识类型"
          name="knowledgeTypeId"
          rules={[
            {
              required: true,
              message: "请选择知识类型!",
            },
          ]}
        >
          <Select onChange={knowledgeTypeChange}>
            {knowledgeTypeList &&
              knowledgeTypeList.map((item) => {
                return (
                  <Select.Option value={item.id} key={item.id}>
                    {item.knowledge_type_name}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>

        {/* 6 参考资料；7 参考研报 时不选择作者，可以输入知识来源*/}
        {knowledgeTypeId == "6" || knowledgeTypeId == "7" ?(<Form.Item
          label={"知识来源"}
          name="knowledgeSource"
          className="knowledgeSource"
        >
          <Input placeholder="请输入来源" allowClear={true} />
        </Form.Item>):
        (<Form.Item
          className="userSelect"
          label={"作者"}
          name="knowledgeUserRelDTOList"
          rules={[
            () => ({
              required: true,
              validator(_, value) {
                if ((userIDList&&userIDList.length > 0) || (authorNameList&&authorNameList.length>0)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("请选择作者!")
                );
              },
            }),
          ]}
        >
          <div>
            {knowledgeTypeId != "6" && knowledgeTypeId != "7" && 
              ((userSelectTags && userSelectTags.length >0) || (authorNameList && authorNameList.length >0))
            ?(
            <Form.Item className="selectedUserList">
              <div className="userTagRender">
                {userSelectTags &&
                  userSelectTags.map((item, idx) => {
                    return (
                      <Tooltip
                        placement="right"
                        title={`${item.userName} ${item?.departmentName}`}
                        key={item.userId}
                      >
                        <Tag
                          closable={true}
                          onClose={() => deleteUserSelectTag(item.userId)}
                        >
                          <span className="tagInfo">
                            {item.userName}&emsp;{item?.departmentName}
                          </span>
                        </Tag>
                      </Tooltip>
                    );
                  })
                }

                {/* 自定义输入的作者 */}
                {authorNameList &&
                  authorNameList.map((item, idx) => {
                    return (
                      <Tooltip
                        placement="right"
                        title={item}
                        key={item}
                      >
                        <Tag
                          closable={true}
                          onClose={() => deleteCustonAuthor(item)}
                        >
                          <span className="tagInfo">
                            {item}
                          </span>
                        </Tag>
                      </Tooltip>
                    );
                  })}
                </div>
              </Form.Item>):""}

              <Button title="请选择作者" onClick={addAuthor} style={{width:"100%",textAlign: 'center',paddingLeft: '10px', color: "#c0c0c0"}}>
                <PlusOutlined />
              </Button>
            </div>
        </Form.Item>)}

        <Form.Item
          label="自定义作者"
          name="nameExts"
          style={{display:"none"}}
        >
          <span></span>
        </Form.Item>

        <Form.Item
          label="知识归属"
          name="departmentId"
          rules={[
            {
              required: true,
              message: "请选择知识归属部门!",
            },
          ]}
        >
          <Select
            allowClear
            showSearch
            onChange={(value) => {
              setDepartmentId(value);
            }}
            placeholder="请选择知识归属部门"
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase()) ||
              option.value
                .toString()
                .toLowerCase()
                .includes(input.toLowerCase())
            } // 同时匹配 label 和 value
          >
            {departmentListKnowledge &&
              departmentListKnowledge.map((item) => {
                return (
                  <Select.Option
                    value={item.id}
                    key={item.id}
                    label={item.name}
                  >
                    {item.name}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>

        {knowledgeTypeId != "9"&&<Form.Item
          label="部门分类"
          name="departmentSubclassificationId"
          className="departmentSubclassificationId"
        >
          <TreeSelect
            showSearch
            style={{
              width: "100%",
            }}
            placeholder="请选择内部部门分类"
            allowClear
            treeDefaultExpandAll
            treeData={secondDepartmentList}
            treeNodeFilterProp="title"
          />
        </Form.Item>}

        {knowledgeTypeId == "9"&&<Form.Item
          label="案例库分类"
          name="overseasCaseTypeId"
          className="overseasCaseTypeId"
          rules={[
            {
              required: true,
              message: "请选择案例库分类!",
            },
          ]}
        >
          <TreeSelect
            showSearch
            style={{
              width: "100%",
            }}
            placeholder="请选择案例库分类"
            allowClear
            treeDefaultExpandAll
            treeData={secondDepartmentList}
            treeNodeFilterProp="label"
            treeIcon={true}
            popupClassName="overseasCaseTree"
          />
        </Form.Item>}


        {knowledgeTypeId == "2"&&<Form.Item
          label="所属项目"
          name="projectId"
          className={"projectSelect"}
        >
          <Select
            showSearch
            filterOption={(input, option) =>
              option.project_name.toLowerCase().includes(input.toLowerCase()) ||
              option.id
                .toString()
                .toLowerCase()
                .includes(input.toLowerCase())
            } // 同时匹配 label 和 value
            fieldNames=	{{label:'project_name', value: 'id'}}
            options={projectNameList}
          />
        </Form.Item>}
        {knowledgeTypeId == "2"&&<Form.Item
          label="项目时间"
          name="time"
          className={"startRangeTime"}
        >
          <RangePicker disabledDate={disabledDate} style={{ width: '100%'}}/>
        </Form.Item>}
        <Form.Item
          label={verticalLabel}
          name="vertical"
          className="vertical"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 22,
          }}
          rules={
            tags.length <= 0
              ? [
                  {
                    required: true,
                    message: "请添加标签内容!",
                  },
                ]
              : false
          }
        >
          {tags && tags.map((tag, index) => {
            if (editInputIndex === index) {
              return (
                <Input
                  ref={editInputRef}
                  key={tag}
                  size="small"
                  className="tag-input"
                  value={editInputValue}
                  onChange={handleEditInputChange}
                  onBlur={handleEditInputConfirm}
                  onPressEnter={handleEditInputConfirm}
                />
              );
            }

            const isLongTag = tag.length > 20;

            const tagElem = (
              <Tag
                className="edit-tag"
                key={tag}
                icon={<CloseCircleOutlined
                  style={{ float: "right" }}
                  onClick={() => handleClose(tag)}
                />}
              >
                <span
                  onDoubleClick={(e) => {
                    if (index !== 0) {
                      setEditInputIndex(index);
                      setEditInputValue(tag);
                      e.preventDefault();
                    }
                  }}
                >
                  {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                </span>
              </Tag>
            );
            return isLongTag ? (
                <Tooltip title={tag} key={tag}>
                    {tagElem}
                </Tooltip>
            ) : (
                tagElem
            );
          })}
          {inputVisible && (
            <Input
              ref={inputRef}
              type="text"
              size="small"
              className="tag-input"
              value={inputValue}
              onChange={handleInputChange}
              onBlur={handleInputConfirm}
              onPressEnter={handleInputConfirm}
              style={{marginTop:10}}
            />
          )}
          {!inputVisible && (
            <Tag className="site-tag-plus" onClick={showInput} style={{cursor: 'pointer'}}>
              <PlusOutlined />
            </Tag>
          )}
        </Form.Item>

        <div style={{marginBottom:10}}>
          <span>推荐标签：</span>
          {lableOptions.map(item=>{
            return <Tag color="blue" style={{cursor: "pointer", marginBottom: 5}} onClick={()=>handleAddLable(item.lableName)}>{item.lableName}</Tag>
          })}
        </div>

        <Form.Item
          label="摘要"
          name="knowledgeDigest"
          className="vertical"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 22,
          }}
          // rules={[
          //   {
          //     required: true,
          //     message: "请输入摘要内容!",
          //   },
          // ]}
        >
          <TextArea rows={4} />
        </Form.Item>

        <div style={{position:"relative"}}>
            <Checkbox
              indeterminate={indeterminate}
              onChange={onCheckAllChange}
              checked={checkAll}
              style={{
                position: 'absolute',
                right: "23px",
                top: "4px",
                zIndex: 1,
              }}
            >
            全选
          </Checkbox>
          <Form.Item
            label={"分享范围"}
            name="shareDepartmentIds"
            className="vertical shareDepartmentIds"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            rules={[
              {
                required: true,
                message: "请选择分享范围!",
              },
            ]}
          >
            <Checkbox.Group onChange={onChangeGroup} style={{ width: "100%" }}>
              {departmentList &&
                departmentList.map((item) => {
                  return (
                    <Checkbox
                      disabled={(userInfo?.departmentId === item.id) ? true :false}
                      key={item.id}
                      value={item.id}
                    >
                      <Tooltip placement="top" title={item.name}>
                        <span className="checkInfo" style={{width:160}}>{item.name}</span>
                      </Tooltip>
                    </Checkbox>
                  );
                })}
            </Checkbox.Group>
          </Form.Item>


          <Form.Item
            label="是否指定为重要文件"
            name="isMark"
            valuePropName="checked"
            labelCol={{
              span: 12,
            }}
          >
            <Switch checkedChildren="是" unCheckedChildren="否"/>
          </Form.Item>


        {/* 储存数据 */}
          <Form.Item
            style={{display:"none"}}
            label={"作者"}
            name="knowledgeUserRelDTOListObj"
            className="knowledgeUserRelDTOListObj"
          >
            <Input placeholder="请输入作者" allowClear={true} />
          </Form.Item>
          <Form.Item
          style={{display:"none"}}
            label={"标签"}
            name="knowledgeDocumentLabel"
            className="knowledgeDocumentLabel"
          >
            <Input placeholder="请输入标签" allowClear={true} />
          </Form.Item>
          
        </div>

      </Form>
      {/* </Modal> */}

      <Modal 
        wrapClassName="selectAuthorModal"  
        title="选择作者" 
        open={isModalOpen} 
        onOk={handleOk} 
        onCancel={handleCancel}
        width={700}
        mask={false}
        style={{
          top: 120, 
          transformOrigin: 'initial',
          left: document.querySelector("#root").offsetWidth/2 - 350,
          margin:0
        }}
      >
        <Transfer
            targetKeys={userIDList}
            dataSource={transferDataSource}
            className="tree-transfer"
            render={(item) => item.title}
            showSelectAll={false}
            onChange={onTransferChange}
            listStyle={{height:'250px'}}
            showSearch={true}
            onSearch={onTransferSearch}
          >
            {({ direction, onItemSelect, selectedKeys }) => {
              if (direction === 'left') {
                const checkedKeys = [...selectedKeys, ...userIDList];
                return (
                  <Spin spinning={userSpinning} tip="加载中...">
                    {newUserList&&newUserList.length>0?<Tree
                      blockNode
                      checkable
                      checkStrictly
                      defaultExpandAll
                      checkedKeys={checkedKeys}
                      showSearch={true}
                      treeData={generateTree(newUserList, userIDList)}
                      onCheck={(_, { node: { key } }) => {
                        onItemSelect(key, !isChecked(checkedKeys, key));
                      }}
                      onSelect={(_, { node: { key } }) => {
                        onItemSelect(key, !isChecked(checkedKeys, key));
                      }}
                    />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                  </Spin>
                );
              }
            }}
          </Transfer>
          <div className="custonAuthor">
            <div className="custonlist">
              {authorNameList && authorNameList.length>0 ? authorNameList.map(item=>{
                return <div className="custonlistItem" key={item}>
                <span className="custonlistItemText">{item}</span>
                <CloseCircleOutlined onClick={()=>deleteCustonAuthor(item)}/>
              </div>
              }):<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"作者不在用户列表中？在此自定义输入"}/>}
            </div>
            
             <Input.Group compact>
              <Input
                style={{
                  width: '241px',
                  borderLeft: 0,
                  borderBottom: 0
                }}
                value={authorName}
                onChange={onCustonAuthorChange}
                placeholder="请输入作者"
              />
              <Button type="primary" onClick={addCustonAuthor}>添加</Button>
            </Input.Group>
          </div>
      </Modal>
    </div>
  );
};

export default AddKnowledgeFormModal;
