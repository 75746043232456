import React from 'react';
import {Layout, Menu, Button, Modal, message, Input, Tooltip} from 'antd';
import Iconfont from "../../components/Iconfont/Iconfont.js";
import './root.scss';
import storage from "../../utils/storage";
import Api from "../../api/index.js";
import Image from "../../components/Image/Image";
import LogoOne from "../../assets/img/logo.png";
import LogoTwo from "../../assets/img/logo-after.png";

const {Header, Content, Footer, Sider} = Layout;
const {SubMenu, Item} = Menu;
const Block = React.Fragment;

class Root extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            navList: [],
            visible: false,
            password: '',
            confirmPassword: '',
            alreadyUpdatePwd: true,
            nav: [
                {
                    text: '文件查询',
                    url: '/page/file-search',
                    name: 'fileSearch',
                },
                {
                    text: '文件归档',
                    url: '/page/documentation',
                    name: 'documentation',
                },
                {
                    text: '管理后台',
                    url: '/page/manager',
                    name: 'manage',
                },
            ],
        };
    }

    async componentWillMount() {
        const nav = this.state.nav
        let {match, updateRoute} = this.props;
        let {history, location} = match;
        const token = storage.getItem('token');
        // const routeRes = await Api.Common.getRouteList()
        const routeRes = {
            data: []
        }
        // const pathList = routeRes?.data || []
        // if (updateRoute) {
        //     updateRoute(pathList)
        // }
        // const fileterNavList = nav.navList.filter((item) => pathList.includes(item.url));
        const fileterNavList = nav
        try {
            // 未登录
            // if (token===null) {
            //     let url = '/page/login';
            //     storage.setItem('redirect', location.pathname+location.search);
            //     history ? history.replace(url) : window.location.replace(url);
            // } else if (location && location.pathname) {
                let activeItem = fileterNavList.filter(item => item.url === location.pathname)
                this.setState({
                    navList: fileterNavList
                }, async () => {
                    if (!activeItem[0]) {
                        // this.toPage('/page/404')
                    } else {
                        const userInfo = storage.getItem('userInfo') || {}
                        if (!userInfo.pwChanged) {
                            this.setState({visible: true, alreadyUpdatePwd: false,})
                        }
                    }
                })
            // }
        } catch (e) {
            // history ? history.replace('/404') : window.location.replace('/404');
        }
    };

    componentDidMount() {}

    componentWillUnmount() {}


    // 跳转页面
    toPage(url) {
        let {pathObj, match} = this.props;
        let {history, location} = match;
        history.push(url)
    }

    // logo 去首页
    toIndex () {
        let url = '/page/file-search'
        const token = storage.getItem('token');
        // 未登录
        if (token===null) {
            let url = '/page/login?isLogin=0';
            storage.setItem('redirect', location.pathname+location.search);
            history ? history.replace(url) : window.location.replace(url);
        }
        this.toPage(url)
    }

    // 退出登录
    async loginOut() {
        let {match} = this.props;
        let {history} = match;
        let token = storage.getItem('token');
        if (token) {
            await Api.Login.loginOutFunc({loading: true});
            storage.removeItem('token');
            history.replace('/page/login?isLogin=0');
        } else {
            history.replace('/page/login?isLogin=0');
        }
    };

    // 关闭弹窗
    closeModal () {
        this.setState({
            visible: false,
            password: '',
            confirmPassword: '',
        })
    }

    // 设置新密码
    async setPasswordFunc () {
        const {password, confirmPassword} = this.state
        if (!(confirmPassword === password)) {
            return message.warning("两次输入的密码不一致！")
        }
        if (password.length < 6) {
            return message.warning("密码长度至少6位数！")
        }
        const res = await Api.Login.setPassword({data: {newPasswords: password}})
        const {code, msg} =  res || {}
        if (code === 200) {
            message.success('密码修改成功！')
            const timer = setTimeout(() => {
                clearTimeout(timer)
                this.closeModal()
                this.loginOut()
            }, 300)
        } else {
            message.error(msg || '密码修改失败！')
        }
    }

    render() {
        const that = this;
        let {match} = that.props;
        let {location} = match;
        const {
            navList, visible, password, confirmPassword, alreadyUpdatePwd,
        } = this.state;
        const activeUrl = location?location.pathname:""
        const userInfo = storage.getItem("userInfo") || {
            headIcon: '',
            userName: '刘德华'
        };
        return (
            <Layout className="rootFrame">
                {/* top */}
                <Header className="rootFrameTop">
                    {/* logo */}
                    <div className="logoBox" onClick={() => that.toIndex()}>
                        <Image imgSrc={LogoOne} iClassName="logoImgOne" />
                        <span className="logoText"><i></i>XXXXX</span>
                    </div>
                    {/* 导航菜单 + 个人头像 */}
                    <div className={"navigator"}>
                        {/* 导航菜单 */}
                        <ul className={"navigatorBox"}>
                            {
                                navList.map((item, idx) => {
                                    return (
                                        <li
                                            className={"navItem" + (activeUrl === item.url ? ' activeNav' : '')}
                                            onClick={() => that.toPage(item.url)}
                                            key={idx}
                                        >
                                            <span className={"navItemText"}>{item.text}</span>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        {/* 个人头像 */}
                        {/* {
                            userInfo ? (
                                <div className='myHeadBox'>
                                    <div className="myHeadIcon">
                                        {
                                            userInfo.headIcon ?
                                                (<img src={userInfo.headIcon} alt=""/>)
                                                : (<span className={"nameText"}>{userInfo.name.slice(0,1)}</span>)
                                        }
                                    </div>
                                    <div className="exitBox">
                                        <span onClick={() => that.setState({visible: true})}>修改密码</span>
                                        <span onClick={() => that.loginOut()}>退出登录</span>
                                    </div>
                                </div>
                            ) : (<span className={"noLoginText"}>未登录</span>)
                        } */}
                    </div>
                </Header>
                {/*contentView */}
                <Content className="contentView">{that.props ? that.props.children : 'notChildPage'}</Content>

                {/* 修改密码 */}
                <Modal
                    open={visible}
                    centered={true}
                    maskClosable={false}
                    closable={alreadyUpdatePwd}
                    wrapClassName={"setPasswordModal"}
                    width={'20rem'}
                    title={alreadyUpdatePwd ? "修改密码" : "为了你的账户安全，请修改密码"}
                    okText={'确定'}
                    cancelText={'取消'}
                    cancelButtonProps={{disabled: !alreadyUpdatePwd}}
                    onCancel={() => this.closeModal()}
                    onOk={() => this.setPasswordFunc()}
                >
                    <div className="setPasswordContent">
                        <div className="itemBox">
                            <span className="labelText">新密码：</span>
                            <Input.Password value={password} placeholder={"输入新密码"} onChange={(e) => this.setState({password: e.target.value})} />
                        </div>
                        <div className="itemBox">
                            <span className="labelText">确认密码：</span>
                            <Input.Password value={confirmPassword} placeholder={"输入确认密码"} onChange={(e) => this.setState({confirmPassword: e.target.value})} />
                        </div>
                    </div>
                </Modal>

                {/* bottom */}
                {/*<Footer className="rootFrameBottom">*/}
                {/*    <div className="bottomContent">bottom</div>*/}
                {/*</Footer>*/}
            </Layout>
        );
    }
}

Root.propTypes = {};

export default Root;
