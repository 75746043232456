import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, Popconfirm, Space, Typography, Select, Modal, message, DatePicker } from 'antd';
import { CloseCircleOutlined, CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

import Api from "../../../api";
import SelfTable from "../../../components/SelfTable"
import BackIcon from "../../../components/BackIcon/index";
import {pagingParam} from "../../../utils/defaultValue";

import './index.scss'


const { RangePicker } = DatePicker;
const { Text, Link } = Typography;
const { confirm } = Modal;

const OperationRecord = () => {

    const [form] = Form.useForm();
    const oprationType = {
        "publish": "发布",
        "unPublish": "取消发布",
        "read": "阅读",
        "download": "下载",
        "collect": "收藏",
        "unCollect": "取消收藏",
        "like": "点赞",
        "unLike": "取消点赞",
        "comment": "评论",
        "takeNotes": "记录笔记",
        "deleteNotes": "删除笔记"
    }
    const recordTypeList = [
        { value: "publish", label: "发布", text: "发布" },
        { value: "unPublish", label: "取消发布", text: "取消发布" },
        { value: "read", label: "阅读", text: "阅读" },
        { value: "download", label: "下载", text: "下载" },
        { value: "collect", label: "收藏", text: "收藏" },
        { value: "unCollect", label: "取消收藏", text: "取消收藏" },
        { value: "like", label: "点赞", text: "点赞" },
        { value: "unLike", label: "取消点赞", text: "取消点赞" },
        { value: "comment", label: "评论", text: "评论" },
        { value: "takeNotes", label: "记录笔记", text: "记录笔记" },
        { value: "deleteNotes", label: "删除笔记", text: "删除笔记" }
    ]

    const [listData, setListData] = useState([]);
    const [esStatusDate, setEsStatusDate] = useState({});
    
    // 查询数据
    const [searchData, setSearchData] = useState({
        ...pagingParam,
        isDeal:""
    }); 
    const [operationData, setOperationData] = useState({
        ...pagingParam,
        isDeal:""
    });

    // 分页
    const [pagination, setPagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "total":0
    });

    const [tableLoading, setTableLoading] = useState(false);
    // 列表选择数据
    // const [selectedRowKey, setSelectedRowKey] = useState([]);
    const columns = [
        {
            title: '知识名称',
            dataIndex: 'knowledgeName',
            editable: true,
        },
        {
            title: '操作人',
            dataIndex: 'userName',
            width: 140,
            editable: true,
        },
        {
            title: '工号',
            dataIndex: 'jobNum',
            width: 140,
            editable: true,
        },
        {
            title: '操作类型',
            dataIndex: 'recordType',
            width: 140,
            editable: true,
            filters: recordTypeList,
            filterMultiple:false,
            filteredValue:searchData.recordType ? [searchData.recordType] : [],
            render: (text, record) => (
                <div>
                    {oprationType[text]}
                </div>
            )
        }, 
        {
            title: '操作时间',
            dataIndex: 'createTime',
            width: 160,
            editable: true,
        },
        {
            title: '操作状态',
            dataIndex: 'isDeal',
            width: 140,
            editable: true,
            render: (text, record) => (
                // 0未处理 1已处理
                <div>
                    {text == 0 ? <Text type="danger">未处理</Text> : <Text type="success">已处理</Text>}
                </div>
            )
        },
    ];

    useEffect(() => {
        getEsStatusDate();
    }, []);

    useEffect(() => {
        getList();
    }, [searchData]);


    const getEsStatusDate = async() => {
        try{
            const esStatus = await Api.PersonalCenter.synchronizedPoints({data:{}});
            if(esStatus&&esStatus.code === 0&& esStatus.data){
                setEsStatusDate(esStatus.data)
            }
        }catch(erro){}
    }

    const getList = async() => {
        setTableLoading(true);
        setListData([]);
        let newSearchData = {
            ...searchData
        }
        if(newSearchData.sort){
            newSearchData.sort = newSearchData.sort ==='descend' ? 0 : newSearchData.sort === 'ascend' ? 1 : 0
        }
        const res = await Api.PersonalCenter.getKnowledgeScoreDetail({data:newSearchData});
        setTableLoading(false);
        if(res&&res.code === 0){
            let _data = res.data;
            setListData(_data.records);
            setPagination({
                "pageIndex": _data.current,
                "pageSize": _data.size,
                "total":_data.total
            });
        }
    }

    const cycleData = (data) => {
        data.map(item=>{
            item.value = item.userId;
            item.text = item.userName+'-'+item.roleName;
        })
        return data
    }

    const changeState = (value) => {
        let newSearchData = {...searchData};
        let newOperationData = {...operationData};
        newSearchData.isDeal = value;
        newOperationData.isDeal = value;
        setOperationData(newOperationData);
        setSearchData(newSearchData);

    }

    const onChanges = (pagination, filters, sorter) => {
        let newSearchData = {...searchData}

        newSearchData.pageIndex = pagination.current ? pagination.current : 1;
        newSearchData.pageSize = pagination.pageSize ? pagination.pageSize : 10;

        newSearchData.sort = sorter.order;

        for (let key in filters) {
            newSearchData[key] = filters[key] ? filters[key][0] : ''
        }
        
        setSearchData(newSearchData);
    };
    const onTimeChange = (date, dateString) => {
        let newSearchData = { ...searchData };
        newSearchData.startTime = dateString[0];
        newSearchData.endTime = dateString[1];
        newSearchData.pageIndex = 1;
        newSearchData.pageSize = 10;
        setSearchData(newSearchData);
    };

    const resetPickDate = () => {
        setSearchData({
            ...pagingParam,
            isDeal: searchData.isDeal
        });
        setOperationData({
            ...pagingParam,
            isDeal: searchData.isDeal
        });
    };

    const syncPoints = async() => {
        confirm({
            title: '确定处理所有数据吗？',
            icon: <ExclamationCircleOutlined />,
            content: '积分处理需要一定时间，操作后，请耐心等待',
            onOk: ()=>{
                return new Promise(async (resolve, reject) => {
                    try{
                        const res = await Api.PersonalCenter.syncPoints({data:{}});
                        resolve();
                        if(res&&res.code === 0){
                            getEsStatusDate();
                            getList();
                            message.success(res.msg)
                        }
                    }catch(erro){
                        reject();
                    }
                }).catch(() => {console.log('Oops errors!');reject()});
            },
            onCancel() {},
        });
        
    }

    const searchChange = (value, type) => {
        let newOperationData = {...operationData};
        if(type === "timeRange"){
            newOperationData.startTime = value[0];
            newOperationData.endTime = value[1];
        }else{
            newOperationData[type] = value;
        }
        newOperationData.pageIndex = 1;
        setOperationData(newOperationData)
    }

    const onSearch = () => {
        // operationData.authorName = operationData.authorName ? operationData.authorName.trim(): operationData.authorName
        // operationData.authorJobNum = operationData.authorJobNum ? operationData.authorJobNum.trim(): operationData.authorJobNum
        // operationData.knowledgeName = operationData.knowledgeName ? operationData.knowledgeName.trim(): operationData.knowledgeName
        
        setSearchData({
            ...searchData,
            ...operationData
        });
    }

    return (
        <div className="operationRecordFrame">
            {/* Elasticsearch */}
            <div className="title">积分变动明细</div>
            <div className="operationState">
                <div className="stateItem" style={searchData.isDeal === "" ? {borderBottom:"2px solid #1890ff"} : {}} onClick={()=>{
                    changeState("");
                }}>
                    <p>全部</p>
                    <p>{esStatusDate.totalNum}</p>
                </div>
                <div className="stateItem" style={searchData.isDeal === 1 ? {borderBottom:"2px solid #1890ff"} : {}} onClick={()=>{
                    changeState(1);
                }}>
                    <p><CheckCircleOutlined />处理成功</p>
                    <p>{esStatusDate.dealNum}</p>
                </div>
                <div className="stateItem" style={searchData.isDeal === 0 ? {borderBottom:"2px solid #1890ff"} : {}} onClick={()=>{
                    changeState(0);
                }}>
                    <p><CloseCircleOutlined />未处理</p>
                    <p>{esStatusDate.unDealNum}</p>
                </div>
            </div>

            <BackIcon />
            <div className="table">
                <div className="oprationBox">
                    <div className="oprationLeft">
                    <div className="operationItem">
                        <span className='operationtitle'>操作时间：</span>
                            <RangePicker
                                onChange={(date, dateString)=>{
                                    searchChange(dateString,"timeRange")
                                }}
                                allowClear={true}
                                value={[
                                    operationData.startTime?moment(operationData.startTime):"",
                                    operationData.endTime?moment(operationData.endTime):""
                                ]}
                            />
                        </div>
                        <div className="operationItem">
                            <span className='operationtitle'>操作人：</span>
                            <Input value={operationData.userName} onPressEnter={onSearch} placeholder='请输入操作人姓名' allowClear onChange={(e)=>{
                                searchChange(e.target.value,"userName")
                            }}/>
                        </div>
                        <div className="operationItem">
                            <span className='operationtitle'>工号：</span>
                            <Input value={operationData.jobNum} onPressEnter={onSearch} placeholder='请输入工号' allowClear onChange={(e)=>{
                                searchChange(e.target.value,"jobNum")
                            }}/>
                        </div>
                        <Space size={"large"}> 
                            <Button type="primary" onClick={onSearch}>搜索</Button>
                            <Button onClick={resetPickDate}>重置</Button>
                        </Space>
                    </div>
                    {searchData.isDeal === 0 && listData?.length > 0 &&<div className="oprationRight">
                        <Link onClick={()=>syncPoints()}>一键全部处理</Link>
                    </div>}
                </div>
                <div className='table'>
                    <Form form={form} component={false}>
                        <SelfTable
                            rowKey={"id"}
                            dataSource={listData}
                            columns={columns}
                            onChange={onChanges} 
                            pagination={pagination}
                            loading={tableLoading}
                        />
                    </Form>
                </div>
            </div>
        </div>
    );
    
};
export default OperationRecord;
