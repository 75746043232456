import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Spin, List, message } from "antd";
import Api from "../../../../api";
import "./index.scss";
const CheckToDoMessage = ({
  toDoMessage,
  setCheckToDoMessageTotal,
  checkStatus
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [checkToDoMessageList, setCheckToDoMessageList] = useState([]);
  const [requestData, setRequestData] = useState({
    pageIndex: 1,
    pageSize: 10,
  });

  useEffect(() => {
    getCheckToDoMessageList(requestData);
  }, [requestData.pageIndex]);

  // 注册订阅刷新我的待办消息列表
  useEffect(() => {
    getCheckToDoMessageList(requestData);
    document.addEventListener("checkToDoMessage", getCheckToDoMessageList);
    return () => {
      document.removeEventListener("checkToDoMessage", getCheckToDoMessageList);
    };
  }, []);

  //待办消息
  const getCheckToDoMessageList = async () => {
    setLoading(true);
    try {
      let res = await Api.Message.getCheckToDoMessage({ data: requestData });
      if (res.data && res.code === 0) {
        let newList;
        if (requestData.pageIndex === 1) {
          newList = res.data?.records;
        } else {
          newList = [...checkToDoMessageList, ...res.data?.records];
        }
        setCheckToDoMessageTotal(res.data.total);
        setHasMore(newList.length < res.data.total);
        setLoading(false);
        setCheckToDoMessageList(newList);
      } else {
        setLoading(false);
        setCheckToDoMessageList([]);
      }
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
  };

  const loadMoreData = () => {
    let _requestData = {...requestData};
    _requestData.pageIndex = _requestData.pageIndex + 1;
    setRequestData(_requestData);
  };

  return (
    <div className="checkToDoMessageFrame">
      <List
        size="small"
        loading={checkToDoMessageList.length <= 0 ? loading : false}
        dataSource={checkToDoMessageList}
        renderItem={(item) => (
          <List.Item>
            <>
              <span>{item.applyUserName}</span>申请
              {item.applyForType === 1
                ? "下载"
                : item.applyForType === 2
                ? "发布"
                : item.applyForType === 3
                ? "取消发布"
                : item.applyForType === 4
                ? "阅读"
                : item.applyForType === 5
                ? "更新文件"
                : item.applyForType === 6
                ? "阅读重要文件"
                : item.applyForType === 7
                ? "下载重要文件"
                : ""}
              <span
                className="interactionKnowledgeName cursorSpan"
                onClick={() => {
                  checkStatus(item)
                  // if((item.applyForType != 4 || (item.applyForType === 4 && item.applyStatus === 1))){
                  //   checkStatus(item)
                  // }else{
                  //   message.info("当前申请未通过")
                  // }
                }}
              >
                《{item.knowledgeName}》
              </span>
              点击
            </>
            <span
              className="cursorSpan"
              onClick={() => {
                toDoMessage(item.checkId,item.flowType);
              }}
            >
              立即审批
            </span>
          </List.Item>
        )}
        loadMore={
          checkToDoMessageList.length == 0 ? null : (
            <div style={{ textAlign: "center", margin: "10px 0" }}>
              {loading ? (
                <Spin />
              ) : hasMore ? (
                  <span
                    className="cursorSpan"
                    style={{ color: "#169bd5", marginTop: 10, cursor: 'pointer' }}
                    onClick={loadMoreData}
                >
                  加载更多
                </span>
              ) : (
                <div style={{ color: "#169bd5", marginTop: 10 }}>
                  已加载全部数据
                </div>
              )}
            </div>
          )
        }
      ></List>
    </div>
  );
};

export default CheckToDoMessage;
