import React, {useState, useEffect, useRef} from 'react';
import {Typography, Button, Input, Modal, Space, Spin} from 'antd';
import { UnorderedListOutlined, SearchOutlined} from '@ant-design/icons';
import moment from 'moment';

import Api from "../../../../../api/index";
import {pagingParam} from '../../../../../utils/defaultValue'
import BackIcon from "../../../../../components/BackIcon/index";
import Iconfont from "../../../../../components/Iconfont/Iconfont";
import PolicyList from "../../../../../components/PolicyList";
import PolicyDataSource from '../../../components/PolicyDataSource'
import PolicyAnnotation from '../../../components/PolicyAnnotation'

import "./index.scss";

const { Text, Link, Title } = Typography;

const PolicyAnnotationData = ({
    changeModalOpen,
    goBack,
    themeDetails,
    refreshThemedetails
}) => {

    const [details, setDetails] = useState({
        // title:'主体名称',
        // department:"部门名称",
        // description:"小知不及大知，小年不及大年。奚以知其然也？朝菌不知晦朔，蟪蛄不知春秋，此小年也。楚之南有冥灵者，以五百岁为春，五百岁为秋；上古有大椿者，以八千岁为春，八千岁为秋，此大年也。而彭祖乃今以久特闻，众人匹之，不亦悲乎！",
        // filterWords:['关键词1','关键词2','关键词3','关键词4','关键词5','关键词26','关键词7'],
        // blockWords:['!关键词1','!关键词2','!关键词3','!关键词4','!关键词5','!关键词6','!关键词7'],
    });

    const [loadingType, setLoadingType] = useState(false);
    const [listDate, setListDate] = useState([]);
    const [pagination, setPagination] = useState("");
    const [searchData, setSearchData] = useState({
        // departmentId: isManage ? -1 : storage.getItem("userInfo").departmentId,
        ...pagingParam,
        policyRegionId: "",
    });
    const [policyType, setPolicyType] = useState({});
    const [toEditFormData, setToEditFormData] = useState(null)
    // 判断新增类型是上传还是站内搜索
    const [policyFromType, setPolicyFromType] = useState(null);
    const [open, setOpen] = useState(false);


    const [activeKey, setActiveKey] = useState('data');

    useEffect(()=>{
        if(themeDetails.id || refreshThemedetails){
            getDetails(themeDetails.id);
        }
    },[themeDetails.id, refreshThemedetails])

    const getDetails = async (id) => {
        
        try{
            // setThemeSpinning(true);
            const res = await Api.Policy.getThemeDeatail({data:{policyLableThemeId:id}});
            // setThemeSpinning(false);
            if(res && res.code ===0 && res.data){
                setDetails(res.data);
                
            }else{
                
            }
        }catch(err){
            // setThemeList([]);
            // setThemeSpinning(false);
        }
    }

    useEffect(()=>{
        if(details.id){
            getPolicyList();
        }
    },[searchData, details.id])


    const getPolicyList = async () => {
        setLoadingType(true);
        setListDate([]);

        let _searchData = {...searchData};

        if (_searchData.regionId === 999) {
            delete _searchData.regionId
        } 
        
        // if (policyTypeKey === '1') {
        //     _searchData.policyThemeId = ""; // 标注管理没有分类
        //     _searchData.startTime = defalutStartData ? defalutStartData : _searchData.startTime;
        //     _searchData.serachUnAssigned = "1";
        //     _searchData.searchReptileOnly = true;
        // }
        // if (policyTypeKey === '2') {
        //     _searchData.policyKeyWords = [] // 后端不要policyKeyWords 他要自己查
        // }
        
        try {
            let res = await Api.Knowledge.getPolicyPageList({
                data: {
                    ..._searchData,
                    policyLableThemeId: details.id,
                    searchReptileOnly: true
                }
            });

            setLoadingType(false);
            if (res && res.code === 0 && res.data.records) {
                setListDate([...res.data.records])
                setPagination({
                    "pageIndex": res.data.current,
                    "pageSize": res.data.size,
                    "total": res.data.total
                })
            } else {
                setPagination("")
            }
        } catch (errInfo) {
            setLoadingType(false);
            console.log('验证失败:', errInfo);
        }
    }


    const policyTypeChange = (param) => {
        setPolicyType({
            ...param,
        });
        delete param.name;
        let _searchData = {
            ...searchData,
            ...param
        }
        setSearchData(_searchData);
    }

    const paginationChange = (pageIndex, pageSize) => {
        let _searchData = {...searchData}
        _searchData.pageIndex = pageIndex;
        _searchData.pageSize = pageSize;
        setSearchData(_searchData);
    }

    // 政策类型下 - 时间和标题筛选
    const operationChange = (value) => {
        
        let _searchData = {
            ...searchData,
            ...value
        }
        setSearchData(_searchData)
    };

    return (
        <div className="policyAnnotationDataFrame">
            <div className='topBox'>
                <div className='titleBox'>
                    <div className='titleLeft'>
                        <Space size="large">
                            <span className='title'>{details.themeName}</span>
                            <span className='department'>{details.departmentNames ? details.departmentNames.join('，') : ""}</span>
                            <span className='sort'>No.{details.sort}</span>
                        </Space>
                    </div>
                    <div className='titleRight'>
                        <Space>
                            <UnorderedListOutlined 
                                onClick={() => changeModalOpen(true, details)}
                            />
                            <Iconfont 
                                type={"icon-fanhui"} 
                                className="backIcon"
                                onClick={goBack}
                            />
                        </Space>
                    </div>
                </div>
                <div className='description'>{details.themeIntro}</div>
            </div>
            <div className="contentBox">
                <div className='leftBox'>
                    <Spin tip="数据加载中" spinning={loadingType}>
                        <PolicyList
                            policyTypeChange={policyTypeChange}
                            listDate={listDate}
                            paginationChange={paginationChange}
                            pagination={pagination}
                            // policyThemeName={selectedTheme.policyName}
                            policyType={policyType}
                            searchData={{
                                ...searchData,
                                policyLableThemeId: details.id
                            }}
                            getPolicyList={getPolicyList}
                            setToEditFormData={setToEditFormData}
                            setOpen={setOpen}
                            operationChange={operationChange}
                            hideTime={true}
                        />
                    </Spin>    
                </div>

                
                <div className='rightBox'>
                    <div className='firstTabs'>
                        <div className={activeKey === 'data' ? 'select' : ''} onClick={()=>setActiveKey('data')}>数据源设置</div>
                        <div className={activeKey === 'annotation' ? 'select' : ''} onClick={()=>setActiveKey('annotation')}>标注设置</div>
                    </div>
                    {
                        activeKey === 'data' && <PolicyDataSource policyLableThemeId={themeDetails.id} getPolicyList={getPolicyList} getDetails={getDetails}/>
                    }
                    {
                        activeKey === 'annotation' && <PolicyAnnotation details={themeDetails} getPolicyList={getPolicyList} getDetails={getDetails}/>
                    }
                </div>
            </div>
            
        </div>)
}

export default PolicyAnnotationData;
