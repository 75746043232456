import React, {useState, useRef, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {DatePicker, Spin, Tooltip, message, Popconfirm, Input, Select, Modal, TreeSelect } from "antd";
import moment from "moment";
import Iconfont from "../../../components/Iconfont/Iconfont";
import NoneData from "../../../components/NoneData";
import {openWPSFileUrl, responseDataFilter, getSearchParams} from "../../../utils/tool";
import getWPSFileUrl from "../../../components/WPS";
import Api from "../../../api";
import storage from "../../../utils/storage";

import "./index.scss";

const policyDetailSign = ({
    policyId,
    policyThemeId,
    fileId="",
    cancelAnnotateModal,
    getPolicyList
}) => {
    const history = useHistory();
    // const {policyId, fileId, policyThemeId} = getSearchParams();
    const userInfo = storage.getItem("userInfo");
    const inputRef = useRef("");
    const [policyDetail, setPolicyDetail] = useState(null);
    const [sourceFormData, setSourceFormData] = useState({
        title: "", // 标题
        countryDeptIds: [], // 发文机构
        time: new Date(), // 发文时间
        docNum: "", // 发文字号
        timeliness: 1, // 时效性
        areaCodes: [], // 地区码
        dictCodes: [], // 税种/行业/政策类型  字典集合
        lables: [], // 其他标签
        digest: "", // 摘要
    });
    const [formData, setFormData] = useState({
        // 必填
        title: "",
        countryDeptIds: [],
        time: new Date(),
        docNum: "",
        timeliness: 1,
        // 非必填
        areaCodes: [],
        dictCodes: [],
        lables: [],
        digest: "",
    });
    const [errorTipTextObject, setErrorTipTextObject] = useState({});
    const [wpsUrl, setWpsUrl] = useState(null);

    const [selectTaxList, setSelectTaxList] = useState([]);
    const [expandList, setExpandList] = useState([]);

    const [classificationTreeData, setClassificationTreeData] = useState([]);

    const timeLinessList = [
        {label: "不限", value: 0},
        {label: "全文有效", value: 1},
        {label: "已修改", value: 2},
        {label: "全文失效", value: 3},
        {label: "全文废止", value: 4},
    ];
    const sortTypeList = [
        {text: "按地理分区", key: "geographic"},
        {text: "按赛迪分区", key: "saidiCity"},
    ];// 赛迪分区(saidiCity) / 地理分区(geographic)
    const [sortType, setSortType] = useState("geographic");// 赛迪分区(saidiCity) / 地理分区(geographic)
    const [regions, setRegions] = useState({
        saidiCity: [],
        geographic: [],
    });
    const [allRegions, setAllRegions] = useState([]);
    const [selectedAreaList, setSelectedAreaList] = useState([]); // 选择的地方codes
    const [selectedDepartList, setSelectedDepartList] = useState([]); // 选择的部委
    const [departmentList, setDepartmentList] = useState([]);

    const [tooltipIsOpen, setTooltipIsOpen] = useState(false); // 控制选择部委弹窗是否显示
    const [tooltipAreaIsOpen, setTooltipAreaIsOpen] = useState(false); // 控制选择地区弹窗是否显示

    const [activeKey, setActiveKey] = useState('stateCouncil');

    const stateCouncil = [{
        "id": "1",
        "name": "国务院",
        "des": "",
        "parentid": "0",
        "order": 0,
        "type": 0,
        "createTime": null,
        "updateTime": null,
        "createBy": "",
        "updateBy": "",
        "visible": 0,
        "childList": [
            {
                "id": "1",
                "name": "国务院",
                "des": "",
                "parentid": "0",
                "order": 0,
                "type": 0,
                "createTime": null,
                "updateTime": null,
                "createBy": "",
                "updateBy": "",
                "visible": 0,
                "childList": []
            },
        ]
    }]

    useEffect(() => {
        getAllOptionData();
        getPolicySortCountFunc();
    }, [policyId]);

    useEffect(() => {
        if (fileId) {
            getWPSFileUrl(fileId, "",'upload', (wpsUrl) => {
                setWpsUrl(wpsUrl);
            });
        }
    }, []);

    const getPolicySortCountFunc = async (param) => { 
        const res = await Api.Policy.getPolicyLableThemeByLoginUser({data:{ searchMyself: true}}); 
        if (res && res.code === 0 && res.data) {
            let newData = res.data.map(item=>{
                let childrenList = [];
                if(item.sysDictDatas){
                    item.sysDictDatas.map(it=>{
                        childrenList.push(
                            {
                                dictLabel: it.dictLabel,
                                dictCode: `${item.id}-${it.dictCode}`,
                                isLeaf: true,
                            }
                        )
                    })
                }
                console.log(childrenList,"childrenList")
                return {
                    dictLabel: item.themeName,
                    dictCode: item.id,
                    isLeaf: childrenList?.length > 0 ? false: true,
                    checkable: false,
                    selectable:false,
                    children: childrenList
                }


                // return {
                //     dictLabel: item.themeName,
                //     dictCode: item.id,
                //     isLeaf: false,
                //     checkable: false,
                //     selectable:false,
                //     children: item.sysDictDatas
                // }
            })
            setClassificationTreeData(newData);
                
        }else{
            setClassificationTreeData([]);
        }
    };

    const getAllDepartmentTree = async() => { 
        const resDepartment = await Api.Dict.selectDeptAndDict({data:{
            knowledgeRel:0,
            dictType: "policy_tax_types",
        }});
        if(resDepartment&&resDepartment.code === 0){
            let newData = resDepartment.data.map(item=>{
                return {
                    dictLabel: item.name,
                    dictCode: item.id,
                    isLeaf: item.sysDictDatas ? false : true,
                    checkable: false,
                    selectable:false,
                    children: item.sysDictDatas
                }
            })
            setClassificationTreeData(newData);
        }
    }

    const updateTreeData = (list, key, children) =>
        list.map((node) => {
          if (node.dictCode === key) {
            return {
              ...node,
              children,
            };
          }
          if (node.children) {
            return {
              ...node,
              children: updateTreeData(node.children, key, children),
            };
          }
          return node;
    });

    const onLoadData = ({key, children}) =>
        
        new Promise(async(resolve) => {
        if (children) {
            resolve();
            return;
        }
        
        let newData = await getPolicySortData(key);

        setClassificationTreeData((origin) =>
            updateTreeData(origin, key, newData),
        );

        resolve();
    });

    const getPolicySortData = (key) => {
    
        return new Promise(async (resolve, reject) => {

            let res = await Api.Policy.selectDictByPolicyLableThemeId({
                data: {
                    policyLableThemeId: key
                },
            })
    
            const {data, code} = res;
            if(code === 0 && data){
                data.map(item=>{
                    item.isLeaf = true;
                    item.dictCode = `${key}-${item.dictCode}`;
                })
                resolve(data);
            }else{
                resolve([]);
            }
        });
    }

    // 返回政策标注页面
    const backPage = () => {
        history.replace("/page/policySign");
        // history.go(-1);
    };

    const getAllOptionData = async () => {
        const modalInfo = Modal.info({
            title: null,
            icon: null,
            content: <div className="loadingBox"><Spin size={"large"} tip={"数据加载中"}/></div>,
            centered: true,
            className: 'loadingModal',
            maskStyle: {
                backgroundColor: 'rgba(0,0,0,0.7)'
            }
        })
        await getRegionsList();
        await getDepartmentList();
        if (!fileId) {
            await getPolicyDetail();
        }
        modalInfo.destroy();
    };

    // 获取政策文件详情数据
    const getPolicyDetail = async () => {
        const res = await Api.Knowledge.getCWPolicyDetailBYPolicyId({
            data: {
                id: policyId,
            },
            loading: false,
        });

        const resData = responseDataFilter(res);
        if (resData) {

            let dictCodes = [];

            if(resData.esPolicyLables){
                resData.esPolicyLables.map(item=>{
                    dictCodes.push(`${item.policyLableThemeId}-${item.dictCode}`)
                })
            }

            setFormData(resData);
            setSourceFormData(resData);

            setSelectTaxList(dictCodes);
            setExpandList(dictCodes);

            setSelectedAreaList(resData.areaCodes || []);
            setSelectedDepartList(resData.countryDeptIds || []);
            getWPSFileUrl(resData?.id, '','detail', (wpsUrl) => {
                setWpsUrl(wpsUrl);
            });
        }
    };


    const getRegionsList = async () => {
        try {
            let res = await Api.Common.getRegions({});
            if (res && res.code === 0 && res.data) {
                setRegions(res.data)
            } else {
            }
        } catch (errInfo) {
            console.log('验证失败:', errInfo);
        }

        try {
            let res = await Api.Common.getDepartments({data: {type: 2}});
            if (res && res.code === 0 && res.data) {
                setAllRegions(res.data[0].childList || [])
            } else {
            }
        } catch (errInfo) {
            console.log('验证失败:', errInfo);
        }
    }

    const getDepartmentList = async () => {
        // 0 国务院 1 部委
        try {
            let res = await Api.Common.getDepartments({data: {type: 1}});
            if (res && res.code === 0 && res.data) {
                
                let departmentList = res.data[0].childList || [];
                // departmentList.unshift(guowuyuan);
                setDepartmentList(departmentList);
            }
        } catch (errInfo) {
            console.log('验证失败:', errInfo);
        }
    }

    // 新窗口打开wps文件
    const openWpsFile = () => {
        let dom = document.getElementById("office");
        let iframeDom = dom?.getElementsByTagName("iframe")[0];
        if (iframeDom && iframeDom.src) {
            window.open(iframeDom.src);
        } else {
            message.warning("文件不存在！！！")
        }
    };

    // 表单值修改
    const formDataValueChange = (key, value) => {
        setFormData({
            ...formData,
            [key]: value,
        });
        if (key === "areaCodes") {
            setSelectedAreaList(value);
        } else if (key === "countryDeptIds") {
            setSelectedDepartList(value);
        }
        setErrorTipTextObject({
            ...errorTipTextObject,
            [key]: undefined,
        })
    };

    // 删除标签
    const removeLabelFunc = (idx) => {
        let _formData = {...formData};
        _formData.lables.splice(idx, 1);
        setFormData(_formData);
    };

    // 添加标签
    const addLabelFunc = (formData) => {
        Modal.confirm({
            width: 300,
            icon: null,
            title: undefined,
            content: (
                <div className="createPolicyLabelBox">
                    <span className="labelName">新增标签</span>
                    <Input
                        ref={inputRef}
                        placeholder={"输入标签名（20字）"}
                        maxLength={20}
                    />
                </div>
            ),
            centered: true,
            maskClosable: false,
            closable: true,
            onOk: (close) => {
                let inputDom = inputRef?.current?.input;
                if (inputDom && inputDom.value) {
                    let _labelList = [...formData.lables];
                    _labelList.push(inputDom.value);
                    formDataValueChange("lables", _labelList);
                    close();
                } else {
                    message.warning("标签名称不能为空！");
                }
            },
            wrapClassName: "selfDesignModal",
        })
    };

    // 提交标注表单数据
    const submitFormData = async (formData) => {
        console.log(formData,"formData")
        const _errorTipTextObject = {};
        if (!formData.title) {
            _errorTipTextObject["title"] = "政策名称不能为空";
        }
        if (!formData.countryDeptIds || (formData.countryDeptIds && formData.countryDeptIds.length < 1)) {
            _errorTipTextObject["countryDeptIds"] = "发文机构不能为空";
        }
        if (!formData.time) {
            _errorTipTextObject["time"] = "发文时间不能为空";
        }
        // if (!formData.docNum) {
        //     _errorTipTextObject["docNum"] = "发文字号不能为空";
        // }
        // if (!formData.timeliness && formData.timeliness !=0) {
        //     _errorTipTextObject["timeliness"] = "时效性不能为空";
        // }

        if (Object.keys(_errorTipTextObject).length > 0) {
            setErrorTipTextObject(_errorTipTextObject);
        } else {
            let func = Api.Knowledge.signPolicyInfo;
            if (fileId) {
                func = Api.Knowledge.addNewFinancePolicy;
                formData.fileId = fileId;
            }
            if(policyThemeId){
                formData.policyThemeId = policyThemeId;
            }
            // formData.dictCodes = [...selectTaxList];

            let esPolicyLables = [];
            
            selectTaxList.map(item=>{
                esPolicyLables.push({
                    'policyLableThemeId': item.split("-")[0],
                    'dictCode': item.split("-")[1]
                })
            })
            formData.esPolicyLables = esPolicyLables;
            console.log(formData,"formData111111");
            
            const res = await func({
                data: formData,
                loading: true,
            });

            const resData = responseDataFilter(res);
            if (resData) {
                message.success(fileId ? "上传成功！": "标注成功！");
                if (!fileId) {
                    // getNextPolicyByCurrentId(() => {
                        cancelAnnotateModal();
                        getPolicyList();
                    // });
                } else {
                    const timerOut = setTimeout(() => {
                        clearTimeout(timerOut);
                        backPage();
                    }, 1000);
                }
            }
        }
    };

    const deletePolicyFunc = async () => {
        const res = await Api.Knowledge.changePolicyStatus({
            data: {
                id: policyId,
            },
            loading: true,
        });

        const resData = responseDataFilter(res);
        if (resData) {
            message.success("冻结成功");
            getNextPolicyByCurrentId(() => {
                cancelAnnotateModal();
            });
        }
    };

    const getNextPolicyByCurrentId = async (callBack) => {
        const res = await Api.Knowledge.getNextPolicyByCurrentId({
            data: {
                id: policyId,
            },
            loading: true,
        });

        const resData = responseDataFilter(res);
        if (resData) {
            Modal.confirm({
                title: callBack ? "有下一篇文章待标注，去标注吗？" : "确定标注下一篇文章吗？",
                okText: callBack ? "去标注" : "确定",
                onOk: () => {
                    location.replace(`/page/policySignDetail?policyId=${resData.id}`);
                },
                onCancel: () => {
                    if (callBack) {
                        callBack();
                    }
                }
            });
        } else {
            message.warning("已经是最后一篇文章了！");
        }
    };

    // 预选机构
    const departmentChange = (codes) => {
        setSelectedDepartList(codes);
    }

    // 取消预选机构
    const cancelChangeDepartment = () => {
        setTooltipIsOpen(false);
        departmentChange([...(formData.countryDeptIds || [])]);
    }

    // 确定选中机构
    const confirmChangeDepartment = (codes) => {

        let confirmSelectedList = [];
        const selectedCountryDeptIdList = [...(codes || [])];
        
        if(activeKey === 'stateCouncil'){
            stateCouncil.map(item => {
                if (selectedCountryDeptIdList.includes(item.id)) {
                    confirmSelectedList.push(item.id);
                }
            })
        }else if(activeKey === 'commissions'){
            departmentList.forEach(item => {
                item.childList.forEach(it => {
                    if (selectedCountryDeptIdList.includes(it.id)) {
                        confirmSelectedList.push(it.id);
                    }
                })
            })
        }else if(activeKey === 'government'){
            regions[sortType]?.forEach(item => {
                item.children.forEach(it => {
                    if (selectedCountryDeptIdList.includes(it.code)) {
                        confirmSelectedList.push(it.code);
                    }
                })
            });
            
            allRegions.map(item => {
                if (selectedCountryDeptIdList.includes(item.id)) {
                    confirmSelectedList.push(item.id);
                }
            })
        }


        
        // console.log(confirmSelectedList,"confirmSelectedList");
        
        
        // if(confirmSelectedList.length > 0){
        //     message.info("请选择发文机构")
        // }
        


        setTooltipIsOpen(false);
        formDataValueChange("countryDeptIds", confirmSelectedList || []);
    }

    // 预选地区
    const regionsChange = (codes) => {
        setSelectedAreaList(codes);
    }

    // 取消预选地区
    const cancelChangeRegions = (regions) => {
        setTooltipAreaIsOpen(false);
        regionsChange([...(formData.areaCodes || [])]);
    }

    // 确定选中地区
    const confirmChangeRegions = (codes) => {
        setTooltipAreaIsOpen(false);
        formDataValueChange("areaCodes", [...codes] || []);
        // formDataValueChange("countryDeptIds", countryDeptIds || []);
    }

    // 删除所选地区或机构
    const deleteSelectedCode = (formData, key, code) => {
        let selectedCodes = [...(formData[key] || [])];
        const idx = selectedCodes.indexOf(code);
        if (idx > -1) {
            selectedCodes.splice(idx, 1);
            formDataValueChange(key, selectedCodes);
        }
    }

    const renderRegions = (sortType, selectedCodes) => {
        const areaList = regions[sortType] || [];

        // 地理分区
        let allAreaCodeList = [];
        areaList.forEach(areaItem => {
            const addressChildren = areaItem.children || [];
            addressChildren.forEach(addressItem => {
                allAreaCodeList.push(addressItem.code);
            })
        });

        const isAllSelected = selectedCodes.length >= allAreaCodeList.length;

        return (
            <div className="regionsPopoverBox">
                <div className="sortTypeListBox">
                    {
                        sortTypeList.map(item => {
                            const {text, key} = item;
                            const isActive = sortType === key;
                            return (
                                <span
                                    className={`sortItem ${isActive ? "activeStatus" : ""}`}
                                    key={key}
                                    onClick={() => {
                                        if (!isActive) {
                                            setSortType(key)
                                        }
                                    }}
                                >{text}</span>
                            )
                        })
                    }
                </div>
                <div className="areaListBox">
                    <div className='regionsPlace'>
                        <span
                            className={`regionsAllArea ${isAllSelected ? "selectRegion" : ""}`}
                            onClick={() => {
                                regionsChange(isAllSelected ? [] : [...allAreaCodeList,...selectedCodes])
                            }}
                        >全部地区</span>
                        <div className="regionsAllListBox">
                            {
                                areaList.map(item => {
                                    let addressIsAllSelected = true;
                                    const addressCodeList = [];
                                    for (const addressItem of item.children) {
                                        const code = addressItem.code;
                                        let flag = selectedCodes.includes(code);
                                        if (addressIsAllSelected) {
                                            addressIsAllSelected = flag;
                                        }
                                        addressCodeList.push(code);
                                    }
                                    return (
                                        <div key={item.id} className="regionsList">
                                            <span
                                                className={`regionsArea ${(addressIsAllSelected || isAllSelected) ? "selectRegion" : ""}`}
                                                onClick={() => {
                                                    let codes = [...selectedCodes];
                                                    if (addressIsAllSelected) {
                                                        addressCodeList.forEach(code => {
                                                            let idx = codes.indexOf(code);
                                                            codes.splice(idx, 1);
                                                        })
                                                    } else {
                                                        addressCodeList.forEach(code => {
                                                            if (!codes.includes(code)) {
                                                                codes.push(code);
                                                            }
                                                        })
                                                    }
                                                    regionsChange(codes)
                                                }}
                                            >{item.name}</span>
                                            <div className="regionsBox">
                                                {
                                                    item.children.map(it => {
                                                        const {code, name} = it;
                                                        const idx = selectedCodes.indexOf(code);
                                                        const isSelected = idx > -1;
                                                        return (
                                                            <span
                                                                key={code}
                                                                onClick={() => {
                                                                    let codes = [...selectedCodes];
                                                                    if (isSelected) {
                                                                        codes.splice(idx, 1);
                                                                    } else {
                                                                        codes.push(code);
                                                                    }
                                                                    regionsChange(codes);
                                                                }}
                                                                className={`regionsItem ${(isSelected || addressIsAllSelected || isAllSelected) ? "selectRegion" : ""}`}
                                                            >{name}</span>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="footerOperateBox">
                    <span className="operateBtn cancelBtn"
                          onClick={() => cancelChangeRegions()}>取消</span>
                    <span className="operateBtn confirmBtn"
                          onClick={() => confirmChangeRegions(selectedAreaList)}>确定</span>
                </div>
            </div>
        )
    }

    const renderDepartmentList = (selectedCodes) => {
        const areaList = regions[sortType] || [];

        
        let allCodeList = [];
        departmentList.forEach(item => {
            const departmentChildren = item.childList || [];
            departmentChildren.forEach(it => {
                allCodeList.push(it.id);
            })
        });

        // 地理分区
        let allAreaCodeList = [];
        areaList.forEach(areaItem => {
            const addressChildren = areaItem.children || [];
            addressChildren.forEach(addressItem => {
                allAreaCodeList.push(addressItem.code);
            })
        });

        // 地方政府
        let allLocalGovernmentCodeList = [];
        allRegions.forEach(localItem => {
            allLocalGovernmentCodeList.push(localItem.id); 
        });

        let _codes = [], countryDeptIds = [];
        selectedCodes.map(item=>{
            if(item.length > 5 || item == 156){
                _codes.push(item)
            }else{
                countryDeptIds.push(item)
            }
        });

        const isAllAreaSelected = selectedCodes.length >= allAreaCodeList.length;
        const isAllLocalGovernmentSelected = countryDeptIds.length >= allLocalGovernmentCodeList.length;
        const isAllSelected = selectedCodes.length >= allCodeList.length;
        return (
            <div className="regionsPopoverBox departmentPopoverBox">
                <div className='firstTabs'>
                    <div className={activeKey === 'stateCouncil' ? 'select' : ''} onClick={()=>{
                        setActiveKey('stateCouncil');
                        // formDataValueChange("countryDeptIds", []);
                    }}>国务院</div>
                    <div className={activeKey === 'commissions' ? 'select' : ''} onClick={()=>{
                        setActiveKey('commissions');
                        // formDataValueChange("countryDeptIds", []);
                    }}>国家部委</div>
                    <div className={activeKey === 'government' ? 'select' : ''} onClick={()=>{
                        setActiveKey('government');
                        // formDataValueChange("countryDeptIds", []);
                    }}>地方政府</div>
                </div>

                {/* 国务院 */}
                {activeKey === 'stateCouncil' && <div className="areaListBox"><div className="regionsAllListBox">
                    {
                        stateCouncil.map((item, idx) => {
                            if (item.childList.length > 0) {
                                let departmentIsAllSelected = true;
                                const departmentCodeList = [];
                                for (const addressItem of item.childList) {
                                    const code = addressItem.id;
                                    let flag = selectedCodes.includes(code);
                                    if (departmentIsAllSelected) {
                                        departmentIsAllSelected = flag;
                                    }
                                    departmentCodeList.push(code);
                                }
                                return (
                                    <div key={idx} className="regionsList">
                                        <span
                                            className={`regionsArea ${(departmentIsAllSelected || isAllSelected) ? "selectRegion" : ""}`}
                                            onClick={() => {
                                                let codes = [...selectedCodes];
                                                if (departmentIsAllSelected) {
                                                    departmentCodeList.forEach(code => {
                                                        let idx = codes.indexOf(code);
                                                        codes.splice(idx, 1);
                                                    })
                                                } else {
                                                    departmentCodeList.forEach(code => {
                                                        if (!codes.includes(code)) {
                                                            codes.push(code);
                                                        }
                                                    })
                                                }
                                                departmentChange(codes)
                                            }}
                                        >{item.name}</span>
                                        <div className="regionsBox">
                                            {
                                                item.childList.map(it => {
                                                    const idx = selectedCodes.indexOf(it.id);
                                                    const isSelected = idx > -1;
                                                    return (
                                                        <span
                                                            key={it.id}
                                                            onClick={() => {
                                                                let codes = [...selectedCodes];
                                                                if (isSelected) {
                                                                    codes.splice(idx, 1);
                                                                } else {
                                                                    codes.push(it.id);
                                                                }
                                                                departmentChange(codes);
                                                            }}
                                                            className={`regionsItem ${(isSelected || departmentIsAllSelected || isAllSelected) ? "selectRegion" : ""}`}
                                                        >{it.name}</span>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                </div></div>}
                

                {/* 国家部委 */}
                {activeKey === 'commissions' && <div className="areaListBox">
                    <span
                        className={`regionsAllArea ${isAllSelected ? "selectRegion" : ""}`}
                        onClick={() => {
                            departmentChange(isAllSelected ? [] : allCodeList)
                        }}
                    >全部机构</span>
                    <div className="regionsAllListBox">
                        {
                            departmentList.map((item, idx) => {
                                if (item.childList.length > 0) {
                                    let departmentIsAllSelected = true;
                                    const departmentCodeList = [];
                                    for (const addressItem of item.childList) {
                                        const code = addressItem.id;
                                        let flag = selectedCodes.includes(code);
                                        if (departmentIsAllSelected) {
                                            departmentIsAllSelected = flag;
                                        }
                                        departmentCodeList.push(code);
                                    }
                                    return (
                                        <div key={idx} className="regionsList">
                                            <span
                                                className={`regionsArea ${(departmentIsAllSelected || isAllSelected) ? "selectRegion" : ""}`}
                                                onClick={() => {
                                                    let codes = [...selectedCodes];
                                                    if (departmentIsAllSelected) {
                                                        departmentCodeList.forEach(code => {
                                                            let idx = codes.indexOf(code);
                                                            codes.splice(idx, 1);
                                                        })
                                                    } else {
                                                        departmentCodeList.forEach(code => {
                                                            if (!codes.includes(code)) {
                                                                codes.push(code);
                                                            }
                                                        })
                                                    }
                                                    departmentChange(codes)
                                                }}
                                            >{item.name}</span>
                                            <div className="regionsBox">
                                                {
                                                    item.childList.map(it => {
                                                        const idx = selectedCodes.indexOf(it.id);
                                                        const isSelected = idx > -1;
                                                        return (
                                                            <span
                                                                key={it.id}
                                                                onClick={() => {
                                                                    let codes = [...selectedCodes];
                                                                    if (isSelected) {
                                                                        codes.splice(idx, 1);
                                                                    } else {
                                                                        codes.push(it.id);
                                                                    }
                                                                    departmentChange(codes);
                                                                }}
                                                                className={`regionsItem ${(isSelected || departmentIsAllSelected || isAllSelected) ? "selectRegion" : ""}`}
                                                            >{it.name}</span>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                </div>}

                {/* 地方政府 */}

                {activeKey === 'government' && <div className="governmentPopoverBox">
                    <div className="sortTypeListBox">
                        {
                            sortTypeList.map(item => {
                                const {text, key} = item;
                                const isActive = sortType === key;
                                return (
                                    <span
                                        className={`sortItem ${isActive ? "activeStatus" : ""}`}
                                        key={key}
                                        onClick={() => {
                                            if (!isActive) {
                                                setSortType(key)
                                            }
                                        }}
                                    >{text}</span>
                                )
                            })
                        }
                    </div>
                    <div className="areaListBox">
                        <div className='regionsPlace'>
                            <span
                                className={`regionsAllArea ${isAllAreaSelected ? "selectRegion" : ""}`}
                                onClick={() => {
                                    departmentChange(isAllAreaSelected ? [] : [...allAreaCodeList,...selectedCodes])
                                }}
                            >全部地区</span>
                            <div className="regionsAllListBox">
                                {
                                    areaList.map(item => {
                                        let addressIsAllSelected = true;
                                        const addressCodeList = [];
                                        for (const addressItem of item.children) {
                                            const code = addressItem.code;
                                            let flag = selectedCodes.includes(code);
                                            if (addressIsAllSelected) {
                                                addressIsAllSelected = flag;
                                            }
                                            addressCodeList.push(code);
                                        }
                                        return (
                                            <div key={item.id} className="regionsLists">
                                                <span
                                                    className={`regionsArea ${(addressIsAllSelected || isAllAreaSelected) ? "selectRegion" : ""}`}
                                                    onClick={() => {
                                                        let codes = [...selectedCodes];
                                                        if (addressIsAllSelected) {
                                                            addressCodeList.forEach(code => {
                                                                let idx = codes.indexOf(code);
                                                                codes.splice(idx, 1);
                                                            })
                                                        } else {
                                                            addressCodeList.forEach(code => {
                                                                if (!codes.includes(code)) {
                                                                    codes.push(code);
                                                                }
                                                            })
                                                        }
                                                        departmentChange(codes)
                                                    }}
                                                >{item.name}</span>
                                                <div className="regionsBox">
                                                    {
                                                        item.children.map(it => {
                                                            const {code, name} = it;
                                                            const idx = selectedCodes.indexOf(code);
                                                            const isSelected = idx > -1;
                                                            return (
                                                                <span
                                                                    key={code}
                                                                    onClick={() => {
                                                                        let codes = [...selectedCodes];
                                                                        if (isSelected) {
                                                                            codes.splice(idx, 1);
                                                                        } else {
                                                                            codes.push(code);
                                                                        }
                                                                        departmentChange(codes);
                                                                    }}
                                                                    className={`regionsItem ${(isSelected || addressIsAllSelected || isAllSelected) ? "selectRegion" : ""}`}
                                                                >{name}</span>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        
                        <div className='localGovernment'>
                            <span
                                className={`regionsAllArea ${isAllLocalGovernmentSelected ? "selectRegion" : ""}`}
                                onClick={() => {
                                    departmentChange(isAllLocalGovernmentSelected ? [] : [...allLocalGovernmentCodeList,...selectedCodes])
                                }}
                            >全部地方政府机构</span>
                            <div className="regionsAllListBox">
                                <div className="regionsAllList">
                                    {
                                        allRegions.map(item => {
                                            let addressIsAllSelected = false;
                                            const addressCodeList = [];
                                            const {id, name} = item;
                                            const idx = selectedCodes.indexOf(id);
                                            const isSelected = idx > -1;
                                            return (
                                                <span
                                                    key={item.id}
                                                    onClick={() => {
                                                        let codes = [...selectedCodes];
                                                        if (isSelected) {
                                                            codes.splice(idx, 1);
                                                        } else {
                                                            codes.push(id);
                                                        }
                                                        departmentChange(codes);
                                                    }}
                                                    className={`regionsItem ${(isSelected || addressIsAllSelected || isAllLocalGovernmentSelected) ? "selectRegion" : ""}`}
                                                >{name}</span>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

                <div className="footerOperateBox">
                    <span className="operateBtn cancelBtn" onClick={() => cancelChangeDepartment()}>取消</span>
                    <span className="operateBtn confirmBtn"
                          onClick={() => confirmChangeDepartment(selectedDepartList)}>确定</span>
                </div>
            </div>
        )
    }

    const nextPolicyFunc = () => {
        getNextPolicyByCurrentId();
    };

    let isChange = JSON.stringify(formData) !== JSON.stringify(sourceFormData);
    const isAdd = Boolean(fileId);
    if (!isAdd) {
        let sysDictCodes = {
            policy_tax_types: selectTaxList,
        };

        let sysDictCodeMap = {
            policy_tax_types: [],
            ...(formData.sysDictCodeMap || {}),
        };
        isChange = JSON.stringify(sysDictCodes) !== JSON.stringify(sysDictCodeMap);
    }

    // 选择发文机构
    let confirmSelectedDepartList = [];
    const selectedCountryDeptIdList = [...(formData.countryDeptIds || [])];
    departmentList.forEach(item => {
        item.childList.forEach(it => {
            if (selectedCountryDeptIdList.includes(it.id)) {
                confirmSelectedDepartList.push({
                    code: it.id,
                    name: it.name,
                });
            }
        })
    })
    regions[sortType]?.forEach(item => {
        item.children.forEach(it => {
            if (selectedCountryDeptIdList.includes(it.code)) {
                confirmSelectedDepartList.push(it);
            }
        })
    });
    stateCouncil.map(item => {
        if (selectedCountryDeptIdList.includes(item.id)) {
            confirmSelectedDepartList.push({
                code: item.id,
                name: item.name,
            });
        }
    })
    allRegions.map(item => {
        if (selectedCountryDeptIdList.includes(item.id)) {
            confirmSelectedDepartList.push({
                code: item.id,
                name: item.name,
            });
        }
    })



    // -----
    let confirmSelectedAreaList = [];
    const selectedAreaCodesList = [...(formData.areaCodes || [])];
    regions[sortType]?.forEach(item => {
        item.children.forEach(it => {
            if (selectedAreaCodesList.includes(it.code)) {
                confirmSelectedAreaList.push(it);
            }
        })
    });
    allRegions.map(item => {
        if (selectedAreaCodesList.includes(item.id)) {
            confirmSelectedAreaList.push(item);
        }
    })

    return (
        <div className="policyDetailSignModalFrame">
            <div className="contentBox">
                {/* 标注表单信息 */}
                <div className="signFormDataBox">
                    
                    {/* 表单 */}
                    <div className="formContentBox">
                        <div className="scrollBox">
                            {/* 必填项 */}
                            <div className="formInfoItemBox">
                                <div className="infoTitleBox">
                                    <Iconfont type="icon-fenlei"/>
                                    <span className="infoTitle">基本信息</span>
                                </div>
                                <div className="formItemListBox">
                                    <div className="formItem isRequired">
                                        <span className="labelText">政策名称</span>
                                        <div className="formItemContent">
                                            <Input
                                                placeholder="输入政策名称"
                                                value={formData.title}
                                                onChange={(e) => formDataValueChange("title", e.target.value)}
                                            />
                                        </div>
                                        <span className="tipText">{errorTipTextObject.title}</span>
                                    </div>
                                    <div className="formItem isRequired">
                                        <span className="labelText">发文机构</span>
                                        <div className="formItemContent">
                                            <div className="selectedAreaOrDepartmentBox">
                                                <div className="operateBtnBox">
                                                    <Tooltip
                                                        open={tooltipIsOpen}
                                                        overlayClassName={"areaOrDepartmentTooltipBox"}
                                                        placement={"bottomRight"}
                                                        title={(
                                                            <div className="areaOrDepartmentContentBox" onClick={e => e.stopPropagation()}>
                                                                {
                                                                    renderDepartmentList(selectedDepartList)
                                                                }
                                                            </div>
                                                        )}
                                                        trigger={"click"}
                                                        onClick={e => e?.stopPropagation()}
                                                    >
                                                        <span
                                                            className="iconBtn"
                                                            onClick={() => setTooltipIsOpen(!tooltipIsOpen)}
                                                        ><Iconfont type={"icon-jiahao"}/>选择发文机构</span>
                                                    </Tooltip>
                                                    <div className="selectedOperateBox">
                                                        {
                                                            (confirmSelectedDepartList.length > 0) ? (
                                                                <Popconfirm
                                                                    title={`确定清空所选机构吗？`}
                                                                    placement="right"
                                                                    onConfirm={() => {
                                                                        confirmChangeDepartment([]);
                                                                    }}
                                                                    overlayClassName={"deletePupConfirmBox"}
                                                                >
                                                                    <div className="listItem deleteBtn">
                                                                        <span className="clickBtn"><Iconfont type="icon-shanchu1"/></span>
                                                                        <span className="itemText">清空</span>
                                                                    </div>
                                                                </Popconfirm>
                                                            ) : undefined
                                                        }
                                                    </div>
                                                </div>

                                                <div
                                                    className="areaOrDepartmentContentBox"
                                                    style={{display: confirmSelectedDepartList.length > 0 ? "" : "none"}}
                                                >
                                                    <div className="areaOrDepartmentListBox">
                                                        {
                                                            confirmSelectedDepartList.map(item => {
                                                                const {code, name} = item;
                                                                return (
                                                                    <div className="listItem" key={code}>
                                                                        <span className="itemText"
                                                                              title={name}>{name}</span>
                                                                        <span
                                                                            className="clickBtn"
                                                                            onClick={() => deleteSelectedCode(formData, "countryDeptIds", code)}
                                                                        ><Iconfont type="icon-close1"/></span>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <span className="tipText">{errorTipTextObject.countryDeptIds}</span>
                                    </div>
                                    <div className="formItem isRequired">
                                        <span className="labelText">发文时间</span>
                                        <div className="formItemContent">
                                            <DatePicker
                                                value={formData.time ? moment(formData.time) : null}
                                                allowClear={false}
                                                onChange={(date, dateStr) => {
                                                    formDataValueChange("time", dateStr);
                                                }}
                                            />
                                        </div>
                                        <span className="tipText">{errorTipTextObject.time}</span>
                                    </div>
                                    <div className="formItem">
                                        <span className="labelText">发文字号</span>
                                        <div className="formItemContent">
                                            <Input
                                                placeholder="输入发文字号"
                                                value={formData.docNum}
                                                onChange={(e) => formDataValueChange("docNum", e.target.value)}
                                            />
                                        </div>
                                        <span className="tipText">{errorTipTextObject.docNum}</span>
                                    </div>
                                    <div className="formItem">
                                        <span className="labelText">时效性</span>
                                        <div className="formItemContent">
                                            <Select
                                                size={"small"}
                                                placeholder="选择时效性"
                                                value={formData.timeliness}
                                                allowClear={true}
                                                options={timeLinessList}
                                                onChange={(value) => {
                                                    formDataValueChange("timeliness", value)
                                                }}
                                            />
                                        </div>
                                        <span className="tipText">{errorTipTextObject.timeliness}</span>
                                    </div>
                                </div>
                            </div>
                            {/* 选填项 */}
                            <div className="formInfoItemBox">
                                <div className="infoTitleBox">
                                    <Iconfont type="icon-biaoqian"/>
                                    <span className="infoTitle">标注信息</span>
                                </div>
                                <div className="formItemListBox">
                                    <div className="formItem">
                                        <span className="labelText">分类</span>
                                        <div className="formItemContent">
                                            <TreeSelect
                                                treeCheckable
                                                multiple={true}
                                                treeExpandedKeys={expandList}
                                                value={selectTaxList}
                                                placeholder="选择分类"
                                                onTreeExpand={(value) => {
                                                    setExpandList(value);
                                                }}
                                                onChange={(value) => {
                                                    console.log(value,"------")
                                                    setSelectTaxList(value);
                                                }}
                                                fieldNames={{
                                                    label: 'dictLabel', value: 'dictCode',
                                                }}
                                                treeData={classificationTreeData} 
                                                // loadData={onLoadData}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="formItem">
                                        <span className="labelText">适用行业</span>
                                        <div className="formItemContent">
                                            <Select
                                                size={"small"}
                                                mode="multiple"
                                                placeholder="选择适用行业"
                                                value={selectIndustryList}
                                                allowClear={true}
                                                options={industryList}
                                                onChange={(value) => {
                                                    setSelectIndustryList(value)
                                                }}
                                            />
                                        </div>
                                    </div> */}
                                    <div className="formItem">
                                        <span className="labelText">适用地区</span>
                                        <div className="formItemContent">
                                            <div className="selectedAreaOrDepartmentBox">
                                                <div className="operateBtnBox">
                                                    <Tooltip
                                                        open={tooltipAreaIsOpen}
                                                        overlayClassName={"areaOrDepartmentTooltipBox"}
                                                        placement={"bottomRight"}
                                                        title={(
                                                            <div className="areaOrDepartmentContentBox"
                                                                 onClick={e => e.stopPropagation()}>
                                                                {
                                                                    renderRegions(sortType, selectedAreaList)
                                                                }
                                                            </div>
                                                        )}
                                                        trigger={"click"}
                                                        onClick={e => e?.stopPropagation()}
                                                    >
                                                        <span
                                                            className="iconBtn"
                                                            onClick={() => setTooltipAreaIsOpen(!tooltipAreaIsOpen)}
                                                        ><Iconfont type={"icon-jiahao"}/>选择适用地区</span>
                                                    </Tooltip>
                                                    <div className="selectedOperateBox">
                                                        {
                                                            (confirmSelectedAreaList.length > 0) ? (
                                                                <Popconfirm
                                                                    title={`确定清空所选地区吗？`}
                                                                    placement="right"
                                                                    onConfirm={() => confirmChangeRegions([])}
                                                                    overlayClassName={"deletePupConfirmBox"}
                                                                >
                                                                    <div className="listItem deleteBtn">
                                                                        <span className="clickBtn">< Iconfont type="icon-shanchu1"/></span>
                                                                        <span className="itemText">清空</span>
                                                                    </div>
                                                                </Popconfirm>
                                                            ) : undefined
                                                        }
                                                    </div>
                                                </div>

                                                <div
                                                    className="areaOrDepartmentContentBox"
                                                    style={{display: confirmSelectedAreaList.length > 0 ? "" : "none"}}
                                                >
                                                    <div className="areaOrDepartmentListBox">
                                                        {
                                                            confirmSelectedAreaList.map(item => {
                                                                const {code, name} = item;
                                                                return (
                                                                    <div className="listItem" key={code}>
                                                                        <span className="itemText" title={name}>{name}</span>
                                                                        <span
                                                                            className="clickBtn"
                                                                            onClick={() => deleteSelectedCode(formData, "areaCodes", code)}
                                                                        ><Iconfont type="icon-close1"/></span>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="formItem">
                                        <span className="labelText">政策类型</span>
                                        <div className="formItemContent">
                                            <Select
                                                size={"small"}
                                                mode="multiple"
                                                placeholder="选择政策类型"
                                                value={selectPolicyTypeList}
                                                allowClear={true}
                                                options={policyTypeList}
                                                onChange={(value) => {
                                                    setSelectPolicyTypeList(value);
                                                }}
                                            />
                                        </div>
                                    </div> */}
                                    <div className="formItem">
                                        <span className="labelText">其他标签</span>
                                        <div className="formItemContent">
                                            <div className="policyLabelListBox">
                                                {
                                                    (formData.lables || []).map((item, idx) => {
                                                        return (
                                                            <div className="labelItem" key={idx}>
                                                                <span className="labelItemText overflowEllipsis"
                                                                      title={item}>{item}</span>
                                                                <span
                                                                    className="deleteBtn"
                                                                    onClick={() => removeLabelFunc(idx)}
                                                                ><Iconfont type="icon-close1"/></span>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                <div
                                                    className="labelItem addLabelBtn"
                                                    onClick={() => addLabelFunc(formData)}
                                                >
                                                    <Iconfont type="icon-jiahao"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="formItem">
                                        <span className="labelText">主题摘要</span>
                                        <div className="formItemContent">
                                            <Input.TextArea
                                                showCount={true}
                                                placeholder={"选中正文内容摘录 或 手动输入"}
                                                rows={5}
                                                maxLength={1000}
                                                value={formData.digest}
                                                onChange={(e) => formDataValueChange("digest", e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 操作按钮 */}
                    <div className="fromBottomOperateBox">
                        <Popconfirm
                            title={`${isAdd ? "上传" : "标注"}内容未提交，确定取消吗？`}
                            placement="top"
                            disabled={!isChange}
                            onConfirm={() => {
                                cancelAnnotateModal();
                            }}
                            overlayClassName={"deletePupConfirmBox"}
                        >
                            <span
                                className="operateBtn cancelBtn"
                                onClick={() => {
                                    if (!isChange) {
                                        cancelAnnotateModal()
                                    }
                                }}
                            >取消</span>
                        </Popconfirm>
                        <span className="operateBtn confirmBtn"
                              onClick={() => {
                                console.log("2")
                                submitFormData(formData);
                              }}>{isAdd ? "上传" : "完成"}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default policyDetailSign;
