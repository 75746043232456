import React, {useState, useRef, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {Spin, DatePicker, Tree, Popover, Modal, Button} from "antd";
import {FolderOpenOutlined} from '@ant-design/icons'
import moment from "moment";

import storage from "../../utils/storage";
import Iconfont from "../../components/Iconfont/Iconfont";
import PolicyList from "../../components/PolicyList";
import NoneData from "../../components/NoneData";
import UploadPolicyBtn from "../../components/PolicyComponents/UploadBtn";
import UploadDrawer from "../../components/PolicyComponents/UploadDrawer";
import {responseDataFilter} from "../../utils/tool";
import PolicySignDetailModal from './policySignDetailModal/index'
import PublicKnowledge from '../../components/PublicKnowledge';
import Api from "../../api";

import "./index.scss";
import { map } from "jquery";

const PolicySign = (props) => {
    const history = useHistory();

    // 政策查询条件分类
    const tabList = [
        {title: "政策标注", key: "nowAdd", icon: "icon-xinzeng"},
        {title: "已标注管理", key: "historySearch", icon: "icon-search"},
    ];

    const [nowAddSortList, setNowAddSortList] = useState([]);
    const [historyTreeList, setHistoryTreeList] = useState([]);


   

    const [loadingType, setLoadingType] = useState(false);

    const [activeTab, setActiveTab] = useState("nowAdd");
    const [activeSort, setActiveSort] = useState(0);
    const [activeDataType, setActiveDataType] = useState(0);

    const [activeHistoryTab, setActiveHistoryTab] = useState(0);



    const [taxList, setTaxList] = useState([]); // 税种数据集合
    const [dataList, setDataList] = useState([]);
    const [policyType, setPolicyType] = useState({policyRegionId: "1"});
    // 查询数据
    const [searchData, setSearchData] = useState({
        pageIndex: 1, // 第几页
        pageSize: 10, //  每页多少条数据
        sort: 3, // 排序方式默认时间降序，0相关度排序，1阅读量排序 2 时间升序 3时间降序
        lableTime: null,
        // codes: [], // 地区编码
        // countryDeptIds: [], // 国务院或者部委id集合
        // endTime: null, // 结束时间
        // startTime: null, // 开始时间
        // labelType: '', // 标注类型（0未标注 1已标注）
        // policyRegionId: "", // 国家或地方 1国家 2地方
        // status: "", // 状态 1正常 2冻结
        // type: "", // 来源类型（0国务院 1部委）
        // dictTypes: [], // 政策分类 税种/行业/文件类型
    });

    // 分页
    const [pagination, setPagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "total": 0,
    });

    // 抽屉开关
    const [open, setOpen] = useState(false);
    // 上传的文件id
    const [uploadFileId, setUploadFileId] = useState(null);
    // 判断新增类型是上传还是站内搜索
    const [policyFromType, setPolicyFromType] = useState(null);
    // 上传的文件名
    const [uploadFileName, setUploadFileName] = useState(null);
    const [uploadFileExtension, setUploadFileExtension] = useState(null);
    const [toEditFormData, setToEditFormData] = useState(null);

    const [conditionList, setConditionList] = useState([]);
    const [selectedConditionIdList, setSelectedConditionIdList] = useState([]);
    const [conditionListExpandedKeys, setConditionListExpandedKeys] = useState([]);
    const [historyTime, setHistoryTime] = useState(null);


    const [annotateModal, setAnnotateModal] = useState(false);

    // 站内搜索文件弹窗
    const [searchModalOpen, setSearchModalOpen] = useState(false);
    const [publicknowledgeOpen, setPublicknowledgeOpen] = useState(false);

    const [policyId, setPolicyId] = useState('');
        
    useEffect(() => {
        
    }, [])

    useEffect(() => {
        let policyThemeSearchDataValue = sessionStorage.getItem('policyThemeSearchDataValue');
        if(policyThemeSearchDataValue){
            let searchDataValue = JSON.parse(policyThemeSearchDataValue);
            sessionStorage.removeItem('policyThemeSearchDataValue');
            console.log(searchDataValue,"searchDataValue")
            let startTime = searchDataValue.searchData.startTime;
            let endTime = searchDataValue.searchData.endTime;
            let policyLableThemeId = searchDataValue.searchData.policyLableThemeId;
            let lableThemeParams = searchDataValue.searchData.lableThemeParams
            if(startTime === endTime){
                setActiveDataType("today")
            }else if(endTime && !startTime){
                setActiveDataType("history")
            }

            setPolicyType({
                countryDeptIds:searchDataValue.searchData.countryDeptIds,
                codes:searchDataValue.searchData.codes
            })
            
            
            if(policyLableThemeId){
                setActiveSort(policyLableThemeId);
                getPolicySortCountFunc({counType: false, searchMyself: true, searchReptileOnly:true}, true);
                getPolicySortCountFunc({counType: true, searchMyself: true}); // 已标注管理
            }else if(lableThemeParams){


                let policyTimeDictParams = lableThemeParams[0].policyTimeDictParams || [];
                let list = [];
                policyTimeDictParams.map(item => {
                    let dict = `${lableThemeParams[0].policyLableThemeId}-${item.dictCode}-dict`
                    list.push(dict);
                    if(item.times && item.times.length > 0){
                        item.times.map(items => {
                            list.push(`${lableThemeParams[0].policyLableThemeId}-${item.dictCode}-${items}-year`);
                        })
                    }
                });
                setSelectedConditionIdList(list);
                setConditionListExpandedKeys(list);
                setActiveHistoryTab(lableThemeParams[0].policyLableThemeId);
                getPolicySortCountFunc({counType: false, searchMyself: true, searchReptileOnly:true}, true);
                getPolicySortCountFunc({counType: true, searchMyself: true},lableThemeParams[0].policyLableThemeId); // 已标注管理
            }

            setActiveTab(searchDataValue.activeTab);
            setSearchData(searchDataValue.searchData);

            

        }else{
            getPolicySortCountFunc({counType: false, searchMyself: true, searchReptileOnly:true});

            getPolicySortCountFunc({counType: true, searchMyself: true});
        }
    }, []);

    useEffect(() => {
        if(searchData.policyLableThemeId || searchData.lableThemeParams){
            getPolicyList();
        }
    }, [JSON.stringify(searchData)]);

    useEffect(() => {
        if (uploadFileId) {
            setUploadFileId(null);
            history.push(`/page/policySignDetail?fileId=${uploadFileId}`);
        }
    }, [uploadFileId])

    // useEffect
    // lableTime: null,
    const getPolicySortData = async(policyLableThemeId, listData) => { 
        const resDepartment = await Api.Policy.selectDictByPolicyLableThemeId({data:{
            policyLableThemeId,
        }});
        if(resDepartment&&resDepartment.code === 0){
            let newData = resDepartment.data.map(item=>{
                return {
                    dictLabel: item.dictLabel,
                    dictCode: `${policyLableThemeId}-${item.dictCode}-dict`,
                    // isLeaf: item.sysDictDatas ? false : true,
                    selectable: false,
                    // disableCheckbox: item.sysDictDatas ? false: true,
                    // children: item.sysDictDatas
                }
            })
            let newHistoryTreeList = listData ? [...listData] : [...historyTreeList];
            newHistoryTreeList.map(item=>{
                if(item.id === policyLableThemeId){
                    item.children = newData;
                }
            })
            setHistoryTreeList(newHistoryTreeList)
            // setConditionList(newData);
        }
        
    }

    const updateTreeData = (list, key, children) =>
            list.map((node) => {
              if (node.dictCode === key) {
                return {
                  ...node,
                  children,
                };
              }
              if (node.children) {
                return {
                  ...node,
                  children: updateTreeData(node.children, key, children),
                };
              }
              return node;
    });
    
    const onLoadData = ({ key, children }) =>
        new Promise(async(resolve) => {

        if (children) {
            resolve();
            return;
        }
        let newData = await getPolicyYearByDictCodeF(key);
        setHistoryTreeList((origin) =>{
            let data = updateTreeData(origin, key, newData);
            console.log(data,"data")
                return data;
        });

        resolve();
    });
    
    const getPolicyYearByDictCodeF = (key) => {
        
        return new Promise(async (resolve, reject) => {
            
            let policyLableThemeId = key.split('-')[0];
            let dictCode = key.split('-')[1];
            
            let res = await Api.Dict.getPolicyYearByDictCode({
                data:{
                    policyLableThemeId,
                    dictCode,
                    searchMyself: true,  //查讯自己的
                }
            })
    
            let {data, code} = res;
            if(code === 0 && data){
                let newData = data.map(item=>{

                    return {
                        dictLabel: item,
                        dictCode: `${policyLableThemeId}-${dictCode}-${item}-year`,
                        isLeaf:true
                    }
                })
                resolve(newData);
            }else{
                resolve([]);
            }
        });
    }

    const onTreeCheck = (checkedKeys, info) => {
        console.log('onCheck', checkedKeys, info);
        setSelectedConditionIdList(checkedKeys);
        setConditionListExpandedKeys(checkedKeys);
        let policyTimeDictParams = [];
        let themeId = activeHistoryTab;
        if(checkedKeys && checkedKeys.length > 0){
            let dataObj = {};
            checkedKeys.map((item)=>{
                let newItem = item.split("-");
                // themeId = newItem[0];
                console.log(newItem,"newItem")
                policyTimeDictParams.push({
                    dictCode: newItem[1],
                    times: newItem[2] === 'dict' ? [] : [newItem[2]]
                })
                // let key = newItem[0], value = newItem[1];
                // if(value != "departmentId"){
                //     if(value){
                //         dataObj[key] = dataObj[key] ? [...dataObj[key],value] : [value];
                //     }else{
                //         dataObj[key] = dataObj[key] ? dataObj[key] :[]
                //     }
                // }
            })
            // for(let key in dataObj){
            //     policyTimeDictParams.push({
            //         dictCode:key,
            //         times:dataObj[key]
            //     })
            // }
        }

        // if(activeHistoryTab != themeId){
        //     setSelectedConditionIdList([]);
        //     setConditionListExpandedKeys([]);
        // }
        setActiveHistoryTab(themeId);

        searchData.lableThemeParams=[
            {
                policyLableThemeId: themeId,
                policyTimeDictParams:policyTimeDictParams
            }
        ]
        
        setSearchData({
            ...searchData,
            "pageIndex": 1,
            "pageSize": 10,
            'serachMyHistory': false,
            'policyTimeDictParams': [],
            "startTime": '',
            "endTime": '',
            "labelType": 1
        });

        // console.log(policyTimeDictParams,"policyTimeDictParams");
        // setActiveSort('');
        // setSearchData({
        //     ...searchData,
        //     policyTimeDictParams: policyTimeDictParams,
        //     "pageIndex": 1,
        //     "pageSize": 10,
        //     'serachMyHistory': true,
        //     'lableTime': historyTime ? searchData.lableTime : null
        // })
    };

    // 获取政策主题列表
    const getPolicyList = async () => {
        setLoadingType(true);
        let _searchData = {...searchData};
        
        if (_searchData.regionId === 999) {
            delete _searchData.regionId
        }
        
        // if (activeTab === tabList[0].key) {
        //     // delete _searchData.lableTime;
        //     delete _searchData.dictCodes;
        // } else {
        //     delete _searchData.labelType;
        // }

        let listData = [];
        let pagination = {
            "pageIndex": 1,
            "pageSize": 10,
            "total": 0,
        };

        if(activeTab === 'nowAdd'){
            _searchData.searchReptileOnly = true;
        }

        try {
            let res = await Api.Knowledge.getPolicyPageList({
                data:{
                    ..._searchData,
                    serachMySelf:1
                }
            });
            setLoadingType(false);
            const resData = responseDataFilter(res);

            if(resData){
                const {records, current, size, total} = resData;
                listData = records;
                pagination = {
                    "pageIndex": current,
                    "pageSize": size,
                    "total": total,
                };
            }
            setDataList(listData);
            setPagination({
                "pageIndex": res.data.current,
                "pageSize": res.data.size,
                "total":res.data.total,
            })
        } catch (errInfo) {
            setDataList(listData);
            setPagination(pagination);
            setLoadingType(false);
            console.log('验证失败:', errInfo);
        }
    }

    // 获取政策分类数量
    const getPolicySortCountFunc = async (param,type) => {
        const res = await Api.Policy.getPolicyLableThemeByLoginUser({data: param});
        if (res && res.code === 0 && res.data) {
            if(param.counType){
                setHistoryTreeList(res.data);
                if(type){
                    getPolicySortData(type, res.data);
                }
            }else{
                setNowAddSortList(res.data);
                if(!type){
                    changeSortFunc(res.data[0].id, true, {'searchData': searchData}, "all");
                }
            }
        }else{
            setNowAddSortList([]);
            setHistoryTreeList([]);
        }
    };

    // 更新查询参数
    const changeSearchValue = (data) => {
        let _searchData = {
            ...searchData,
            ...data
        }
        setSearchData(_searchData);
    }

    // 政策类型改变
    const policyTypeChange = (param) => {
        sessionStorage.policyType = JSON.stringify({
            ...param
        })
        setPolicyType({
            ...param
        });

        delete param.name;
        changeSearchValue({
            "pageIndex": 1,
            ...param
        });
    };

    // 分页变化
    const paginationChange = (pageIndex, pageSize) => {
        let _searchData = {...searchData}
        _searchData.pageIndex = pageIndex;
        _searchData.pageSize = pageSize;
        setSearchData(_searchData);
    }

    // 政策类型下 - 时间和标题筛选
    const operationChange = (value) => {
        changeSearchValue({
            ...value
        });
    };

    const changeTabFunc = (tabKey, isActive) => {
        
        if (!isActive) {
            setActiveTab(tabKey);

            if(tabKey === 'nowAdd'){
                if(nowAddSortList && nowAddSortList.length > 0){
                    changeSortFunc(nowAddSortList[0].id, true, {'searchData': searchData}, "all");
                }
            }else{
                if(historyTreeList && historyTreeList.length > 0){
                    changeThemeFunc(historyTreeList[0].id, true, {'searchData': searchData})
                }
            }
        }
    };

    const changeSortFunc = (sortKey, isActive, state, type) => {

        // 清空已标注管理选择的数据
        setActiveHistoryTab(0);
        setSelectedConditionIdList([]);
        setConditionListExpandedKeys([]);

        if(type === "all"){
            setActiveSort(sortKey);

        }else{
            setActiveSort(sortKey);
        }
        
        let param =  {
            ...state.searchData,
            "policyLableThemeId": sortKey,
            "pageIndex": 1,
            "pageSize": 10,
            'serachMyHistory': false,
            'policyTimeDictParams': [],
            "startTime": '',
            "endTime": '',
            "labelType": '',
            'lableThemeParams':[]
        }

        if(type === "today" && activeDataType != type){
            param.startTime = moment().format('YYYY-MM-DD');
            param.endTime = moment().format('YYYY-MM-DD');
        }else if(type === "history" && activeDataType != type){
            param.endTime = moment().format('YYYY-MM-DD');
        }

        setSearchData(param);
        setActiveDataType(activeDataType === type ? "" : type);

        
        // if (!isActive) {
        //     setSelectedConditionIdList([]);
        //     setConditionListExpandedKeys([]);
        //     setHistoryTime(null);
        //     setSearchData({
        //         ...state.searchData,
        //         labelType: sortKey,
        //         lableTime: sortKey === 1 ? moment().format('YYYY-MM-DD') : '',
        //         "pageIndex": 1,
        //         "pageSize": 10,
        //         'serachMyHistory': false,
        //         'policyTimeDictParams': []
        //     })
        // }
    };

    const changeThemeFunc = (sortKey, isActive, state) => {
        console.log(sortKey, isActive, state);
        setActiveHistoryTab(sortKey);
        setSelectedConditionIdList([]);
        setConditionListExpandedKeys([]);
        getPolicySortData(sortKey);
        
        setSearchData({
            ...state.searchData,
            "policyLableThemeId": "",
            "pageIndex": 1,
            "pageSize": 10,
            'serachMyHistory': false,
            'policyTimeDictParams': [],
            "startTime": '',
            "endTime": '',
            "lableThemeParams":[{
                policyLableThemeId: sortKey
            }],
            "labelType": 1
        });
        setActiveSort(0);
    }

    const selectedTaxFunc = (taxId, isSelected, state) => {
        const {searchData,} = state;
        const list = [...(searchData.dictCodes || [])];
        if (isSelected) {
            const idx = list.findIndex(item => item === taxId);
            list.splice(idx, 1);
        } else {
            list.push(taxId)
        }
        setSearchData({
            ...searchData,
            dictCodes: list,
            "pageIndex": 1,
            "pageSize": 10,
        })
    };

    const dataChangeFunc = (date, dateStr, state) => {
        const {searchData} = state;
        setActiveSort('');
        setHistoryTime(dateStr);
        setSearchData({
            ...searchData,
            'lableTime': dateStr,
            'serachMyHistory': true,
        })
    };

    const annotationPolicy = (item) => {
        setPolicyId(item.id);
        console.log(item);
        setAnnotateModal(true);
        
        // setFileId(item.policyId)
        // policyId={policyId}
        // policyThemeId={policyThemeId}
        // fileId={fileId}
    }

    const addKnowledgeF = () => {

    }

    const cancelAnnotateModal = () => {
        setAnnotateModal(false);
        setPolicyId("");
    }

    const state = {
        searchData,
        taxList,
    };
    const isNowAdd = activeTab === tabList[0].key;
    const selectedTaxIds = searchData.dictCodes;
    const selectedSignList = taxList.filter(item => selectedTaxIds.includes(item.id)).map(item => item.text);

    return (
        <div className="policySignFrame">
            <div className="topHeadBox">
                <i></i>
                <div className="pageTitleBox">
                    <Iconfont type="icon-zhengce"/>
                    <span className="pageTitle">政策标注</span>
                </div>
                <i></i>
            </div>

            <div className="contentBox">
                {/* leftBox */}
                <div className="leftConditionBox">
                    {/* 今日新增 */}
                    <div className="tabListBox">
                        {
                            tabList.map(item => {
                                const {title, icon, key} = item;
                                const isActive = activeTab === key;
                                return (
                                    <div
                                        className={`tabItem ${isActive ? "activeStatus" : ""}`}
                                        key={key}
                                        onClick={() => changeTabFunc(key, isActive)}
                                    >
                                        <Iconfont type={icon}/>
                                        <span className="tabName">{title}</span>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {/* 政策标注 */}
                    <div className="tabContentBox">
                        {
                            isNowAdd ? (
                                <div className="nowAddContentBox">
                                    {
                                        nowAddSortList.map(item => {
                                            const {themeName, icon, id, policyCountVO} = item;
                                            const isActive = activeSort === id;
                                            return (
                                               <div>
                                                    <div
                                                        className={`sortItem ${isActive ? "activeStatus" : ""}`}
                                                        key={id}
                                                        onClick={() => changeSortFunc(id, isActive, state, "all")}
                                                    >
                                                        <FolderOpenOutlined />
                                                        <span className="sortName">{themeName}</span>
                                                        <span className="count">{policyCountVO?.nonLableNum}</span>
                                                    </div>

                                                    <div 
                                                        className={`labelNumBox ${(activeDataType === 'today' && isActive) ? "activeLabel" : ""}`} 
                                                        onClick={() => changeSortFunc(id, isActive, state, "today")}
                                                    >
                                                        <span className="labelName">今日新增</span>
                                                        <span className="labelCount">{policyCountVO?.addNum}</span>
                                                    </div>
                                                    <div 
                                                        className={`labelNumBox ${(activeDataType === 'history' && isActive) ? "activeLabel" : ""}`} 
                                                        onClick={() => changeSortFunc(id, isActive, state , "history")}
                                                    >
                                                        <span className="labelName">累计数据</span>
                                                        <span className="labelCount">{policyCountVO?.cumulativeNum}</span>
                                                    </div>
                                               </div>
                                            )
                                        })
                                    }
                                </div>
                            ) : (
                                <div className="historySearchBox">
                                    
                                    <div className="taxSearchBox">

                                        <div className="nowAddContentBox">
                                            {
                                                historyTreeList.map(item => {
                                                    const {themeName, icon, id, labelNum, children = []} = item;
                                                    const isActive = activeHistoryTab === id;
                                                    return (
                                                    <div>
                                                        <div
                                                            className={`sortItem ${isActive ? "activeStatus" : ""}`}
                                                            key={id}
                                                            onClick={() => changeThemeFunc(id, isActive, state)}
                                                        >
                                                            <FolderOpenOutlined />
                                                            <span className="sortName">{themeName}</span>
                                                            <span className="count">{labelNum}</span>
                                                        </div>

                                                        <div className="searchContentBox">
                                                            {/* {selectedConditionIdList.toString()} */}
                                                            {/* {
                                                                children.length > 0 ? ( */}
                                                                <Tree
                                                                    checkable
                                                                    checkedKeys={selectedConditionIdList}
                                                                    defaultExpandedKeys={conditionListExpandedKeys}
                                                                    defaultCheckedKeys={selectedConditionIdList}
                                                                    onCheck={onTreeCheck}
                                                                    treeData={children}
                                                                    loadData={onLoadData}
                                                                    fieldNames={{
                                                                        title: 'dictLabel', key: 'dictCode',
                                                                    }}
                                                                />
                                                            {/* // ) : (
                                                            //         <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                            //     )
                                                            // } */}
                                                        </div>
                                                    </div>
                                                    )
                                                })
                                            }
                                        </div>

                                            


                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>

                {/* rightBox */}
                <div className="rightContentBox">
                    <Spin tip="数据加载中" spinning={loadingType}>
                        <div className="policyBox">
                            {
                                dataList.length >= 0 ? (
                                    <React.Fragment>
                                        <div className="uploadBtnBox">
                                            <Popover content={()=>{
                                                return (
                                                    <div className="uploadBtnBox">
                                                        <UploadPolicyBtn
                                                            setOpen={setOpen}
                                                            setUploadFileId={setUploadFileId}
                                                            setPolicyFromType={setPolicyFromType}
                                                            setUploadFileName={setUploadFileName}
                                                            setUploadFileExtension={setUploadFileExtension}
                                                            type="text"
                                                            text={"上传本地文件"}
                                                        />
                                                        <Button type="text" onClick={()=>setPublicknowledgeOpen(true)}>搜索全量库</Button>
                                                    </div>
                                                )
                                            }} title={null}>
                                                <Button type="primary">添加政策文件</Button>
                                            </Popover>
                                            {/* <UploadPolicyBtn
                                                setOpen={setOpen}
                                                setUploadFileId={setUploadFileId}
                                                setPolicyFromType={setPolicyFromType}
                                                setUploadFileName={setUploadFileName}
                                                setUploadFileExtension={setUploadFileExtension}
                                            /> */}
                                        </div>
                                        <PolicyList
                                            listDate={dataList}
                                            policyTypeChange={policyTypeChange}
                                            policyType={policyType}
                                            pagination={pagination}
                                            paginationChange={paginationChange}
                                            operationChange={operationChange}
                                            searchData={searchData}
                                            selectedSignList={selectedSignList}
                                            isPolicySign={true}
                                            annotationPolicy={annotationPolicy}
                                            getPolicyList={getPolicyList}
                                            activeTab={activeTab}
                                        />
                                    </React.Fragment>
                                ) : NoneData({})
                            }
                        </div>
                    </Spin>
                </div>
            </div>
            

            <Modal
                open={annotateModal}
                footer={null}
                onCancel={cancelAnnotateModal}
            >
                {annotateModal&&<PolicySignDetailModal
                    policyId={policyId}
                    policyThemeId={searchData.policyLableThemeId}
                    // fileId={fileId}
                    cancelAnnotateModal={cancelAnnotateModal}
                    getPolicyList={getPolicyList}
                />}
            </Modal>
            

            {publicknowledgeOpen&&<PublicKnowledge
                open={publicknowledgeOpen} 
                cancelModal={()=>setPublicknowledgeOpen(false)}
                addKnowledgeF={addKnowledgeF}
                singleChoice = {true}
                dataType={'policy'}
            />}
        </div>
    )
}

export default PolicySign;
