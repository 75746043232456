import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, Radio, Space, Typography, InputNumber, Modal, message, DatePicker, Switch, Image, Popconfirm } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment'

import Api from "../../../api";
import SelfTable from "../../../components/SelfTable"
import {pagingParam} from "../../../utils/defaultValue";
import BackIcon from "../../../components/BackIcon/index";
import {ModalConfirm} from '../../../utils/tool'

import './index.scss'

const { RangePicker } = DatePicker;

const HotWords = () => {
    
    const [form] = Form.useForm();
    const [addForm] = Form.useForm();

    const [searchValue, setSearchValue] = useState('');

    // 分页
    const [pagination, setPagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "total":0
    });

    // 列表数据
    const [listData, setListData] = useState([]);

    // 当前正在操作的数据KEY
    const [editingKey, setEditingKey] = useState('');

    const [tableLoading, setTableLoading] = useState(false);

    // 列表选择数据
    const [selectedRowKey, setSelectedRowKey] = useState([]);

    // 查询数据
    const [searchData, setSearchData] = useState({
        ...pagingParam,
    });

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [fileList, setFileList] = useState([])
    const [uploadFile, setUploadFile] = useState('')

    const columns = [
        {
            title: '热词',
            dataIndex: 'hotWord',
            width: '',
        },
        {
            title: '搜索量(次)',
            dataIndex: 'searchNum',
            width: '120px',
            sorter: true,
        },
        {
            title: '排序',
            dataIndex: 'sort',
            width: '120px',
            sorter: true,
            render: (status,records) => {
                return <InputNumber min={1} defaultValue={status} onPressEnter={(e)=>onSortChange(e.target.value,records)} />
            }
        },
        
        {
            title: '最新搜索时间',
            dataIndex: 'updateTime',
            width: '180px',
        },
        {
            title: '是否开启热词',
            dataIndex: 'hotFlag',
            width: '120px',
            render: (status,records) => {
                // 0不是 1是
                return <Switch 
                checkedChildren="开启" 
                unCheckedChildren="关闭" 
                checked={status} 
                loading={confirmLoading}
                onChange={()=>onHotFlagChange(records)} 
                />
            }
        },

        {
            title: '是否显示图标',
            dataIndex: 'iconFlag',
            width: '120px',
            render: (status) => {
                return status === 0 ? '' : status === 1 ? <span className="hotLabel">热</span> : ""
            }
        },
        // {
        //     title: '热词图标',
        //     dataIndex: 'fileUrl',
        //     width: '120px',
        //     render:(url)=>{
        //         return url ? <Image
        //             width={30}
        //             preview={false}
        //             src={url}
        //         /> : ''
        //     }
        // },
        {
            title: '操作',
            width: '130px',
            dataIndex: 'operation',
            render: (_, record) => {
                return <Space>
                    <Typography.Link onClick={() => edit(record)}>
                        调整
                    </Typography.Link>
                    <Popconfirm
                        title="您确认要删除这条数据吗?"
                        onConfirm={()=>deleteProject(record)}
                        okText="确定"
                        cancelText="取消"
                        placement="topRight"
                    >
                        <Typography.Link>
                            删除
                        </Typography.Link>
                    </Popconfirm>
                    
                </Space>
                
            },
        },
    ];

    useEffect(() => {
        getList();
    }, [searchData]);

    useEffect(() => {
        if(!searchValue){
            onSearch()
        }
    }, [searchValue]);

    const getList = async() => {
        setTableLoading(true);
        setListData([]);
        try {
            const res = await Api.Home.getHotWords({data:searchData});
            setTableLoading(false);
            if(res&&res.code === 0){
                let _data = res.data;
                setListData(_data.records);
                setEditingKey('');
                setPagination({
                    "pageIndex": _data.current,
                    "pageSize": _data.size,
                    "total":_data.total
                });
            }else{
                setListData([]);
            }
        } catch (errInfo) {
            setListData([]);
            console.log('验证失败:', errInfo);
        }
    }

    // 修改
    const edit = (record) => {
        addForm.setFieldsValue({
            ...record,
        });
        setEditingKey(record.id);
        setIsModalOpen(true);
        setFileList([{url:record.fileUrl}]);
    };

    // 搜索框
    const changeValue = (e) => {
        setSearchValue(e.target.value)
    }

    const onSearch = () => {
        let newSearchData = {...searchData}
        newSearchData.pageIndex = 1;
        newSearchData.hotWord = searchValue;
        setSearchData(newSearchData);
    }

    const onResetting = () => {
        setSearchValue("");
        setSearchData({
            ...pagingParam,
            hotWord:"",
        })
    }

    const onSortChange = async(value,records) => {
        try {
            let param = {
                id: records.id,
                sort: parseInt(value)
            }
            const res = await Api.Home.updateHotWords({data:param});
            if(res.code === 0){
                getList();
            }
        } catch (errInfo) {
            console.log('验证失败:', errInfo);
        }
    }

    
    // 新增
    const handleOk = async() => {
        if(uploadFile.name){
            uploadImgs();
        }else{
            setConfirmLoading(true);
            if(editingKey){
                update()
            }else{
                add()
            }
        }
    }

    // 上传图片获取id
    const uploadImgs = async() => {
        
        try {
            const formData = new FormData();
            formData.append("file",uploadFile);
            setConfirmLoading(true);
            const res = await Api.Common.uploadFile({
                data:formData,
            });
            if(res&&res.code === 0){
                let fileId = res.data.id
                if(editingKey){
                    update(fileId)
                }else{
                    add(fileId)
                }

            }else{
                setConfirmLoading(false);
            }
        } catch (errInfo) {
            setConfirmLoading(false);
            console.log('验证失败:', errInfo);
        }
    }

    // 修改-保存
    const update = async (fileId) => {
        try {
            const row = await addForm.validateFields();
            row.id = editingKey;
            row.fileId = fileId;
            const res = await Api.Home.updateHotWords({data:row});
            setConfirmLoading(false);
            if(res.code === 0){
                message.success("修改信息成功",2,()=>{
                    handleCancel();
                    getList();
                })
            }
        } catch (errInfo) {
            setConfirmLoading(false);
            console.log('验证失败:', errInfo);
        }
    };

    const onHotFlagChange = async (records) => {
        setConfirmLoading(true);
        try {
            let param = {
                id:records.id,
                hotFlag: records.hotFlag == 1 ? 0 : 1
            }
            const res = await Api.Home.updateHotWords({data:param});
            setConfirmLoading(false);
            if(res.code === 0){
                getList();
            }
        } catch (errInfo) {
            setConfirmLoading(false);
            console.log('验证失败:', errInfo);
        }
    }

    const add = async (fileId) => {
        try {
            const row = await addForm.validateFields();
            row.fileId = fileId;
            const res = await Api.Home.addHotWords({data:row});
            setConfirmLoading(false);
            if(res&&res.code === 0){
                message.success("新增信息成功",2,()=>{
                    handleCancel();
                    getList();
                })
            }
        } catch (errInfo) {
            setConfirmLoading(false);
            console.log('验证失败:', errInfo);
        }
    }

    const deleteProjects = async (record) => {
        if(selectedRowKey && selectedRowKey.length > 0){
            ModalConfirm({
                title: `您确认要删除所选择的数据吗?`,
                onOk:async(resolve, reject)=> {
                    try{
                        const res = await Api.Home.deleteHotWords({data:{ids:selectedRowKey}});
                        resolve()
                        if(res&&res.code === 0){
                            
                            let newSearchData = {...searchData};
                            newSearchData.pageIndex = 1;
                            setSearchData(newSearchData);

                            setSelectedRowKey([]);
                            message.success("删除成功")
                        }
                    }catch{reject()}
                },
            })
        }else{
            message.info("请选择您需要删除的数据")
        }
    }

    const deleteProject = async (record) => {
        try{
            const res = await Api.Home.deleteHotWords({data:{ids:[record.id]}});
            if(res&&res.code === 0){
                let newSearchData = {...searchData};
                newSearchData.pageIndex = 1;
                setSearchData(newSearchData);

                message.success("删除成功")
            }
        }catch{}
    }


    // 打开
    const addProject = () => {
        addForm.setFieldsValue({
            iconFlag:0,
            hotFlag:0,
        });
        setIsModalOpen(true);
    }

    const handleCancel = () => {
        setEditingKey('');
        setConfirmLoading(false);
        setIsModalOpen(false);
        setFileList([]);
        setUploadFile('');
    }

    const onChanges = (pagination, filters, sorter) => {
        // 排序方式，0按排序序号降序 1按排序序号升序 2按搜索量降序 3按搜索量升序
        // "descend" 降序 "ascend"升序
        
        let newSearchData = {...searchData};
      
        if(sorter.field === "sort" && sorter.order){
            newSearchData.sort = sorter.order === "descend" ? 0 : 1
        }else if(sorter.field === "searchNum" && sorter.order){
            newSearchData.sort = sorter.order === "descend" ? 2 : 3
        }else{
            newSearchData.sort = ""
        }
        
        newSearchData.pageIndex = pagination.current;
        newSearchData.pageSize = pagination.pageSize;
        setSearchData(newSearchData);
    };

    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    };

    const dateChange = (date, dateString) => {
        let newSearchData = { ...searchData };
        newSearchData.startTime = dateString[0];
        newSearchData.endTime = dateString[1];
        setSearchData(newSearchData);
    };

    // 获取上传的图片
    const handleChange = ({ file,fileList: newFileList }) => {
        setFileList(newFileList);
        setUploadFile(file);
    };

    const uploadImg = (file, fileList) => {
        return false;
    };

     // 上传按钮
     const uploadButton = (
        <div>
          <PlusOutlined />
          <div
            style={{
              marginTop: 8,
            }}
          >
            上传图标
          </div>
        </div>
    );

    return (
        <div className="hotWordsFrame">
            <div className="title">热词库</div>
            <div className="operation">
                <Space size={"large"}>
                    <Button type="primary" onClick={addProject}>新增热词</Button>
                    <Button type="primary" danger onClick={deleteProjects}>删除热词</Button>
                </Space>
                
                <div className="operationRight">

                    <Space size={"large"}>
                        <div className="operationItem">
                            <RangePicker
                                disabledDate={disabledDate}
                                value={[
                                    searchData.startTime?moment(searchData.startTime):"",
                                    searchData.endTime?moment(searchData.endTime):""
                                ]}
                                onChange={dateChange}
                                style={{marginLeft:20}}
                            />
                        </div>
                        <div className="operationItem">
                            <Input
                                placeholder="输入关键词搜索"
                                onChange={changeValue}
                                onPressEnter={onSearch}
                                style={{width:350,float:"right"}}
                                value={searchValue}
                                allowClear={true}
                            />
                        </div>
                        <Button type="primary" onClick={onSearch}>搜索</Button>
                        <Button onClick={onResetting}>重置</Button>
                    </Space>
                </div>
            </div>
            <BackIcon />
            <div className="table">
                <Form form={form} component={false}>
                    <SelfTable
                        rowRadioSelection={{
                            selectedRowKey:selectedRowKey,
                            setSelectedRowKey:setSelectedRowKey,
                            type:"checkbox",
                            preserveSelectedRowKeys:true
                        }}
                        pagination={pagination}
                        dataSource={listData}
                        columns={columns}
                        loading={tableLoading}
                        onChange={onChanges} 
                    />
                </Form>
            </div>

            <Modal 
                title="新增热词" 
                open={isModalOpen} 
                onOk={handleOk} 
                onCancel={handleCancel}
                maskClosable={false}
                destroyOnClose={true}
                confirmLoading={confirmLoading}
                wrapClassName={"hotWordsModal"}
            >
                <Form
                    form={addForm}
                    component={false}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 14 }}
                    preserve={false}
                >
                    <Form.Item
                        key={"hotWord"}
                        label={"热词名称"}
                        name={"hotWord"}
                        rules={[{ required: true, message: `请输入热词名称!` }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        key={"sort"}
                        label={"排序"}
                        name={"sort"}
                        rules={[{ required: true, message: `请输入排序!` }]}
                    >
                        <InputNumber 
                            min={1} 
                            style={{width:"100%"}}
                        />
                    </Form.Item>

                    <Form.Item
                        key={"hotFlag"}
                        label={"是否热词"}
                        name={"hotFlag"}
                        rules={[{ required: true, message: `请输入排序!` }]}
                    >
                        <Radio.Group>
                            <Radio value={1}>是</Radio>
                            <Radio value={0}>否</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        key={"iconFlag"}
                        label={"是否显示热词图标"}
                        name={"iconFlag"}
                        // rules={[{ required: true, message: `请是否显示热词图标!` }]}
                    >
                        <Radio.Group>
                            <Radio value={1}>是</Radio>
                            <Radio value={0}>否</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <span className="hotLabel">热</span>
                    
                    {/* <Form.Item
                        key={'fileId'}
                        label={'热词图标'}
                        name={'fileId'}
                    >
                        <Upload
                            accept="image/png, image/jpeg, image/svg+xml"
                            listType="picture-card"
                            maxCount={1}
                            showUploadList={{
                                showPreviewIcon:false
                            }}
                            fileList={fileList}
                            onChange={handleChange}
                            beforeUpload={uploadImg}
                            accept={"image/*"}
                        >
                            {(fileList.length > 0) ? null : uploadButton}
                        </Upload>
                    </Form.Item> */}
                </Form>
            </Modal>
        </div>
    )
}

export default HotWords