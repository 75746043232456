import React from 'react';
import {Modal, Input, Checkbox, message, Tooltip, Table, Popconfirm,} from 'antd';
import Iconfont from "../../../../components/Iconfont/Iconfont";
import API from '../../../../api/index'
import moment from 'moment';
import NoneData from "../../../../components/NoneData";
import {responseDataFilter} from "../../../../utils/tool";
import BackIcon from "../../../../components/BackIcon/index";
import './index.scss';

moment.locale('zh-cn');

const Block = React.Fragment;
const OperateObject = {
    move: 'move',
    copy: 'copy',
    edit: 'edit',
    delete: 'delete',
}
class SignManage extends React.Component {
    inputRef = React.createRef()
    positionIndex = null
    dragCurrentIndex = null

    constructor(props) {
        super(props);
        const that = this;
        that.state = {
            groupModalVisible: false,
            labelModalVisible: false,
            operateType: 'add',
            groupList: [],
            activeGroupId: null,
            labelList: [],
            groupName: '',
            labelName: '',
            columns: [
                {
                    title: '标签名称',
                    key: 'className',
                    dataIndex: 'className',
                    align: 'center',
                    width: 150,
                },
                {
                    title: '标签值(双击修改)',
                    key: 'tags',
                    dataIndex: 'tags',
                    align: 'center',
                    render: (val, record, index) => {
                        return (
                            <div
                                className="labelValueListBox"
                            >
                                {
                                    val.map((item, idx) =>{
                                        return (
                                            <div
                                                className="labelValueItem"
                                                key={`${record.id}-${idx}`}
                                                draggable={true}
                                                onDragStart={(e) => that.dragStartFunc(e, idx)}
                                                onDragEnd={(e) => that.dragEndFunc(e, index)}
                                            >
                                                <i
                                                    className="positionSignBox"
                                                    onDragEnter={(e) => that.dragEnterFunc(e, idx)}
                                                    onDragLeave={(e) => that.dragLeaveFunc(e)}
                                                />
                                                <span onDoubleClick={(e) => {
                                                    e?.stopPropagation()
                                                    that.editOrAddLabelValue(record.id, item.id, item.title)
                                                }}>{item.title}</span>
                                                <Popconfirm
                                                    placement="topRight"
                                                    title={"确定删除标签值？"}
                                                    zIndex={6}
                                                    onConfirm={(e) => {
                                                        e?.stopPropagation()
                                                        that.deleteLabelValueFunc(record.id, item.id)
                                                    }}
                                                    okText="确定"
                                                    cancelText="取消"
                                                >
                                                    <span
                                                        draggable={false}
                                                    ><Iconfont type={"icon-jujue"} /></span>
                                                </Popconfirm>
                                            </div>
                                        )
                                    })
                                }
                                <div
                                    className="labelValueItem labelValueAddBtn"
                                    onClick={() => that.editOrAddLabelValue(record.id)}
                                >
                                    <i
                                        className="positionSignBox"
                                        onDragEnter={(e) => that.dragEnterFunc(e, val.length)}
                                        onDragLeave={(e) => that.dragLeaveFunc(e)}
                                    />
                                    <Iconfont type={"icon-roundadd"} />
                                    <span>新增</span>
                                </div>
                            </div>
                        )
                    },
                },
                {
                    title: '操作',
                    key: 'operate',
                    dataIndex: 'operate',
                    width: 200,
                    align: 'center',
                    render: (val, record, idx) => {
                        return (
                            <div className="labelOperatorBox">
                                <Tooltip placement="top" title={'编辑'} key={idx}>
                                    <span className="labelOperatorBtn" onClick={() => that.editorAddLabel(record)}><Iconfont type={'icon-bianji1'} /></span>
                                </Tooltip>

                                <Tooltip placement="top" title={'删除'} key={idx} zIndex={5}>
                                    <Popconfirm
                                        placement="topRight"
                                        title={"确定删除标签？"}
                                        zIndex={6}
                                        onConfirm={() => that.deleteLabelFunc(record.id)}
                                        okText="确定"
                                        cancelText="取消"
                                    >
                                        <span className="labelOperatorBtn deleteLabelBtn"><Iconfont type={'icon-shanchu1'} /></span>
                                    </Popconfirm>
                                </Tooltip>
                            </div>
                        )
                    },
                },
            ],
            dataList: [],
        };
    }

    componentWillMount() {
        this.getGroupDataList()
    }

    async componentDidMount() {
        this.getHeight()
        window.onresize = this.getHeight
        // document.addEventListener('mouseup', this.mouseUpFunc)
    }

    componentWillUnmount() {
        // document.removeEventListener('mouseup', this.mouseUpFunc)
    };

    //  获取标签分组列表
    async getGroupDataList () {
        const res = await API.OverseasCaseManager.getLabelGroupList({loading: true})
        const data = responseDataFilter(res, '获取分组失败');
        if (data) {
            this.setState({
                groupList: data
            })
        }
    }

    // 根据选择的分组id获取标签列表
    async getLabelList () {
        const {activeGroupId} = this.state
        if (activeGroupId) {
            this.apiFunc(API.OverseasCaseManager.getLabelListByGroupId,
                {data: {groupId: activeGroupId}, loading: true},
                '', '',
                (data) => {this.setState({labelList: data})}
            )
        } else {
            this.setState({
                labelList: []
            })
        }
    }

    // 开始拖拽函数
    dragStartFunc = (e, idx) => {
        const that = this
        let dom = e.target
        dom.style.boxShadow = '0 2px 9px 1px rgba(96,180,246,0.65)'
        that.dragCurrentIndex = idx
    }

    // 结束拖拽函数
    dragEndFunc = (e, index) => {
        const that = this
        e.target.style = null
        const {positionIndex, dragCurrentIndex} = that
        if (positionIndex !== dragCurrentIndex && positionIndex !== null && dragCurrentIndex !== null) {
            const labelList = [...that.state.labelList]
            const activeItem = JSON.parse(JSON.stringify(labelList[index]))
            const {tags: valueList, id} = activeItem
            const isBefore = positionIndex < dragCurrentIndex
            valueList.splice(positionIndex, 0, valueList[dragCurrentIndex])
            if (isBefore) {
                valueList.splice(dragCurrentIndex + 1, 1)
            } else {
                valueList.splice(dragCurrentIndex, 1)
            }
            const tagIds = valueList.map(item => item.id)
            labelList[index] = activeItem

            that.positionIndex = null
            that.dragCurrentIndex = null
            that.setState({labelList}, () => {
                this.labelValueSortFunc(tagIds)
            })
        }
    }


    // 拖拽进入函数
    dragEnterFunc = (e, idx) => {
        const that = this
        let dom = e.target
        const dragCurrentIndex = that.dragCurrentIndex
        if (idx !== dragCurrentIndex && (idx - 1) !== dragCurrentIndex) {
            dom.className = 'positionSignBox activeLabelValueItem'
            that.positionIndex = idx
        }
    }

    // 拖拽移出函数
    dragLeaveFunc = (e) => {
        const that = this
        let dom = e.target
        dom.className = 'positionSignBox'
        // that.positionIndex = null
    }

    async apiFunc (func, params, successMessage, errorMessage, successFunc) {
        try {
            const res = await func(params);
            const data = responseDataFilter(res, errorMessage);
            if (data) {
                if (successMessage) {
                    message.success(successMessage);
                }

                if (successFunc) {
                    successFunc(data);
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    // 返回上一级
    returnPage () {
        const {changeType} = this.props
        if (Object.prototype.toString.call(changeType) === '[object Function]') {
            changeType('')
        }
    }

    // 选择分组
    choiceGroup (groupId, isChoice) {
        this.setState({
            activeGroupId: isChoice ? groupId : null
        }, () => {
            this.getHeight()
            // 获取labelList
            this.getLabelList()
        })
    }

    // 删除分组
    deleteGroup (id) {
        this.apiFunc(API.OverseasCaseManager.deleteLabelGroup, {data:{groupId: id}},
            '分组删除成功', '分组删除失败', () => this.getGroupDataList())
    }

    // 编辑/新增分组名称
    editorAddGroup (group) {
        const that = this
        const {id, groupName} = group || {}
        const beforeText = id === undefined ? '新增' : '编辑'
        Modal.confirm({
            className: 'signModal',
            title: (
                <div className="signModalTitleBox">
                    <Iconfont type="icon-24gl-tags2" />
                    {`${beforeText}分组`}
                </div>
            ),
            icon: null,
            centered: true,
            content: (
                <div>
                    <Input
                        placeholder={"请输入分组名称"}
                        defaultValue={groupName}
                        ref={that.inputRef}
                    />
                </div>
            ),
            onOk: () => {
                const name = that.inputRef.current.input.value;
                if (!name) {
                    return message.warning('请输入分组名称')
                }else{
                    this.apiFunc(API.OverseasCaseManager.addOrEditLabelGroup,
                        {data: {id, groupName: name}, loading: true},
                        `分组${beforeText}成功`, `分组${beforeText}失败`,
                        () => this.getGroupDataList())
                }
            },
            okText: '确定',
            destroyOnClose: true,
        })
    }

    // 编辑/新增标签名称
    editorAddLabel (label) {
        const that = this
        const {id, className} = label || {}
        const isAdd = id === undefined
        const beforeText = isAdd ? '新增' : '编辑'
        Modal.confirm({
            className: 'signModal',
            title: (
                <div className="signModalTitleBox">
                    <Iconfont type="icon-24gl-tags2" />
                    {`${beforeText}标签`}
                </div>
            ),
            icon: null,
            centered: true,
            content: (
                <div>
                    <Input
                        placeholder={"请输入标签名称"}
                        defaultValue={className}
                        ref={that.inputRef}
                    />
                </div>
            ),
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
                const name = that.inputRef.current.input.value
                if (!name) {
                    return message.warning('请输入标签名称')
                }
                this.apiFunc(API.OverseasCaseManager.addOrEditLabel,
                    {data: {id, className: name, groupId: that.state.activeGroupId}, loading: true},
                    `标签${beforeText}成功`, `标签${beforeText}失败`,
                    async () => {
                        if (isAdd) {
                            await this.getGroupDataList()
                        }
                        await this.getLabelList()
                    })
            },
            destroyOnClose: true,
        })
    }

    // 新增标签值
    editOrAddLabelValue (labelId, labelValueId, labelValue) {
        const that = this
        const isAdd = labelValueId === undefined
        const beforeText = isAdd ? '新增' : '编辑'
        Modal.confirm({
            className: 'signModal',
            title: (
                <div className="signModalTitleBox">
                    <Iconfont type="icon-24gl-tags2" />
                    {beforeText}标签值
                </div>
            ),
            icon: null,
            centered: true,
            content: (
                <div>
                    <Input
                        placeholder={"请输入标签值"}
                        defaultValue={labelValue}
                        ref={that.inputRef}
                    />
                </div>
            ),
            onOk: () => {
                const name = that.inputRef.current.input.value
                if (!name) {
                    return message.warning('请输入标签值')
                }
                this.apiFunc(API.OverseasCaseManager.addLabelValue,
                    {data: {classId: labelId, value: name, id: labelValueId}, loading: true},
                    `标签值${beforeText}成功`, `标签值${beforeText}失败`,
                    () => this.getLabelList()
                )
            },
            okText: '确定',
            destroyOnClose: true,
        })
    }

    // 删除标签
    deleteLabelFunc (id) {
        this.apiFunc(API.OverseasCaseManager.deleteLabel,
            {data: {classId: id}},
            '标签删除成功', '标签删除失败',
            async () => {
                await this.getGroupDataList()
                await this.getLabelList()
            }
        )
    }

    // 删除标签值
    deleteLabelValueFunc (labelId, labelValueId) {
        this.apiFunc(API.OverseasCaseManager.deleteLabelValue,
            {data: {tagId: labelValueId}},
            '标签值删除成功', '标签值删除失败',
            () => this.getLabelList()
        )
    }

    // 标签值排序
    async labelValueSortFunc (tagIds) {
        await this.apiFunc(API.OverseasCaseManager.labelValueSort,
            {data: tagIds},
            '排序成功', '排序失败',
        )
        this.getLabelList()
    }

    // 计算表格所占高度
    getHeight = () => {
        const tableContent = document.getElementById('tableBox')
        if (tableContent) {
            let reacts = tableContent.getClientRects()[0]
            this.setState({
                height: reacts.height - 57,
            })
        }
    }

    render() {
        const that = this;
        const {
            groupModalVisible, labelModalVisible,
            operateType, columns, groupList,
            activeGroupId, height, labelList: dataList,
        } = that.state;
        return (
            <div className="signManageFrame">
                {/* 返回 & 新增分类按钮 */}
                <div className={"topBox"}>
                    {/* 返回 */}
                    <div className={"returnBox"}>
                        {/* <Tooltip placement="right" title={'返回'} zIndex={10}>
                            <span style={{lineHeight: 1}} onClick={() => this.returnPage()}><Iconfont type={'icon-fanhui'} /></span>
                        </Tooltip> */}
                        <BackIcon position="left"/>
                    </div>

                    <div className={"titleBox"}>
                        <Iconfont type={'icon-fenjifenlei'} />
                        <span>标签管理</span>
                    </div>

                    {/* 占位 */}
                    <span />
                </div>
                {/* 内容 */}
                <div className="contentBox">
                    {/* 分组列表 */}
                    <div className="leftGroupBox">
                        {/* top */}
                        <div className="groupOperatorBox">
                            <span className="groupTitleBox">
                                <Iconfont type={"icon-leftfont-18"} />
                                标签分类列表
                            </span>
                            <Tooltip title="新增">
                                <span className="createGroupBtn" onClick={() => this.editorAddGroup()}><Iconfont type={"icon-roundadd"} /></span>
                            </Tooltip>
                        </div>
                        <div className="groupListBox">
                            {
                                (groupList && groupList.length > 0)
                                    ? groupList.map((item, idx) => {
                                        const {groupName, id, deleteable=[]} = item
                                        const isChoice = id === activeGroupId
                                        return (
                                            <div
                                                className={`groupListItem ${isChoice ? 'activeGroupItem' : ''}`}
                                                key={'group-'+idx}
                                                onClick={() => this.choiceGroup(item.id, !isChoice)}
                                            >
                                                <span className="groupName" title={groupName}>{groupName}</span>
                                                <div className="operateBtnBox">
                                                    <Tooltip title="编辑">
                                                        <span
                                                            className="editGroupBtn"
                                                            onClick={(e) => {
                                                                e?.stopPropagation()
                                                                this.editorAddGroup(item)
                                                            }}
                                                        >
                                                            <Iconfont type={"icon-bianji1"} />
                                                        </span>
                                                    </Tooltip>
                                                    {
                                                        (deleteable) ? (
                                                            <Tooltip title="删除" zIndex={5}>
                                                                <Popconfirm
                                                                    title="确定删除该分类吗？"
                                                                    onConfirm={(e) => {
                                                                        e?.stopPropagation()
                                                                        this.deleteGroup(id)
                                                                    }}
                                                                    okText="确定"
                                                                    cancelText="取消"
                                                                    zIndex={6}
                                                                >
                                                                    <span
                                                                        className="deleteGroupBtn"
                                                                        onClick={(e) => {e?.stopPropagation()}}
                                                                    >
                                                                    <Iconfont type={"icon-shanchu1"} />
                                                                </span>
                                                                </Popconfirm>
                                                            </Tooltip>
                                                        ) : undefined
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                    :
                                    (NoneData('暂无数据，请新建分组！', 'icon-shuju'))
                            }
                        </div>
                    </div>
                    {/* 标签列表 */}
                    <div className="labelTableBox">
                        {
                            activeGroupId ? (
                                <React.Fragment>
                                    <div className="labelTableTopBox">
                                        <span onClick={() => this.editorAddLabel()}><Iconfont type="icon-roundadd" /> 新增标签</span>
                                    </div>
                                    <div id="tableBox" className="tableBox">
                                        <Table
                                            bordered
                                            sticky={true}
                                            scroll={{ y: height}}
                                            columns={columns}
                                            dataSource={dataList}
                                            rowKey={(item) =>`labelList-${item.id}-${Math.random()}`}
                                            pagination={{
                                                defaultPageSize: 10000,
                                                hideOnSinglePage: true,
                                            }}
                                        />
                                    </div>
                                </React.Fragment>
                            ) : (NoneData('选择标签分组'))
                        }

                    </div>
                </div>
            </div>
        );
    }
}

SignManage.propTypes = {};

export default SignManage;
