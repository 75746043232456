import React, { useState, useEffect } from "react";
import {
  Form,
  Popconfirm,
  Typography,
  message,
  InputNumber,
  Popover,
  Input,
  Pagination,
} from "antd";
import { QuestionCircleTwoTone } from "@ant-design/icons";
import Api from "../../../../api";
import SelfTable from "../../../../components/SelfTable/index";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    typeof children[1] === "number" ? <InputNumber min={0} /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `请输入${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const ScoringTable = () => {
  const [form] = Form.useForm();
  const [scoreList, setScoreList] = useState([]);
  // 当前正在操作的数据KEY
  const [editingKey, setEditingKey] = useState("");
  const [tableLoading, setTableLoading] = useState(false);
  // 分页
  const [siftData, setSiftData] = useState({
    pageIndex: 1,
    pageSize: 10,
  });
  const [total, setTotal] = useState(null);
  useEffect(() => {
    getKnowledgeValueScoreList();
  }, [siftData]);
  const getKnowledgeValueScoreList = async () => {
    try {
      setTableLoading(true);
      let res = await Api.Points.getKnowledgeValueScore({ data: siftData });
      if (res.data && res.code === 0) {
        setScoreList(res.data.records);
        setEditingKey("");
        setTableLoading(false);
        setTotal(res.data.total);
      }
    } catch (error) {}
  };
  const isEditing = (record) => record.knowledgeTypeId == editingKey;
  // 修改
  const edit = (records) => {
    // 回填数据
    form.setFieldsValue({
      ...records,
    });
    setEditingKey(records.knowledgeTypeId);
  };
  // 取消
  const cancel = () => {
    setEditingKey("");
  };
  // 修改-保存
  const save = async (records) => {
    try {
      const row = await form.validateFields();
      console.log(row, "row");
      setTableLoading(true);
      if (records.id) {
        row.id = records.id;
      } else {
        row.id = new Date().getTime();
      }
      row.knowledgeTypeId = records.knowledgeTypeId;
      const res = await Api.Points.knowledgeValueScoreInsertOrUpdate({
        data: row,
      });
      if (res && res.code === 0) {
        message.success("修改成功", 2, () => {
          getKnowledgeValueScoreList();
          setEditingKey("");
        });
      } else {
        setEditingKey("");
        setTableLoading(false);
      }
    } catch (errInfo) {
      setTableLoading(false);
    }
  };
  // const paginationChange = (page, pageSize) => {
  //   console.log(page, pageSize, "pagination");
  //   let _pagination = pagination
  //   _pagination.current = page
  //   _pagination.pageSize = pageSize
  //   _pagination.total = scoreList?.length
  //   setPagination(_pagination)
  // };
  const columns = [
    {
      title: "知识类型",
      dataIndex: "knowledgeTypeName",
    },
    {
      title: "基础得分",
      dataIndex: "baseScore",
      editable: true,
    },
    {
      title: "被阅读量/次",
      dataIndex: "readScore",
      editable: true,
    },
    {
      title: "被下载量/次",
      dataIndex: "downloadScore",
      editable: true,
    },
    {
      title: "被收藏量/次",
      dataIndex: "collectionScore",
      editable: true,
    },
    {
      title: "收获点赞量/次",
      dataIndex: "likeScore",
      editable: true,
    },
    {
      title: "收获评论量/条",
      dataIndex: "commentScore",
      editable: true,
    },
    {
      title: "操作",
      render: (_, records) => {
        const editable = isEditing(records);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(records)}
              style={{
                marginRight: 8,
              }}
            >
              保存
            </Typography.Link>
            <Popconfirm title="取消后数据将不会保存" onConfirm={cancel}>
              <a>取消</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(records)}
          >
            调整
          </Typography.Link>
        );
      },
    },
  ];
  const onChange = (page, pageSize) => {
    let _siftData = { ...siftData };
    _siftData.pageIndex = page;
    _siftData.pageSize = pageSize;
    setSiftData(_siftData);
  };
  const scoringContent = (
    <ol style={{ padding: "1rem 0 1rem 1rem" }}>
      <li>每一类型的知识分别计算得分，知识总价值为每行求和</li>
      <li>计算出的得分为该知识的价值，然后按权重分配给作者</li>
      <li>
        当用户自行取消已发布知识，已获取的积分中基础得分部分将被扣回，其他积分不受影响。
      </li>
      <li>当知识被取消收藏、取消点赞、删除评论，以获取的相应积分将被扣回。</li>
    </ol>
  );
  return (
    <div className="scoringTableFrame">
      <div className="tableTitleSmall">
        <span>计分规则</span>
        <Popover
          placement="rightTop"
          title={"规则说明"}
          content={scoringContent}
          trigger="click"
        >
          <QuestionCircleTwoTone twoToneColor="#FFD700" />
        </Popover>
      </div>
      <Form form={form} component={false}>
        <SelfTable
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          columns={columns}
          dataSource={scoreList}
          isEditing={isEditing}
          pagination={{ ...siftData, total: total, onChange: onChange }}
          loading={tableLoading}
          rowKey={"knowledgeTypeId"}
          footerHeight={55}
        ></SelfTable>
      </Form>
    </div>
  );
};

export default ScoringTable;
