import myAxios from "../utils/request.js";

export default {
  // 获取数据总览
  async getShowData(params = {}, cb) {
    let res = await myAxios.postJson("/userPersonalData/pageList", params);
    return res.data;
  },
  // 获取阅读数据
  async getUserKnowledgeRead(params = {}, cb) {
    let res = await myAxios.postJson("/userKnowledgeRead/pageList", params);
    return res.data;
  },
  //  获取用户积分情况总览
  async getUserScore(params = {}, cb) {
    let res = await myAxios.postJson("/userScoreManagement/getUserScore", params);
    return res.data;
  },
  // 查询登录用户的积分
  async getScoreTotal(params = {}, cb) {
    let res = await myAxios.getJson(
      "/scoreTotal/selectByLoginUser",
      params
    );
    return res;
  },
  // 获取已上传数据
  async getUserKnowledgeUpload(params = {}, cb) {
    let res = await myAxios.postJson(
      "/knowledgeManagement/getPersonalKnowledgePublished",
      params
    );
    return res.data;
  },
  // 获取我的收藏知识
  async getKnowledgeCollection(params = {}, cb) {
    let res = await myAxios.postJson(
      "/knowledgeCollectionManagement/pageList",
      params
    );
    return res.data;
  },
  // 取消收藏
  async cancleCollection(params = {}, cb) {
    let res = await myAxios.getJson(
      "/knowledgeCollectionManagement/cancleCollection",
      params
    );
    return res;
  },
  // 获取用户已上传的知识
  async getKnowledgePublished(params = {}, cb) {
    let res = await myAxios.postJson(
      "/knowledgeManagement/getPersonalKnowledgePublished",
      params
    );
    return res.data;
  },
  // 获取未发布知识
  async getKnowledgeNotPublished(params = {}, cb) {
    let res = await myAxios.postJson(
      "/knowledgeManagement/getPersonalKnowledgeUnPublished",
      params
    );
    return res.data;
  },
  // 未发布页面进行删除操作
  async deleteKnowledgeNotPublished(params = {}, cb) {
    let res = await myAxios.postJson(
      "/knowledgeManagement/removeKnowledge",
      params
    );
    return res.data;
  },
  // 取消发布
  async cancelPublish(params = {}, cb) {
    let res = await myAxios.getJson(
      "/knowledgeManagement/cancelPublish",
      params
    );
    return res.data;
  },
  // 点击发布
  async okPublish(params = {}, cb) {
    let res = await myAxios.getJson(
      "/knowledgeManagement/publishKnowledge",
      params
    );
    return res.data;
  },
  // 获取笔记原文管理
  async getKnowledgeNoteOwner(params = {}, cb) {
    let res = await myAxios.postJson(
      "/knowledgeNoteDetailManagement/searchKnowledgeNoteOwner",
      params
    );
    return res.data;
  },
  // 上传后新增知识--保存/发布
  async addNewKnowledge(params = {}, cb) {
    let res = await myAxios.postJson(
      "/knowledgeManagement/addKnowledge",
      params
    );
    return res.data;
  },
  // 获取积分变更记录
  async getUserPointsHistory(params = {}, cb) {
    let res = await myAxios.postJson("/userScoreDetail/pageList", params);
    return res.data;
  },

  // 查询登录用户的积分变动记录
  async getScoreDetail(params = {}, cb) {
    let res = await myAxios.postJson("/scoreDetail/pageByLoginUser", params);
    return res.data;
  },

  // 查询积分相关操作记录
  async getKnowledgeScoreDetail(params = {}, cb) {
    let res = await myAxios.postJson("/knowledgeRecord/pageList", params);
    return res.data;
  },

  // 处理未处理的记录
  async syncPoints(params = {}, cb) {
    let res = await myAxios.getJson("/knowledgeRecord/dealUnDeal", params);
    return res.data;
  },

  // 查询积分处理数
  async synchronizedPoints(params = {}, cb) {
    let res = await myAxios.getJson(
      "/knowledgeRecord/countDeal",
      params
    );
    return res.data;
  },

  // 获取tab筛选类型列表
  async getAllKnowledgeTab(params = {}, cb) {
    let tabsList = [];
    let res = await myAxios.getJson(
      "/knowledgeTypeManagement/getKnowledger",
      params
    );
    res.data.data.forEach((item) => {
      if (item.id != 4 && item.id != 8) {
        tabsList.push({
          label: item.knowledgeTypeName,
          key: item.id,
          value: item.id,
        });
      }
    });
    tabsList.unshift({ label: "全部知识", key: "all", value: "all" });
    return tabsList;
  },


  // 获取书摘管理列表
  async getMyExcerptList(params = {}, cb) {
    let res = await myAxios.postJson(
      "/knowledgeExcerptManagement/getMyExcerptVO",
      params
    );
    return res.data;
  },

  // 我的空间分类树
  async querySpaceTree(params = {}, cb) {
    let res = await myAxios.getJson(
      "/spaceType/queryList",
      params
    );
    return res.data;
  },

  async addSpaceTree(params = {}, cb) {
    let res = await myAxios.postJson(
      "/spaceType/add",
      params
    );
    return res.data;
  },
  async updataSpaceTree(params = {}, cb) {
    let res = await myAxios.postJson(
      "/spaceType/edit",
      params
    );
    return res.data;
  },
  async deleteSpaceTree(params = {}, cb) {
    let res = await myAxios.getJson(
      "/spaceType/remove",
      params
    );
    return res.data;
  },

  /*==============我的空间===============*/
  // 我的空间列表
  async getSpaceLKist(params = {}, cb) {
    let res = await myAxios.postJson(
      "/spaceKnowledge/pageList",
      params
    );
    return res.data;
  },

  // 我的空间列表
  async getSpaceUsedStatus(params = {}, cb) {
    let res = await myAxios.getJson(
      "/sysUserSapce/getUserSpace",
      params
    );
    return res.data;
  },

  // 批量删除知识文件
  async batchDeleteKnowledgeFiles(params = {}, cb) {
    let res = await myAxios.postJson(
      "/spaceKnowledge/batchDelete",
      params
    );
    return res.data;
  },

  // 批量下载知识文件
  async batchDownloadKnowledgeFiles(params = {}, cb) {
    let res = await myAxios.postJson(
      "/spaceKnowledge/downloadKnowledge",
        {
          ...params,
          'responseType': 'blob',
        }
    );
    return res.data;
  },

  // 批量复制知识文件
  async batchCopyKnowledgeFiles(params = {}, cb) {
    let res = await myAxios.postJson(
      "/spaceKnowledge/cpoyKnowledge",
      params
    );
    return res.data;
  },

  // 批量移动知识文件
  async batchMoveKnowledgeFiles(params = {}, cb) {
    let res = await myAxios.postJson(
      "/spaceKnowledge/moveKnowledge",
      params
    );
    return res.data;
  },


  // 查看知识文件的历史版本
  async getKnowledgeFileVersions(params = {}, cb) {
    let res = await myAxios.getJson(
      "/knowledgeVersion/getHistoryVersion",
      params
    );
    return res.data;
  },

};
