import React, {useState, useEffect, useRef} from 'react';
import {Typography, Button, Input, Modal, Space, Spin, Tooltip, Form, InputNumber} from 'antd';
import { ExclamationCircleOutlined, SearchOutlined} from '@ant-design/icons';
import moment from 'moment';

import Api from "../../../../../api/index";
import {getSearchParams, ModalConfirm} from "../../../../../utils/tool";
import {pagingParam} from '../../../../../utils/defaultValue'
import BackIcon from "../../../../../components/BackIcon/index";
import Iconfont from "../../../../../components/Iconfont/Iconfont";
import PolicyList from "../../../../../components/PolicyList";
import PolicyDataSource from '../../../components/PolicyDataSource'
import PolicyAnnotation from '../../../components/PolicyAnnotation'
import PolicyTree from "../../../../../components/PolicyTree";
import storage from "../../../../../utils/storage";

import "./index.scss";

const { Text, Link, Title } = Typography;
let policyThemeIDandName = {}
const GeneralPolicyList = ({
    
}) => {

    let expandedKeys = [];
    const isManage = getSearchParams().inlet === "manage";
    const [userInfo, setUserInfo] = useState(storage.getItem("userInfo") ? storage.getItem("userInfo") : {})
    const [addPolicyThemesForm] = Form.useForm();

    const [searchData, setSearchData] = useState({
        departmentId: isManage ? -1 : storage.getItem("userInfo").departmentId,
        ...pagingParam,
        policyRegionId: "",
    });

    const [listDate, setListDate] = useState([]);
    const [pagination, setPagination] = useState("");
    const [loadingType, setLoadingType] = useState(false);
    const [toEditFormData, setToEditFormData] = useState(null)
    const [open, setOpen] = useState(false);
    const [policyFromType, setPolicyFromType] = useState(null);


    // 分类树
    const [policyType, setPolicyType] = useState({});
    const [loadingTreeType, setLoadingTreeType] = useState(false);
    const [treeDate, setTreeDate] = useState("");
    const [treeName, setTreeName] = useState("");
    const [selectedTheme, setSelectedTheme] = useState(""); //当前选中的主题
    const [expandedKeyList, setExpandedKeyList] = useState([]);
    const [policyThemeType, setPolicyThemeType] = useState(""); // 0主题类型，1主题名称
    const [selectedNode, setSelectedNode] = useState(null); // 选中的操作节点
    const [modalTitle, setModalTitle] = useState(""); // 弹窗title

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [ConfirmLoading, setConfirmLoading] = useState(false);
    const [themeDetails, setThemeDetails] = useState({});

    useEffect(() => {
        getpolicyTree();
    }, []);

    useEffect(() => {
        if (searchData.policyLableThemeId) {
            sessionStorage.searchData = JSON.stringify(searchData)
            getPolicyList();
        }
    }, [JSON.stringify(searchData)])

    useEffect(() => {
        if (selectedTheme) {
            sessionStorage.selectedTheme = JSON.stringify(selectedTheme)
            let _searchData = {
                ...searchData,
                ...pagingParam,
                policyRegionId: "",
                "search": "",
                "startTime": "",
                "endTime": "",
                "code": "",
                "regionId": ""
            }
            _searchData.policyThemeId = selectedTheme.id;
            _searchData.policyLableThemeId = selectedTheme.policyLableThemeId;
            _searchData.policyKeyWords = selectedTheme.keywords;
            setSearchData(_searchData);
            setPagination("");
            setPolicyType({});
        }
    }, [JSON.stringify(selectedTheme)])

    const getpolicyTree = async () => {
        setLoadingTreeType(true);
        try {
            let res = await Api.Knowledge.getPolicyeThemeList({
                data: {
                    departmentId: isManage ? -1 : storage.getItem("userInfo").departmentId
                }
            });
            setLoadingTreeType(false);
            if (res && res.code === 0 && res.data) {
                setTreeDate(res.data)
                
                getPolicyeThemeName(res.data);

                getFirstPolicyeTheme(res.data);

                if(expandedKeys.length > 0){
                    setExpandedKeyList(expandedKeys)
                }

            }else{setTreeDate([])}
        } catch (errInfo) {
            setTreeDate([]);
            setLoadingTreeType(false);
            console.log('验证失败:', errInfo);
        }
    }

    const getPolicyList = async () => {
            setLoadingType(true);
            setListDate([])
    
            let _searchData = {...searchData};
    
            if (_searchData.regionId === 999) {
                delete _searchData.regionId
            }
            
            // if (policyTypeKey === '1') {
                // _searchData.policyThemeId = ""; // 标注管理没有分类
                // _searchData.startTime = defalutStartData ? defalutStartData : _searchData.startTime;
                // _searchData.serachUnAssigned = "1";
                // _searchData.searchReptileOnly = true;
            // }
            // if (policyTypeKey === '2') {
            //     _searchData.policyKeyWords = [] // 后端不要policyKeyWords 他要自己查
            // }
            
            try {
                let res = await Api.Knowledge.getPolicyPageList({
                    data: {
                        ..._searchData
                    }
                });
    
                setLoadingType(false);
                if (res && res.code === 0 && res.data.records) {
                    setListDate([...res.data.records])
                    setPagination({
                        "pageIndex": res.data.current,
                        "pageSize": res.data.size,
                        "total": res.data.total
                    })
                } else {
                    setPagination("")
                }
            } catch (errInfo) {
                setLoadingType(false);
                console.log('验证失败:', errInfo);
            }
        }

    // 获取所有的分类ID
    const getFirstPolicyeTheme = (data) => {
        data.map(item=>{
            if(item.children){

                expandedKeys.push(item.id);

                getFirstPolicyeTheme(item.children)
            }
        })
    }

    const getPolicyeThemeName = (data,name) => {
        data.map(item=>{
            let _name = name ? name+'-'+item.policyName : item.policyName
            if(item.children&&item.children.length>0){
                getPolicyeThemeName(item.children,_name)
            }else{
                policyThemeIDandName[item.id] = _name
            }
        })
    }

    // 选中主题
    const changePolicyTree = (param) => {
        setSelectedTheme(param);
        console.log(param,policyThemeIDandName,"11111")
        if (!param) {
            // 取消选中的主题后重置查询数据
            let _searchData = {
                departmentId: isManage ? -1 : storage.getItem("userInfo").departmentId,
                ...pagingParam,
                policyRegionId: "",
                "search": "",
                "startTime": "",
                "endTime": "",
                "code": "",
                "regionId": ""
            }
            setSearchData(_searchData)
            setPolicyType({});
        }else{
            sessionStorage.themeTreeName = policyThemeIDandName[param.id]
            setTreeName(policyThemeIDandName[param.id])
        }
    }

    const menuClickFunc = (type, nodeData) => {
        setSelectedNode(nodeData); // 记录操作的节点

        switch (type) {
            case "addRootSort":
                setModalTitle("新增根分类");
                setPolicyThemeType(0);
                setIsEdit(false);
                setIsModalOpen(true);
                break;
            case "addSort":
                setModalTitle("新增子分类");
                setPolicyThemeType(0);
                setIsEdit(false);
                setIsModalOpen(true);
                break;
            case "addTheme":
                setModalTitle("新增主题");
                setPolicyThemeType(1);
                setIsEdit(false);
                setIsModalOpen(true);
                break;
            case "edit":
                const themeType = nodeData?.policyThemeType;
                const isTheme = String(themeType) === "1";
                setPolicyThemeType(themeType);
                setModalTitle(`编辑${ isTheme ? "主题" : "分类"}`);
                setIsEdit(true);

                addPolicyThemesForm.setFieldsValue({
                    "policyName": nodeData.policyName,
                    "sort": nodeData.sort,
                });

                if (isTheme) {
                    setKeyWordsList(nodeData.keywords ? nodeData.keywords : []);
                }
                setIsModalOpen(true);
                break;
            case "delete":
                deleteModal(nodeData);
                break;
        }
    }

    const deleteModal = async (nodeData) => {
        if (!nodeData) {
            message.success("请选择需要删除的政策类型");
            return false;
        }
        ModalConfirm({
            title: '你确定要删除该分类吗？',
            content: <Text type="danger">删除此分类后分类下所有数据都会被删除</Text>,
            onOk:async(resolve, reject)=> {
                try {
                    let res = await Api.Knowledge.deletePolicyTheme({
                        data: {
                            "id": nodeData.id
                        }
                    });
                    if (res && res.code === 0) {
                        resolve();
                        getpolicyTree();
                        message.success("删除成功");
                    }else{
                        reject();
                    }
                } catch (errInfo) {
                    reject();
                    console.log('删除失败:', errInfo);
                }
            },
        })
    }


    const handleCancel = () => {
        setIsModalOpen(false);
        setIsEdit(false);
        addPolicyThemesForm.resetFields();
    };
    const handleOk = async (state) => {
        const {
            isTheme, isEdit, selectedNode, addPolicyThemesForm,
            policyThemeType,
        } = state;
        setConfirmLoading(true);
        try {
            const row = await addPolicyThemesForm.validateFields();
            let tipText = "新增";
            let param = {
                ...row,
                policyThemeType,
                departmentId: isManage ? -1 : storage.getItem("userInfo").departmentId,
                parentId: selectedNode?.id || 0,
            }
            let func = Api.Knowledge.addPolicyTheme;

            // if (isTheme) {
            //     param.keywords = [...KeyWordsList];
            // }

            if (isEdit) {
                tipText = "修改";
                param.id = selectedNode.id;
                param.parentId = selectedNode.parentId;
                func = Api.Knowledge.updatePolicyTheme;
            }

            let res = await func({
                data: param
            });
            setConfirmLoading(false);
            if (res && res.code === 0) {

                if (isEdit && isTheme) {
                    // 修改成功后更新右侧的标题和关键词数据
                    setSelectedTheme({
                        ...selectedTheme,
                        // keywords: KeyWordsList,
                        "policyName": row.policyName,
                        "sort": row.sort,
                    })
                }

                addPolicyThemesForm.resetFields();
                getpolicyTree();
                handleCancel();

                message.success(tipText + "成功");
            } else {
                message.error(tipText + "失败");
            }
        } catch (errInfo) {
            setConfirmLoading(false);
            console.log('验证失败:', errInfo);
        }
    }

    const getDetails = () => {

    }


    // ----------------------------------

    const policyTypeChange = (param) => {
        setPolicyType({
            ...param,
        });
        delete param.name;

        let _searchData = {
            ...searchData,
            ...param
        }
        setSearchData(_searchData);
    }
    const paginationChange = (pageIndex, pageSize) => {
        let _searchData = {...searchData}
        _searchData.pageIndex = pageIndex;
        _searchData.pageSize = pageSize;
        setSearchData(_searchData);
    }
    // 政策类型下 - 时间和标题筛选
    const operationChange = (value) => {
            
        let _searchData = {
            ...searchData,
            ...value
        }
        setSearchData(_searchData)
    };




    const isTheme = String(policyThemeType) === "1";

    const state = {
        isTheme, isEdit, selectedNode, addPolicyThemesForm,
        policyThemeType
    };

    return (
        <div className="generalPolicyListFrame">
            <div className="contentBox">
                <div className='leftBox'>
                    <div className="leftHeadBox">
                        <span className="headTitleText">主题列表</span>
                        <div className="classificationList" style={{display: userInfo.departmentId ? "" : "none"}}>
                            <Tooltip title={"新增根分类"}>
                                <span
                                    className="operateBtn"
                                    onClick={() => menuClickFunc("addRootSort")}
                                ><Iconfont type={"icon-roundadd"} /></span>
                            </Tooltip>
                        </div>
                    </div>
                    <div className="policyTreeBox">
                        <Spin tip="数据加载中" spinning={loadingTreeType}>
                            <PolicyTree
                                treeDate={treeDate}
                                changePolicyTree={changePolicyTree}
                                selectedNode={selectedTheme}
                                menuClickFunc={menuClickFunc}
                                expandedKeyList={expandedKeyList}
                            />
                        </Spin>
                    </div>
                </div>

                <div className='centerBox'>
                    <div>{treeName}</div>
                    <div className="policyListBox">
                        <Spin tip="数据加载中" spinning={loadingType}>
                            <PolicyList
                                policyTypeChange={policyTypeChange}
                                listDate={listDate}
                                paginationChange={paginationChange}
                                pagination={pagination}
                                policyType={policyType}
                                searchData={searchData}
                                getPolicyList={getPolicyList}
                                setToEditFormData={setToEditFormData}
                                operationChange={operationChange}
                            />
                        </Spin>
                    </div>
                </div>
                
                <div className='rightBox'>
                    {selectedTheme?.policyLableThemeId&&<PolicyDataSource 
                        policyLableThemeId={selectedTheme?.policyLableThemeId} 
                        getDetails={getDetails}
                        getPolicyList={getPolicyList}
                    />}
                </div>
            </div>


            <Modal
                title={<div className='modalTitle'><ExclamationCircleOutlined />{modalTitle}</div>}
                open={isModalOpen}
                onOk={() => handleOk(state)}
                onCancel={handleCancel}
                centered={true}
                confirmLoading={ConfirmLoading}
                forceRender={true}
                wrapClassName={"addThemeModal"}
            >
                <Form
                    form={addPolicyThemesForm}
                    component={false}
                    labelCol={{span: 6}}
                    wrapperCol={{span: 18}}
                    preserve={false}
                >
                    <Form.Item
                        key={'policyName'}
                        name={'policyName'}
                        label={isTheme ? '政策主题名称' : '政策类型名称'}
                        rules={[{
                            required: true,
                            message: isTheme ? '请输入政策主题名称!' : '请输入政策类型名称!'
                        }]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        key={'sort'}
                        name={'sort'}
                        label={'排序'}
                        rules={[{required: true, message: `请输入排序!`}]}
                    >
                        <InputNumber min={0} style={{width: "100%"}}/>
                    </Form.Item>

                </Form>
            </Modal>
        </div>)
}

export default GeneralPolicyList;
