import React, {useState, useEffect, useRef} from 'react';
import { Space, Button, List, message, Input, Result, Spin } from "antd";
import { CaretDownOutlined } from '@ant-design/icons';


import Api from "../../api";
import Iconfont from "../../components/Iconfont/Iconfont";
import {getSearchParams} from '../../utils/tool'
import './index.scss';

const { TextArea } = Input;

const Examine = ({history}) => {

    // info
    const [resultType, setResultType] = useState("");
    const [checkId, setCheckId] = useState("");
    const [userInfo, setUserInfo] = useState("");

    const [checkListNum, setCheckListNum] = useState(0);
    
    
    const [spinType, setSpinType] = useState(false);

    const [checkMessage, setCheckMessage] = useState({});
    const [okLoading, setOkLoading] = useState(false);
    const [rejectLoading, setRejectLoading] = useState(false);

    const [isEdit, setIsEdit] = useState(false);
    const [checkOption, setCheckOption] = useState(null);
    const textareaRef = useRef(null);

    const [flowType, setFlowType] = useState("");

    useEffect(()=>{
        getCheckMessage();
    },[])

    useEffect(() => {
        if (isEdit) {
          textareaRef.current.focus();
        }
        // window.btoa(val.password)
    }, [isEdit]);

    const getCheckMessage = async () => {
        setSpinType(true);

        try {
            // userInfo是base64编码的，数据中带有=号，所以用常规的=分割数据会有问题
            // 采用截取的方式

            let param = location.search.split("&");
            let checkId = param[0].substring(9); //  ?checkId= 后所有的数据
            let userInfo = param[1].substring(9) // userInfo= 后所有的数据
            let flowType = param[2].substring(9) // flowType= 后所有的数据

            setCheckId(checkId);
            setUserInfo(userInfo);
            setFlowType(flowType)

            let res = null;
            if(String(flowType) === "1"){ //流程平台
                res = await Api.Common.getWorkflowDetailByCheckIdForQTPlatform({
                    data: { 
                        checkId: checkId,
                        userInfo: userInfo 
                    },
                });
            }else{
                res = await Api.Common.getWorkflowDetailByCheckIdForQT({
                    data: { 
                        checkId: checkId,
                        userInfo: userInfo 
                    },
                });
            }
          
            setSpinType(false);
            if (res && res.code === 0) {
    
                let approvalProcessUsers = [];
        
                res.data.workflowUsers.map(item=>{
                    let names = [], steps = {};
                    
                    item.map(items=>{
                        names.push(items.checkUserName)
                    })
        
                    steps = {
                        ...item[0],
                        names,
                    }
                    approvalProcessUsers.push(steps)
                })

                var departmentNamesArr = [...res.data.shareKnowledgeDepartmentNames];
                departmentNamesArr.sort(function (a, b) {
                    return b.length - a.length ; // 按照文本长度从大到小排序
                });
                res.data.shareKnowledgeDepartmentNames = departmentNamesArr

                res.data.approvalProcessUsers = [...approvalProcessUsers];
                setCheckMessage(res.data);
            }else if(res && res.code === 2){
                setResultType("info");
                getCheckMessageList(userInfo);
            }
        } catch (error) {
            console.log("报错了")
            setSpinType(false);
            setResultType("info");
        }
    };

    const getCheckMessageList = async (_userInfo) => {
        try {

            let res = await Api.Common.getCheckToDoMessageForQT({
                data: { 
                    userInfo: _userInfo?_userInfo:userInfo 
                },
            });
          
            if (res && res.code === 0) {
                setCheckListNum(res.data.length);
            }

        } catch (error) {
            console.log("报错了")
        }
    };

    const handleOk = async () => {
        let data = { 
          checkId: checkId, 
          checkOption:checkOption?checkOption:"同意", 
          type: 1 ,
          userInfo:userInfo
        };
          try {
            setOkLoading(true);

            let res = null;
            if(String(flowType) === "1"){ //流程平台
                data.checkType = data.type;
                res = await Api.Common.updateSkipForQT({ data });
            }else{
                res = await Api.Common.updateApplyTypeForQT({ data });
            }

            // updateSkipForQT
            if (res.data) {
                message.success("操作成功");
                // 获取未操作审批数量
                getCheckMessageList();
                setOkLoading(false);
                setIsEdit(false);
                setCheckOption("");
                // getCheckMessage();
                setCheckMessage({});
                setResultType("info");
                
            }else{
              setOkLoading(false);
            }
          } catch (error) {
            setOkLoading(false);
          }
      };

    const handleReject = async () => {
        let data = { 
            checkId: checkId, 
            checkOption, 
            type: 0,
            userInfo:userInfo
        };
        if (checkOption) {
          try {
            setRejectLoading(true);
            let res = null;
            if(String(flowType) === "1"){ //流程平台
                data.checkType = data.type;
                res = await Api.Common.updateSkipForQT({ data });
            }else{
                res = await Api.Common.updateApplyTypeForQT({ data });
            }
            if (res.data) {
                message.success("操作成功");
                // 获取未操作审批数量
                getCheckMessageList();
                setRejectLoading(false);
                setIsEdit(false);
                setCheckOption("");
                // getCheckMessage();
                setCheckMessage({});
                setResultType("info");
            }
          } catch (error) {
          }
        } else {
          message.info("请输入审批意见后,再进行审批哦");
        }
    };    

    // 上级审批人批签
    const superiorOpinionMessage = (item) => {
        return (
        <div className="opinionMessageS">
            <div className="checkOptionS">
                {item.checkOption}
            </div>
            <div className="optionInfoS">
                <span>{item.checkUserName}</span>
                <span>
                    {/* 批准于 */}
                    {item.updateTime}</span>
            </div>
        </div>);
    }

        // 当前审批人批签
    const opinionMessage = (
        <div className="opinionMessage">
        {isEdit ? (
            <TextArea
            ref={textareaRef}
            placeholder="请输入审批意见"
            showCount
            maxLength={100}
            style={{ width: "100%", height: "80%" }}
            value={checkOption}
            onChange={(e) => setCheckOption(e.target.value)}
            />
        ) : (
            <div
            onClick={() => {
                setIsEdit(true);
            }}
            >
            <Iconfont
                type={"icon-bianji"}
                iStyle={{ color: "#AAAAAA", fontSize: "1rem" }}
            ></Iconfont>
            请批签
            </div>
        )}
        </div>
    );

    const goApprovalList = () => {
        history.replace(`/examineList?userInfo=${userInfo}`);
    }

    return (
        <div className="examineFrame">
            <Spin  tip="加载中..." spinning={spinType}>
                {resultType ==="info" ? <>
                    <Result
                        status="success"
                        title="当前审批已完成"
                        extra={
                            checkListNum > 0 ? <div className="prompt">
                                <span>还有{checkListNum}条未处理的待办消息</span><br/>
                                <a onClick={goApprovalList} key="list-loadmore-edit">立即审批</a>
                            </div> : ""
                        }
                    />
                </> : 
                <div className="contentBox">
                    <div className="infoBox">
                        <div className="detailsTitle">申请人基本信息</div>
                        <div className="detailsItem">
                            <div className="title">姓名</div>
                            <div className="content">{checkMessage.userName}</div>
                        </div>

                        <div className="detailsItem">
                            <div className="title">工号</div>
                            <div className="content">{checkMessage.jobNum}</div>
                        </div>

                        <div className="detailsItem">
                            <div className="title">所在部门</div>
                            <div className="content">{checkMessage.userDepartmentName}</div>
                        </div>
                        <div className="detailsItem">
                            <div className="title">申请时间</div>
                            <div className="content">{checkMessage.createTime}</div>
                        </div>
                    </div>
                    
                    <div className="infoBox">
                        <div className="detailsTitle">申请详情</div>

                        <div className="detailsItem">
                            <div className="title">申请类型</div>
                            <div className="content">{
                                checkMessage.applyForType === 1 ? "下载" :
                                checkMessage.applyForType === 2 ? "发布知识" :
                                checkMessage.applyForType === 3 ? "取消发布知识" :
                                checkMessage.applyForType === 4 ? "阅读权限" : 
                                checkMessage.applyForType === 5 ? "更新文件" : 
                                checkMessage.applyForType === 6 ? "阅读重要文件" :
                                checkMessage.applyForType === 7 ? "下载重要文件" : ""
                            }</div>
                        </div>
                        <div className="detailsItem">
                            <div className="title">知识名称</div>
                            <div className="content">
                                {checkMessage.knowledgeName}
                                {/* <Button type="primary" size={'small'} onClick={()=>{
                                    checkStatus(checkMessage)
                                }}>查看</Button> */}
                            </div>
                        </div>
                        <div className="detailsItem">
                            <div className="title">知识类型</div>
                            <div className="content">{checkMessage.knowledgeTypeName}</div>
                        </div>
                        <div className="detailsItem">
                            <div className="title">分享范围</div>
                            <div className="content">{checkMessage.shareKnowledgeDepartmentNames&&checkMessage.shareKnowledgeDepartmentNames.map(item=>{
                                return <span className="shareDepartmentNames">{item}</span>
                            })}</div>
                        </div>
                    </div>

                    <div className="infoBox">
                        <div className="detailsTitle">流转意见</div>

                        <List itemLayout="horizontal">
                            {checkMessage.approvalProcessUsers&&checkMessage.approvalProcessUsers.map(item=>{
                                return(<List.Item>
                                <List.Item.Meta
                                    avatar={
                                        <div className="avatar">
                                            <div className={`currentStatus names`} title={item.names.join("/")}>
                                                {item.names.join("/")}
                                            </div>

                                            {item.checkType === 1 &&
                                                <div className="arrowIcon"
                                                    style={{borderColor:"#1890ff"}}
                                                >
                                                    <CaretDownOutlined
                                                        style={{color:"#1890ff"}}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    }
                                    title={
                                        <div className="userDepartmentInfo">
                                            <span>
                                            {item.departmentName}
                                            </span>
                                            <span>{item.businessRoleName}</span>
                                        </div>
                                    }
                                    description={item.checkType === 1?superiorOpinionMessage(item):opinionMessage}
                                />
                                </List.Item>)
                            })
                            }
                        </List>
                    </div>
                </div>}
            </Spin>
            {JSON.stringify(checkMessage) != "{}" && resultType !="info" &&<div className="footerBox">
                <Space>
                    <Button danger onClick={handleReject} loading={rejectLoading}>拒绝</Button>
                    <Button type="primary" onClick={handleOk} loading={okLoading}>同意</Button>
                </Space>
            </div>}
        </div>
    );
}

export default Examine;