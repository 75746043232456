import React,{ useState, useEffect } from 'react';
import {useHistory} from "react-router-dom";
import {Table, Input, Typography, Space, message, Spin, Tooltip, Modal, Popconfirm, Button, Popover, Empty, Dropdown} from 'antd';
import {
    InfoCircleOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";

import Api from "../../api/index";
import SelfTable from "../SelfTable/index"
import { getSizeText } from '../../utils/tool'
import KnowledgeSearch from '../../pages/knowledgeCentre/knowledgeSearch/index'
import './index.scss';

const { Search } = Input;
const Personalknowledge = ({open, cancelModal, addKnowledgeF, defaultValue, singleChoice, dataType}) => {
   
    const history = useHistory();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fullScreen, setFullScreen] = useState(false);

    const publicKnowledge = useSelector(state => state.AIModal.publicKnowledge);
    
    useEffect(() => {
        setIsModalOpen(open)
    }, [open])


    const handleCancel = () => {
        setIsModalOpen(false);
        cancelModal();
    };

    const addPublicKnowledge = (knowledgeIds) => {
        addKnowledgeF(knowledgeIds);
        handleCancel();
    }
    
    const onAddCarBtn = (idList) => {
        if(singleChoice && dataType === 'policy'){
            if(idList > 0){
                history.push(`/page/policySignDetail?policyId=${idList[0]}`);
            }else{
                message.info('请选择需要标注的知识')
            }
        }else{
            console.log(publicKnowledge,"publicCheckedListpublicCheckedListpublicCheckedList")
            const {publicCheckedList, publicPageIndex} = publicKnowledge;
            if(publicCheckedList[publicPageIndex]?.length > 0){
                addPublicKnowledge(publicCheckedList[publicPageIndex]);
            }else{
                message.info('请选择需要添加的知识')
            }
        }
    }

    return (
        <div>
            <Modal 
                centered={true} 
                open={isModalOpen} 
                footer={null}
                width={1200}
                wrapClassName={`publicknowledgeModal ${fullScreen?'fullScreen':''}` }
                onCancel={handleCancel}
            >
                <div className='headerTitle'>
                    <span className='titleIcon'><InfoCircleOutlined />添加知识</span>

                    <div className='fullScreenIcon'>
                        {fullScreen?<Tooltip 
                            placement={"top"} 
                            title={"缩小"}
                        >
                            <FullscreenExitOutlined onClick={()=>{setFullScreen(false)}}/>
                        </Tooltip>:
                        <Tooltip 
                            placement={"top"} 
                            title={"全屏"}
                        >
                            <FullscreenOutlined onClick={()=>{setFullScreen(true)}}/>
                        </Tooltip>}
                    </div>
                    
                    
                    
                    {/* <Button 
                        className='addCarBtn'
                        onClick={onAddCarBtn} 
                        type="primary" 
                    >
                            确定添加
                    </Button> */}
                </div>
                <div className='content' style={{height:600}}>
                    <KnowledgeSearch 
                        pageType={"car"} 
                        addPublicKnowledge={addPublicKnowledge}
                        defaultValue={defaultValue}
                        onAddCarBtn={onAddCarBtn}
                        singleChoice={singleChoice}
                        dataType={dataType}
                    />
                </div>
            </Modal>
        </div>
    )
};

export default Personalknowledge;