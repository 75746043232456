import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Popconfirm,
  Typography,
  message,
  Popover,
  Button,
  Modal,
  InputNumber,
} from "antd";
import { QuestionCircleTwoTone } from "@ant-design/icons";

import SelfTable from "../../../../components/SelfTable/index";
import Api from "../../../../api";
import "./index.scss";
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    typeof children[1] === "number" ? <InputNumber min={0} /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `请输入${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const ScoreSettingsTable = () => {
  const [form] = Form.useForm();
  const [formTable] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [coefficientList, setCoefficientList] = useState([]);
  //  当前正在操作的数据KEY
  const [editingKey, setEditingKey] = useState("");
  const [tableLoading, setTableLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  // 分页
  const [total, setTotal] = useState(null);
  const [siftData, setSiftData] = useState({
    pageIndex: 1,
    pageSize: 10,
  });
  useEffect(() => {
    getUserScoreConvertCoefficientList();
  }, [siftData]);
  const getUserScoreConvertCoefficientList = async () => {
    try {
      setTableLoading(true);
      let res = await Api.Points.getUserScoreConvertCoefficient({
        data: siftData,
      });
      if (res.data && res.code === 0) {
        setCoefficientList(res.data?.records);
        setTotal(res.data?.total);
        setEditingKey("");
        setTableLoading(false);
      }
    } catch (error) {}
  };
  const isEditing = (record) => record.id === editingKey;
  // 修改
  const edit = (records) => {
    // 回填数据
    formTable.setFieldsValue({
      ...records,
    });
    setEditingKey(records.id);
  };
  // 取消
  const cancel = () => {
    setEditingKey("");
  };
  // 修改-保存
  const save = async (records) => {
    const row = await formTable.validateFields();
    setTableLoading(true);
    row.id = records.id;
    try {
      const res = await Api.Points.userScoreConvertCoefficientUpdate({
        data: row,
      });
      if (res && res.code === 0) {
        message.success("修改成功", 2, () => {
          getUserScoreConvertCoefficientList();
        });
      } else {
        setTableLoading(false);
      }
    } catch (errInfo) {
      setTableLoading(false);
    }
  };
  const deleteRule = async (records) => {
    try {
      let res = await Api.Points.userScoreConvertCoefficientDeleteById({
        data: { id: records.id },
      });
      if (res.data) {
        let _siftData = { ...siftData };
        _siftData.pageIndex = 1;
        setSiftData(_siftData);
      }
    } catch (error) {}
  };
  const columns = [
    {
      title: "规则名称",
      dataIndex: "ruleName",
      editable: true,
    },
    {
      title: "作者人数下限(名)",
      dataIndex: "numLower",
      editable: true,
    },
    {
      title: "作者人数上限(名)(不含)",
      dataIndex: "numHigher",
      editable: true,
    },
    {
      title: "折算系数",
      dataIndex: "convertCoefficient",
      editable: true,
    },
    {
      title: "操作",
      render: (_, records) => {
        const editable = isEditing(records);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(records)}
              style={{
                marginRight: 8,
              }}
            >
              保存
            </Typography.Link>
            <Popconfirm title="取消后数据将不会保存" onConfirm={cancel}>
              <a>取消</a>
            </Popconfirm>
          </span>
        ) : (
          <div className="tablePro">
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(records)}
            >
              调整
            </Typography.Link>
            <Popconfirm
              title="确认删除该条规则吗？"
              onConfirm={() => deleteRule(records)}
            >
              <a>删除</a>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const ScoreSettingsContent = (
    <ol style={{ padding: "1rem 0 1rem 1rem" }}>
      <li>
        某篇知识的具体作者能够分得的积分计算方式为该知识的总价值乘以折算系数，再除以作者人数
      </li>
    </ol>
  );
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setConfirmLoading(true);
    form.submit();
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const onFinish = async (values) => {
    // console.log(111111, "表单数据");
    try {
      let res = await Api.Points.userScoreConvertCoefficientInsert({
        data: values,
      });
      // console.log(res, "新增结果");
      if (res && res.code == 0) {
        getUserScoreConvertCoefficientList();
        setOpen(false);
      }
      setConfirmLoading(false);
    } catch (error) {
      setConfirmLoading(false);
    }
  };
  const onChange = (page, pageSize) => {
    let _siftData = { ...siftData };
    _siftData.pageIndex = page;
    _siftData.pageSize = pageSize;
    setSiftData(_siftData);
  };
  return (
    <div className="scoreSettingsFrame">
      <div className="tableTitleSmall tableTitleAdd">
        <div className="title">
          <span>用户得分折算系数设置</span>
          <Popover
            placement="rightTop"
            title={"规则说明"}
            content={ScoreSettingsContent}
            trigger="click"
          >
            <QuestionCircleTwoTone twoToneColor="#FFD700" />
          </Popover>
        </div>
        <div className="addRule">
          <Button type="primary" onClick={showModal}>
            新增规则
          </Button>
          <Modal
            title="新增规则"
            open={open}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
          >
            <Form
              labelCol={{
                span: 6,
              }}
              wrapperCol={{
                span: 16,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
              form={form}
            >
              <Form.Item
                label="规则名称"
                name="ruleName"
                rules={[
                  {
                    required: true,
                    message: "请输入新的规则名称!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="折算系数"
                name="convertCoefficient"
                rules={[
                  {
                    required: true,
                    message: "请输入折算系数!",
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>

              <Form.Item
                label="作者人数上限"
                name="numHigher"
                rules={[
                  {
                    required: true,
                    message: "请输入作者人数上限!",
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
              <Form.Item
                label="作者人数下限"
                name="numLower"
                rules={[
                  {
                    required: true,
                    message: "请输入作者人数下限!",
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
            </Form>
          </Modal>
        </div>
      </div>
      <Form form={formTable} component={false}>
        <SelfTable
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          columns={columns}
          dataSource={coefficientList}
          isEditing={isEditing}
          loading={tableLoading}
          pagination={{ ...siftData, total: total, onChange: onChange }}
          footerHeight={55}
        ></SelfTable>
      </Form>
    </div>
  );
};

export default ScoreSettingsTable;
