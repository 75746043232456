/*
    * 政策
*/ 
import myAxios from '../utils/request.js';

export default {
    
    // 获取主题列表
    async getThemeList (params={}, cb) {
        let res = await myAxios.getJson('/policyLableTheme/listTheme', params);
        return res.data;
    },
    // 新增政策标注主题
    async addPolicyLableTheme (params={}, cb) {
        let res = await myAxios.postJson('/policyLableTheme/addPolicyLableTheme', params);
        return res.data;
    },
    // 修改政策标注主题
    async editPolicyLableTheme (params={}, cb) {
        let res = await myAxios.postJson('/policyLableTheme/editPolicyLableTheme', params);
        return res.data;
    },
    // 根据政策标注主题ID查询详情
    async getThemeDeatail (params={}, cb) {
        let res = await myAxios.getJson('/policyLableTheme/deatail', params);
        return res.data;
    },

    // 根据政策标注主题ID获取数据来源信息
    // 数据源设置 - 数据源
    async getPageSource (params={}, cb) {
        let res = await myAxios.postJson('/policyLableTheme/pageSource', params);
        return res.data;
    },
    // 设置政策标注主题的数据来源
    async setSourceRule (params={}, cb) {
        let res = await myAxios.postJson('/policyLableTheme/setSourceRule', params);
        return res.data;
    },
    

    /*
    * 屏蔽词
    */ 

     // 查讯
     async getShieldWordsPageList (params={}, cb) {
        let res = await myAxios.postJson('/policyLableThemeShieldword/pageList', params);
        return res.data;
    },
    // 新增关键词
    async addShieldWordsPage (params={}, cb) {
        let res = await myAxios.postJson('/policyLableThemeShieldword/add', params);
        return res.data;
    },
    // 修改
    async editShieldWordsPage (params={}, cb) {
        let res = await myAxios.postJson('/policyLableThemeShieldword/edit', params);
        return res.data;
    },
    // 删除
    async deleteShieldWordsPage (params={}, cb) {
        let res = await myAxios.postJson('/policyLableThemeShieldword/delete', params);
        return res.data;
    },

    // 根据政策标注主题ID查询屏蔽词是否作用于正文
    async getShieldWordsRule (params={}, cb) {
        let res = await myAxios.getJson('/policyLableTheme/getShieldWordsRule', params);
        return res.data;
    },
    // 设置政策标注主题的屏蔽词是否作用于正文
    async setShieldWordsRule (params={}, cb) {
        let res = await myAxios.postJson('/policyLableTheme/setShieldWordsRule', params);
        return res.data;
    },

     // 导入
     async exportShieldWordsRule (params={}, cb) {
        let res = await myAxios.axiosFunc({
            url: "/policyLableThemeShieldword/export",
            method: "post",
            headers: {
                //可加可不加
                "Content-Type": "multipart/form-data;",
            },
            ...params,
        });
        return res.data;
    },
    // 导入系统屏蔽词
    async exportShieldBySystem (params={}, cb) {
        let res = await myAxios.getJson('/policyLableThemeShieldword/exportBySystem', params);
        return res.data;
    },
    


    /*
    *  关键词
    */ 
    // 查讯
    async getKeyWordsPageList (params={}, cb) {
        let res = await myAxios.postJson('/policyLableThemeKeyword/pageList', params);
        return res.data;
    },
    // 新增关键词
    async addKeyWordsPage (params={}, cb) {
        let res = await myAxios.postJson('/policyLableThemeKeyword/add', params);
        return res.data;
    },
    // 修改
    async editKeyWordsPage (params={}, cb) {
        let res = await myAxios.postJson('/policyLableThemeKeyword/edit', params);
        return res.data;
    },
    // 删除
    async deleteKeyWordsPage (params={}, cb) {
        let res = await myAxios.postJson('/policyLableThemeKeyword/delete', params);
        return res.data;
    },
    
    // 根据政策标注主题ID查询关键词是否作用于正文
    async getKeyWordsRule (params={}, cb) {
        let res = await myAxios.getJson('/policyLableTheme/getKeyWordsRule', params);
        return res.data;
    },

    // 设置政标注策主题的关键词是否作用于正文
    async setKeyWordsRule (params={}, cb) {
        let res = await myAxios.postJson('/policyLableTheme/setKeyWordsRule', params);
        return res.data;
    },
    // 导入
    async exportWordsRule (params={}, cb) {
        let res = await myAxios.axiosFunc({
            url: "/policyLableThemeKeyword/export",
            method: "post",
            headers: {
                //可加可不加
                "Content-Type": "multipart/form-data;",
            },
            ...params,
        });
        return res.data;
    },


    
    /*
    *  时间
    */ 
    // 根据政策标注主题ID查询时间段
    async getTimeRule (params={}, cb) {
        let res = await myAxios.getJson('/policyLableTheme/getTimeRule', params);
        return res.data;
    },
   
    // 设置政策标注主题的数据源时间
    async setTimeRule (params={}, cb) {
        let res = await myAxios.postJson('/policyLableTheme/setTimeRule', params);
        return res.data;
    },


    /*
    *  标注团队
    */ 
    async getLableUserRule (params={}, cb) {
        let res = await myAxios.getJson('/policyLableTheme/getLableUserRule', params);
        return res.data;
    },
    
    // 设置政策标注主题的标注团队
    async setLableUserRule (params={}, cb) {
        let res = await myAxios.postJson('/policyLableTheme/setLableUserRule', params);
        return res.data;
    },



    /*
    *  标注选项设置
    */ 

    // 根据政策标注主题ID查询字典
    async getSysDictTypeRule (params={}, cb) {
        let res = await myAxios.getJson('policyLableTheme/getSysDictTypeRule', params);
        return res.data;
    },

    // 设置政策标注主题的分类字典
    async setSysDictTypeRule (params={}, cb) {
        let res = await myAxios.postJson('/policyLableTheme/setSysDictTypeRule', params);
        return res.data;
    },

    // 根据政策标注主题id获取字典信息
    async selectDictByPolicyLableThemeId (params={}, cb) {
        let res = await myAxios.getJson('/policyLableTheme/selectDictByPolicyLableThemeId', params);
        return res.data;
    },
    



    // 政策标注
    async getPolicyLableThemeByLoginUser (params={}, cb) {
        let res = await myAxios.getJson('/policyCw/getPolicyLableThemeByLoginUser', params);
        return res.data;
    },
    
    // 根据政策标注主题id获取字典信息
    async selectDictByPolicyLableThemeId (params={}, cb) {
        let res = await myAxios.getJson('/policyLableTheme/selectDictByPolicyLableThemeId', params);
        return res.data;
    },

}