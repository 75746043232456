import React, {useState, useEffect, useRef} from 'react';
import {Typography, Tabs, Modal, Form, Input, InputNumber, Select, Button, message, Spin} from 'antd';
import { ExclamationCircleOutlined, MinusOutlined, PlusOutlined} from '@ant-design/icons';
import moment from 'moment';

import Api from "../../../../api/index";
import {pagingParam} from '../../../../utils/defaultValue'
import BackIcon from "../../../../components/BackIcon/index";
import ThemeCconfiguration from './themeCconfiguration/index';
import PolicyAnnotationData from './policyAnnotationData/index';
import GeneralPolicyList from './generalPolicyList/index';

import "./index.scss";

const { TextArea } = Input;
const GeneralPolicy = () => {
    
   
    const typeItems = [
        { label: '政策标注管理', key: '1', children: '' }, // 务必填写 key
        { label: '通用政策运维', key: '2', children: '' },
    ];

    const [policyTypeKey, setPolicyTypeKey] = useState('1');

    // 新增主题
    const [addPolicyThemesForm] = Form.useForm();
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [departmentData, setDepartmentData] = useState([]);
    const [themeDetails, setThemeDetails] = useState({});
    const [showThemeDetails, setShowThemeDetails] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false)
    // 主题列表
    const [themeList , setThemeList] = useState([]);
    const [serachValue, setSerachValue] = useState("");
    const [themeSpinning, setThemeSpinning] = useState(false);
    // 主题详情
    const [refreshThemedetails, setRefreshThemedetails] = useState(false);

    
    useEffect(()=>{
        getThemeList();
    },[])

    // tab切换
    const onChangePolicyTypeKey = (value) =>{
        setPolicyTypeKey(value);
    }

    // 获取主题列表
    const getThemeList = async() => {
        try{
            setThemeSpinning(true);
            const res = await Api.Policy.getThemeList({data:{themeName:serachValue}});
            setThemeSpinning(false);
            if(res && res.code ===0 && res.data){
                setThemeList(res.data);
            }else{
                setThemeList([]);
            }
        }catch(err){
            setThemeList([]);
            setThemeSpinning(false);
        }
    }
    // 新增/修改主题 - 弹窗
    const changeModalOpen = (type,detail) => {
        getDepartmentList();
        setAddModalOpen(type);
        if(detail){
            addPolicyThemesForm.setFieldsValue({
                ...detail,
            });
            setThemeDetails(detail);
        }
    }
    // 新增/修改弹窗 - 获取部门列表
    const getDepartmentList = async() => {
        const resDepartment = await Api.Department.getDepartmentListAll({data:{
            knowledgeRel:0
        }});
        if(resDepartment&&resDepartment.code === 0){
            setDepartmentData(resDepartment.data);
        }
    }
    // 新增/修改 - 确认
    const handleAddThemeOk = async() => {
        try{
            setConfirmLoading(true);
            let res = null;
            const row = await addPolicyThemesForm.validateFields();
            if(themeDetails&&themeDetails.id){
                res = await Api.Policy.editPolicyLableTheme({data:{
                    ...row,
                    id: themeDetails.id
                }});
            }else{
                res = await Api.Policy.addPolicyLableTheme({data:{
                    ...row
                }});
            }
            if(res.code === 0){
                message.success(res.msg,2,()=>{
                    setConfirmLoading(false);
                    if(themeDetails&&themeDetails.id){
                        setRefreshThemedetails(Date.now().toString());
                    }else{
                        getThemeList();
                    }
                    handleAddThemeCancel();
                })
            }
        }catch(err){
            setConfirmLoading(false);
        }
    }
    // 新增/修改 - 修改
    const handleAddThemeCancel = () => {
        setAddModalOpen(false);
        addPolicyThemesForm.resetFields();
    }
    // 序号
    const minusSortF = () =>{
        let sort = addPolicyThemesForm.getFieldValue("sort");
        if(sort && (sort > 0)){
            sort = sort - 1;
            addPolicyThemesForm.setFieldValue("sort", sort)
        }
    }
    const plusSortF = () =>{
        let sort = addPolicyThemesForm.getFieldValue("sort") || 0;
        sort = sort + 1;
        addPolicyThemesForm.setFieldValue("sort", sort)
    }
    // 编辑主题详情
    const goDetailsPage = (detail) => {
        setShowThemeDetails(true);
        setThemeDetails(detail);
    }


    const goBack = () => {
        setShowThemeDetails(false);
        setThemeDetails({});
        setRefreshThemedetails(false);
    }

    return (
        <div className="generalPolicyFrame">
            
            {!showThemeDetails&&<div className='policyThemeListBox'>
                <div className="topTabs">
                    <Tabs items={typeItems} activeKey={policyTypeKey} onChange={onChangePolicyTypeKey} />
                    <BackIcon /> 
                </div>

                {/* 政策主题列表 */}
                {policyTypeKey === '1'&&<div className='policyThemeList'>
                    <Spin spinning={themeSpinning}>
                        <ThemeCconfiguration 
                            changeModalOpen={changeModalOpen}
                            getThemeList={getThemeList}
                            themeList={themeList}
                            setSerachValue={setSerachValue}
                            serachValue={serachValue}
                            goDetailsPage={goDetailsPage}
                        />
                    </Spin>
                </div>}

                {/* 通用政策运维 */}
                {policyTypeKey === '2'&&<GeneralPolicyList/>}
            </div>}

            {/* 政策主题详情 */}
            {showThemeDetails && policyTypeKey === '1' &&<PolicyAnnotationData
                goBack={goBack}
                changeModalOpen={changeModalOpen}
                themeDetails={themeDetails}
                refreshThemedetails={refreshThemedetails}
            />}

            
            

            <Modal
                title={<div className='modalTitle'><ExclamationCircleOutlined />新增主题</div>}
                open={addModalOpen} 
                onOk={handleAddThemeOk} 
                onCancel={handleAddThemeCancel}
                wrapClassName="addThemeModal"
                confirmLoading={confirmLoading}
            >
                <Form
                    form={addPolicyThemesForm}
                    component={false}
                    labelCol={{span: 4}}
                    wrapperCol={{span: 20}}
                    preserve={false}
                >
                    <Form.Item
                        key={'themeName'}
                        name={'themeName'}
                        label={'主题名称'}
                        rules={[{
                            required: true,
                            message: '请输入主题名称!'
                        }]}
                        extra="*主题名称将在知识中心政策列表中显示"
                    >
                        <Input/>
                    </Form.Item>
                    
                    <Form.Item
                        key={'themeIntro'}
                        name={'themeIntro'}
                        label={'主题简介'}
                        rules={[{
                            required: true,
                            message: '请输入主题名称!'
                        }]}
                    >
                        <TextArea/>
                    </Form.Item>
                    <Form.Item
                        key={'departmentIdList'}
                        name={'departmentIdList'}
                        label={'展示位置'}
                        rules={[{
                            required: true,
                            message: '请选择展示位置!'
                        }]}
                    >
                        <Select
                            style={{ width: "100%" }}
                            fieldNames = {{ label: "name", value: "id"}}
                            options={departmentData}
                            mode="multiple"
                        />
                    </Form.Item>
                    <Form.Item
                        key={'sort'}
                        name={'sort'}
                        label={'排序'}
                        rules={[{required: true, message: `请输入排序!`}]}
                    >
                        <InputNumber 
                            min={0} 
                            style={{width: "100%"}}
                            addonBefore={<Button onClick={minusSortF} icon={<MinusOutlined />} />}
                            addonAfter={<Button onClick={plusSortF} icon={<PlusOutlined />} />}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </div>)
}

export default GeneralPolicy;
