import React, { useEffect, useState, useRef, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Form, message, Space, Button } from "antd";
import moment from "moment";

import AddKnowledgeForm from "../components/addKnowledgeForm/AddKnowledgeForm";
import UEditor from "../../../components/Ueditor/Ueditor";
import { ModalConfirm, getSearchParams } from "../../../utils/tool";
import storage from "../../../utils/storage";
import Api from "../../../api";
import "./index.scss";

// const { TextArea } = Input;
let time = null;
let _second = 0;

const OnLineWrite = () => {
  const ueRef = useRef(null);
  const parser = new DOMParser();
  const history = useHistory();
  const location = getSearchParams();
  // const location = useLocation().state;
  // console.log(location, "history location");
  // console.log(getSearchParams(), "获取到的路由参数");
  const [form] = Form.useForm();
  const userinfo = storage.getItem("userInfo");
  const tabsList = [
    { id: 1, value: 1, title: "基本信息" },
    // { id: 2, value: 2, title: "目录结构" },
  ];
  // 左侧tab切换下标
  const [tabKey, setTabKey] = useState(1);
  // 文章标题
  const [addKnowledgeTitle, setAddKnowledgeTitle] = useState(null);
  // 摘要
  const [knowledgeDigest, setKnowledgeDigest] = useState("");
  // 所有用户
  const [userList, setUserList] = useState([]);
  // 作者列表
  const [selectOptions, setSelectOptions] = useState([]);
  const [userSelectTags, setUserSelectTags] = useState([]);

  // 文档标签
  const [tags, setTags] = useState([]);
  // 判断点击的是发布还是存草稿
  const [type, setType] = useState();
  // 目录
  const [wordMenu, setWordMenu] = useState([]);
  const [usEditor, setUsEditor] = useState(null);
  // 带标签的富文本内容
  const [ueContent, setUeContent] = useState("");
  // 不带标签的富文本内容
  const [ueContentTxt, setUeContentTxt] = useState("");
  //编辑器的宽度
  const [config, setConfig] = useState({
    // initialFrameWidth: "100%",
    // initialFrameHeight: 450,
  });
  //编辑器的默认值
  const [initData, setInitData] = useState(null);
  // 提示弹框
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [modalMessage, setModalMessage] = useState();
  // 点击发布/保存--加载中
  // const [confirmLoading, setConfirmLoading] = useState(false);
  // const [digestValue, setDigestValue] = useState();
  const [formData, setFormData] = useState({});
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  // 知识详情信息
  const [knowledgeDetail, setKnowledgeDetail] = useState({});
  // 所有项目
  const [projectNameList, setProjectNameList] = useState([]);
  const [isAdd, setIsAdd] = useState(false);
  // const [isAddKnowledgeTitle, setIsAddKnowledgeTitle] = useState(true);
  // 按钮加载中状态
  const [saveLoading, setSaveLoading] = useState(false);
  const [publishLoading, setPublishLoading] = useState(false);

  useEffect(() => {
    getUserAll();
    getKnowledgeProject();
    return () => {
      window.UE.delEditor("editor");
    };
  }, []);
  useEffect(() => {
    window.addEventListener("beforeunload", (ev) => {
      ev.returnValue = "你确定离开此页面吗?";
    });
  },[])
  useEffect(() => {
    if (usEditor && initData) {
      usEditor.setContent(initData);
    }
  }, [usEditor, initData]);

  useEffect(() => {
    if (location?.knowledgeId) {
      getKnowledgeDetail(location?.knowledgeId);
    } else {
      setTimeout(() => {
        setIsAdd(true);
      }, 1000);
    }
    if (location?.type === "view") {
      getKnowledgeDetail(location?.knowledgeId);
      setIsFormDisabled(true);
      setConfig({
        ...config,
        readonly: true,
        toolbars:[]
      });
    }else{
      memoizedTimerFun();
    }

    return ()=>{
      clearInterval(time);
      time = null;
    }
  }, []);
  // 获取所有用户
  const getUserAll = async () => {
    let res = await Api.User.getUserListAll();
    setUserList(res.data);
  };

  const memoizedTimerFun = useMemo(() => () => {
    if(!time){
      
      time = setInterval(()=>{
        _second = _second + 1;
      },1000)
    }
  }, []);
  // useEffect(() => {
  //   let allValues = form.getFieldsValue();
  //   formDataChange({}, allValues);
  // }, [
  //   form,
  //   initData,
  //   wordMenu,
  //   ueContent,
  //   ueContentTxt,
  //   addKnowledgeTitle,
  //   tags,
  //   selectOptions,
  //   userInfoList,
  //   knowledgeDigest,
  // ]);

  // // 表单变化时获取表单数据
  // const formDataChange = (changedValues, allValues) => {
  //   // console.log(allValues, "表单变化时");
  //   if (allValues.time) {
  //     allValues.time[0] = changeDate(allValues.time[0]);
  //     allValues.time[1] = changeDate(allValues.time[1]);
  //   }
  //   storage.setItem("knowledgeEdit", {
  //     allValues,
  //     wordMenu,
  //     ueContent,
  //     ueContentTxt,
  //     addKnowledgeTitle,
  //     tags,
  //     selectOptions,
  //     userInfoList,
  //     knowledgeDigest,
  //   });
  // };

  // 转化时间类型函数--用于回显表单数据--时间选择框
  const changeDate = (date) => {
    const newDate = new Date(date);
    const Year = newDate.getFullYear();
    const Month = newDate.getMonth();
    const Day = newDate.getDate();
    return moment(`${Year}-${Month + 1}-${Day}`);
  };
  const editorReady = (editor) => {
    setUsEditor(editor);
  };
  const getKnowledgeDetail = async (knowledgeId) => {
    try {
      let res = await Api.Knowledge.getKnowledgeDetailById({
        data: { knowledgeId },
      });
      if (res && res.code === 0 && res.data) {
        let _data = { ...res.data };
        let selectOptionsNew;
        let newUserIdList;
        let resList;
        setKnowledgeDetail(_data);
        
        if (_data.knowledgeUserRelVOS?.length > 0) {
          selectOptionsNew = _data.knowledgeUserRelVOS.map((item) => {
            return { userId: item.userId };
          });
          newUserIdList = _data.knowledgeUserRelVOS.map((item) => {
            return item.userId;
          });
          let _resList = [];
          newUserIdList.map((item) => {
            _data.knowledgeUserRelVOS.forEach((obj) => {
              if (obj.userId == item) {
                _resList.push({
                  userId: obj.userId,
                  userName: obj.userName,
                  departmentName: obj.departmentMessage,
                });
              }
            });
            return _resList;
          });
          resList = _resList;
        } else {
          selectOptionsNew = [];
          newUserIdList = [];
          resList = [];
        }
        let formData = {
          departmentId: _data.departmentId,
          departmentSubclassificationId: _data.departmentSubclassificationId,
          // isMust: _data.isMust,
          knowledgeTypeId: _data.knowledgeTypeId,
          shareDepartmentIds: _data.shareDepartmentIds,
          knowledgeUserRelDTOList: newUserIdList,
          knowledgeName: _data.knowledgeTitle,
          knowledgeDigest: _data.knowledgeDigest,
          knowledgeSource: _data.knowledgeSource,
          nameExts: _data.nameExts,
          isMark: _data.isMark == 1 ? true : false,
        };
        if (_data?.knowledgeTypeId == 2) {
          formData.projectId = _data.projectId;
          if(_data.projectStartTime && _data.projectEndTime){
            formData.time = [
              changeDate(_data.projectStartTime),
              changeDate(_data.projectEndTime),
            ];
          }
        }
        setFormData(formData);
        setUserSelectTags(resList);
        setInitData(_data.knowledgeMainBodyHtml);
        setUeContent(_data.knowledgeMainBodyHtml);
        setUeContentTxt(_data.knowledgeMainBody);
        // setAddKnowledgeTitle(_data.knowledgeTitle);
        setWordMenu(_data.knowledgeMenuStructureList);
        // setDigestValue(_data.knowledgeDigest);
        setTags(_data.knowledgeDocumentLabelList);
        // console.log(selectOptionsNew,"selectOptionsNew");
        setSelectOptions(selectOptionsNew);
        // setKnowledgeDigest(_data.knowledgeDigest);
        // history.replace("/page/onLineWrite");
      } else {
      }
    } catch {}
  };
  // 获取对应项目接口
  const getKnowledgeProject = async () => {
    let res = await Api.Knowledge.getProjectIdAndName({data:{}});
    setProjectNameList(res.data);
  };
  // const knowledgeDigestContent = () => {
  //   return (
  //     <TextArea
  //       placeholder="请输入摘要内容"
  //       bordered={false}
  //       onChange={(e) => digestChange(e.target.value)}
  //     ></TextArea>
  //   );
  // };
  //富文本就触发setContent函数设置表单的content内容
  const setContent = (e, txt) => {
    let num = 1;
    setUeContent(e);
    setUeContentTxt(txt);
    const doc = parser.parseFromString(e, "text/html");
    const headings = doc.querySelectorAll("h1, h2, h3");
    const newToc = Array.from(headings).map((heading, index) => ({
      level: parseInt(heading.tagName.replace("H", ""), 10),
      title: heading.textContent,
      id: index, // 每个标题已经有对应的唯一ID属性
      num: parseInt(heading.tagName.replace("H", ""), 10) === 1 ? num++ : null,
    }));
    setWordMenu(newToc);
  };
  const changeTabKey = (id) => {
    setTabKey(id);
  };
  // 添加摘要
  // const toAddKnowledgeDigest = () => {
  //   setIsModalOpen(true);
  //   setModalMessage(knowledgeDigestContent());
  // };
  // const digestChange = (value) => {
  //   setDigestValue(value);
  // };
  // const handleOk = () => {
  //   setIsModalOpen(false);
  //   setConfirmLoading(true);
  //   setKnowledgeDigest(digestValue);
  //   setConfirmLoading(false);
  // };
  // const handleCancel = () => {
  //   setIsModalOpen(false);
  // };
  const onFinish = (values) => {
    if (
      values &&
      // selectOptions?.length > 0 &&
      tags?.length > 0
    ) {
      if (!ueContentTxt) {
        message.info("请添加正文内容！");
        return false;
      }

      ModalConfirm({
        title: `${type === 0 ? "确认发布吗?" : "确认存入草稿箱?"}`,
        onOk: async (resolve, reject) => {
          if (type === 0) {
            setPublishLoading(true);
          } else {
            setSaveLoading(true);
          }
          if (values.knowledgeTypeId == "2" && values.time && values.time.length > 0) {
            values.projectStartTime = moment(values.time[0]._d).format(
              "YYYY-MM-DD"
            );
            values.projectEndTime = moment(values.time[1]._d).format(
              "YYYY-MM-DD"
            );
          }

           /* 6 参考资料；7 参考研报 时不选择作者，输入知识来源*/
          if(values.knowledgeTypeId != 6 && values.knowledgeTypeId != 7){
            values.knowledgeUserRelDTOList = selectOptions;
          }
          values.knowledgeDocumentLabel = tags;
          values.knowledgeTitle = values.knowledgeName;
          values.operateType = type;
          values.isMark = values.isMark ? 1 : 0;
          useApi(values);
          resolve();
        },
      });

    } else {
      // if (!addKnowledgeTitle) {
      //   setIsAddKnowledgeTitle(false);
      // }
      message.info(`请补充完整信息后再进行${type === 0 ? "发布" : "保存"}`);
    }

  };
  const onFinishField = () => {
    message.info(`请补充完整信息后再进行${type === 0 ? "发布" : "保存"}`);
    if (type === 0) {
      setPublishLoading(false);
    } else {
      setSaveLoading(false);
    }
    // }
  };
  // 接口
  const useApi = async (data) => {
    // 提交
    // if (!ueContentTxt) {
    //   if (type === 0) {
    //     setPublishLoading(false);
    //   } else {
    //     setSaveLoading(false);
    //   }
    //   message.info("请添加正文内容！");
    //   return false;
    // }
    let reqData = {
      fileType: "online",
      knowledgeCatalogStructureList: wordMenu,
      knowledgeMainBody: ueContentTxt,
      knowledgeMainBodyHtml: ueContent,
      // knowledgeName: addKnowledgeTitle,
      // knowledgeDigest: knowledgeDigest,
      operateType: type,
      operationDuration:_second/60
    };
    if (knowledgeDetail) {
      reqData.knowledgeManagementId = knowledgeDetail?.id;
    }
    // console.log({ ...data, ...reqData },"保存数据")
    // return false;
    
    try {
      let res = await Api.PersonalCenter.addNewKnowledge({
        data: { ...data, ...reqData },
      });
      if (res.data.bool) {
        if (type === 0) {
          setPublishLoading(false);
          message.success(res.data.message);
        } else {
          setSaveLoading(false);
          message.success("已存入草稿箱");
        }

        // 不需要审核的权限，直接发布成功，跳转已发布知识列表
        if(res.data.code === 1){
          history.push("/personalCenter/myUpload/published");
        }else{
          history.push("/personalCenter/myUpload/notPublished");
        }
        
      }
    } catch (error) {
      if (type === 0) {
        setPublishLoading(false);
      } else {
        setSaveLoading(false);
      }
    }
  };
  //点击保存/发布/添加摘要
  const saveNewKnowledge = (id) => {
    setType(id);
    form.submit();
  };

  // 点击目录编辑器内容滚动到对应位置
  const scrollToHeading = (level, id) => {
    let iframeDom = document.querySelector("#ueditor_0");
    const iframeDocument =
      iframeDom.contentDocument || iframeDom.contentWindow.document;
    let headingNodes = iframeDocument.querySelectorAll(`h${level}`);
    let list = wordMenu.filter((item) => item.level === level);
    let ind = list.findIndex((item) => item.id == id);
    const headingNode = headingNodes[ind];
    if (headingNode) {
      headingNode.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="onLineWriteFrame">
      {isFormDisabled ? null : (
        <div className="title">
          <h4>新增维基知识</h4>
          <div className="btn">
            <Space>
              <Button
                loading={saveLoading}
                onClick={() => {
                  saveNewKnowledge(2);
                }}
                type={"primary"}
              >
                保存
              </Button>
              <Button
                loading={publishLoading}
                onClick={() => {
                  saveNewKnowledge(0);
                }}
                type={"primary"}
              >
                发布
              </Button>
            </Space>
          </div>
        </div>
      )}
      <div className="content">
        <div className="info">
          <div className="tabs">
          基本信息
            {/* {tabsList.map((item) => {
              return (
                <span
                  className={item.id === tabKey ? "tabLight" : null}
                  key={item.id}
                  onClick={() => changeTabKey(item.id)}
                >
                  {item.title}
                </span>
              );
            })} */}
          </div>
          <div className="baseInfo">
            {tabKey === 1 ? (
              <div className="infoDetail">
                <AddKnowledgeForm
                  onFinish={onFinish}
                  form={form}
                  formData={formData}
                  selectOptions={selectOptions}
                  setSelectOptions={setSelectOptions}
                  tags={tags}
                  setTags={setTags}
                  pageType={2}
                  isFormDisabled={isFormDisabled}
                  projectNameList={projectNameList}
                  onFinishField={onFinishField}
                  userSelectTags={userSelectTags}
                  setUserSelectTags={setUserSelectTags}
                  userList={userList}
                  setUserList={setUserList}
                ></AddKnowledgeForm>
                <div className="creatInfo">
                  <p>
                    <span className="label">
                      创&nbsp;建&nbsp;者&nbsp;&nbsp;
                    </span>
                    <span>
                      {location?.knowledgeId
                        ? knowledgeDetail?.creator
                        : userinfo?.userName}
                    </span>
                  </p>
                  <p>
                    <span className="label">创建时间</span>
                    {location?.knowledgeId ? (
                      <span>{knowledgeDetail?.createDate}</span>
                    ) : (
                      <span>{moment().format("L")}</span>
                    )}
                  </p>
                </div>
              </div>
            ) : (
              <div className="directory">
                {wordMenu &&
                  wordMenu.map((item, index) => {
                    return (
                      <div
                        className={`title${item.level}`}
                        key={item.anchor}
                        onClick={() => {
                          scrollToHeading(item.level, item.id);
                        }}
                      >
                        {item.num && item.num}.{item.title}
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
        <div className="rightEdit">
          {/* <div className="editTopView"> */}
            {/* <div className="addKnowledgeTitle">
              <div className="label">
                <span
                  class="required"
                  style={{ color: "red", marginRight: "0.1rem" }}
                >
                  *
                </span>
                <label for="author">标题</label>
              </div>
              <div className="titleInp">
                <Input
                  disabled={isFormDisabled}
                  allowClear={true}
                  placeholder="请输入标题"
                  bordered={false}
                  value={addKnowledgeTitle}
                  onChange={(e) => {
                    setAddKnowledgeTitle(e.target.value),
                      e.target.value
                        ? setIsAddKnowledgeTitle(true)
                        : setIsAddKnowledgeTitle(false);
                  }}
                />
                {isAddKnowledgeTitle ? null : (
                  <span
                    style={{
                      color: "red",
                      fontSize: "0.7rem",
                      marginLeft: "0.6rem",
                    }}
                  >
                    请输入标题！
                  </span>
                )}
              </div>
            </div> */}
            {/* <div className="knowledgeDigest">
              <label for="author">摘要</label>
              <div className="knowledgeDigestInp">
                <Input
                  disabled={isFormDisabled}
                  allowClear={true}
                  placeholder="请输入摘要内容"
                  bordered={false}
                  value={knowledgeDigest}
                  onChange={(e) => {
                    // console.log(e.target.value,"e.target.value");
                    setKnowledgeDigest(e.target.value);
                  }}
                />
              </div>
            </div> */}
          {/* </div> */}
          <div className="detail">
            {initData || isAdd ? (
              <UEditor
                ref={ueRef}
                config={config}
                initData={initData}
                setContent={(e, txt) => setContent(e, txt)}
                editorReady={editorReady}
              ></UEditor>
            ) : ""}
            <input type="file" accept="image/gif,image/jpeg,image/png,image/jpg,image/bmp" id="diyimg" style={{ opacity: "0" }} />
          </div> 
        </div>
      </div>
      
    </div>
  );
};

export default OnLineWrite;
